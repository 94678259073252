import { useState } from "react"
import { useNavigate, useLocation, Link } from "react-router-dom"
import { Form, Button, Spinner, Card, Modal } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { t } from "@lingui/macro"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import useFetch from "common/hooks/useFetch"

export const INVALID_EMAIL_OR_PASSWORD = "INVALID_EMAIL_OR_PASSWORD"
const ACCOUNT_LOCKED = "ACCOUNT_LOCKED"
export const INVALID_PASSWORD_RESET_TOKEN = "INVALID_PASSWORD_RESET_TOKEN"
export const PASSWORD_DOES_NOT_MEET_REQUIREMENTS =
  "PASSWORD_DOES_NOT_MEET_REQUIREMENTS"
export const PASSWORD_ALREADY_USED = "PASSWORD_ALREADY_USED"
export const CONTACT_SUPPORT = "CONTACT_SUPPORT"

export default function LoginForm() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [authenticating, setAuthenticating] = useState(false)
  const [otpCode, setOtpCode] = useState("")
  const [otpRequired, setOtpRequired] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [showPassword, setShowPassword] = useState(false)
  const [passwordExpired, setPasswordExpired] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [showForgot, setShowForgot] = useState(false)
  const [forgotEmail, setForgotEmail] = useState("")
  const customFetch = useFetch()
  const updatePassword = () => {
    customFetch("/logins/reset_password", {
      method: "POST",
      body: {
        email,
        current_password: password,
        new_password: newPassword,
        password_confirmation: confirmNewPassword
      }
    }).then(response => {
      if (
        response.error === PASSWORD_ALREADY_USED ||
        response.error === PASSWORD_DOES_NOT_MEET_REQUIREMENTS
      ) {
        return toast.error(
          <Trans>
            New password must not match current password. New password must not
            contain any of the previous passwords
          </Trans>
        )
      }

      clearLoginForm()
      setPasswordExpired(false)
      if (response) {
        toast.success(
          <Trans>Password updated! Please log in with your new password</Trans>
        )
      }
    })
  }

  const closeModal = () => {
    setShowForgot(false)
    setForgotEmail("")
  }

  const passwordAtleast12Characters = newPassword?.length >= 12
  const passwordContains1Uppercase = /[A-Z]/.test(newPassword)
  const passwordContains1Lowercase = /[a-z]/.test(newPassword)
  const passwordContains1Number = /[0-9]/.test(newPassword)
  const passwordContains1Symbol = /[`!@#€$%^&*() _+\-={};':"|,.<>?~]/.test(
    newPassword
  )

  return (
    <div>
      <Modal size="md" centered onHide={closeModal} show={showForgot}>
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>Reset password</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="small text-muted mb-3">
            <Trans>
              Password reset instructions will be sent to the email address
              below.
            </Trans>
          </p>
          <Form
            onSubmit={event => {
              event.preventDefault()
              customFetch("/logins/forgot_password", {
                method: "POST",
                body: {
                  email: forgotEmail
                }
              })
                .then(response => {
                  toast.success(
                    <span>
                      <Trans>Instructions have been sent to</Trans>{" "}
                      <b>{forgotEmail}</b>
                    </span>
                  )
                })
                .finally(closeModal)
            }}
          >
            <Form.Group>
              <Form.Label>
                <Trans>Email</Trans>
              </Form.Label>
              <Form.Control
                className="mb-3"
                value={forgotEmail}
                type="email"
                onChange={event => setForgotEmail(event.target.value)}
                autoComplete="off"
                required
              />
            </Form.Group>
            <Form.Group className="text-end">
              <Button
                variant="outline-secondary"
                onClick={closeModal}
                style={{ borderColor: "transparent" }}
                className="float-start"
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button type="submit">
                <Trans>Confirm</Trans>
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      <Card
        style={{ maxWidth: "32rem", width: passwordExpired ? "auto" : "20rem" }}
      >
        <Card.Body>
          {passwordExpired ? (
            <div>
              <Card.Title>
                <Trans>Your password has expired</Trans>
              </Card.Title>
              <Card.Text className="mb-3">
                <Trans>Update your password below to continue.</Trans>
              </Card.Text>
              <Form>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <Trans>New password</Trans>
                  </Form.Label>
                  <Form.Control
                    autoComplete="new"
                    type={showPassword ? "text" : "password"}
                    spellCheck={false}
                    autoCorrect="false"
                    autoCapitalize="false"
                    value={newPassword}
                    onChange={event => setNewPassword(event.target.value)}
                  />
                  <Button
                    title={t`Show password`}
                    tabIndex={-1}
                    onClick={() => setShowPassword(!showPassword)}
                    variant="none"
                    style={{
                      position: "absolute",
                      right: 0,
                      marginTop: "-30px",
                      marginRight: "28px"
                    }}
                    className="p-0 border-0"
                  >
                    {newPassword &&
                      (showPassword ? (
                        <FontAwesomeIcon
                          className="opacity-25"
                          icon={faEyeSlash}
                        />
                      ) : (
                        <FontAwesomeIcon className="opacity-25" icon={faEye} />
                      ))}
                  </Button>
                  <Form.Text
                    as="p"
                    className="text-black-50 opacity-75 mt-1 lh-1"
                  >
                    <span
                      className={`${
                        passwordAtleast12Characters
                          ? "text-success"
                          : "text-danger"
                      } me-2 text-nowrap`}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        size="xs"
                        className="me-1"
                      />
                      <Trans>Minimum length 12</Trans>
                    </span>
                    <span
                      className={`${
                        passwordContains1Uppercase
                          ? "text-success"
                          : "text-danger"
                      } me-2 text-nowrap`}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        size="xs"
                        className="me-1"
                      />
                      <Trans>Contains 1 uppercase letter</Trans>
                    </span>
                    <span
                      className={`${
                        passwordContains1Lowercase
                          ? "text-success"
                          : "text-danger"
                      } me-2 text-nowrap`}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        size="xs"
                        className="me-1"
                      />
                      <Trans>Contains 1 lowercase letter</Trans>
                    </span>
                    <span
                      className={`${
                        passwordContains1Number ? "text-success" : "text-danger"
                      } me-2 text-nowrap`}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        size="xs"
                        className="me-1"
                      />
                      <Trans>Contains 1 number</Trans>
                    </span>
                    <span
                      className={`${
                        passwordContains1Symbol ? "text-success" : "text-danger"
                      } me-2 text-nowrap`}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        size="xs"
                        className="me-1"
                      />
                      <Trans>Contains 1 symbol</Trans>
                    </span>
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Trans>Confirm new password</Trans>
                  </Form.Label>
                  <Form.Control
                    autoComplete="new"
                    type={showPassword ? "text" : "password"}
                    spellCheck={false}
                    autoCorrect="false"
                    autoCapitalize="false"
                    value={confirmNewPassword}
                    onChange={event =>
                      setConfirmNewPassword(event.target.value)
                    }
                  />
                  <Button
                    title={t`Show password`}
                    tabIndex={-1}
                    onClick={() => setShowPassword(!showPassword)}
                    variant="none"
                    style={{
                      position: "absolute",
                      right: 0,
                      marginTop: "-30px",
                      marginRight: "28px"
                    }}
                    className="p-0 border-0"
                  >
                    {confirmNewPassword &&
                      (showPassword ? (
                        <FontAwesomeIcon
                          className="opacity-25"
                          icon={faEyeSlash}
                        />
                      ) : (
                        <FontAwesomeIcon className="opacity-25" icon={faEye} />
                      ))}
                  </Button>
                </Form.Group>
                <Button className="w-full" onClick={updatePassword}>
                  <Trans>Update</Trans>
                </Button>
              </Form>
            </div>
          ) : (
            <Form
              onSubmit={otpRequired ? twoFactorAuthLogin : login}
              autoComplete="on"
            >
              {!otpRequired && (
                <div>
                  <Form.Group>
                    <Form.Control
                      className="mb-2"
                      name="email"
                      placeholder={t`Email address`}
                      type="email"
                      onChange={event => setEmail(event.target.value)}
                      value={email}
                      disabled={authenticating}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="d-flex">
                    <Form.Control
                      className="mb-3"
                      type={showPassword ? "text" : "password"}
                      spellCheck={false}
                      autoCorrect="false"
                      autoCapitalize="false"
                      placeholder={t`Password`}
                      value={password}
                      onChange={event => setPassword(event.target.value)}
                      autoComplete="off"
                      disabled={authenticating}
                      required
                    />
                    <Button
                      title={t`Show password`}
                      tabIndex={-1}
                      onClick={() => setShowPassword(!showPassword)}
                      variant="none"
                      className="p-0 border-0 d-flex"
                    >
                      {password &&
                        (showPassword ? (
                          <FontAwesomeIcon
                            className="opacity-25"
                            style={{
                              position: "absolute",
                              right: 0,
                              marginTop: "10px",
                              marginRight: "24px"
                            }}
                            icon={faEyeSlash}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="opacity-25"
                            style={{
                              position: "absolute",
                              right: 0,
                              marginTop: "10px",
                              marginRight: "24px"
                            }}
                            icon={faEye}
                          />
                        ))}
                    </Button>
                  </Form.Group>
                </div>
              )}
              {otpRequired && (
                <Form.Group>
                  <Form.Label>
                    <Trans>Two-step verification</Trans>
                  </Form.Label>
                  <Form.Control
                    className="mb-3"
                    placeholder={t`PIN`}
                    value={otpCode}
                    onChange={event => setOtpCode(event.target.value)}
                    autoComplete="off"
                    disabled={authenticating}
                    required
                  />
                </Form.Group>
              )}
              <div className="d-grid gap-3">
                <Button
                  type="submit"
                  disabled={authenticating}
                  title={t`Log In`}
                >
                  {authenticating ? (
                    <Spinner
                      variant="light"
                      size="sm"
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <Trans>Log In</Trans>
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Card.Body>
      </Card>
      {passwordExpired ? null : (
        <div className="small d-flex justify-content-between  mt-3">
          <Link
            className="text-light opacity-50 text-decoration-none fw-medium"
            onClick={() => setShowForgot(true)}
          >
            <Trans>Forgot password</Trans>
          </Link>
          <Link
            className="text-light text-decoration-none fw-medium"
            to="/register"
            title={t`Sign up`}
          >
            <Trans>Create new account</Trans>
          </Link>
        </div>
      )}
    </div>
  )

  function clearLoginForm() {
    setEmail("")
    setPassword("")
    setOtpRequired("")
    setOtpCode("")
    setAuthenticating(false)
  }

  async function login(event) {
    event.preventDefault()
    setAuthenticating(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TIMBETER_API}/logins`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            credentials: {
              email: email,
              password: password
            }
          })
        }
      )

      const json = await response.json()

      if (json.error === INVALID_EMAIL_OR_PASSWORD) {
        toast.error(<Trans>Invalid email or password</Trans>)
        return clearLoginForm()
      }

      if (json.error === ACCOUNT_LOCKED) {
        toast.error(<Trans>Your account has been locked for 30 minutes</Trans>)
        return clearLoginForm()
      }

      if (json.error === CONTACT_SUPPORT) {
        toast.error(
          <Trans>
            This account does not belong to any organization. Please contact
            Timbeter support
          </Trans>
        )
        return clearLoginForm()
      }

      if (response.status === 400) {
        if (json.message === "password expired") {
          setPasswordExpired(true)
        }
        // clearLoginForm()
        // throw new Error(t`Invalid email or password`)
      } else if (response.status === 200) {
        if (!json.access_token) {
          clearLoginForm()
          setAuthenticating(false)
          return toast.error(<Trans>Something went wrong</Trans>)
        }

        localStorage.setItem("access_token", json.access_token)
        localStorage.setItem(
          "access_token_expires_at",
          (json.created_at + json.expires_in) * 1000
        )
        // set expiration
        navigate(
          location.state?.from?.pathname
            ? location.state.from.pathname +
                (location.state.from?.search ? location.state.from?.search : "")
            : "/",
          {
            replace: true
          }
        )
      } else if (response.status === 202) {
        setOtpRequired(true)
        setAuthenticating(false)
      } else {
        clearLoginForm()
        toast.error(<Trans>Login failed</Trans>)
      }
    } catch (error) {
      clearLoginForm()
      setAuthenticating(false)

      if (error.message === "Failed to fetch") {
        return toast.error(
          <Trans>Something went wrong. Please try again later</Trans>
        )
      }

      toast.error(error.message)
    }
  }

  function twoFactorAuthLogin(event) {
    event.preventDefault()

    window
      .fetch(`${process.env.REACT_APP_TIMBETER_API}/logins/otp`, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          credentials: {
            email: email,
            password: password,
            otp_code: otpCode
          }
        })
      })
      .then(async response => {
        if (response.status === 200) {
          const json = await response.json()

          if (!json.access_token) {
            clearLoginForm()
            setAuthenticating(false)
            return toast.error(<Trans>Something went wrong</Trans>)
          }

          localStorage.setItem("access_token", json.access_token)
          localStorage.setItem(
            "access_token_expires_at",
            (json.created_at + json.expires_in) * 1000
          )
          // set expiration
          navigate(location.state?.from?.pathname || "/", {
            replace: true
          })
        } else if (response.status === 401) {
          toast.error(<Trans>Login failed. Invalid OTP code</Trans>)
          clearLoginForm()
        }
      })
  }
}
