import { Button, Col, InputGroup, FormControl, Row } from "react-bootstrap"
import { t, Trans } from "@lingui/macro"
import {
  faEdit,
  faPlusCircle,
  faTrash,
  faHandPointer,
  faTimesCircle,
  faUndo,
  faUpDown
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  REFERENCE,
  LOGS,
  LOAD_LINES,
  MEASUREMENT_AREA,
  PLANKS,
  EDIT
} from "common/enums/constants"
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from "recoil"
import {
  featureEnabled,
  measurementAreaState,
  measurementEditorLoadsState,
  woodAssortmentsState,
  woodCullsState
} from "common/recoil/atoms"
import {
  measurementByIdAndTypeQuery,
  truckStoragesQuery
} from "common/recoil/selectors"
import { toast } from "react-toastify"
import useEditorState, {
  logsState,
  measurementEditorState,
  selectedLogState
} from "./useEditorState"
import { editorSavingChangesState, editorSettingsState, planksState } from "."
import ReferencePane from "./ReferencePane"
import {
  lastActionLogState,
  selectedLogAssortmentState,
  selectedLogCullState,
  selectedLogQrCodeState
} from "./Log"
import { useEffect } from "react"
import useFetch from "common/hooks/useFetch"
import useShapesActions from "./useShapesActions"
import { lastActionPlankState, selectedPlankState } from "./Plank"
import Select from "common/other/Select"
import { lastActionAreaState } from "./Polygon"

export default function EditMode() {
  const setEditorSavingChanges = useSetRecoilState(editorSavingChangesState)
  const [lastActionArea, setLastActionArea] =
    useRecoilState(lastActionAreaState)
  const [lastActionLog, setLastActionLog] = useRecoilState(lastActionLogState)
  const [lastActionPlank, setLastActionPlank] =
    useRecoilState(lastActionPlankState)
  const culls = useRecoilValue(woodCullsState)
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const edit = useRecoilValue(editorSettingsState("edit"))
  const setLogs = useSetRecoilState(logsState)
  const setPlanks = useSetRecoilState(planksState)
  const loads = useRecoilValue(measurementEditorLoadsState)
  const { updateLogsAndReferences, updatePlanks } = useEditorState()
  const [measurementArea, setMeasurementArea] =
    useRecoilState(measurementAreaState)
  const selectedLog = useRecoilValue(selectedLogState)
  const refreshTruckStorages = useRecoilRefresher_UNSTABLE(truckStoragesQuery)
  const selectedPlank = useRecoilValue(selectedPlankState)
  const { id: measurementId, type: measurementType } = useRecoilValue(
    measurementEditorState
  )
  const [measurement, setMeasurement] = useRecoilState(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const resetSelectedLog = useResetRecoilState(selectedLogState)
  const resetSelectedPlank = useResetRecoilState(selectedPlankState)
  const [qrCode, setQrCode] = useRecoilState(selectedLogQrCodeState)
  const [assortment, setAssortment] = useRecoilState(selectedLogAssortmentState)
  const [cull, setCull] = useRecoilState(selectedLogCullState)
  const locked = measurement?.locked_at
  const mode = useRecoilValue(editorSettingsState("mode"))
  const fetch = useFetch()
  const { getSelectedLog, deleteLog, deletePlank, logsChanged } =
    useShapesActions()
  const qrEnabled = useRecoilValue(featureEnabled("qr_code"))

  const updateMeasurementArea = measurementArea => {
    setEditorSavingChanges(true)
    fetch(`/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        measurement_area: measurementArea
      }
    })
      .then(response => {
        let updatedAttributes = { ...measurement }
        updatedAttributes.volume = response.volume
        updatedAttributes.measurement_area = measurementArea
        updatedAttributes.coefficient = response.coefficient

        setMeasurement({
          ...measurement,
          ...updatedAttributes
        })

        toast.success(<Trans>Your changes have been saved</Trans>)
      })
      .finally(() => setEditorSavingChanges(false))
  }

  useEffect(() => {
    function handleDelete(event) {
      if (
        event.key === "Delete" &&
        mode === "EDIT" &&
        getSelectedLog() !== null
      ) {
        setLogs(logs =>
          logs.map((log, index) => {
            if (index !== getSelectedLog()) return log
            return {
              ...log,
              isDeleted: true
            }
          })
        )
        resetSelectedLog()
      }
    }

    window.document.addEventListener("keydown", handleDelete)

    return () => {
      window.document.removeEventListener("keydown", handleDelete)
    }
  }, [getSelectedLog, mode, resetSelectedLog, setLogs])

  return mode === EDIT ? (
    <>
      {/* <PlanksColumn /> */}
      {mode === "EDIT" && edit === null && (
        <Col className="px-3 py-2">
          <span className="text-white-50 small me-3">
            <Trans>Select something to edit.</Trans>
          </span>
        </Col>
      )}
      {edit === MEASUREMENT_AREA && <MeasurementAreaControls />}
      {edit === PLANKS && <PlankControls />}
      {edit === REFERENCE && <ReferencePane />}
      {edit === LOAD_LINES && <LoadLineControls />}
      <>
        {edit === LOGS && (
          <Row
            style={{ color: "rgba(255, 255, 255, 50%)" }}
            className="py-2 px-3 gap-2"
          >
            <TooltipsColumn />
            {measurementType === "container" &&
              qrEnabled &&
              selectedLog !== null && (
                <Col
                  xs="auto"
                  className="ps-3 border-start border-light border-opacity-10"
                >
                  <InputGroup>
                    <InputGroup.Text className="text-muted">
                      QR code
                    </InputGroup.Text>
                    <FormControl
                      value={qrCode}
                      onChange={event => setQrCode(event.target.value)}
                    />
                  </InputGroup>
                </Col>
              )}
            {selectedLog !== null && (
              <Col className="ps-3 border-start border-light border-opacity-10">
                <Select
                  placeholder={<Trans>No assortment</Trans>}
                  value={
                    assortment
                      ? {
                          value: assortment,
                          label: woodAssortments.find($ => $.id === assortment)
                            ?.name
                        }
                      : null
                  }
                  onChange={event => setAssortment(event.value)}
                  options={woodAssortments?.map($ => ({
                    value: $.id,
                    label: $.name
                  }))}
                />

                <Select
                  placeholder={<Trans>No cull</Trans>}
                  value={
                    cull
                      ? {
                          value: cull,
                          label: culls.find($ => $.id === cull)?.reason
                        }
                      : null
                  }
                  onChange={event => setCull(event.value)}
                  options={culls?.map($ => ({
                    value: $.id,
                    label: $.reason
                  }))}
                />
              </Col>
            )}
            <ButtonsColumn />
          </Row>
        )}
      </>
    </>
  ) : null

  function ButtonsColumn() {
    return (
      <Col
        xs="4"
        className="border-start border-light border-opacity-10 gap-3 align-items-center d-flex flex-wrap"
      >
        {selectedLog !== null ? (
          <Button
            variant="danger"
            onClick={deleteLog}
            className="px-3 rounded-pill"
          >
            <Trans>Remove log</Trans>
          </Button>
        ) : (
          <span className="opacity-50">
            <Trans>No log selected</Trans>
          </span>
        )}
        <Button
          className="px-3 rounded-pill"
          onClick={() => {
            resetSelectedLog()
            updateLogsAndReferences()
          }}
          disabled={locked ? true : false}
        >
          <Trans>Save changes</Trans>
        </Button>

        <Button
          className="px-3 rounded-pill"
          variant="outline-light"
          disabled={lastActionLog?.index === null}
          onClick={() => {
            setLogs(previousLogs => {
              return previousLogs.map(($, i) => {
                if (i === lastActionLog.index) {
                  return {
                    ...$,
                    ...(lastActionLog?.position ? lastActionLog.position : {}),
                    ...(lastActionLog?.radius
                      ? { r: lastActionLog.radius }
                      : {})
                  }
                }
                return $
              })
            })
            setLastActionLog({
              index: null,
              position: null,
              radius: null,
              "modified-at": undefined
            })
          }}
        >
          <FontAwesomeIcon icon={faUndo} className="me-2" />
          <Trans>Undo</Trans>
        </Button>

        <Button
          className="px-3 rounded-pill"
          variant="warning"
          onClick={() => {
            if (logsChanged()) {
              setLogs(measurement.logs)
            } else {
              window.alert(t`No changes detected`)
            }
          }}
        >
          <Trans>Reset</Trans>
        </Button>

        <Button
          variant="warning"
          className="rounded-pill px-3"
          onClick={() => {
            if (
              window.confirm(t`This will remove all logs without a QR code`)
            ) {
              setLogs(logs =>
                logs.map(log => {
                  if (
                    log?.qr_code !== undefined &&
                    log?.qr_code !== null &&
                    log?.qr_code !== ""
                  )
                    return log
                  return {
                    ...log,
                    isDeleted: true
                  }
                })
              )
            }
          }}
        >
          <Trans>Remove logs without QR code</Trans>
        </Button>
      </Col>
    )
  }

  function TooltipsColumn() {
    return (
      <Col xs="auto" className="me-2">
        <div className="small">
          <FontAwesomeIcon size="sm" icon={faPlusCircle} className="me-2" />
          <Trans>Double-click to add a circle</Trans>
        </div>
        <div className="small">
          <FontAwesomeIcon size="sm" icon={faEdit} className="me-2" />
          <Trans>Click on a circle to edit its diameter</Trans>
        </div>
        <div className="small">
          <FontAwesomeIcon size="sm" icon={faTrash} className="me-2" />
          <Trans>Select a circle to remove it</Trans>
        </div>
        <div className="d-flex align-items-center">
          <div
            className="d-inline-block me-2"
            style={{
              height: 12,
              width: 12,
              borderRadius: 50,
              border: "1px solid dodgerblue"
            }}
          />
          <span className="small">
            <Trans>Log with QR code</Trans>
          </span>
        </div>
      </Col>
    )
  }

  async function updateLoadLines() {
    fetch(`/truck_measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        truck_measurement: {
          loads: loads
        }
      }
    })
      .then(response => {
        // setLoads(response.loads)
        setMeasurement({
          ...measurement,
          ...response,
          loads
        })
        refreshTruckStorages()
        toast.success(<Trans>Your changes have been saved</Trans>)
      })
      .catch(() =>
        toast.error(
          <Trans>Encountered an error while updating load lines</Trans>
        )
      )
  }

  // function PlanksColumn() {
  //   return (
  //     edit === PLANKS && (
  //       <div className="d-flex py-2 px-3 flex-col">
  //         <Col xs="auto text-white-50">
  //           <div className="small">
  //             <FontAwesomeIcon size="sm" icon={faPlusCircle} className="me-2" />
  //             <Trans>Double–click to add a plank</Trans>
  //           </div>
  //           <div className="small">
  //             <FontAwesomeIcon size="sm" icon={faEdit} className="me-2" />
  //             <Trans>Click on a plank to edit its size or location</Trans>
  //           </div>
  //           <div className="small">
  //             <FontAwesomeIcon size="sm" icon={faTrash} className="me-2" />
  //             <Trans>
  //               Select a plank and click "Remove plank" to remove it
  //             </Trans>
  //           </div>
  //         </Col>
  //         <Col className="align-self-center px-3">
  //           {selectedPlank !== null ? (
  //             <Button
  //               variant="outline-danger"
  //               className="mx-4 rounded-pill px-3"
  //               onClick={deletePlank}
  //             >
  //               <Trans>Remove plank</Trans>
  //             </Button>
  //           ) : (
  //             <span className="mx-4 opacity-50">
  //               <Trans>No plank selected</Trans>
  //             </span>
  //           )}
  //           <Button onClick={updatePlanks} className="rounded-pill px-3">
  //             <Trans>Save</Trans>
  //           </Button>
  //         </Col>
  //       </div>
  //     )
  //   )
  // }

  function MeasurementAreaControls() {
    return (
      <Col className="px-3 py-2">
        <span className="text-white-50 small me-3">
          <FontAwesomeIcon icon={faHandPointer} className="me-2" />
          <Trans>Drag nodes to change the measurement area</Trans>
        </span>
        <span className="text-white-50 small me-3">
          <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
          <Trans>Double-click node to remove node</Trans>
        </span>
        <span className="text-white-50 small me-3">
          <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
          <Trans>Double-click near contour to add node</Trans>
        </span>
        <Button
          onClick={() => updateMeasurementArea(measurementArea)}
          className="rounded-pill"
        >
          <Trans>Save changes</Trans>
        </Button>
        <Button
          className="ms-3 rounded-pill"
          variant="light"
          disabled={lastActionArea?.index === null}
          onClick={() => {
            setMeasurementArea(previousMeasurementArea => {
              return previousMeasurementArea.map((point, i) => {
                if (i === lastActionArea?.index) {
                  const lastChar =
                    point?.["modified-at"].toString()?.slice(-1) ?? undefined
                  return {
                    ...(lastChar === "Z"
                      ? { "modified-at": point["modified-at"] }
                      : {}),
                    x: lastActionArea.position.x,
                    y: lastActionArea.position.y
                  }
                }
                return point
              })
            })
            setLastActionArea({})
          }}
        >
          <FontAwesomeIcon className="me-2" icon={faUndo} />
          <Trans>Undo</Trans>
        </Button>
        <Button
          className="ms-3 px-3 rounded-pill"
          variant="warning"
          onClick={() => {
            setMeasurementArea(measurement.measurement_area)
          }}
        >
          <Trans>Reset</Trans>
        </Button>
      </Col>
    )
  }

  function PlankControls() {
    return (
      <div className="d-flex py-2 px-3 flex-col">
        <Col xs="auto text-white-50">
          <div className="small">
            <FontAwesomeIcon size="sm" icon={faPlusCircle} className="me-2" />
            <Trans>Double–click to add a plank</Trans>
          </div>
          <div className="small">
            <FontAwesomeIcon size="sm" icon={faEdit} className="me-2" />
            <Trans>Click on a plank to edit its size or location</Trans>
          </div>
          <div className="small">
            <FontAwesomeIcon size="sm" icon={faTrash} className="me-2" />
            <Trans>Select a plank and click "Remove plank" to remove it</Trans>
          </div>
        </Col>
        <Col className="px-3 gap-3 d-flex align-items-center">
          {selectedPlank !== null ? (
            <Button
              variant="outline-danger"
              className="rounded-pill px-3"
              onClick={deletePlank}
            >
              <Trans>Remove plank</Trans>
            </Button>
          ) : (
            <span className="opacity-50">
              <Trans>No plank selected</Trans>
            </span>
          )}
          <Button onClick={updatePlanks} className="rounded-pill px-3">
            <Trans>Save</Trans>
          </Button>
          <Button
            className="px-3 rounded-pill"
            variant="outline-light"
            disabled={lastActionPlank?.index === null}
            onClick={() => {
              setPlanks(previousPlanks => {
                return previousPlanks.map(($, i) => {
                  if (i === lastActionPlank.index) {
                    return {
                      ...$,
                      ...(lastActionPlank?.position
                        ? lastActionPlank.position
                        : {}),
                      ...(lastActionPlank?.width
                        ? { width: lastActionPlank.width }
                        : {}),
                      ...(lastActionPlank?.height
                        ? { height: lastActionPlank.height }
                        : {})
                    }
                  }
                  return $
                })
              })
              setLastActionPlank({
                index: null,
                position: null,
                width: null,
                height: null,
                "modified-at": undefined
              })
            }}
          >
            <FontAwesomeIcon icon={faUndo} className="me-2" />
            <Trans>Undo</Trans>
          </Button>

          <Button
            className="px-3 rounded-pill"
            variant="warning"
            onClick={() => {
              setPlanks(measurement.planks)
              resetSelectedPlank()
            }}
          >
            <Trans>Reset</Trans>
          </Button>
        </Col>
      </div>
    )
  }

  function LoadLineControls() {
    return (
      <div
        className="d-flex p-3 align-items-center"
        style={{ color: "rgba(255, 255, 255, 50%)" }}
      >
        <Col xs="auto" className="me-3">
          <div className="small">
            <FontAwesomeIcon size="sm" icon={faUpDown} className="me-2" />
            <Trans>
              Drag the white circles vertically to adjust load lines.
            </Trans>
          </div>
        </Col>
        <Button className="px-3 rounded-pill" onClick={updateLoadLines}>
          <Trans>Save changes</Trans>
        </Button>
      </div>
    )
  }
}
