import { MapContainer, TileLayer } from "react-leaflet"
import MapMarker from "timber-measurement/map/MapMarker"
import { useEffect, useState } from "react"

export default function LocationSelector({ position, setPosition, tooltip }) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
      setReady(true)
    }, 2000)

    return () => {
      clearTimeout(timeout)
      setReady(false)
    }
  }, [])

  return ready ? (
    <div style={{ background: "transparent" }}>
      <MapContainer
        zoom={5}
        minZoom={2}
        center={position}
        scrollWheelZoom={true}
        style={{ height: "200px" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapMarker
          setPosition={setPosition}
          isMarker
          draggable
          position={position}
          tooltip={tooltip}
        />
      </MapContainer>
    </div>
  ) : (
    <>Loading...</>
  )
}
