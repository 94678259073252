import { Row } from "react-bootstrap"
import Container from "./Container"
import LogCounter from "./LogCounter"
import Lumber from "./Lumber"

export default function MoreProducts() {
  return (
    <Row xs="2" sm="2" md="3" lg="4" className="align-items-start text-center">
      <LogCounter />
      <Container />
      <Lumber />
    </Row>
  )
}
