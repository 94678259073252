import { Trans } from "@lingui/macro"
import { subscriptionItemsState } from "billing"
import { Col, Row } from "react-bootstrap"
import { useRecoilValue } from "recoil"

export default function LicensesOverview() {
  const subscriptionItems = useRecoilValue(subscriptionItemsState)
  const lumber = subscriptionItems.find(
    item => item.package_id === "lumber_subscription"
  )
  const logCounter = subscriptionItems.find(
    item => item.package_id === "log_counter_subscription"
  )
  const container = subscriptionItems.find(
    item => item.package_id === "container_subscription"
  )
  const timber = subscriptionItems.find(
    item =>
      item.package_id === "pro_subscription" ||
      item.package_id === "enterprise_subscription"
  )
  // return (
  //   <Card className="d-inline-flex px-3 py-1">
  //     <Row className="align-items-center">
  //       <Col xs="auto" className="align-self-center ps-1 pe-0">
  //         <FontAwesomeIcon
  //           icon={faTabletAlt}
  //           size="2x"
  //           className="opacity-25"
  //         />
  //       </Col>
  //       <Col className="lh-1 align-items-center px-2">
  //         <p className="fw-semibold small">
  //           <Trans>Licenses</Trans>
  //         </p>
  //         <span className="font-monospace small">
  //           <span title={t`Available licenses`}>
  //             {currentOrganization.available_licenses}
  //           </span>
  //           /<span title={t`In use`}>{currentOrganization.used_licenses}</span>
  //         </span>
  //       </Col>
  //     </Row>
  //   </Card>
  // )

  return timber || container || logCounter || lumber ? (
    <>
      <p className="text-white-50 mb-3">Licenses overview</p>
      {!timber && !container && !logCounter && !lumber && (
        <p className="text-white-50 fst-italic">
          <Trans>No products to show</Trans>
        </p>
      )}
      <Row xs="auto" className="px-2">
        {timber && (
          <Col className="bg-light py-2 me-3">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="align-self-start mb-2 mt-1">
                      <img
                        alt="Timbeter"
                        src="/logos/timber.svg"
                        width="32"
                        className="me-2"
                      />
                      <span className="fw-semibold">Timbeter</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Total</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{timber.quantity}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>In use</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{timber.devices_linked}</b>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Available</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>
                      {timber.quantity - timber.devices_linked >= 0
                        ? timber.quantity - timber.devices_linked
                        : 0}
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        {container && (
          <Col className="bg-light py-2 me-3">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="align-self-start mb-2 mt-1">
                      <img
                        alt="Container"
                        src="/logos/container.svg"
                        width="32"
                        className="me-2"
                      />
                      <span className="fw-semibold">Container</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Total</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{container.quantity}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>In use</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{container.devices_linked}</b>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Available</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>
                      {container.quantity - container.devices_linked >= 0
                        ? container.quantity - container.devices_linked
                        : 0}
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        {logCounter && (
          <Col className="bg-light py-2 me-3">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="align-self-start mb-2 mt-1">
                      <img
                        alt="Log Counter"
                        src="/logos/log_counter.svg"
                        width="32"
                        className="me-2"
                      />
                      <span className="fw-semibold">Log Counter</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Total</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{logCounter.quantity}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>In use</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{logCounter.devices_linked}</b>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Available</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>
                      {logCounter.quantity - logCounter.devices_linked >= 0
                        ? logCounter.quantity - logCounter.devices_linked
                        : 0}
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        {lumber && (
          <Col className="bg-light py-2">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="align-self-start mb-2 mt-1">
                      <img
                        alt="Lumber"
                        src="/logos/lumber.svg"
                        width="32"
                        className="me-2"
                      />
                      <span className="fw-semibold">Lumber</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Total</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{lumber.quantity}</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>In use</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>{lumber.devices_linked}</b>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-muted small fw-semibold text-uppercase">
                      <Trans>Available</Trans>
                    </span>
                  </Col>
                  <Col xs="auto">
                    <b>
                      {lumber.quantity - lumber.devices_linked >= 0
                        ? lumber.quantity - lumber.devices_linked
                        : 0}
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <hr className="text-light" />
    </>
  ) : null
}
