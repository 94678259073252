import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { forwardRef } from "react"

const billOptions = forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={event => {
      event.preventDefault()
      onClick(event)
    }}
  >
    <FontAwesomeIcon
      icon={faBars}
      size="lg"
      className="ms-3 text-primary cursor-pointer hover-dark"
    />
  </span>
))

export default billOptions
