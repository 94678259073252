import {
  faArrowDown,
  faBuilding,
  faCalendar,
  faChartLine,
  faUser,
  faUserGroup
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Badge, Card, Container, NavbarBrand, Table } from "react-bootstrap"

export default function PartnerLogin() {
  const [tableVisible, setTableVisible] = useState(true)
  // const [measurementsVisible, setMeasurementsVisible] = useState(false)

  return (
    <Container className="p-3 d-flex flex-column gap-3">
      <div className="pb-3 d-flex border-bottom border-light border-opacity-25">
        <NavbarBrand className="d-flex align-items-center gap-2">
          <img src="/logos/timbeter.svg" className="logo ms-3" alt="Timbeter" />
          <Badge bg="info">
            <FontAwesomeIcon icon={faUserGroup} /> Partner Portal
          </Badge>
        </NavbarBrand>
      </div>
      <div className="fs-2 fw-bold text-light">Overview</div>
      <div className="d-flex gap-3 flex-wrap">
        <Card
          body
          className="d-flex cursor-pointer"
          onClick={() => setTableVisible(!tableVisible)}
        >
          <FontAwesomeIcon
            icon={faBuilding}
            size="2x"
            className="mb-2 opacity-50"
          />
          <div className="fw-bold fs-3 lh-1">5</div>
          <div className="small text-muted">organizations</div>
        </Card>
        <Card body className="d-flex">
          <FontAwesomeIcon
            icon={faUser}
            size="2x"
            className="mb-2 opacity-50"
          />
          <div className="fw-bold fs-3 lh-1">14</div>
          <div className="small text-muted">accounts</div>
        </Card>
        <Card body className="d-flex">
          <FontAwesomeIcon
            icon={faCalendar}
            size="2x"
            className="mb-2 opacity-50"
          />
          <div className="fw-bold fs-3 lh-1">9</div>
          <div className="small text-muted">active subscriptions</div>
        </Card>
        <Card body className="d-flex">
          <FontAwesomeIcon
            icon={faChartLine}
            size="2x"
            className="mb-2 opacity-50"
          />
          <div className="d-flex align-items-center">
            <div className="fw-bold fs-3 lh-1 me-1">174</div>
            <span
              style={{ letterSpacing: "-1px" }}
              className="badge bg-danger-subtle text-danger-emphasis rounded-pill"
            >
              <FontAwesomeIcon icon={faArrowDown} /> 13%
            </span>
          </div>
          <div className="small text-muted">measurements last week</div>
        </Card>
      </div>
      {tableVisible && (
        <>
          <div className="fw-bold fs-4 text-light">Organizations</div>
          <Table hover className="rounded-table">
            <thead>
              <tr>
                <th>Organization</th>
                <th>Last activity</th>
                <th>Measurements</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Leaf Corp</td>
                <td>{new Date().toLocaleString()}</td>
                <td>143</td>
              </tr>
              <tr>
                <td>Big Wood Enterprises</td>
                <td>{new Date().toLocaleString()}</td>
                <td>1583</td>
              </tr>
              <tr>
                <td>Logs & Logs</td>
                <td>{new Date().toLocaleString()}</td>
                <td>675</td>
              </tr>
              <tr>
                <td>Huge International Group</td>
                <td>{new Date(1686408705000).toLocaleString()}</td>
                <td>789</td>
              </tr>
              <tr>
                <td>Saekaater OÜ</td>
                <td>{new Date(1696924305000).toLocaleString()}</td>
                <td>47</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      <div className="text-light fs-5 fw-bold">Leaf Corp</div>
      <Card
        body
        className="d-flex cursor-pointer"
        onClick={() => setTableVisible(!tableVisible)}
      >
        <FontAwesomeIcon
          icon={faBuilding}
          size="2x"
          className="mb-2 opacity-50"
        />
        <div className="fw-bold fs-3 lh-1">5</div>
        <div className="small text-muted">organizations</div>
      </Card>
    </Container>
  )
}
