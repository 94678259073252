import { faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import woodTypes from "common/enums/woodTypes"
import Select from "common/other/Select"
import Spinner from "common/other/Spinner"
import {
  volumeFormulasState,
  woodAssortmentsState,
  woodTypesState
} from "common/recoil/atoms"
import { useState } from "react"
import { Button, Card, Form, InputGroup } from "react-bootstrap"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { toast } from "react-toastify"
import { lengthUnitSelector } from "common/recoil/selectors"
import useFetch from "common/hooks/useFetch"
import { containerMeasurementState } from "./ContainerMeasurementRowList"

export default function ContainerMeasurementDetails({ measurement }) {
  const setMeasurement = useSetRecoilState(
    containerMeasurementState(measurement.id)
  )
  const [woodType, setWoodType] = useState(measurement?.wood_type_id || "")
  const [assortment, setAssortment] = useState(
    measurement?.wood_characteristic_id || ""
  )
  const [volumeFormula, setVolumeFormula] = useState(
    measurement?.volume_formula || ""
  )
  const [comment, setComment] = useState(measurement?.comment || "")
  const [logLength, setLogLength] = useState(measurement?.log_length || "")
  const [saving, setSaving] = useState(false)
  const assortments = useRecoilValue(woodAssortmentsState)
  const woodSpecies = useRecoilValue(woodTypesState)
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const lengthUnit = useRecoilValue(lengthUnitSelector)
  const fetch = useFetch()

  const handleSubmit = event => {
    event.preventDefault()
    setSaving(true)
    fetch(`/containers/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        wood_type_id: woodType || undefined,
        wood_characteristic_id: assortment || undefined,
        log_length: logLength || undefined,
        volume_formula: volumeFormula || undefined,
        comment: comment || undefined
      }
    })
      .then(updatedMeasurement => {
        // console.log(updatedMeasurement)
        if (updatedMeasurement) {
          setMeasurement({
            ...measurement,
            ...updatedMeasurement
          })
          toast.success(<Trans>Your changes have been saved</Trans>)
        } else {
          toast.error(<Trans>Your changes could not be saved</Trans>)
        }
      })
      .finally(() => setSaving(false))
  }

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faList} className="me-2" />
        <Trans>Measurement details</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="update-measurement" onSubmit={handleSubmit}>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Log length</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                value={logLength}
                onChange={event => setLogLength(event.target.value)}
              />
              <InputGroup.Text>{lengthUnit}</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Wood type</Trans>
            </Form.Label>
            <Select
              onChange={event => setWoodType(event.value)}
              value={{ value: woodType, label: woodTypes[woodType] }}
              options={woodSpecies.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Wood assortment</Trans>
            </Form.Label>
            <Select
              onChange={event => setAssortment(event.value)}
              value={
                assortment && {
                  value: assortment,
                  label: assortments?.filter($ => $.id === assortment)[0]?.name
                }
              }
              options={assortments?.map($ => ({
                value: $.id,
                label: $.name
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Volume formula</Trans>
            </Form.Label>
            <Select
              onChange={event => setVolumeFormula(event.value)}
              value={{
                value: volumeFormula,
                label: volumeFormulas
                  ? volumeFormulas[volumeFormula]
                  : "Loading..."
              }}
              options={Object.entries(volumeFormulas || {}).map(
                ([value, label]) => ({
                  value,
                  label
                })
              )}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Comment</Trans>
            </Form.Label>
            <Form.Control
              style={{ height: "8rem", resize: "none" }}
              as="textarea"
              value={comment}
              onChange={event => setComment(event.target.value)}
            />
          </Form.Group>
          <div className={`d-grid mt-3`}>
            <Button form="update-measurement" type="submit" disabled={saving}>
              {saving ? (
                <Spinner
                  size="sm"
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Trans>Save</Trans>
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}
