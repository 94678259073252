import { faFaceFrown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import {
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  selectedMeasurementsState,
  showMapState,
  markersState
} from "common/recoil/atoms"
import MeasurementMarker from "timber-measurement/map/MeasurementMarker"
import TruckMeasurementRow from "./TruckMeasurementRow"
import { querySelector } from "common/recoil/selectors"
import { useSearchParams } from "react-router-dom"
import { PageItem, Pagination } from "react-bootstrap"

export const truckStorageMeasurementsPageState = atomFamily({
  key: "truckStorageMeasurementsPageState",
  default: 1
})

export const truckMeasurementsQuery = selectorFamily({
  key: "truckMeasurementsQuery",
  get:
    ({ pagination, storage }) =>
    ({ get }) => {
      const response = get(
        querySelector({
          url: "/truck_measurements/search",
          options: {
            method: "POST",
            body: {
              q: {
                f: {
                  storage_id: storage.id,
                  ...(storage.measurements?.length && {
                    measurement_id: storage.measurements.join(",")
                  })
                }
              },
              paginate: {
                per_page: 5,
                page: pagination.page
              }
            }
          }
        })
      )

      if (response && response?.meta && response?.meta?.total_pages >= 1) {
        return {
          meta: response?.meta,
          measurements: response?.measurements
        }
      } else {
        return {
          meta: {
            total_pages: 0
          },
          measurements: []
        }
      }
    }
})

export default function TruckMeasurementRowList({ storage }) {
  const [searchParams] = useSearchParams()
  const [storagePage, setStoragePage] = useRecoilState(
    truckStorageMeasurementsPageState(storage.id)
  )
  const {
    measurements,
    meta: { total_pages }
  } = useRecoilValue(
    truckMeasurementsQuery({
      pagination: { page: storagePage },
      storage
    })
  )
  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedMeasurementsState
  )
  const setShowMap = useSetRecoilState(showMapState)
  const [checkAll, setCheckAll] = useState(false)
  const setMarkers = useSetRecoilState(markersState)

  useEffect(() => {
    if (!measurements.length) return

    let selected = 0

    measurements
      .map($ => $.id)
      .forEach(id => {
        if (selectedMeasurements.find($ => $.id === id)) {
          selected = selected + 1
        }
      })

    setCheckAll(selected === measurements.length)
  }, [selectedMeasurements, measurements])

  useEffect(() => {
    if (searchParams.get("p")) {
      // setPage(Number(searchParams.get("p")))
      // const [page, setPage] = useState(searchParams.get("p") || storagePage || 1)
      setStoragePage(searchParams.get("p"))
    }
    // return () => {
    //   setSearchParams()
    // }
  }, [searchParams, setStoragePage])

  return (
    <tr>
      <td style={{ padding: 0 }} colSpan="8">
        <table style={{ marginTop: "-8px", width: "100%" }}>
          <thead>
            <tr className="bg-whitesmoke border text-muted">
              <td style={{ textAlignLast: "center" }} className="py-1">
                <input
                  type="checkbox"
                  checked={checkAll}
                  onChange={() => {
                    if (checkAll) {
                      setCheckAll(false)
                      setSelectedMeasurements(
                        selectedMeasurements.filter(
                          m => !measurements.find($ => $ !== m)
                        )
                      )
                    } else {
                      setCheckAll(true)

                      let newSelectedMeasurements = [...selectedMeasurements]
                      measurements.forEach(m => {
                        if (!newSelectedMeasurements.find($ => $.id === m.id)) {
                          newSelectedMeasurements.push(m)
                        }
                      })
                      setSelectedMeasurements(newSelectedMeasurements)
                    }
                  }}
                ></input>
              </td>
              <td className="py-1">
                ID / <Trans>Measured on</Trans>
              </td>
              <td className="py-1">
                <Trans>Measurement type</Trans>
              </td>
              <td className="py-1">
                <Trans>Coordinates</Trans>
              </td>
              <td className="py-1">
                <Trans>Wood</Trans>
              </td>
              <td className="py-1">
                <Trans>Length</Trans>
              </td>
              <td className="py-1 text-center">
                <Trans>Volume</Trans>
              </td>
            </tr>
          </thead>
          <tbody>
            {measurements.length ? (
              withMonths(measurements)
            ) : (
              <tr
                className="shadow-lg"
                style={{
                  background: "whitesmoke",
                  textAlign: "center",
                  color: "gray"
                }}
              >
                <td colSpan="8">
                  <div className="text-center p-2 text-muted opacity-75">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faFaceFrown}
                      className="opacity-75"
                    />
                    <p>
                      <Trans>No Measurements</Trans>
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <MeasurementsPagination />
        </table>
      </td>
    </tr>
  )

  function MeasurementsPagination() {
    // useEffect(() => {
    //   setStoragePage(page)

    // }, [page])

    return (
      total_pages > 1 && (
        <tfoot>
          <tr className="bg-light border-top">
            <td colSpan="8">
              <Pagination className="justify-content-center m-0 cursor-pointer">
                {storagePage > 1 && (
                  <Pagination.First onClick={() => setStoragePage(1)} />
                )}
                {storagePage > 1 && (
                  <Pagination.Prev
                    onClick={() => setStoragePage(storagePage - 1)}
                  />
                )}
                <PageItem active title="Click to edit page number">
                  <span className="small">
                    <span
                      contentEditable
                      suppressContentEditableWarning={true}
                      onInput={event => {
                        const value = Number(event.target.textContent.trim())
                        if (value >= 1 && value <= total_pages) {
                          setTimeout(() => setStoragePage(value), 1000)
                        }
                      }}
                    >
                      {storagePage}
                    </span>
                    /{total_pages}
                  </span>
                </PageItem>
                {total_pages > storagePage && (
                  <Pagination.Next
                    onClick={() => setStoragePage(storagePage + 1)}
                  />
                )}
                {total_pages > storagePage + 1 && (
                  <Pagination.Last
                    onClick={() => setStoragePage(total_pages)}
                  />
                )}
              </Pagination>
            </td>
          </tr>
        </tfoot>
      )
    )
  }

  function withMonths(measurements) {
    let previousMonth = null
    return measurements.reduce((rows, measurement, index) => {
      let month = new Date(measurement.created_at).getMonth()
      let monthRow
      if (month !== previousMonth) {
        monthRow = (
          <tr key={index} style={{ background: "gainsboro" }}>
            <td colSpan="8" className="text-center fs-6 text-secondary p-0">
              <div className="d-flex justify-content-center">
                <div
                  className="fw-semibold text-body"
                  style={{ fontSize: "14px" }}
                >
                  {new Intl.DateTimeFormat("en", {
                    month: "long",
                    year: "numeric"
                  }).format(new Date(measurement.created_at))}
                </div>
              </div>
            </td>
          </tr>
        )
      }

      previousMonth = month

      return [
        ...rows,
        monthRow ?? undefined,
        <TruckMeasurementRow
          storage={storage}
          manual={measurement.type?.includes("Manual")}
          key={measurement.id}
          measurement={measurement}
          showOnMap={() => {
            setShowMap(true)
            setMarkers([
              <MeasurementMarker key={index} measurement={measurement} open />
            ])
            setTimeout(
              () => window.document.getElementById("map").scrollIntoView(),
              100
            )
          }}
        />
      ]
    }, [])
  }
}
