import {
  accountSettingsState,
  devicesState,
  measurementsModeState
} from "common/recoil/atoms"
import { Navigate } from "react-router-dom"
import { useRecoilValue } from "recoil"

export default function Index() {
  const module = useRecoilValue(measurementsModeState)
  const devices = useRecoilValue(devicesState)
  const account = useRecoilValue(accountSettingsState)

  if (account.verified_at === null) {
    return <Navigate replace to="/verification" />
  }

  return devices?.length === 0 && !devices?.devices_updated_at ? (
    <Navigate replace to="/setup" />
  ) : (
    <Navigate replace to={`/measurements/${module}`} />
  )
}
