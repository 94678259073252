import L from "leaflet"

export const start = L.icon({
  iconUrl: "/logos/marker.svg",
  iconSize: [16, 22],
  iconAnchor: [6, 20],
  popupAnchor: [0, -36]
})

export const finish = L.icon({
  iconUrl: "/logos/destination_marker.svg",
  iconSize: [16, 22],
  iconAnchor: [6, 20],
  popupAnchor: [0, -36]
})
