import { t, Trans } from "@lingui/macro"
import { Badge, Button, Card, Form, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  faMinusCircle,
  faPlus,
  faUserLock
} from "@fortawesome/free-solid-svg-icons"
import { accountSettingsState, organizationsState } from "common/recoil/atoms"
import useFetch from "common/hooks/useFetch"
import { useState } from "react"
import { toast } from "react-toastify"
import { userState } from "./EditUserCard"
import { storagesListQuery } from "common/recoil/selectors"
import { useParams } from "react-router-dom"

const ORGANIZATION = "Organization"
const STORAGE = "Storage"

// export const userRolesState = atomFamily({
//   key: "userRoles",
//   default: selectorFamily({
//     key: "userRoles/default",
//     get:
//       userId =>
//       ({ get }) =>
//         userId
//           ? get(rolesState).find(role => role.id === Number(userId)).roles
//           : null,
//     set:
//       userId =>
//       ({ get, set }, newRoles) =>
//         set(
//           rolesState,
//           rolesState.map(role =>
//             role.id === userId
//               ? {
//                   ...role,
//                   roles: newRoles
//                 }
//               : role
//           )
//         )
//   })
// })

const roleBadge = role => {
  let badgeBg = "secondary"

  if (role.name.includes("admin")) badgeBg = "danger"
  if (role.name.includes("super_admin")) badgeBg = "danger"
  if (role.name.includes("measurer")) badgeBg = "warning"
  if (role.name.includes("viewer")) badgeBg = "secondary"

  return (
    <Badge key={role.id} bg={badgeBg} className="me-2">
      {role.name}
    </Badge>
  )
}

export default function EditUserAccessCard() {
  const { id } = useParams()
  const [user, setUser] = useRecoilState(userState(Number(id)))
  const storages = useRecoilValue(storagesListQuery)
  const organizations = useRecoilValue(organizationsState)
  const fetch = useFetch()
  const [role, setRole] = useState("")
  const [resourceType, setResourceType] = useState("")
  const [resourceId, setResourceId] = useState("")
  const account = useRecoilValue(accountSettingsState)

  return account.id !== Number(id) ? (
    <Card className="mt-3">
      <Card.Header>
        <FontAwesomeIcon icon={faUserLock} className="me-2" />
        <Trans>User access management</Trans>
      </Card.Header>
      <Table responsive size="sm">
        {/* <thead>
          <tr>
            <th>
              <Trans>Role</Trans>
            </th>
            <th>
              <Trans>Type</Trans>
            </th>
            <th>
              <Trans>Name</Trans>
            </th>
            <th />
          </tr>
        </thead> */}
        <tbody>
          {user.roles.map($ => (
            <tr key={$.id}>
              <td>
                <small className="text-muted">Role</small>
                <br />
                <span>{roleBadge($)}</span>
              </td>
              <td>
                <small className="text-muted">Resource</small>
                <br />
                {$.type}
              </td>
              <td>
                <small className="text-muted">Name</small>
                <br />
                {getResourceName($.type, $.resource_id)}
              </td>
              <td style={{ verticalAlign: "bottom" }}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faMinusCircle}
                  className="cursor-pointer text-danger"
                  onClick={() => removeRole($.id)}
                  style={{ alignSelf: "center" }}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={4} className="bg-secondary-subtle px-3">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <small>
                <Trans>Create new access</Trans>
              </small>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="p-0 rounded-0 border-0">
              <Form.Select
                value={role}
                className="border-0 border-bottom rounded-0"
                onChange={event => setRole(event?.target?.value)}
                size="sm"
              >
                <option key="placeholder" hidden value>
                  {t`Select role...`}
                </option>
                <option key="viewer" value="viewer">
                  {t`Viewer`}
                </option>
                <option key="measurer" value="measurer">
                  {t`Measurer`}
                </option>
                <option key="admin" value="admin">
                  {t`Admin`}
                </option>
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="p-0 border-0">
              <Form.Select
                value={resourceType}
                onChange={event => setResourceType(event?.target?.value)}
                size="sm"
                className="border-0 rounded-0"
              >
                <option key="placeholder" hidden value>
                  {t`Select type...`}
                </option>
                <option key="organization" value="Organization">
                  Organization
                </option>
                <option key="storage" value="Storage">
                  Storage
                </option>
              </Form.Select>
            </td>
          </tr>
          <tr className={resourceType ? "d-table-row" : "d-none"}>
            <td colSpan="4" className="p-0 border-top">
              <Form.Select
                value={resourceId}
                onChange={event => setResourceId(event?.target?.value)}
                size="sm"
                className="border-0 rounded-0"
              >
                <option key="placeholder" hidden value>
                  {resourceType
                    ? resourceType === "Organization"
                      ? t`Select organization...`
                      : t`Select storage...`
                    : ""}
                </option>
                {resourceType === "Organization" &&
                  organizations.map($ => (
                    <option key={$.id} value={$.id}>
                      {$.name}
                    </option>
                  ))}
                {resourceType === "Storage" &&
                  storages.map($ => (
                    <option key={$.id} value={$.id}>
                      {$.name}
                    </option>
                  ))}
              </Form.Select>
            </td>
          </tr>
        </tbody>
      </Table>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          style={{ borderColor: "transparent" }}
          disabled={!(role && resourceId && resourceType)}
          onClick={() => {
            setRole("")
            setResourceId("")
            setResourceType("")
          }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant={role && resourceType && resourceId ? "primary" : "secondary"}
          disabled={!(role && resourceType && resourceId)}
          style={{ alignSelf: "center" }}
          onClick={() => {
            if (role && resourceType && resourceId) {
              addRole({
                name: role,
                user_id: Number(id),
                ...(resourceType === ORGANIZATION && {
                  organization_id: resourceId
                }),
                ...(resourceType === STORAGE && {
                  storage_id: resourceId
                })
              })
            }
          }}
        >
          <Trans>Add role</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : null

  function getResourceName(type, id) {
    if (type === ORGANIZATION) {
      return organizations.find($ => $.id === id)?.name
    } else if (type === STORAGE) {
      return storages.find($ => $.id === id)?.name
    }
  }

  function removeRole(roleId) {
    if (window.confirm("Are you sure?")) {
      fetch(`/roles/${roleId}`, {
        method: "DELETE"
      }).then(() => {
        setUser({
          roles: user.roles.filter($ => $.id !== roleId)
        })
        toast.success(<Trans>Removed role</Trans>)
      })
    }
  }

  function addRole({ name, user_id, organization_id, storage_id }) {
    fetch("/roles", {
      method: "POST",
      body: {
        role: {
          name,
          user_id,
          ...(organization_id && { organization_id }),
          ...(storage_id && { storage_id })
        }
      }
    })
      .then(response => {
        setUser({
          roles: [
            ...user.roles,
            {
              id: response.id,
              type: response.assignable_type,
              name: response.name,
              resource_id: response.assignable_id
            }
          ]
        })
        toast.success(<Trans>Added role</Trans>)
      })
      .finally(() => {
        setRole("")
        setResourceType("")
        setResourceId("")
      })
  }
}
