import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { useEffect } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import {
  atomFamily,
  selector,
  useRecoilState,
  useResetRecoilState
} from "recoil"
import { customFieldsState } from "common/recoil/atoms"

export const storageCustomFieldState = atomFamily({
  key: "storageCustomField",
  default: ""
})

export const storageCustomFields = selector({
  key: "storageCustomFields",
  get: ({ get }) => {
    const fieldNames = get(customFieldsState)
      .filter($ => !$.archived)
      .filter($ => $.source_key === "storage")
      .map($ => $.key)
    return fieldNames
      .map($ => ({
        key: $,
        value: get(storageCustomFieldState($))
      }))
      .filter($ => $?.value)
  }
})

export default function StorageCustomField({ customField }) {
  const [field, setField] = useRecoilState(
    storageCustomFieldState(customField.key)
  )
  const resetField = useResetRecoilState(
    storageCustomFieldState(customField.key)
  )

  useEffect(() => {
    resetField()
    if (customField.value) setField(customField.value)
  }, [customField.value, resetField, setField])

  return (
    <Form.Group className="mb-2">
      <OverlayTrigger
        overlay={
          <Tooltip>
            <Trans>This is a custom field</Trans>
          </Tooltip>
        }
      >
        <>
          {customField.data_type !== "boolean" && (
            <Form.Label>
              <Trans>{customField.name}</Trans>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ms-1 opacity-50"
              />
            </Form.Label>
          )}
        </>
      </OverlayTrigger>
      {customField.values?.length ? (
        <Form.Select
          onChange={event => setField(event.target.value)}
          value={field}
        >
          <option key="placeholder" hidden value>
            {t`Select`} {customField.name.toLowerCase()}...
          </option>
          {customField.values.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
      ) : customField.data_type === "boolean" ? (
        <Form.Check
          checked={field}
          onChange={() => setField(!field)}
          label={
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <Trans>This is a custom field</Trans>
                </Tooltip>
              }
            >
              <Form.Label>
                <Trans>{customField.name}</Trans>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ms-1 opacity-50"
                />
              </Form.Label>
            </OverlayTrigger>
          }
        />
      ) : (
        <Form.Control
          onChange={event => {
            if (
              (customField.data_type === "integer" ||
                customField.data_type === "decimal") &&
              isNaN(event.target.value)
            ) {
              return
            }

            setField(event.target.value)
          }}
          value={field}
          placeholder={t`Enter` + " " + customField.name.toLowerCase()}
        />
      )}
    </Form.Group>
  )
}
