import { Trans } from "@lingui/macro"
import { querySelector } from "common/recoil/selectors"
import { Button, Row } from "react-bootstrap"
import {
  atomFamily,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import ContainerMeasurementRow from "./ContainerMeasurementRow"
import { selectedContainerMeasurementsState } from "./Toolbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

export const containerMeasurementsQuery = selectorFamily({
  key: "containerMeasurementsState",
  get:
    id =>
    async ({ get }) =>
      get(
        querySelector(
          `/containers/measurements?container_id=${id}&extra_fields=logs,references`
        )
      )
})

export const containerMeasurementsState = atomFamily({
  key: "containerMeasurements",
  default: containerMeasurementsQuery
})

export const containerMeasurementState = atomFamily({
  key: "containerMeasurement",
  default: null
})

export default function ContainerMeasurementRowList({ containerId }) {
  const measurements = useRecoilValue(containerMeasurementsState(containerId))
  const setSelectedContainerMeasurements = useSetRecoilState(
    selectedContainerMeasurementsState
  )

  return (
    <tr>
      <td colSpan="6" className="p-0">
        <Row className="g-0 bg-whitesmoke border-top">
          {measurements.map(measurement => (
            <ContainerMeasurementRow
              key={measurement.id}
              measurement={measurement}
            />
          ))}
          {!measurements?.length && (
            <div className="text-center text-muted border-top border-bottom opacity-50">
              <p>
                <Trans>This container is empty</Trans>
              </p>
            </div>
          )}
          {measurements.length > 1 && (
            <Button
              variant="light"
              className="w-auto m-2 p-3 shadow-sm"
              style={{
                border: "1px dashed darkgray"
              }}
              onClick={() => setSelectedContainerMeasurements(measurements)}
            >
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Select all</Trans>
            </Button>
          )}
        </Row>
      </td>
    </tr>
  )
}
