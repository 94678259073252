import {
  faArrowRight,
  faFileLines,
  faWarehouse
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Spinner from "common/other/Spinner"
import { reportsState } from "common/recoil/atoms"
import { useState } from "react"
import { Alert, Button, Dropdown, Form, Modal } from "react-bootstrap"
import { useRecoilValue } from "recoil"

export default function AddStorageToReportModal({ storage }) {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState("")
  const [added, setAdded] = useState(false)
  const [selectedReportId, setSelectedReportId] = useState("")
  const reports = useRecoilValue(reportsState)
  let sortedReports = [...reports]
  sortedReports.sort((a, b) =>
    new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
  )
  const fetch = useFetch()
  const onHide = () => {
    setShow(false)
    setResponseMessage("")
    setAdded(false)
    setSelectedReportId(null)
  }

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <Trans>Add storage to report</Trans>
        <span className="float-end">
          <FontAwesomeIcon icon={faFileLines} />
        </span>
      </Dropdown.Item>
      <Modal
        show={show}
        centered
        backdrop="static"
        keyboard={false}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex flex-column">
            <div>
              <FontAwesomeIcon icon={faFileLines} className="me-2" />
              <Trans>Add storage to report</Trans>
            </div>
            <div className="fw-normal small fs-6 text-black-50">
              <FontAwesomeIcon icon={faWarehouse} className="me-2" />
              <span>{storage.name}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseMessage ? (
            <>{responseMessage}</>
          ) : (
            <>
              <Form.Label className="small text-muted">
                <Trans>Select a report</Trans>
              </Form.Label>
              <Form.Select
                value={selectedReportId}
                onChange={$ => setSelectedReportId($?.target?.value)}
                aria-label={t`Select a report`}
              >
                <option value="" disabled defaultValue>
                  {t`No report selected`}
                </option>
                {sortedReports.map($ => (
                  <option key={$.id} value={$.id}>
                    {$.name}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-column">
          {added ? (
            <>
              <Button
                variant="primary"
                className="w-100"
                onClick={() =>
                  window.location.assign("/reports/" + selectedReportId)
                }
              >
                <Trans>Go to report</Trans>
                <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="primary"
                className="w-100"
                disabled={selectedReportId === "" || loading}
                onClick={addStorageToReport}
              >
                {loading ? <Spinner /> : <Trans>Add to report</Trans>}
              </Button>
              <Button variant="secondary" className="w-100" onClick={onHide}>
                <Trans>Cancel</Trans>
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )

  function addStorageToReport() {
    setLoading(true)
    fetch(`/reports/${selectedReportId}/storage/${storage.id}`, {
      method: "PATCH"
    })
      .then(response => {
        if (response?.measurements?.added_measurements?.length === 0) {
          setAdded(true)
          setResponseMessage(
            <Alert variant="danger">
              <Trans>No measurements were added</Trans>
            </Alert>
          )
          return
        }
        if (response?.measurements?.not_added_measurements?.length === 0) {
          setAdded(true)
          setResponseMessage(
            <Alert variant="success">
              <Trans>
                All {response?.measurements?.added_measurements?.length}{" "}
                measurements have been added to the report.
              </Trans>
            </Alert>
          )
        }
        if (response?.measurements?.not_added_measurements?.length !== 0) {
          setAdded(true)
          setResponseMessage(
            <span>
              <Trans>
                {response?.measurements?.added_measurements?.length}{" "}
                measurements were added to the report.
              </Trans>
              <Trans>
                {response?.measurements?.not_added_measurements?.length}{" "}
                measurements were not added to the report.
              </Trans>
            </span>
          )
        }
      })
      .finally(() => setLoading(false))
  }
}
