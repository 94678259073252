import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import { measurementEditorState } from "./useEditorState"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { t } from "@lingui/macro"
import { truckDiameterMeasurementsQuery } from "cmpc-dashboard/MeasurementsList"

export default function useNavigateTruckDiameterMeasurements() {
  const [
    {
      id: measurementId,
      type: measurementType,
      shipment: { id: shipmentId = null } = {}
    },
    setMeasurementEditor
  ] = useRecoilState(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )

  const getNextTruckDiameterMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const { measurements } = snapshot.getLoadable(
          truckDiameterMeasurementsQuery({
            pagination: {
              page: 1
            },
            shipment: {
              id: shipmentId
            }
          })
        ).contents

        if (
          measurements.findIndex($ => $.id === measurement.id) ===
          measurements.length - 1
        ) {
          window.alert(t`There are no more measurements`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) < 8) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ],
            last: true
          }
        }
      },
    [measurement]
  )

  const getPreviousTruckDiameterMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const { measurements } = snapshot.getLoadable(
          truckDiameterMeasurementsQuery({
            pagination: {
              page: 1
            },
            shipment: {
              id: shipmentId
            }
          })
        ).contents

        if (measurements.findIndex($ => $.id === measurement.id) === 0) {
          window.alert(t`This is the latest measurement`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) > 1) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ],
            last: true
          }
        }
      },
    [measurement]
  )

  const nextTruckDiameterMeasurement = () => {
    const next = getNextTruckDiameterMeasurement()
    if (next === null) {
      return
    }
    setMeasurementEditor($ => ({
      id: next.measurement.id,
      type: "truck_diameter",
      shipment: {
        id: shipmentId
      }
    }))
  }

  const previousTruckDiameterMeasurement = () => {
    const previous = getPreviousTruckDiameterMeasurement()
    if (previous === null && !previous?.measurement?.id) {
      return
    }
    setMeasurementEditor($ => ({
      id: previous.measurement.id,
      type: "truck_diameter",
      shipment: {
        id: shipmentId
      }
    }))
  }

  return {
    nextTruckDiameterMeasurement,
    previousTruckDiameterMeasurement
  }
}
