import { faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Alert } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default function UpgradePlanCard({ feature, url, className }) {
  const navigate = useNavigate()

  return (
    <Alert variant="warning" className={`border-0 rounded-0 mb-0 ${className}`}>
      <Alert.Heading>
        <FontAwesomeIcon icon={faLock} className="me-2" />
        <Trans>This feature is locked</Trans>
      </Alert.Heading>
      <p>
        <Trans>
          This is an additional feature and requires a subscription.
        </Trans>{" "}
        <Alert.Link onClick={() => navigate("/billing")}>
          <Trans>Learn more</Trans>
        </Alert.Link>
      </p>
    </Alert>
  )
}
