import { Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilCallback, useRecoilState } from "recoil"
import useFetch from "common/hooks/useFetch"
import { woodCullsState } from "common/recoil/atoms"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

export default function NewWoodCullCard() {
  const [woodCullReason, setWoodCullReason] = useState("")
  const [woodCullMax, setWoodCullMax] = useState("")
  const [woodCullMin, setWoodCullMin] = useState("")
  const [woodCulls, setWoodCulls] = useRecoilState(woodCullsState)
  const fetch = useFetch()
  const addCull = useAddWoodCull()
  const navigate = useNavigate()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New wood cull</Trans>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Cull reason</Trans>
            </Form.Label>
            <Form.Control
              value={woodCullReason}
              onChange={event => setWoodCullReason(event?.target?.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Minimum diameter</Trans>
            </Form.Label>
            <Form.Control
              value={woodCullMin}
              onChange={event => setWoodCullMin(event?.target?.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Trans>Maximum diameter</Trans>
            </Form.Label>
            <Form.Control
              value={woodCullMax}
              onChange={event => setWoodCullMax(event?.target?.value)}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          onClick={() => navigate("/timber/culls")}
          style={{ borderColor: "transparent" }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          onClick={() =>
            addCull({
              ...(woodCullReason && { reason: woodCullReason }),
              ...(woodCullMax && {
                max: Number(woodCullMax)
              }),
              ...(woodCullMin && {
                min: Number(woodCullMin)
              })
            })
          }
          disabled={woodCullReason.trim() === ""}
        >
          <Trans>Add cull</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function useAddWoodCull() {
    const navigate = useNavigate()

    return useRecoilCallback(
      () => async newWoodCull => {
        fetch("/culls", {
          method: "POST",
          body: {
            cull: newWoodCull
          }
        }).then(response => {
          if (response) {
            setWoodCulls([...woodCulls, response])
            navigate("/timber/culls")
            toast.success(<Trans>Added</Trans>)
          }
        })
      },
      []
    )
  }
}
