import { roundingPrecisionState } from "common/recoil/atoms"
import { volumeUnitSelector } from "common/recoil/selectors"
import { useRecoilValue } from "recoil"

export default function useVolumeFormatter() {
  const volumeUnit = useRecoilValue(volumeUnitSelector)
  const roundingPrecision = useRecoilValue(roundingPrecisionState)

  return volume => {
    const number = volume
      ? Number(volume).toFixed(roundingPrecision)
      : (0).toFixed(roundingPrecision)
    return (
      <span>
        <span>{number.split(".")[0]}</span>
        <span className="small">.{number.split(".")[1]}</span>
        <span className="small ms-1">{volumeUnit}</span>
      </span>
    )
  }
}
