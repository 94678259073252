import { measurementEditorLoadsState } from "common/recoil/atoms"
import { useRecoilValue } from "recoil"
import LoadLine from "./LoadLine"

export default function LoadLines() {
  const loads = useRecoilValue(measurementEditorLoadsState)

  return (
    <>
      {loads.map(load => (
        <LoadLine key={load.id} load={load} />
      ))}
    </>
  )
}
