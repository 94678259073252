import { Marker, Popup, Tooltip, useMap } from "react-leaflet"
import { useRef, useEffect } from "react"
import { markerIcon } from "./StorageMarker"

export default function MapMarker({
  setPosition,
  children,
  position,
  id,
  center,
  openPopup,
  isMarker,
  isStorage,
  className,
  tooltip
}) {
  const map = useMap()

  const markerRef = useRef(null)

  useEffect(() => {
    if (openPopup) {
      map.setView(position, 10)
      markerRef.current.openPopup()
    }
  }, [openPopup, map, position])

  useEffect(() => {
    markerRef?.current?.on("dragend", event => {
      setPosition(event.target.getLatLng())
    })
  }, [setPosition])

  return (
    <Marker
      icon={markerIcon}
      ref={markerRef}
      position={position}
      draggable={isMarker}
    >
      {tooltip && (
        <Tooltip permanent direction="top" offset={[0, -40]}>
          {tooltip}
        </Tooltip>
      )}
      {!isMarker && <Popup>{children}</Popup>}
    </Marker>
  )
}
