import { faCube } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loading from "common/other/Loading"
import { useEffect, useState } from "react"
import { Card, Col, Container, NavbarBrand, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import ContainerMeasurementCard from "./ContainerMeasurementCard"

export default function PublicContainer() {
  const { token } = useParams()
  const [container, setContainer] = useState()

  useEffect(() => {
    if (token) {
      fetch(
        `${process.env.REACT_APP_TIMBETER_API}/containers/container/public/${token}`
      )
        .then(response => {
          return response.json()
        })
        .then(json => {
          setContainer(json)
        })
    }
  }, [token])

  return container ? (
    <Container className="p-3 d-flex flex-column gap-3">
      <div className="d-flex">
        <NavbarBrand className="d-flex align-items-center">
          <img src="/logos/timbeter.svg" className="logo ms-3" alt="Timbeter" />
        </NavbarBrand>
      </div>
      <Card body>
        <Row>
          <Col xs="auto" className="align-self-center">
            <FontAwesomeIcon icon={faCube} size="2x" />
          </Col>
          <Col xs="auto">
            <small className="text-muted">Container</small>
            <br />
            <span>{container.name}</span>
          </Col>
          <Col xs="auto">
            <small className="text-muted">Measurements</small>
            <br />
            <span>{container?.measurements?.length}</span>
          </Col>
          <Col xs="auto">
            <small className="text-muted">Log count</small>
            <br />
            <span>{container.log_count}</span>
          </Col>
          <Col xs="auto">
            <small className="text-muted">Total volume</small>
            <br />
            <span>{container.volume} m³</span>
          </Col>
        </Row>
      </Card>
      <div className="d-flex gap-3 flex-wrap justify-content-center">
        {container.measurements.map($ => (
          <ContainerMeasurementCard measurement={$} />
        ))}
      </div>
    </Container>
  ) : (
    <Loading />
  )
}
