export const FETCH_STORAGES = "FETCH_STORAGES"
export const FILTER_STORAGES = "FILTER_STORAGES"
export const SET_MEASUREMENT_FILTER = "SET_MEASUREMENT_FILTER"
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"
export const SET_MARKER = "SET_MARKER"
export const UPDATE_STORAGE = "UPDATE_STORAGE"
export const ADD_STORAGE = "ADD_STORAGE"
export const SUCCESS = "SUCCESS"
export const VIEW = "VIEW"
export const EDIT = "EDIT"
export const REFERENCE = "REFERENCE"
export const LOGS = "LOGS"
export const LOAD_LINES = "LOAD_LINES"
export const MEASUREMENT_AREA = "MEASUREMENT_AREA"
export const REMOVE_STORAGE = "REMOVE_STORAGE"
export const PLANKS = "PLANKS"
