import {
  faChevronDown,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Spinner from "common/other/Spinner"
import { Suspense, useState } from "react"
import MeasurementsList from "./MeasurementsList"
import { Badge } from "react-bootstrap"
import useConvertTimezone from "common/hooks/useConvertTimezone"

export default function ShipmentRow({ shipment }) {
  const [expanded, setExpanded] = useState(false)
  const convertTimezone = useConvertTimezone()
  const volume = Number(shipment?.volume)

  return (
    <>
      <tr onClick={() => setExpanded(!expanded)} className="cursor-pointer">
        <td style={{ width: "1rem" }} className="pe-0">
          <FontAwesomeIcon
            className={expanded ? "" : "opacity-50"}
            icon={expanded ? faChevronDown : faChevronRight}
          />
        </td>
        <td>
          <div className="position-relative d-inline">
            <span>{shipment?.number || "-"}</span>
            {/* {!measurementsVisible && <PulsatingDot />} */}
          </div>

          {/* <div className="ms-2 d-inline border text-muted bg-success-subtle border-success-subtle text-success-emphasis py-1 px-2 rounded">
          <small>CO₂ 112.6g/km</small>
        </div> */}
        </td>
        <td>
          {shipment?.truck_routes?.filter($ => $?.checkpoints?.length > 0)
            .length > 0 ? (
            <Badge bg="secondary" className="position-relative">
              Completed
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                {shipment.truck_routes.length}{" "}
                <span className="visually-hidden">completed routes</span>
              </span>
            </Badge>
          ) : (
            <Badge bg="none">No routes</Badge>
          )}

          {/* <Badge bg="success" className="position-relative">
            Active route
            <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
              <span className="visually-hidden">unread messages</span>
            </span>
          </Badge>
          <Badge bg="secondary" className="position-relative">
            Completed
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
              1 <span className="visually-hidden">unread messages</span>
            </span>
          </Badge> */}
        </td>
        <td>{convertTimezone(shipment.created_at)}</td>
        <td>{shipment?.logs_count}</td>
        <td>{volume?.toFixed(2)} m³</td>
      </tr>
      {expanded && (
        <Suspense fallback={<MeasurementsLoading />}>
          <MeasurementsList shipment={shipment} />
        </Suspense>
      )}
    </>
  )
}

const MeasurementsLoading = () => (
  <tr className="table-active">
    <td colSpan={6} className="p-3">
      <span>
        <Spinner variant="dark" />
      </span>
    </td>
  </tr>
)
