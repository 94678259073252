import { Trans } from "@lingui/macro"

export const storageStatuses = {
  1: <Trans>On hold</Trans>,
  2: <Trans>In progress</Trans>,
  3: <Trans>Stopped</Trans>,
  4: <Trans>Finished</Trans>,
  5: <Trans>Awaiting transport</Trans>,
  6: <Trans>Problem</Trans>
}

export const storageStatusesColors = {
  1: "warning",
  2: "none",
  3: "secondary",
  4: "success",
  5: "info",
  6: "danger"
}
