import { Suspense, useEffect, useState } from "react"
import { MapContainer, TileLayer, useMap } from "react-leaflet"
import { Card, Form, Row, Button, Spinner } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"
import TruckLoad from "truck-measurement/TruckLoad"
import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  truckMeasurementLoadsState,
  truckMeasurementsStateFamily
} from "common/recoil/atoms"
import { querySelector, truckStoragesQuery } from "common/recoil/selectors"
import RouteLine, { routePositions } from "truck-measurement/map/RouteLine"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons"

export const routeDetailsState = atomFamily({
  key: "routeDetails",
  default: null
})

export const truckRoutesByMeasurementId = selectorFamily({
  key: "truckRoutesByMeasurementId",
  get:
    id =>
    ({ get }) =>
      get(querySelector(`/truck_measurements/${id}/routes`))
})

export default function LogisticsMap({ measurement }) {
  const [loading, setLoading] = useState(true)
  const [truckMeasurementLoads, setTruckMeasurementLoads] = useRecoilState(
    truckMeasurementLoadsState(measurement.id)
  )
  const [updating, setUpdating] = useState(false)
  const fetch = useFetch()
  const refreshTruckStorages = useRecoilRefresher_UNSTABLE(truckStoragesQuery)
  const routes = useRecoilValue(truckRoutesByMeasurementId(measurement.id))
  const [selectedRoute, setSelectedRoute] = useState("all")
  const setMeasurement = useSetRecoilState(
    truckMeasurementsStateFamily(measurement.id)
  )
  useEffect(() => {
    setTruckMeasurementLoads(measurement.loads)

    return () => {
      setTruckMeasurementLoads([])
    }
  }, [measurement.loads, setTruckMeasurementLoads])

  function updateTruckLoads(event) {
    event.preventDefault()

    setUpdating(true)
    fetch(`/truck_measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        truck_measurement: {
          loads: truckMeasurementLoads
        }
      }
    })
      .then(response => {
        if (response) {
          setMeasurement(response)
          refreshTruckStorages()
          toast.success(<Trans>Truck loads updated</Trans>)
        }
      })
      .catch(() => toast.error(<Trans>Failed to update truck loads</Trans>))
      .finally(() => {
        setUpdating(false)
      })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
      setLoading(false)
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faLocationArrow} className="me-2" />
        <Trans>Logistics</Trans>
      </Card.Header>
      <div className="border-bottom">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center text-black-50"
            style={{ height: "380px" }}
          >
            <Trans>Loading...</Trans>
          </div>
        ) : (
          <MapContainer
            zoom={3}
            // minZoom={6}
            maxZoom={18}
            center={[59.437, 24.7536]}
            scrollWheelZoom={true}
            style={{ height: "380px" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Suspense
              fallback={
                <div
                  className="leaflet-top leaflet-left"
                  style={{ marginTop: 80 }}
                >
                  <div className="leaflet-control leaflet-bar bg-white py-1 px-2 d-flex">
                    <Spinner
                      as="span"
                      className="me-2 align-self-center"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="black"
                    />
                    <p className="fs-6">
                      <Trans>Loading routes</Trans>
                    </p>
                  </div>
                </div>
              }
            >
              {selectedRoute === "all" ? (
                routes.map($ => <RouteLine key={$.id} routeId={$.id} />)
              ) : (
                <RouteLine routeId={selectedRoute.id} />
              )}
              {selectedRoute === "all" ? (
                <MapController routeIds={routes.map($ => $.id)} />
              ) : (
                <MapController routeId={selectedRoute.id} />
              )}
            </Suspense>
          </MapContainer>
        )}
      </div>
      <Card.Body>
        <Form.Group className="mb-1">
          <Form.Label>
            <Trans>Truck route</Trans>
          </Form.Label>
          <Form.Select
            size="sm"
            value={selectedRoute?.id}
            onChange={event => {
              const single = routes.find(
                $ => Number($.id) === Number(event.target.value)
              )
              if (single) setSelectedRoute(single)
              else setSelectedRoute(event.target.value)
            }}
          >
            <option key="all" value="all">
              All
            </option>
            {routes.map($ => {
              const startedAt = $.started_at
                ? new Date($.started_at).toLocaleString()
                : "–"
              const finishedAt = $.finished_at
                ? new Date($.finished_at).toLocaleString()
                : "–"

              return (
                <option key={$.id} value={$.id}>
                  No. {$.id} ·{" "}
                  {$.finished_at
                    ? `Started ${startedAt} · Finished ${finishedAt}`
                    : `Started ${startedAt}`}
                </option>
              )
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-1">
          <Form.Label>
            <Trans>License plate</Trans>
          </Form.Label>
          <Form.Control
            size="sm"
            readOnly
            disabled
            value={measurement?.truck?.license_plate || "–"}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>
            <Trans>Shipment code</Trans>
          </Form.Label>
          <Form.Control
            size="sm"
            readOnly
            disabled
            value={measurement?.shipment_number || "–"}
          />
        </Form.Group>
        <Form onSubmit={updateTruckLoads} id="truckLoads">
          <Row xs={1} md={2}>
            {[...measurement?.loads]
              .slice()
              .reverse()
              .sort((a, b) => (a.index < b.index ? -1 : 1))
              .map(load => (
                <TruckLoad key={load.id} load={load} />
              ))}
          </Row>
        </Form>
        <Button
          form="truckLoads"
          className="w-full"
          type="submit"
          disabled={updating}
        >
          {updating ? (
            <>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
            </>
          ) : (
            <Trans>Save</Trans>
          )}
        </Button>
      </Card.Body>
    </Card>
  )
}

function MapController({ routeId, routeIds }) {
  const getRouteBounds = useRecoilCallback(
    ({ snapshot }) =>
      async routeId => {
        if (Array.isArray(routeId)) {
          return Promise.all(
            routeId.map($ => snapshot.getLoadable(routePositions($)).contents)
          )
        }

        return snapshot.getLoadable(routePositions(routeId)).contents
      },
    []
  )

  const map = useMap()

  useEffect(() => {
    async function fetchData() {
      const bounds = await getRouteBounds(routeIds ? routeIds : routeId)
      if (routeIds?.length || routeId) {
        map.flyToBounds(routeIds ? bounds?.flat() : bounds)
      }
    }
    const timeout = setTimeout(fetchData, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [routeId, routeIds, getRouteBounds, map])

  return null
}
