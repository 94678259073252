import {
  faCubesStacked,
  faDrawPolygon,
  faRuler,
  faTruckMoving
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import {
  LOAD_LINES,
  LOGS,
  MEASUREMENT_AREA,
  REFERENCE
} from "common/enums/constants"
import {
  measurementAreaState,
  measurementEditorLoadsState
} from "common/recoil/atoms"
import { Button } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import { editorSettingsState } from "."
import { logsState, measurementEditorState } from "./useEditorState"

export default function EditableItemsControl() {
  const loads = useRecoilValue(measurementEditorLoadsState)
  const logs = useRecoilValue(logsState)
  const measurementArea = useRecoilValue(measurementAreaState)
  const [edit, setEdit] = useRecoilState(editorSettingsState("edit"))
  const { type } = useRecoilValue(measurementEditorState)

  return (
    <div className="d-flex">
      {/* <Button
        id="view-mode"
        onClick={() => {
          detectChanges(() => {
            setMode(VIEW)
            resetSelectedLog()
            resetSelectedPlank()
          })
        }}
        variant={mode === VIEW ? "light" : "outline-light"}
        className={`me-1 d-flex flex-column align-items-center ${
          mode !== VIEW && "opacity-50"
        }`}
      >
        <FontAwesomeIcon icon={faEye} />
        <span style={{ fontSize: "0.625rem" }}>
          <Trans>View</Trans>
        </span>
      </Button> */}
      {type !== "lumber" && (
        <Button
          onClick={() => setEdit(REFERENCE)}
          variant={edit === REFERENCE ? "light" : "outline-light"}
          className={`d-flex flex-column border-0 border-start border-light border-opacity-10 rounded-0 align-items-center ${
            edit !== REFERENCE && "opacity-50"
          }`}
        >
          <FontAwesomeIcon icon={faRuler} />
          <span className="mt-1" style={{ fontSize: "0.625rem" }}>
            <Trans>Reference</Trans>
          </span>
        </Button>
      )}
      {logs !== null &&
        (type === "timber" ||
          type === "container" ||
          type === "truck_diameter") && (
          <Button
            onClick={() => setEdit(LOGS)}
            variant={edit === LOGS ? "light" : "outline-light"}
            className={`d-flex flex-column border-0 border-start border-end border-light border-opacity-10 rounded-0 align-items-center ${
              edit !== LOGS && "opacity-50"
            }`}
          >
            <FontAwesomeIcon icon={faCubesStacked} />
            <span className="mt-1" style={{ fontSize: "0.625rem" }}>
              <Trans>Logs</Trans>
            </span>
          </Button>
        )}
      {loads?.length ? (
        <Button
          onClick={() => setEdit(LOAD_LINES)}
          variant={edit === LOAD_LINES ? "light" : "outline-light"}
          className={`d-flex flex-column rounded-0 border-0 border-end border-start border-light border-opacity-10 align-items-center ${
            edit !== LOAD_LINES && "opacity-50"
          }`}
        >
          <FontAwesomeIcon icon={faTruckMoving} />
          <span className="mt-1" style={{ fontSize: "0.625rem" }}>
            <Trans>Load lines</Trans>
          </span>
        </Button>
      ) : null}
      {measurementArea?.length ? (
        <Button
          onClick={() => setEdit(MEASUREMENT_AREA)}
          variant={edit === MEASUREMENT_AREA ? "light" : "outline-light"}
          className={`d-flex flex-column rounded-0 border-0 border-end border-start border-light border-opacity-10 align-items-center ${
            edit !== MEASUREMENT_AREA && "opacity-50"
          }`}
        >
          <FontAwesomeIcon icon={faDrawPolygon} />
          <span className="mt-1" style={{ fontSize: "0.625rem" }}>
            <Trans>Area</Trans>
          </span>
        </Button>
      ) : null}
    </div>
  )
}
