import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { billsOfLadingState } from "common/recoil/atoms"
import { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import { useRecoilRefresher_UNSTABLE, useSetRecoilState } from "recoil"
import { billsOfLadingOptionsQuery } from "./ContainerFilters"

export default function AddBillOfLadingModal() {
  const [show, setShow] = useState(false)
  const [name, setName] = useState("")
  const setBillsOfLading = useSetRecoilState(billsOfLadingState)
  const refreshBillsOfLadingDropdown = useRecoilRefresher_UNSTABLE(
    billsOfLadingOptionsQuery
  )

  const fetch = useFetch()

  function handleSubmit(event) {
    event.preventDefault()

    fetch("/containers/bill_of_ladings", {
      method: "POST",
      body: {
        bill_of_lading: {
          name
        }
      }
    })
      .then(billOfLading => {
        refreshBillsOfLadingDropdown()
        setBillsOfLading($ => [billOfLading, ...$])
        toast.success(<Trans>Added!</Trans>)
      })
      .finally(() => {
        setShow(false)
      })
  }

  return (
    <>
      <Button
        variant="light"
        className="rounded-pill px-3"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New bill of lading</Trans>
      </Button>
      <Modal centered show={show} size="sm" onHide={() => setShow(false)}>
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>New bill of Lading</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form id="add-bill-of-lading" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>
                <Trans>Name</Trans>
              </Form.Label>
              <Form.Control
                value={name}
                onChange={event => setName(event.target.value)}
                required
              />
            </Form.Group>
            <Button className="w-full rounded-pill" type="submit">
              <Trans>Add</Trans>
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
