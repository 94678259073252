import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import MeasurementPhotoSquare from "common/other/MeasurementPhotoSquare"
import { Card } from "react-bootstrap"
import { useSetRecoilState } from "recoil"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"

export default function MeasurementEditorCard({ measurement, type }) {
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const formatVolume = useVolumeFormatter()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Measurement editor</Trans>
      </Card.Header>
      <div className="px-2 pt-2 align-self-center">
        <MeasurementPhotoSquare
          thumbnail
          measurement={measurement}
          onClick={() => {
            window.history.pushState("forward", null, "#editor")
            measurementEditor({ id: Number(measurement.id), type })
          }}
        />
      </div>
      <p className="m-2 d-flex flex-row justify-content-center gap-3">
        <span>
          <span className="text-muted small">
            <Trans>Volume</Trans>:
          </span>{" "}
          {formatVolume(measurement.volume)}
        </span>
        <span>
          <span className="text-muted small">
            <Trans>Log count</Trans>:
          </span>{" "}
          {measurement?.logs?.length}
        </span>
      </p>
    </Card>
  )
}
