import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { filteredStoragesState } from "common/recoil/atoms"
import StorageRow from "./TimberStorageRow"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { timberFilteringState } from "./TimberFilters"
import Loading from "common/other/Loading"

export default function TimberStorageRowList() {
  const filtering = useRecoilValue(timberFilteringState)
  const storages = useRecoilValue(filteredStoragesState)
  const formatVolume = useVolumeFormatter()
  let storagesVolume = 0
  if (storages?.length > 0) {
    storagesVolume = storages?.reduce((acc, val) => {
      return acc + Number(val?.volume?.unit ? val.volume.scalar : val.volume)
    }, 0)
  }

  return filtering ? (
    <Loading />
  ) : (
    <>
      <div className="text-light px-2 fw-medium">
        <span className="fs-6 text-white-50 me-2">
          <Trans>Results</Trans>
          <br />
        </span>
        <span className="fs-5 me-1">{storages.length}</span>
        {storages.length === 1 ? (
          <Trans>storage</Trans>
        ) : (
          <Trans>storages</Trans>
        )}
        <span className="mx-1"></span>
        <span className="fs-5 me-1">{formatVolume(storagesVolume)}</span>
        <Trans>volume</Trans>
      </div>
      <hr className="text-light" />
      <Table
        className="storagesTable"
        borderless
        responsive="xxl"
        style={{ borderSpacing: "0 8px", paddingBottom: "12rem" }}
      >
        <thead className="text-light">
          <tr className="bg-none">
            <td style={{ width: 0 }} className="bg-transparent" />
            <td className="bg-transparent text-light">
              <div className="d-flex align-items-center">
                <Trans>Storage</Trans>
              </div>
            </td>
            <td className="text-center bg-transparent text-light d-none d-xl-table-cell">
              <Trans>Location</Trans>
            </td>
            <td className="bg-transparent text-light d-none d-xl-table-cell">
              <Trans>Updated</Trans>
            </td>
            <td className="bg-transparent text-light  d-none d-xl-table-cell">
              <Trans>Status</Trans>
            </td>
            <td className="bg-transparent text-light  d-none d-xl-table-cell">
              <Trans>Logs</Trans>
            </td>
            <td className="bg-transparent text-light  d-none d-xl-table-cell">
              <Trans>Volume</Trans>
            </td>
          </tr>
        </thead>
        <tbody className="storages">
          {storages?.length ? (
            [...storages]
              .sort((a, b) =>
                new Date(a.updated_at) > new Date(b.updated_at) ? -1 : 1
              )
              .map((storage, index) => (
                <StorageRow key={storage.id} storage={storage} />
              ))
          ) : (
            <tr style={{ background: "white" }}>
              <td colSpan="8" style={{ textAlign: "center" }}>
                <FontAwesomeIcon icon={faTimes} className="hover-dark me-2" />
                <Trans>No measurements found that match your criteria</Trans>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
