import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import { measurementEditorState } from "./useEditorState"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import {
  measurementsQuery,
  storageMeasurementsPageState
} from "timber-measurement/MeasurementRowList"
import { t } from "@lingui/macro"

export default function useNavigateMeasurements() {
  const [
    { id: measurementId, type: measurementType, storage },
    setMeasurementEditor
  ] = useRecoilState(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )

  const [storagePage, setStoragePage] = useRecoilState(
    storageMeasurementsPageState(measurement.storage_id)
  )

  // const {
  //   measurements,
  //   meta: { total_pages }
  // } = useRecoilValue(
  //   measurementsQuery({
  //     pagination: { page: storagePage },
  //     storage
  //   })
  // )

  const getNextMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const response = snapshot.getLoadable(
          measurementsQuery({
            pagination: { page: storagePage },
            storage
          })
        )

        if (response.state === "loading" || response.state === "hasError") {
          return null
        }

        const { measurements, meta } = response.contents
        // console.log(measurements.findIndex($ => $.id === measurement.id), measurements.length - 1)
        // console.log(storagePage, meta.total_pages)
        if (
          storagePage === meta?.total_pages &&
          measurements.findIndex($ => $.id === measurement.id) ===
            measurements.length - 1
        ) {
          window.alert(t`There are no more measurements`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) < 3) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ],
            last: true
          }
        }
      },
    [measurement, storagePage]
  )

  const getPreviousMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const {
          measurements
          // meta
        } = snapshot.getLoadable(
          measurementsQuery({
            pagination: { page: storagePage },
            storage
          })
        ).contents

        if (
          storagePage === 1 &&
          measurements.findIndex($ => $.id === measurement.id) === 0
        ) {
          window.alert(t`This is the latest measurement`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) > 1) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ],
            last: true
          }
        }
      },
    [measurement, storagePage]
  )

  const nextMeasurement = () => {
    const next = getNextMeasurement()
    if (next === null) {
      // console.log("nonext")
      return
    }
    if (next.last) setStoragePage($ => $ + 1)
    setMeasurementEditor($ => ({
      id: next.measurement.id,
      type: "timber",
      storage: $.storage
    }))
  }

  const previousMeasurement = () => {
    const previous = getPreviousMeasurement()
    if (previous === null && !previous?.measurement?.id) {
      return
    }
    if (previous.last && storagePage > 1) {
      setStoragePage($ => $ - 1)
    }
    setMeasurementEditor($ => ({
      id: previous.measurement.id,
      type: "timber",
      storage: $.storage
    }))
  }

  return {
    nextMeasurement,
    previousMeasurement
  }
}
