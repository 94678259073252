import { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  ListGroup,
  NavbarBrand,
  Row
} from "react-bootstrap"
import { useLocation, useParams } from "react-router-dom"
import Location from "./Location"
import Timber from "./Timber"
import Logs from "./Logs"
import LogsDiameters from "./LogsDiameters"
import {
  faCertificate,
  faCheckCircle,
  faRulerCombined,
  faSun,
  faTimesCircle,
  faWater
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import { useSetRecoilState } from "recoil"
import { languageState } from "common/recoil/atoms"
import Loading from "common/other/Loading"

export default function PublicMeasurement() {
  const { token } = useParams()
  const path = useLocation()
  const [measurement, setMeasurement] = useState()
  const [vmu, setVmu] = useState(false)
  const setLanguage = useSetRecoilState(languageState)

  useEffect(() => {
    if (path?.pathname?.includes("vmu_public")) {
      setVmu(true)
      setLanguage("lt")
    }
  }, [path, setLanguage])

  useEffect(() => {
    if (token) {
      fetch(
        `${process.env.REACT_APP_TIMBETER_API}/measurements/public/${token}`
      )
        .then(response => {
          return response.json()
        })
        .then(json => {
          setMeasurement(json.measurement)
        })
    }
  }, [token])

  return measurement ? (
    <Container className="mb-3">
      <div className="d-flex py-3">
        <NavbarBrand className="d-flex align-items-center">
          <img src="/logos/timbeter.svg" className="logo ms-3" alt="Timbeter" />
        </NavbarBrand>
      </div>
      <Row>
        <Col xs={12} md={6}>
          {!vmu && (
            <Location
              latitude={measurement.latitude}
              longitude={measurement.longitude}
              storage={measurement.storage}
            />
          )}
          {vmu && measurement?.metrology && (
            <MetrologyCard metrology={measurement.metrology} />
          )}
          <LogsDiameters logsSheet={measurement?.logs_sheet} />
        </Col>
        <Col xs={12} md={6}>
          <Timber measurement={measurement} token={token} vmu={vmu} />
          {measurement?.photo_url.includes("missing.png") ? (
            <div className="rounded bg-light text-muted border px-2">
              No photo
            </div>
          ) : (
            <Logs
              imgUrl={measurement.photo_url}
              logs={measurement.logs}
              reference={measurement?.references[0]}
              measurementArea={measurement?.measurement_area}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={10}></Col>
      </Row>
    </Container>
  ) : (
    <Loading text={t`Loading measurement...`} />
  )
}

export function MetrologyCard({ metrology }) {
  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <div className="flex-grow-1">
          <FontAwesomeIcon icon={faCertificate} className="me-2" />
          <Trans>Metrology certification</Trans>
        </div>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={`fs-5 ${
            metrology?.is_valid ? "text-success" : "text-danger"
          }`}
        />
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item className="d-flex align-items-center">
          <div className="flex-grow-1 d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faSun} />
            <Trans>Brightness</Trans>
            <small className="text-muted opacity-50">
              {metrology?.brightness?.score || "-"}
            </small>
          </div>
          {metrology?.brightness?.status === "1" ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
          )}
        </ListGroup.Item>
        <ListGroup.Item className="d-flex align-items-center">
          <div className="flex-grow-1 d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faRulerCombined} />
            <Trans>Angle</Trans>
            <small className="text-muted opacity-50">
              {metrology?.angle_validation?.angle}
            </small>
          </div>
          {metrology?.angle_validation?.status === "1" ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
          )}
        </ListGroup.Item>
        <ListGroup.Item className="d-flex align-items-center">
          <div className="flex-grow-1 d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faWater} />
            <Trans>Interference</Trans>
            <small className="text-muted opacity-50">
              {metrology?.interference?.score}
            </small>
          </div>
          {metrology?.interference?.status === "1" ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
          ) : (
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
          )}
        </ListGroup.Item>
      </ListGroup>
    </Card>
  )
}
