import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Button, Form, InputGroup } from "react-bootstrap"
import { useState } from "react"
import Select from "common/other/Select"
import ReactDatePicker from "react-datepicker"
import { useRecoilState, useRecoilValue } from "recoil"
import measurementTypes from "common/enums/measurementTypes"
import useFetch from "common/hooks/useFetch"
import { analyzeReportsState } from "./useAnalyzeReportState"
import { storagesListQuery } from "common/recoil/selectors"
import { toast } from "react-toastify"

export default function NewAnalyzeReport() {
  const [name, setName] = useState("")
  const [type, setType] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [analysisStorages, setAnalysisStorages] = useState([])
  const [analysisMeasurementTypes, setAnalysisMeasurementTypes] = useState(
    Object.entries(measurementTypes).map(([id, name]) => ({
      value: id,
      label: name
    }))
  )

  const [analyzeReports, setAnalyzeReports] =
    useRecoilState(analyzeReportsState)

  const storages = useRecoilValue(storagesListQuery)
  const fetch = useFetch()

  function handleSubmit(event) {
    event.preventDefault()

    fetch("/analyze_reports", {
      method: "POST",
      body: {
        analyze_report: {
          name: name,
          type_key: type.value,
          period_from: new Date(from).toISOString(),
          period_to: to,
          storage_ids: analysisStorages.map($ => $.value),
          measurement_types: analysisMeasurementTypes.map($ => $.value)
        }
      }
    })
      .then(response => {
        if (response) {
          toast.success(<Trans>New analysis report created</Trans>)
          setAnalyzeReports([response, ...analyzeReports])
        }
      })
      .finally(() => {
        setName("")
        setType("")
        setFrom("")
        setTo("")
        setAnalysisStorages([])
        setAnalysisMeasurementTypes(
          Object.entries(measurementTypes).map(([id, name]) => ({
            value: id,
            label: name
          }))
        )
      })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h5>
        <Trans>New duplicates analysis</Trans>
      </h5>
      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Report name</Trans>
        </Form.Label>
        <Form.Control
          required
          onChange={event => setName(event.target.value)}
          value={name}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Type</Trans>
        </Form.Label>
        <Select
          onChange={event => setType(event)}
          disabled={true}
          value={{
            value: "duplicate_storage_measurements",
            label: "Duplicate measurements"
          }}
          options={[
            {
              value: "duplicate_storage_measurements",
              label: "Duplicate measurements"
            }
          ]}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Analysis period</Trans>
        </Form.Label>
        <InputGroup style={{ flexWrap: "nowrap" }}>
          <ReactDatePicker
            wrapperClassName="datepicker"
            dateFormat="dd.MM.yyyy"
            className="form-control no-focus cursor-pointer rounded-0 rounded-start"
            selected={from}
            onChange={date => setFrom(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
            required
          />
          <InputGroup.Text>
            <FontAwesomeIcon
              icon={faArrowRight}
              size="xs"
              className="text-muted opacity-50"
            />
          </InputGroup.Text>
          <ReactDatePicker
            wrapperClassName="datepicker"
            dateFormat="dd.MM.yyyy"
            className="form-control no-focus cursor-pointer rounded-0 rounded-end"
            selected={to}
            onChange={date => setTo(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            required
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Storages</Trans>
        </Form.Label>
        <Select
          isMulti
          onChange={value => setAnalysisStorages(value)}
          value={analysisStorages}
          options={storages.map(({ id, name }) => ({
            value: id,
            label: name
          }))}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>
          <Trans>Measurement types</Trans>
        </Form.Label>
        <Select
          isMulti
          onChange={value => setAnalysisMeasurementTypes(value)}
          value={analysisMeasurementTypes}
          options={Object.entries(measurementTypes).map(([id, name]) => ({
            value: id,
            label: name
          }))}
        />
      </Form.Group>
      <Button type="submit" className="w-full">
        <Trans>Create analysis</Trans>
      </Button>
    </Form>
  )
}
