import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import {
  billOfLadingQuery,
  billOfLadingState,
  measurementsStateFamily,
  referencesState,
  storagesState
} from "common/recoil/atoms"
import {
  containerByIdQuery,
  containerMeasurementQuery,
  diameterUnitSelector,
  measurementByIdAndTypeQuery,
  truckStoragesQuery
} from "common/recoil/selectors"
import { containerMeasurementState } from "container-measurement/ContainerMeasurementRowList"
import { containersByBillOfLadingIdQuery } from "container-measurement/ContainerRowList"
import { toast } from "react-toastify"
import {
  atom,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import { editorSavingChangesState, planksState } from "."
import { measurementEditorVolumeFormulaState } from "./ReferencePane"
import useShapesActions from "./useShapesActions"
import { shipmentsQuery } from "cmpc-dashboard/ShipmentsTable"

export const logsState = atom({
  key: "LOGS",
  default: []
})

export const selectedLogState = atom({
  key: "selectedLog",
  default: null
})

export const measurementEditorState = atom({
  key: "measurementEditor",
  default: { id: null, type: null }
})

export default function useEditorState() {
  const { id, type: measurementType } = useRecoilValue(measurementEditorState)
  const [measurement, setMeasurement] = useRecoilState(
    measurementByIdAndTypeQuery({ id, type: measurementType })
  )
  const logs = useRecoilValue(logsState)
  const references = useRecoilValue(referencesState)
  const planks = useRecoilValue(planksState)
  const fetch = useFetch()
  const { logsChanged, referenceChanged } = useShapesActions()
  const setEditorSavingChanges = useSetRecoilState(editorSavingChangesState)
  const measurementEditorVolumeFormula = useRecoilValue(
    measurementEditorVolumeFormulaState
  )
  const refreshTruckStorages = useRecoilRefresher_UNSTABLE(truckStoragesQuery)
  const refreshTruckDiameterShipments =
    useRecoilRefresher_UNSTABLE(shipmentsQuery)
  // const refreshLumberMeasurements = useRecoilRefresher_UNSTABLE(
  //   lumberMeasurementsQuery
  // )

  function updateLogsAndReferences() {
    if (measurementType === "container") {
      setEditorSavingChanges(true)
      updateContainerMeasurementLogsAndReferences({
        logs,
        measurement
      })
    }

    if (measurementType === "timber") {
      if (logsChanged()) {
        setEditorSavingChanges(true)
        updateTimberMeasurementLogs(logs)
      }

      if (referenceChanged()) {
        setEditorSavingChanges(true)
        fetch(`/measurements/${measurement.id}`, {
          method: "PATCH",
          body: {
            references,
            volume_formula: measurementEditorVolumeFormula
          }
        })
          .then(response => {
            if (response) {
              setMeasurement(previousMeasurement => ({
                ...previousMeasurement,
                references,
                volume_formula: response.volume_formula,
                volume: response.volume,
                logs: response.logs
              }))
              toast.success(<Trans>Your changes have been saved</Trans>)
            } else {
              toast.error(<Trans>Your changes could not be saved</Trans>)
            }
          })
          .catch(error => {
            // console.log(error)
          })
          .finally(() => setEditorSavingChanges(false))
      }
    }

    if (measurementType === "truck_diameter") {
      setEditorSavingChanges(true)
      fetch(`/truck_diameter_measurements/${measurement.id}`, {
        method: "PATCH",
        body: {
          logs: logs,
          references: references,
          volume_formula: measurementEditorVolumeFormula
        }
      })
        .then(response => {
          if (response) {
            // console.log(response)
            setMeasurement(previousMeasurement => ({
              ...previousMeasurement,
              references,
              volume_formula: response.volume_formula,
              volume: response.volume,
              logs: response.logs
            }))
            refreshTruckDiameterShipments()
            toast.success(<Trans>Your changes have been saved</Trans>)
          } else {
            toast.error(<Trans>Your changes could not be saved</Trans>)
          }
        })
        .catch(error => {
          // console.log(error)
        })
        .finally(() => setEditorSavingChanges(false))
    }

    if (measurementType === "truck") {
      setEditorSavingChanges(true)
      fetch(`/truck_measurements/${measurement.id}`, {
        method: "PATCH",
        body: {
          truck_measurement: {
            references,
            volume_formula: measurementEditorVolumeFormula
          }
        }
      })
        .then(response => {
          if (response) {
            setMeasurement(previousMeasurement => ({
              ...previousMeasurement,
              references,
              volume_formula: response.volume_formula,
              volume: response.volume
            }))
            refreshTruckStorages()
            toast.success(<Trans>Your changes have been saved</Trans>)
          } else {
            toast.error(<Trans>Your changes could not be saved</Trans>)
          }
        })
        .catch(error => {
          // console.log(error)
        })
        .finally(() => setEditorSavingChanges(false))
    }
  }

  const updatePlanks = useRecoilCallback(
    () => () => {
      setEditorSavingChanges(true)
      fetch(`/lumber_measurements/${measurement.id}`, {
        method: "PATCH",
        body: {
          planks: planks.filter($ => !$.isDeleted),
          planks_removed: planks.filter($ => $.isDeleted && $.id).map($ => $.id)
        }
      })
        .then(response => {
          // console.log({
          //   ...measurement,
          //   planks
          // })
          setMeasurement(measurement => ({
            ...measurement,
            planks: planks.filter($ => !$.isDeleted)
          }))
          // setPlanks(planks => planks.filter($ => !$.isDeleted))
          toast.success(<Trans>Your changes have been saved</Trans>)
        })
        .catch(error => {
          toast.error(<Trans>Failed to save changes</Trans>)
        })
        .finally(() => setEditorSavingChanges(false))
    },
    [planks]
  )

  const updateTimberMeasurementLogs = useRecoilCallback(
    ({ set, snapshot, refresh }) =>
      async logs => {
        try {
          const diameterUnit =
            snapshot.getLoadable(diameterUnitSelector).contents
          const response = await fetch(`/measurements/${measurement.id}`, {
            method: "PATCH",
            body: {
              logs:
                diameterUnit === "cm"
                  ? logs.filter($ => !$.isDeleted)
                  : logs
                      .filter($ => !$.isDeleted)
                      .map($ => ({
                        ...$,
                        r: $.r * 2.54,
                        d: $.d * 2.54
                      })),
              logs_removed: logs.filter($ => $.isDeleted && $.id).map($ => $.id)
            }
          })
          if (!response) return
          set(logsState, response.logs)
          set(measurementsStateFamily(measurement.id), previous => ({
            ...previous,
            logs: response.logs,
            volume: response.volume
          }))
          const logCountChange = response.logs.length - measurement.logs.length
          const volumeChange =
            response.logs.reduce((acc, val) => acc + val.v, 0) -
            measurement.logs.reduce((acc, val) => acc + val.v, 0)
          const storages = await snapshot.getLoadable(storagesState).contents
          set(
            storagesState,
            storages.map(storage =>
              storage.id === measurement.storage_id
                ? {
                    ...storage,
                    volume: storage.volume + volumeChange,
                    logs_count: storage.logs_count + logCountChange
                  }
                : storage
            )
          )

          toast.success(<Trans>Your changes have been saved</Trans>)
        } catch (error) {
          toast.error(<Trans>An error occurred</Trans>)
          set(editorSavingChangesState, false)
        } finally {
          set(editorSavingChangesState, false)
        }
      }
  )

  const updateContainerMeasurementLogsAndReferences = useRecoilCallback(
    ({ snapshot, refresh, set }) =>
      async ({ logs, measurement }) => {
        const container = snapshot.getLoadable(
          containerByIdQuery(measurement.container_id)
        ).contents

        try {
          const response = await fetch(
            `/containers/measurements/${measurement.id}`,
            {
              method: "PATCH",
              body: {
                ...(logsChanged() && {
                  logs: logs.filter($ => !$.isDeleted),
                  logs_removed: logs
                    .filter($ => $.isDeleted && $.id)
                    .map($ => $.id)
                }),
                ...(referenceChanged() && {
                  references,
                  volume_formula: measurementEditorVolumeFormula
                })
              }
            }
          )
          if (!response) return

          refresh(containerMeasurementQuery(measurement.id))
          const freshMeasurement = await snapshot.getPromise(
            containerMeasurementQuery(measurement.id)
          )
          set(containerMeasurementState(measurement.id), freshMeasurement)

          refresh(containersByBillOfLadingIdQuery(container.bill_of_lading_id))

          refresh(billOfLadingQuery(container.bill_of_lading_id))
          const freshBill = await snapshot.getPromise(
            billOfLadingQuery(container.bill_of_lading_id)
          )
          set(billOfLadingState(container.bill_of_lading_id), freshBill)

          toast.success(<Trans>Your changes have been saved</Trans>)
        } catch (error) {
          toast.error(<Trans>An error occurred</Trans>)
          set(editorSavingChangesState, false)
        } finally {
          set(editorSavingChangesState, false)
        }
      }
  )

  return {
    updatePlanks,
    updateLogsAndReferences
  }
}
