import { Trans, t } from "@lingui/macro"
import UpgradePlanCard from "common/other/UpgradePlanCard"
import { featureEnabled } from "common/recoil/atoms"
import { Col, Row } from "react-bootstrap"
import { NavLink, Outlet } from "react-router-dom"
import { useRecoilValue } from "recoil"

export default function DiameterProfilesIndex() {
  const enabled = useRecoilValue(featureEnabled("diameter_profiles"))

  return enabled ? (
    <>
      <div className="d-flex flex-wrap">
        <NavLink
          to="/timber/diameter_profiles"
          end
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>All diameter profiles</Trans>
        </NavLink>
        <NavLink
          to="/timber/diameter_profiles/new"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>New diameter profile</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <Row>
        <Col xs="12" lg="8" xxl="6">
          <Outlet />
        </Col>
      </Row>
    </>
  ) : (
    <UpgradePlanCard feature={t`diameter profiles`} />
  )
}
