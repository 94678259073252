import { accountSettingsState } from "common/recoil/atoms"
import { useRecoilValue } from "recoil"

export default function useConvertTimezone() {
  const account = useRecoilValue(accountSettingsState)

  return datetimestring => {
    try {
      return new Intl.DateTimeFormat(undefined, {
        dateStyle: "medium",
        timeStyle: "short",
        timeZone: account?.time_zone
      }).format(new Date(datetimestring))
    } catch {
      return datetimestring
    }
  }
}
