import { Trans } from "@lingui/macro"
import { toast } from "react-toastify"

export default function useFetch(url, options) {
  async function query(url, options) {
    const isFormData = options?.body instanceof FormData
    options = {
      ...options,
      headers: {
        ...(options?.body &&
          !isFormData && { "Content-Type": "application/json" }),
        // Authorization: localStorage.getItem("access_token") ? `Bearer ${localStorage.getItem("access_token")}` : undefined,
        ...(localStorage.getItem("access_token") && {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }),
        ...(options?.headers && {
          ...options.headers
        })
      },
      ...(options?.body &&
        !isFormData && {
          body: JSON.stringify(options.body)
        })
    }

    try {
      const response = await window.fetch(
        `${process.env.REACT_APP_TIMBETER_API}${url}`,
        options
      )

      if (!response.ok) {
        if (response.status === 401) {
          return window.dispatchEvent(new Event("expired_token"))
        }

        if (response.status === 403) {
          return toast.error(
            <Trans>You don't have permission to access this resource</Trans>
          )
        }

        if (response.status === 500) {
          throw new Error("An error occurred on the server")
        }
      }

      if (response.status === 204) return true

      let json = null
      try {
        json = await response.json()
      } catch {
        return null
      } finally {
        return json
      }
    } catch (error) {
      if (error.name === "AbortError") return

      toast.error(error.message)

      return null
    }
  }

  return query
}
