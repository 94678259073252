import { useEffect, useState } from "react"
import { Button, Form, InputGroup, FormLabel, Col } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { atom, selector, useRecoilState, useRecoilValue } from "recoil"
import { referencesState, volumeFormulasState } from "common/recoil/atoms"
import {
  measurementByIdAndTypeQuery,
  referenceUnitSelector
} from "common/recoil/selectors"
import useEditorState, { measurementEditorState } from "./useEditorState"

export const measurementEditorVolumeFormulaState = atom({
  key: "measurementEditorVolumeFormula",
  default: selector({
    key: "measurementEditorVolumeFormula/default",
    get: ({ get }) => {
      const { id: measurementId, type: measurementType } = get(
        measurementEditorState
      )
      const measurement = get(
        measurementByIdAndTypeQuery({
          id: measurementId,
          type: measurementType
        })
      )
      return measurement.volume_formula
    }
  })
})

export default function ReferencePane() {
  const [{ id: measurementId, type: measurementType }] = useRecoilState(
    measurementEditorState
  )
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const [volumeFormula, setVolumeFormula] = useRecoilState(
    measurementEditorVolumeFormulaState
  )
  const { updateLogsAndReferences } = useEditorState()
  const referenceUnit = useRecoilValue(referenceUnitSelector)
  const [size, setSize] = useState(0)
  const [references, setReferences] = useRecoilState(referencesState)
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const locked = measurement.locked_at

  useEffect(() => {
    if (references && references[0]) {
      // console.log("tere")
      setSize(
        referenceUnit === "ft" ? references[0]?.size : references[0].size * 100
      )
    }
  }, [references, referenceUnit])

  function handleAddReference() {
    setReferences([
      {
        size: 1,
        x1: 800,
        x2: 800,
        y1: 800,
        y2: 100
      }
    ])
  }

  return (
    <>
      <Col xs="auto">
        {references?.length === 0 && (
          <div className="d-grid mb-2">
            <Button variant="info" onClick={handleAddReference}>
              Add reference
            </Button>
          </div>
        )}
      </Col>
      <Col xs="auto" className="px-3 pt-1 pb-3">
        <FormLabel className="small">
          <Trans>Reference size</Trans>
        </FormLabel>
        <InputGroup size="sm">
          <input
            className="form-control form-control-sm form-control-append"
            value={size}
            onChange={event => setSize(event.target.value)}
            // onBlur={() => {
            //   setReferences(
            //     references.map(($, i) => {
            //       if (i === 0) {
            //         return {
            //           ...$,
            //           size: referenceUnit === "ft" ? size : size / 100
            //         }
            //       }
            //       return $
            //     })
            //   )
            // }}
            type="number"
            step={0.01}
          />
          <InputGroup.Text>
            {referenceUnit === "m" ? "cm" : referenceUnit}
          </InputGroup.Text>
        </InputGroup>
      </Col>
      {measurementType !== "truck" && (
        <Col xs="auto" className="px-2 pt-1 pb-3">
          <FormLabel className="small">
            <Trans>Volume formula</Trans>
          </FormLabel>
          <Form.Select
            size="sm"
            value={volumeFormula}
            onChange={event => setVolumeFormula(event.target.value)}
          >
            {Object.entries(volumeFormulas).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Col>
      )}
      <Col className="align-self-center me-3">
        <Button
          className="ms-3 rounded-pill"
          disabled={
            (measurementType === "container" && locked) ||
            measurementType === "truck_diameter"
              ? true
              : false
          }
          onClick={() => updateLogsAndReferences()}
        >
          <Trans>Save changes</Trans>
        </Button>
      </Col>
    </>
  )
}
