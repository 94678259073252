import React from "react"
import Card from "react-bootstrap/Card"
import { Spinner } from "react-bootstrap"

export default function Loading({ color, text, size }) {
  return (
    <Card
      className="m-5 p-5 align-items-center border-0 rounded text-light"
      bg="transparent"
    >
      <Spinner
        size={size}
        as="span"
        variant={color}
        animation="border"
        role="status"
        className="mb-2"
        aria-hidden="true"
      />
      <span className={color === "dark" ? "text-body" : "text-light"}>
        {text}
      </span>
    </Card>
  )
}
