import { faCube } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Card, Form, InputGroup } from "react-bootstrap"

export default function ContainerCard({ container }) {
  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faCube} className="me-2" />
        <Trans>Container details</Trans>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Container seal number</Trans>
            </Form.Label>
            <Form.Control defaultValue={container?.seal_number} disabled />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Container width</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control defaultValue={container?.width} disabled />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Container height</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control defaultValue={container?.height} disabled />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
