import {
  faCheckSquare,
  faCircle,
  faEye,
  faFileLines,
  faList,
  faLocationArrow,
  faLock,
  faQrcode,
  faShield
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import Spinner from "common/other/Spinner"
import {
  billingCurrencySelector,
  pricingState,
  subscriptionItemsState
} from "billing"
import { useEffect, useState } from "react"
import { Alert, Button, Card, Col, Modal, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import {
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  subscriptionCanceledSelector,
  subscriptionExistsSelector,
  subscriptionStatusSelector
} from "./TimbeterPro"
import { invoicesState } from "./Overview"
import { useNavigate } from "react-router-dom"

export const organizationPlanInterval = selector({
  key: "orgPlanInterval",
  get: ({ get }) => get(currentOrganizationState).interval
})

export const organizationPlanDate = selector({
  key: "orgPlanDate",
  get: ({ get }) => get(currentOrganizationState).paid_until
})

export const bundleEnabledSelector = selectorFamily({
  key: "bundleEnabled",
  get:
    bundleName =>
    ({ get }) =>
      get(subscriptionItemsState)?.find(set => set.package_id === bundleName)
})

export default function AdditionalFeatures() {
  const isBundleEnabled = useRecoilCallback(
    ({ snapshot }) =>
      bundleName =>
        snapshot.getLoadable(bundleEnabledSelector(bundleName)).contents
          ? true
          : false,
    []
  )

  const isBundleCancelled = useRecoilCallback(
    ({ snapshot }) =>
      bundleName =>
        snapshot.getLoadable(bundleEnabledSelector(bundleName)).contents
          .cancelled,
    []
  )

  const logisticsEnabled = useRecoilValue(
    bundleEnabledSelector("logistics_bundle")
  )
  const qrCodeEnabled = useRecoilValue(bundleEnabledSelector("qr_code_bundle"))
  const storageAccessManagementEnabled = useRecoilValue(
    bundleEnabledSelector("storage_access_management_bundle")
  )
  const customFieldsEnabled = useRecoilValue(
    bundleEnabledSelector("custom_fields_bundle")
  )
  const diameterProfilesEnabled = useRecoilValue(
    bundleEnabledSelector("diameter_profiles_bundle")
  )
  const duplicatesDetectionEnabled = useRecoilValue(
    bundleEnabledSelector("duplicate_storage_measurements_bundle")
  )
  const reportsEnabled = useRecoilValue(bundleEnabledSelector("reports_bundle"))
  const pricing = useRecoilValue(pricingState)
  const fetch = useFetch()
  const [selectedFeature, setSelectedFeature] = useState()
  const [confirmingFeature, setConfirmingFeature] = useState(false)
  const setSubscriptionItems = useSetRecoilState(subscriptionItemsState)
  const [paymentPreview, setPaymentPreview] = useState(null)
  const orgPlanInterval = useRecoilValue(organizationPlanInterval)
  const orgPlanDate = useRecoilValue(organizationPlanDate)
  const subscriptionExists = useRecoilValue(subscriptionExistsSelector)
  const [invoice, setInvoice] = useState()
  const setInvoices = useSetRecoilState(invoicesState)
  const navigate = useNavigate()
  const billingCurrency = useRecoilValue(billingCurrencySelector)
  const subscriptionStatus = useRecoilValue(subscriptionStatusSelector)
  const subscriptionWillCancel = useRecoilValue(subscriptionCanceledSelector)

  function addFeature() {
    setConfirmingFeature(true)
    fetch("/subscription/items", {
      method: "POST",
      body: {
        package_id: selectedFeature.package_id
      }
    })
      .then(response => {
        setInvoice(response)
        setInvoices(invoices => [response, ...invoices])
      })
      .finally(() => {
        setConfirmingFeature(false)
        setSelectedFeature()
      })
  }

  function deactivateFeature() {
    setConfirmingFeature(true)
    fetch("/subscription/items/" + selectedFeature.package_id, {
      method: "DELETE"
    })
      .then(response => {
        if (response) {
          setSubscriptionItems(items =>
            items.map($ =>
              $.package_id === selectedFeature.package_id
                ? {
                    ...$,
                    cancelled: true
                  }
                : $
            )
          )
          toast.success(
            <span>
              {selectedFeature.name} <Trans>is set to be deactivated</Trans>
            </span>
          )
        }
      })
      .finally(() => {
        setConfirmingFeature(false)
      })
  }

  useEffect(() => {
    if (selectedFeature && !isBundleEnabled(selectedFeature?.package_id)) {
      fetch("/subscription/items/preview", {
        method: "POST",
        body: {
          package_id: selectedFeature.package_id
        }
      }).then(response => {
        setPaymentPreview(response)
      })
    } else {
      setPaymentPreview(null)
    }
    // eslint-disable-next-line
  }, [selectedFeature])

  return (
    <div>
      <Modal show={invoice} onHide={() => setInvoice()}>
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>Order received</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">
            <Trans>
              A new pending invoice has been generated and is awaiting payment.
            </Trans>
          </p>
          <Button
            onClick={() => navigate("/billing/invoices")}
            variant="info"
            className="w-full"
          >
            <Trans>Invoices</Trans>
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={selectedFeature} onHide={() => setSelectedFeature(false)}>
        {isBundleEnabled(selectedFeature?.package_id) ? (
          isBundleCancelled(selectedFeature?.package_id) ? (
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Trans>{selectedFeature?.name}</Trans>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span>
                  <Trans>
                    This feature will be deactivated on{" "}
                    <b>{new Date(orgPlanDate).toLocaleDateString()}</b>
                  </Trans>
                </span>
              </Modal.Body>
            </div>
          ) : (
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Trans>{selectedFeature?.name}</Trans>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span>
                  <Trans>This feature is already activated.</Trans>
                </span>
              </Modal.Body>
              <Modal.Footer className="d-flex flex-nowrap">
                <Button
                  variant="danger"
                  className="w-50"
                  disabled={confirmingFeature}
                  onClick={deactivateFeature}
                >
                  <Trans>Deactivate</Trans>
                </Button>
                <Button
                  variant=""
                  className="w-50 text-secondary"
                  disabled={confirmingFeature}
                  onClick={() => setSelectedFeature()}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </div>
          )
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <Trans>Activate feature</Trans>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                This will add <b>{selectedFeature?.name}</b> to your active{" "}
                <u>{orgPlanInterval}</u> plan.
              </p>
              <div className="bg-light px-3 py-2 rounded mt-2">
                <p className="small text-muted">
                  <Trans>Total</Trans>
                </p>
                {paymentPreview ? (
                  <span className="fw-semibold">
                    {billingCurrency}
                    {paymentPreview.amount_due}
                  </span>
                ) : (
                  <Spinner variant="dark" />
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-nowrap">
              <Button
                variant="info"
                disabled={confirmingFeature}
                className="w-100 position-relative"
                onClick={() => addFeature()}
              >
                <Trans>Confirm</Trans>
                <span className="position-absolute start-0 align-items-center ps-3 opacity-25">
                  {confirmingFeature && <Spinner />}
                </span>
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <h4 className="text-light mt-3" id="additional-features">
        <Trans>Additional features</Trans>
      </h4>
      <hr className="text-light" />
      <div
        className={
          subscriptionStatus === "canceled" || subscriptionWillCancel
            ? "pe-none opacity-50"
            : ""
        }
      >
        {subscriptionExists ? (
          <div>
            <Row sm="1" lg="2" xxl="3" className="mb-4">
              <Col className="mb-3">
                <Card
                  body
                  className="feature-card"
                  onClick={() => setSelectedFeature(pricing.logistics_bundle)}
                >
                  <Row>
                    <Col xs="auto" className="pe-0 align-self-center">
                      <FontAwesomeIcon
                        icon={faLocationArrow}
                        size="3x"
                        className="text-secondary opacity-75 me-2"
                        style={{ width: "3rem" }}
                      />
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 fw-semibold">
                          <Trans>Logistics Tracking</Trans>
                        </span>
                      </div>
                      <p className="text-muted small">
                        <Trans>
                          Real-time tracking of truck measurements using GPS
                          data.
                        </Trans>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        size="xl"
                        className={
                          logisticsEnabled
                            ? isBundleCancelled(
                                pricing.logistics_bundle.package_id
                              )
                              ? "text-warning opacity-75"
                              : "text-primary opacity-75"
                            : "text-secondary opacity-75"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card
                  body
                  className="feature-card"
                  onClick={() => setSelectedFeature(pricing.qr_code_bundle)}
                >
                  <Row>
                    <Col xs="auto" className="pe-0 align-self-center">
                      <FontAwesomeIcon
                        icon={faQrcode}
                        style={{ width: "3rem" }}
                        size="3x"
                        className="text-secondary opacity-75  me-2"
                      />
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 fw-semibold">
                          <Trans>QR Code Detection</Trans>
                        </span>
                      </div>
                      <p className="text-muted small">
                        <Trans>
                          Use QR codes as reference instead of stick in
                          container measurements.
                        </Trans>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        size="xl"
                        className={
                          qrCodeEnabled
                            ? isBundleCancelled(
                                pricing.qr_code_bundle.package_id
                              )
                              ? "text-warning opacity-75"
                              : "text-primary opacity-75"
                            : "text-secondary opacity-75"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card
                  body
                  className="feature-card"
                  onClick={() =>
                    setSelectedFeature(pricing.storage_access_management_bundle)
                  }
                >
                  <Row>
                    <Col xs="auto" className="pe-0 align-self-center">
                      <FontAwesomeIcon
                        icon={faShield}
                        style={{ width: "3rem" }}
                        size="3x"
                        className="text-secondary opacity-75 me-2"
                      />
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 fw-semibold">
                          <Trans>Storage Access Management</Trans>
                        </span>
                      </div>
                      <p className="text-muted small">
                        <Trans>
                          Restrict device access to specified storages.
                        </Trans>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        size="xl"
                        className={
                          storageAccessManagementEnabled
                            ? isBundleCancelled(
                                pricing.storage_access_management_bundle
                                  .package_id
                              )
                              ? "text-warning opacity-75"
                              : "text-primary opacity-75"
                            : "text-secondary opacity-75"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card
                  body
                  className="feature-card"
                  onClick={() =>
                    setSelectedFeature(pricing.custom_fields_bundle)
                  }
                >
                  <Row>
                    <Col xs="auto" className="pe-0 align-self-center">
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ width: "3rem" }}
                        size="3x"
                        className="text-secondary opacity-75 me-2"
                      />
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 fw-semibold">
                          <Trans>Custom Fields</Trans>{" "}
                        </span>
                      </div>
                      <p className="text-muted small">
                        <Trans>
                          Create specific fields with different data types for
                          your measurements.
                        </Trans>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        size="xl"
                        className={
                          customFieldsEnabled
                            ? isBundleCancelled(
                                pricing.custom_fields_bundle.package_id
                              )
                              ? "text-warning opacity-75"
                              : "text-primary opacity-75"
                            : "text-secondary opacity-75"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card
                  body
                  className="feature-card"
                  onClick={() =>
                    setSelectedFeature(pricing.diameter_profiles_bundle)
                  }
                >
                  <Row>
                    <Col xs="auto" className="pe-0 align-self-center">
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{ width: "3rem" }}
                        size="3x"
                        className="text-secondary opacity-75 me-2"
                      />
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 fw-semibold">
                          <Trans>Diameter Profiles</Trans>
                        </span>
                      </div>
                      <p className="text-muted small">
                        <Trans>
                          Create custom diameter groups for your measurements.
                        </Trans>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        size="xl"
                        className={
                          diameterProfilesEnabled
                            ? isBundleCancelled(
                                pricing.diameter_profiles_bundle.package_id
                              )
                              ? "text-warning opacity-75"
                              : "text-primary opacity-75"
                            : "text-secondary opacity-75"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card
                  body
                  className="feature-card"
                  onClick={() => {
                    if (subscriptionStatus === "trialing") {
                      toast.info(
                        <Trans>
                          Duplicates detection feature is not available while
                          trialing
                        </Trans>
                      )
                      return
                    }
                    setSelectedFeature(
                      pricing.duplicate_storage_measurements_bundle
                    )
                  }}
                >
                  <Row>
                    <Col xs="auto" className="pe-0 align-self-center">
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ width: "3rem" }}
                        size="3x"
                        className="text-secondary opacity-75 me-2"
                      />
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 fw-semibold">
                          <Trans>Duplicates Detection</Trans>
                        </span>
                      </div>
                      <p className="text-muted small">
                        <Trans>
                          Detect and remove duplicate measurements from your
                          storages.
                        </Trans>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        size="xl"
                        className={
                          duplicatesDetectionEnabled
                            ? isBundleCancelled(
                                pricing.duplicate_storage_measurements_bundle
                                  .package_id
                              )
                              ? "text-warning opacity-75"
                              : "text-primary opacity-75"
                            : "text-secondary opacity-75"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="mb-3">
                <Card
                  body
                  className="feature-card"
                  onClick={() => setSelectedFeature(pricing.reports_bundle)}
                >
                  <Row>
                    <Col xs="auto" className="pe-0 align-self-center">
                      <FontAwesomeIcon
                        icon={faFileLines}
                        style={{ width: "3rem" }}
                        size="3x"
                        className="text-secondary opacity-75 me-2"
                      />
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 fw-semibold">
                          <Trans>Reports</Trans>
                        </span>
                      </div>
                      <p className="text-muted small">
                        <Trans>
                          Generate reports of measurements for production
                          overview.
                        </Trans>
                      </p>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faCheckSquare}
                        size="xl"
                        className={
                          reportsEnabled
                            ? isBundleCancelled(
                                pricing.reports_bundle.package_id
                              )
                              ? "text-warning opacity-75"
                              : "text-primary opacity-75"
                            : "text-secondary opacity-75"
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <Alert variant="light" className="text-center">
            <FontAwesomeIcon
              size="3x"
              icon={faLock}
              className="my-2 opacity-50"
            />
            <div className="my-3">
              <h5 className="text-muted">
                <Trans>Currently unavailable</Trans>
              </h5>
              <p className="text-muted">
                <Trans>
                  Additional features are available only with a subscription.
                </Trans>
              </p>
            </div>
          </Alert>
        )}
      </div>
    </div>
  )
}
