import { Trans } from "@lingui/macro"
import woodTypes from "common/enums/woodTypes"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { Col } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { measurementEditorState } from "./useEditorState"
import { woodQualitiesState } from "common/recoil/atoms"

export default function WoodPane() {
  const { id: measurementId, type: measurementType } = useRecoilValue(
    measurementEditorState
  )
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const woodQualities = useRecoilValue(woodQualitiesState)

  return (
    <Col xs="auto" className="px-3 py-2">
      <table className="w-full">
        <tbody>
          <tr>
            <td className="text-white-50 small pe-2">
              <Trans>Species</Trans>
            </td>
            <td>{woodTypes[measurement.wood_type_id] || "–"}</td>
          </tr>
          <tr>
            <td className="text-white-50 small pe-2">
              <Trans>Quality</Trans>
            </td>
            <td>
              {woodQualities?.find($ => $.id === measurement.wood_quality_id)
                ?.name || "–"}
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  )
}
