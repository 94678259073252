import { Trans } from "@lingui/macro"
import { querySelector } from "common/recoil/selectors"
import { Table } from "react-bootstrap"
import { atom, selector, useRecoilValue } from "recoil"
import ShipmentRow from "./ShipmentRow"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"

export const shipmentsQuery = selector({
  key: "shipmentsQuery",
  get: ({ get }) =>
    get(querySelector("/dashboard_filter/truck_diameter_measurements"))
})

export const shipmentsState = atom({
  key: "shipmentsState",
  default: shipmentsQuery
})

export default function ShipmentsTable() {
  const shipments = useRecoilValue(shipmentsState)
  const formatVolume = useVolumeFormatter()
  let shipmentsVolume = 0
  if (shipments?.length > 0) {
    shipmentsVolume = shipments?.reduce((acc, val) => {
      return acc + Number(val.volume)
    }, 0)
  }

  return (
    <>
      <div>
        <span className="fs-6 text-white-50 fw-medium">
          <Trans>Results</Trans>
        </span>
        <br />
        <div className="fw-medium text-white fs-6">
          <span className="me-1">{shipments?.length}</span>
          <Trans>shipments</Trans>
          <span className="mx-1"></span>
          <span className="me-1">{formatVolume(shipmentsVolume)}</span>
          <Trans>volume</Trans>
        </div>
      </div>
      <hr className="text-light" />
      <Table className="rounded-table">
        <thead>
          <tr>
            <th />
            <th>
              <Trans>Shipment number</Trans>
            </th>
            <th>
              <Trans>Recent route</Trans>
            </th>
            <th>
              <Trans>Created at</Trans>
            </th>
            <th>
              <Trans>Logs</Trans>
            </th>
            <th>
              <Trans>Volume</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {shipments?.length === 0 && (
            <tr>
              <td colSpan={6} className="text-center text-muted">
                <Trans>No shipments found</Trans>
              </td>
            </tr>
          )}
          {shipments?.map($ => (
            <ShipmentRow key={$?.id} shipment={$} />
          ))}
        </tbody>
      </Table>
    </>
  )
}
