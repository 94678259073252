import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { Suspense, useState } from "react"
import { Badge, Button } from "react-bootstrap"
import MeasurementSquare, {
  MeasurementSquareLoading
} from "./MeasurementSquare"

export default function DuplicateCase({ duplicateCase }) {
  const [resolvedAt, setResolvedAt] = useState(duplicateCase.resolved_at)
  const fetch = useFetch()

  function handleResolve() {
    fetch(
      `/analyze_reports/${duplicateCase.analyze_report_id}/cases/${duplicateCase.id}/resolve`,
      {
        method: "POST"
      }
    ).then(response => {
      if (response) {
        setResolvedAt(response.resolved_at)
      }
    })
  }

  return (
    <div className="p-3 mb-4 rounded border bg-whitesmoke">
      <div className="mb-2">
        {duplicateCase.reasons?.map($ => (
          <Badge key={$} bg="info">
            {$}
          </Badge>
        ))}
      </div>
      <p className="mb-2">
        Our algorithm detected these {duplicateCase.measurements.length}{" "}
        measurements to be duplicates:
      </p>
      <div className="rounded d-flex flex-wrap">
        {duplicateCase.measurements.map(measurementId => (
          <Suspense
            key={measurementId}
            fallback={<MeasurementSquareLoading key={measurementId} />}
          >
            <MeasurementSquare
              key={measurementId}
              id={measurementId}
              duplicateCaseMeasurements={duplicateCase.measurements}
            />
          </Suspense>
        ))}
      </div>
      <div className="d-grid">
        {resolvedAt === null ? (
          <Button variant="outline-secondary" onClick={handleResolve}>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            <Trans>Mark as resolved</Trans>
          </Button>
        ) : (
          <Button variant="outline-primary" disabled>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            <Trans>Resolved at</Trans> {new Date(resolvedAt).toLocaleString()}
          </Button>
        )}
      </div>
    </div>
  )
}
