import {
  faExclamationTriangle,
  faRefresh
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Alert, Button } from "react-bootstrap"

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: "" }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error?.name }
  }

  componentDidCatch(error, errorInfo) {
    // console.log(error, errorInfo)
  }

  render() {
    if (this.state.error === "ChunkLoadError") {
      window.location.reload()
      return null
    }

    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback
      }
      return (
        <Alert variant="danger" className="text-center">
          <FontAwesomeIcon
            size="3x"
            icon={faExclamationTriangle}
            className="my-2 opacity-50"
          />
          <div className="my-3">
            <h5>An error occurred</h5>
            <p className="mb-4">
              Something went wrong while trying to display this page.
            </p>
            <Button
              onClick={() => {
                window.location.reload()
              }}
              variant="light"
              className="rounded-pill border-0 shadow p-2 text-body"
            >
              <FontAwesomeIcon size="xl" icon={faRefresh} />
            </Button>
            <details className="text-start">
              <summary className="opacity-25">Show more</summary>
              <code>{this?.state?.message?.stack ?? this?.state?.message}</code>
            </details>
          </div>
        </Alert>
      )
    }

    return this.props.children
  }
}
