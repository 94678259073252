import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { usersState } from "common/recoil/atoms"
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { selectorFamily, useRecoilValue } from "recoil"

const ORGANIZATION = "Organization"
const STORAGE = "Storage"

const userRolesSelector = selectorFamily({
  key: "userRoles",
  get:
    id =>
    ({ get }) =>
      get(usersState).find(user => user.id === id)?.roles || []
})

export default function UserRow({ user }) {
  const roles = useRecoilValue(userRolesSelector(user.id))
  const navigate = useNavigate()

  const hasStorageRoles = roles.some($ => $.type === STORAGE)
  const organizationRoles = roles.filter($ => $.type === ORGANIZATION)

  return (
    <tr key={user.id} onClick={() => navigate("/users/" + user.id)}>
      <td>
        <span>{user.email}</span>
        <br />
        <small className="text-muted me-2">{user.name ?? "-"}</small>
        <span className="float-end">
          {organizationRoles.map(role => {
            let badgeBg = "secondary"

            if (role.name.includes("admin")) badgeBg = "danger"
            if (role.name.includes("super_admin")) badgeBg = "danger"
            if (role.name.includes("measurer")) badgeBg = "warning"
            if (role.name.includes("viewer")) badgeBg = "secondary"

            return (
              <Badge key={role.id} bg={badgeBg} className="me-2">
                {role.name}
              </Badge>
            )
          })}
          {hasStorageRoles && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={props => (
                <Tooltip id="button-tooltip" {...props}>
                  This user has storage specific roles
                </Tooltip>
              )}
            >
              <Badge key={"hasCustom"} bg="info">
                <Trans>custom_storage</Trans>
                <FontAwesomeIcon icon={faExclamationCircle} className="ms-1" />
              </Badge>
            </OverlayTrigger>
          )}
        </span>
      </td>
    </tr>
  )
}
