import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import { measurementEditorState } from "./useEditorState"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { t } from "@lingui/macro"
import {
  truckMeasurementsQuery,
  truckStorageMeasurementsPageState
} from "truck-measurement/TruckMeasurementRowList"

export default function useNavigateTruckMeasurements() {
  const [
    { id: measurementId, type: measurementType, storage },
    setMeasurementEditor
  ] = useRecoilState(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )

  const [storagePage, setStoragePage] = useRecoilState(
    truckStorageMeasurementsPageState(storage?.id)
  )

  const getNextTruckMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const { measurements, meta } = snapshot.getLoadable(
          truckMeasurementsQuery({
            pagination: { page: storagePage },
            storage
          })
        ).contents

        if (
          storagePage === meta.total_pages &&
          measurements.findIndex($ => $.id === measurement.id) ===
            measurements.length - 1
        ) {
          window.alert(t`There are no more measurements`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) < 3) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ],
            last: true
          }
        }
      },
    [measurement, storagePage]
  )

  const getPreviousTruckMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const { measurements } = snapshot.getLoadable(
          truckMeasurementsQuery({
            pagination: { page: storagePage },
            storage
          })
        ).contents

        if (
          storagePage === 1 &&
          measurements.findIndex($ => $.id === measurement.id) === 0
        ) {
          window.alert(t`This is the latest measurement`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) > 1) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ],
            last: true
          }
        }
      },
    [measurement, storagePage]
  )

  const nextTruckMeasurement = () => {
    const next = getNextTruckMeasurement()
    if (next === null) {
      return
    }
    if (next.last) setStoragePage($ => $ + 1)
    setMeasurementEditor($ => ({
      id: next.measurement.id,
      type: "truck",
      storage: $.storage
    }))
  }

  const previousTruckMeasurement = () => {
    const previous = getPreviousTruckMeasurement()
    if (previous === null && !previous?.measurement?.id) {
      return
    }
    if (previous.last && storagePage > 1) {
      setStoragePage($ => $ - 1)
    }
    setMeasurementEditor($ => ({
      id: previous.measurement.id,
      type: "truck",
      storage: $.storage
    }))
  }

  return {
    nextTruckMeasurement,
    previousTruckMeasurement
  }
}
