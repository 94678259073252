import { querySelector } from "common/recoil/selectors"
import { atom, selector } from "recoil"
import { Col, Row } from "react-bootstrap"
import { NavLink, Outlet } from "react-router-dom"
import { Trans } from "@lingui/macro"

const lumberProductsQuery = selector({
  key: "lumberProductsQuery",
  get: ({ get }) => get(querySelector("/lumber_products"))
})

export const lumberProductsState = atom({
  key: "lumberProducts",
  default: lumberProductsQuery
})

export default function LumberProductsIndex() {
  return (
    <>
      <div className="d-flex flex-wrap">
        <NavLink
          to="/timber/lumber_products"
          end
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>All lumber products</Trans>
        </NavLink>
        <NavLink
          to="/timber/lumber_products/new"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>New lumber product</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <Row>
        <Col xs="12" lg="8" xxl="6">
          <Outlet />
        </Col>
      </Row>
    </>
  )
}
