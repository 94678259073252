import { Col, Row } from "react-bootstrap"
import PasswordCard from "./PasswordCard"
import ProfileCard from "./ProfileCard"
import TwoFactorAuthCard from "./TwoFactorAuthCard"

export default function AccountPage() {
  return (
    <Row>
      <Col className="mb-4">
        <ProfileCard />
      </Col>
      <Col>
        <div className="mb-4">
          <PasswordCard />
        </div>
        <TwoFactorAuthCard />
      </Col>
    </Row>
  )
}
