export default function LogsIcon({ fill, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 16}
      viewBox="0 0 576 512"
      className="me-2"
      id="logs-icon"
    >
      <path
        fill={fill}
        d="M288 160c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80zM176 328c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80zM160 432c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zm128 80c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80zm288-80c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zM400 328c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z"
      />
    </svg>
  )
}
