import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import Spinner from "common/other/Spinner"
import { organizationsState, reportsState } from "common/recoil/atoms"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"

export default function ReportsTable() {
  const reports = useRecoilValue(reportsState)
  let sortedReports = [...reports]
  sortedReports.sort((a, b) =>
    new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
  )
  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <Trans>Reports</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedReports.map(report => (
          <ReportRow key={report.id} report={report} />
        ))}
      </tbody>
    </Table>
  )
}

function ReportRow({ report }) {
  const organizations = useRecoilValue(organizationsState)
  const recipientOrganization = organizations.find(
    $ => $.id === report.recipient_organization_id
  )?.name
  const navigate = useNavigate()
  const [reportColorClass, setReportColorClass] = useState("text-secondary")

  useEffect(() => {
    if (report?.confirmed) {
      setReportColorClass("text-primary")
    }

    if (report?.seen_at === null) {
      setReportColorClass("text-info")
    }
  }, [report])

  return (
    <tr
      onClick={
        report?.generating_in_progress
          ? () => window.alert(t`This report is in progress`)
          : () => navigate("/reports/" + report.id)
      }
    >
      <td>
        {report?.generating_in_progress ? (
          <span className="me-2">
            <Spinner variant="dark" />
          </span>
        ) : (
          <FontAwesomeIcon
            icon={report?.seen_at ? faCheckCircle : faCircle}
            className={`${reportColorClass} me-2`}
          />
        )}
        {report.name || "–"}
        {recipientOrganization ? (
          <>
            <br />
            <small className="text-muted">{recipientOrganization}</small>
          </>
        ) : null}
      </td>
    </tr>
  )
}
