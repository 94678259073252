import {
  diameterProfilesState,
  woodAssortmentsState,
  woodCullsState
} from "common/recoil/atoms"
import useDiameterProfilesState from "./useDiameterProfileState"
import { Trans, t } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil"
import useFetch from "common/hooks/useFetch"
import CustomSelect from "common/other/Select"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import ResourceNotFoundAlert from "common/other/ResourceNotFoundAlert"

export default function EditDiameterProfileCard() {
  const { id } = useParams()
  const [diameterProfile, setDiameterProfile] = useDiameterProfilesState(
    Number(id)
  )

  const [diameterProfileName, setDiameterProfileName] = useState("")
  const [diameterProfileCull, setDiameterProfileCull] = useState("")
  const [diameterProfileAssortments, setDiameterProfileAssortments] = useState(
    []
  )
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const navigate = useNavigate()
  const woodCulls = useRecoilValue(woodCullsState)
  const deleteDiameterProfile = useDeleteDiameterProfile(id)
  const fetch = useFetch()

  useEffect(() => {
    if (diameterProfile?.name) {
      setDiameterProfileName(diameterProfile.name)
    }

    if (diameterProfile?.wood_characteristic_ids) {
      setDiameterProfileAssortments(diameterProfile.wood_characteristic_ids)
    }

    if (diameterProfile?.default_cull_id) {
      setDiameterProfileCull(diameterProfile.default_cull_id)
    }
  }, [diameterProfile, id])

  return diameterProfile ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit diameter profile</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="edit-diameter-profile">
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              value={diameterProfileName}
              onChange={event => setDiameterProfileName(event?.target?.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Assortments</Trans>
            </Form.Label>
            <CustomSelect
              isMulti
              value={diameterProfileAssortments?.map(id => {
                const assortment = woodAssortments?.find($ => $.id === id)
                return {
                  value: id,
                  label: assortment.name
                }
              })}
              onChange={event =>
                setDiameterProfileAssortments(event.map($ => $.value))
              }
              options={woodAssortments.filter(checkCollision).map($ => ({
                value: $.id,
                label: $.name + ` (${$.min}–${$.max})`
              }))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <Trans>Cull</Trans>
            </Form.Label>
            <Form.Select
              value={diameterProfileCull}
              onChange={event => setDiameterProfileCull(event?.target?.value)}
            >
              <option key="placeholder" hidden value>
                Choose cull
              </option>
              {woodCulls
                ?.filter($ => !$.min && !$.max)
                .map($ => (
                  <option key={$.id} value={$.id}>
                    {$.reason}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          style={{ borderColor: "transparent" }}
          onClick={() => navigate("/timber/diameter_profiles")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="secondary"
          className="me-3"
          onClick={() => {
            if (window.confirm(t`Are you sure?`)) {
              deleteDiameterProfile()
            }
          }}
        >
          <Trans>Archive</Trans>
        </Button>
        <Button
          onClick={() => {
            setDiameterProfile({
              ...(diameterProfileName && { name: diameterProfileName }),
              ...(diameterProfileCull && {
                default_cull_id: Number(diameterProfileCull)
              }),
              ...(diameterProfileAssortments && {
                wood_characteristic_ids: diameterProfileAssortments
              })
            })
          }}
          disabled={
            diameterProfileName === diameterProfile?.name &&
            Number(diameterProfileCull) ===
              Number(diameterProfile.default_cull_id) &&
            diameterProfileAssortments?.length === 0
          }
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <ResourceNotFoundAlert />
  )

  function checkCollision(assortment) {
    let ok = true

    if (assortment.min === null || assortment.max === null) {
      return false
    }

    if (diameterProfileAssortments?.length === 0) {
      return true
    }

    const selectedDiameterProfileAssortments = diameterProfileAssortments?.map(
      id => {
        const assortment = woodAssortments?.find($ => $.id === id)
        return assortment
      }
    )

    for (let $ of selectedDiameterProfileAssortments) {
      if (
        Number(assortment.min) > Number($.min) &&
        Number(assortment.min) < Number($.max)
      ) {
        ok = false
        break
      }

      if (
        Number(assortment.min) < Number($.min) &&
        Number(assortment.max) > Number($.min)
      ) {
        ok = false
        break
      }

      if (
        Number(assortment.min) === Number($.min) ||
        Number(assortment.max) === Number($.max)
      ) {
        ok = false
        break
      }
    }

    return ok
  }

  function useDeleteDiameterProfile(diameterProfileId) {
    const setDiameterProfiles = useSetRecoilState(diameterProfilesState)

    return useRecoilCallback(
      () => async () => {
        fetch(`/diameter_profiles/${diameterProfileId}`, {
          method: "DELETE"
        }).then(response => {
          setDiameterProfiles(profiles =>
            profiles.filter($ => $.id !== Number(diameterProfileId))
          )
          navigate("/timber/diameter_profiles")
          toast.success(<Trans>Archived</Trans>)
        })
      },
      [diameterProfileId]
    )
  }
}
