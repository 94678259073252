import { Trans } from "@lingui/macro"
import { woodCullsState } from "common/recoil/atoms"
import { volumeUnitSelector } from "common/recoil/selectors"
import { Card, Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"

export default function MeasurementCullsTable({ logs }) {
  const culls = useRecoilValue(woodCullsState)
  const volumeUnit = useRecoilValue(volumeUnitSelector)
  const uniqueCullIds = [
    ...new Set(logs.filter($ => $.cull_id).map($ => $.cull_id))
  ]
  const logsTotalVolume = logs.reduce((acc, val) => acc + val.v, 0).toFixed(2)
  const uniqueCulls = uniqueCullIds.map(id => {
    const cull = culls.find($ => $.id === id)
    const volume = logs
      .reduce((acc, val) => (val.cull_id === id ? acc + val.v : acc), 0)
      .toFixed(2)
    return {
      ...cull,
      volume,
      volumePercentage: ((volume / logsTotalVolume) * 100).toFixed(2),
      count: logs.filter($ => $.cull_id === id).length
    }
  })

  return uniqueCulls.length > 0 ? (
    <Card>
      <Card.Body className="pb-0">
        <Card.Title className="w-full d-inline-flex justify-content-between mb-0">
          <span className="text-nowrap mb-3 align-self-center">
            <Trans>Culls</Trans>
          </span>
        </Card.Title>
      </Card.Body>
      <Table>
        <thead>
          <tr>
            <th>
              <Trans>Reason</Trans>
            </th>
            <th>
              <Trans>Volume</Trans>
            </th>
            <th>
              <Trans>Count</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {uniqueCulls.map($ => (
            <tr key={$.id}>
              <td>{$.reason}</td>
              <td>
                {$.volume} {volumeUnit}
              </td>
              <td>{$.count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  ) : null
}
