import { Trans, t } from "@lingui/macro"
import { NavLink, Outlet } from "react-router-dom"
import { Suspense } from "react"
import Loading from "common/other/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTree } from "@fortawesome/free-solid-svg-icons"

export default function TimberPage() {
  return (
    <>
      <h4 className="text-light mb-3">
        <FontAwesomeIcon icon={faTree} className="me-2" />
        <Trans>Timber management</Trans>
      </h4>
      <hr className="text-light" />
      <div className="d-flex flex-wrap">
        <NavLink
          to="species"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Species</Trans>
        </NavLink>
        <NavLink
          to="qualities"
          className={({ isActive }) =>
            `text-light me-4 text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Qualities</Trans>
        </NavLink>
        <NavLink
          to="assortments"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Assortments</Trans>
        </NavLink>
        <NavLink
          to="diameter_profiles"
          className={({ isActive }) =>
            `text-light me-4 text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Diameter profiles</Trans>
        </NavLink>
        <NavLink
          to="culls"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Culls</Trans>
        </NavLink>
        <NavLink
          to="lumber_products"
          className={({ isActive }) =>
            `text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Lumber products</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <Suspense fallback={<Loading text={t`Loading...`} />}>
        <Outlet />
      </Suspense>
    </>
  )
}
