import { useRecoilValue } from "recoil"
import { planksState } from "."
import Plank from "./Plank"

export default function Planks() {
  const planks = useRecoilValue(planksState)

  return (
    <>
      {planks.map((plank, index) => (
        <Plank
          index={index}
          key={`${index}_${plank.isDeleted}_${plank.x}_${plank.y}`}
          plank={plank}
        />
      ))}
    </>
  )
}
