import { t } from "@lingui/macro"

const woodTypes = {
  1: t`Pine`,
  2: t`Spruce`,
  3: t`Birch`,
  4: t`Aspen`,
  5: t`Alder`,
  6: t`Ash`,
  7: t`Oak`,
  8: t`Other`,
  9: t`Black Alder`,
  10: t`Grey Alder`,
  1001: t`Cedar`,
  1002: t`Eucalyptus`,
  1003: t`Cypress`,
  1004: t`Teak`
}

export default woodTypes
