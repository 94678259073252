import { useEffect, useState } from "react"
import {
  faMapMarkerAlt,
  faPencilRuler
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import measurementTypes from "common/enums/measurementTypes"
import { Link, useNavigate } from "react-router-dom"
import { Image } from "react-bootstrap"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  roundingPrecisionState,
  selectedMeasurementsState,
  truckMeasurementsStateFamily,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import {
  isMeasurementSelectedState,
  volumeUnitSelector
} from "common/recoil/selectors"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"

export default function TruckMeasurementRow({
  measurement: m,
  showOnMap,
  manual,
  storage
}) {
  const [measurement, setMeasurement] = useRecoilState(
    truckMeasurementsStateFamily(m.id)
  )
  useEffect(() => {
    if (!measurement) {
      setMeasurement(m)
    }
    // eslint-disable-next-line
  }, [])
  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedMeasurementsState
  )
  const navigate = useNavigate()
  const isSelected = useRecoilValue(isMeasurementSelectedState(measurement?.id))
  const roundingPrecision = useRecoilValue(roundingPrecisionState)
  const [latitude] = useState(Number(measurement?.latitude))
  const [longitude] = useState(Number(measurement?.longitude))
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const woodTypes = useRecoilValue(woodTypesState)
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const woodQualities = useRecoilValue(woodQualitiesState)
  const volumeUnit = useRecoilValue(volumeUnitSelector)

  let woodInformation = null

  if (measurement?.wood_characteristic_ids?.filter($ => $)?.length > 0) {
    woodInformation = measurement?.wood_characteristic_ids.map((id, i) => (
      <span key={i}>
        <small>{woodAssortments?.find($ => $.id === id)?.name}</small>
        <br />
      </span>
    ))
  } else if (measurement?.wood_qualities_ids?.filter($ => $)?.length > 0) {
    woodInformation = measurement?.wood_qualities_ids?.map((id, index) => (
      <span key={index}>
        <small>
          {
            woodTypes?.find($ => $.id === measurement?.wood_types_ids[index])
              ?.name
          }
        </small>
        /{woodQualities?.find($ => $.id === id)?.name}
        <br />
      </span>
    ))
  } else {
    woodInformation = measurement?.wood_type_ids?.map((id, i) => (
      <span key={i}>
        <small>{woodTypes?.find($ => $.id === id)?.name}</small>
        <br />
      </span>
    ))
  }

  return measurement ? (
    <tr style={{ background: "white" }} className="border-top">
      <td style={{ textAlignLast: "center" }}>
        <input
          type="checkbox"
          onChange={() => {
            if (isSelected) {
              setSelectedMeasurements(
                selectedMeasurements.filter($ => $.id !== measurement?.id)
              )
            } else {
              setSelectedMeasurements([...selectedMeasurements, measurement])
            }
          }}
          checked={isSelected}
        />
      </td>
      <td>
        <div className="d-flex align-items-center">
          {manual ? (
            <FontAwesomeIcon
              icon={faPencilRuler}
              className="text-primary me-2"
              style={{
                width: "60px",
                height: "60px",
                padding: "1rem",
                border: "1px solid #dee2e6",
                borderRadius: "4px"
              }}
            />
          ) : (
            <Image
              thumbnail
              src={measurement?.photo_thumb}
              onError={e => (e.target.src = "/android-chrome-192x192.png")}
              onClick={() => {
                window.history.pushState("forward", null, "#editor")
                measurementEditor({
                  id: measurement?.id,
                  type: "truck",
                  storage
                })
              }}
              className="me-2 hover-dark"
              style={{
                width: "64px",
                height: "64px",
                cursor: "pointer"
              }}
            />
          )}
          {manual ? (
            <Link
              className="text-primary link"
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/truck_measurement/" + measurement?.id,
                state: { measurement }
              }}
            >
              {measurement?.created_at.substring(0, 10)}
            </Link>
          ) : (
            <p style={{ whiteSpace: "break-spaces" }}>
              <Link
                className="text-primary link"
                style={{ textDecoration: "none" }}
                to={{
                  pathname: "/truck_measurement/" + measurement?.id,
                  state: { measurement }
                }}
              >
                {measurement?.photo_file_name
                  ? measurement?.photo_file_name
                  : measurement?.shown_name}
              </Link>
              {/* {!measurement?.validity && (
                <FontAwesomeIcon
                  title="Invalid measurement"
                  icon={faBan}
                  className="ms-2"
                  style={{ color: "tomato" }}
                />
              )} */}
              <br />
              <small style={{ color: "black" }}>
                <strong>{measurement?.id}</strong> /{" "}
                {measurement?.created_at.substring(0, 10)}
              </small>
            </p>
          )}
        </div>
      </td>
      <td>
        {measurementTypes[measurement["measurement_type_id"]] || "ENUM_ERROR"}
      </td>
      <td>
        {latitude && longitude ? (
          <div>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              size="lg"
              className="ms-2 me-2 text-primary"
              onClick={() => navigate("/truck_measurement/" + measurement?.id)}
            />
            <span className="me-2 small">
              {latitude.toFixed(5)}, {longitude.toFixed(5)}
            </span>
          </div>
        ) : (
          <span className="ms-2">–</span>
        )}
      </td>
      <td>{woodInformation}</td>
      <td>
        {measurement?.log_lengths?.map(($, i) => (
          <span key={i}>
            <small>{$}</small>
            <br />
          </span>
        ))}
      </td>
      <td style={{ textAlign: "center" }}>
        {Number(measurement?.volume).toFixed(roundingPrecision)} {volumeUnit}
        <br></br>
        <small>{measurement?.coefficient}</small>
      </td>
    </tr>
  ) : null
}
