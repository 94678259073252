import {
  faBuilding,
  faCheck,
  faCircle,
  faEye,
  faEyeSlash,
  faTabletScreenButton,
  faUser
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner
} from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { PASSWORD_DOES_NOT_MEET_REQUIREMENTS } from "./LoginForm"
import { LocaleSelector } from "common/navigation/LocaleSelector"
const countriesJSON = require("./countries.json")

export default function RegisterForm() {
  const [name, setName] = useState("")
  const [contactPerson, setContactPerson] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("")
  // const [promotionCode, setPromotionCode] = useState("")
  const [registering, setRegistering] = useState(false)
  const [industry, setIndustry] = useState("")
  const [nextFields, setNextFields] = useState(false)
  const [dashboards, setDashboards] = useState(["timber"])
  const [showPassword, setShowPassword] = useState(false)
  const [signedUp, setSignedUp] = useState(false)
  const navigate = useNavigate()
  const fetch = useFetch()
  const countries = [...countriesJSON]
  countries.sort((a, b) => {
    a = Object.entries(a)[0][1]
    b = Object.entries(b)[0][1]
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })

  useEffect(() => {
    if (!dashboards.length) {
      setDashboards(["timber"])
    }
  }, [dashboards])

  function handleSubmit(event) {
    event.preventDefault()
    setRegistering(true)

    if (password !== confirmPassword) {
      setRegistering(false)
      return toast.error(<Trans>Passwords do not match</Trans>)
    }

    const nameStrings = name.split(" ").filter($ => $.length >= 3)
    const contactPersonStrings = contactPerson
      .split(" ")
      .filter($ => $.length >= 3)
    const personalDataStrings = [...nameStrings, ...contactPersonStrings]
    let passwordContainsPersonalData = false

    personalDataStrings.forEach(str => {
      if (password.toLowerCase().includes(str.toLowerCase())) {
        passwordContainsPersonalData = true
      }
    })

    if (passwordContainsPersonalData) {
      setRegistering(false)
      return toast.error(<Trans>Password must not contain personal data</Trans>)
    }

    fetch("/registration", {
      method: "POST",
      body: {
        signup: {
          name,
          phone,
          contact_person: contactPerson,
          country_alpha2: country,
          admin_password: password,
          admin_email: email,
          industry,
          dashboards
        }
      }
    })
      .then(response => {
        if (response.error === PASSWORD_DOES_NOT_MEET_REQUIREMENTS) {
          return toast.error(
            <Trans>Password must not contain any personal data</Trans>
          )
        }

        localStorage.setItem("access_token", response.access_token)
        localStorage.setItem(
          "access_token_expires_at",
          (response.created_at + response.expires_in) * 1000
        )
        setSignedUp(true)
      })
      .catch(error => {
        toast.error(<Trans>Registration failed</Trans>)
      })
      .finally(() => setRegistering(false))
  }

  useEffect(() => {
    if (dashboards.length === 0) {
      setDashboards(["timber"])
    }
  }, [dashboards])

  const passwordAtleast12Characters = password?.length >= 12
  const passwordContains1Uppercase = /[A-Z]/.test(password)
  const passwordContains1Lowercase = /[a-z]/.test(password)
  const passwordContains1Number = /[0-9]/.test(password)
  const passwordContains1Symbol = /[`!@#€$%^&*() _+\-={};':"|,.<>?~]/.test(
    password
  )

  // const toggleTimber = () =>
  //   dashboards.includes("timber")
  //     ? setDashboards(dashboards.filter($ => $ !== "timber"))
  //     : setDashboards([...dashboards, "timber"])

  // const toggleContainer = () =>
  //   dashboards.includes("container")
  //     ? setDashboards(dashboards.filter($ => $ !== "container"))
  //     : setDashboards([...dashboards, "container"])

  // const toggleTruck = () =>
  //   dashboards.includes("truck")
  //     ? setDashboards(dashboards.filter($ => $ !== "truck"))
  //     : setDashboards([...dashboards, "truck"])

  // const toggleLumber = () =>
  //   dashboards.includes("lumber")
  //     ? setDashboards(dashboards.filter($ => $ !== "lumber"))
  //     : setDashboards([...dashboards, "lumber"])

  return (
    <Container className="position-absolute top-50 start-50 translate-middle">
      <title>
        <Trans>Login</Trans> | Timbeter
      </title>
      <Row className="justify-content-center">
        <Col xs="auto">
          <div className="text-center mb-4 mt-2">
            <Image style={{ width: "16rem" }} fluid src="/logos/timbeter.svg" />
          </div>
          <Card style={{ width: "20rem" }}>
            <Card.Body>
              <div className="d-flex justify-content-center">
                <div
                  className={`align-self-center text-primary rounded-start`}
                  style={{ borderTop: "5px solid", width: "15%" }}
                ></div>
                <Badge
                  pill
                  bg="primary"
                  className="cursor-pointer"
                  onClick={() => setNextFields(false)}
                >
                  <FontAwesomeIcon
                    size="xl"
                    icon={faBuilding}
                    className="p-1"
                  />
                </Badge>
                <div
                  className={`align-self-center ${
                    nextFields ? "text-primary" : "text-secondary"
                  }`}
                  style={{ borderTop: "5px solid", width: "25%" }}
                ></div>
                <Badge pill bg={nextFields ? "primary" : "secondary"}>
                  <FontAwesomeIcon size="xl" icon={faUser} className="p-1" />
                </Badge>
                <div
                  className={`align-self-center ${
                    signedUp ? "text-primary" : "text-secondary"
                  }`}
                  style={{ borderTop: "5px solid", width: "25%" }}
                ></div>
                <Badge pill bg={signedUp ? "primary" : "secondary"}>
                  <FontAwesomeIcon size="xl" icon={faCheck} className="p-1" />
                </Badge>
                <div
                  className={`align-self-center ${
                    signedUp ? "text-primary" : "text-secondary"
                  } rounded-end`}
                  style={{ borderTop: "5px solid", width: "15%" }}
                ></div>
              </div>
              <div className="d-flex mb-2">
                <span
                  className="text-primary mt-1 text-center"
                  style={{
                    fontSize: "0.625rem",
                    fontWeight: 500,
                    width: "35%"
                  }}
                >
                  <Trans>Organization</Trans>
                </span>
                <span
                  className={
                    nextFields
                      ? "text-primary text-center mt-1"
                      : "text-center text-black-50 mt-1"
                  }
                  style={{
                    fontSize: "0.625rem",
                    fontWeight: 500,
                    width: "29%"
                  }}
                >
                  <Trans>Account Details</Trans>
                </span>
                <span
                  className={`text-center ${
                    signedUp ? "text-primary" : "text-black-50"
                  } mt-1`}
                  style={{
                    fontSize: "0.625rem",
                    fontWeight: 500,
                    width: "35%"
                  }}
                >
                  <Trans>Done</Trans>
                </span>
              </div>
              {!signedUp && (
                <Form onSubmit={handleSubmit}>
                  {!nextFields && (
                    <div>
                      <Form.Group>
                        <Form.Label>
                          <Trans>Organization name</Trans>
                        </Form.Label>
                        <Form.Control
                          className="mb-2"
                          name="name"
                          onChange={event => setName(event.target.value)}
                          value={name}
                          disabled={registering}
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Trans>Contact person</Trans>
                        </Form.Label>
                        <Form.Control
                          className="mb-2"
                          name="contact_person"
                          onChange={event =>
                            setContactPerson(event.target.value)
                          }
                          value={contactPerson}
                          disabled={registering}
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Trans>Industry</Trans>
                        </Form.Label>
                        <Form.Select
                          className="mb-2"
                          value={industry}
                          onChange={event => setIndustry(event.target.value)}
                          disabled={registering}
                          required
                        >
                          <option key="placeholder" hidden value>
                            {t`Select industry...`}
                          </option>
                          {[
                            {
                              sawmill: "Sawmill"
                            },
                            {
                              forest_owner: "Forest Owner"
                            },
                            {
                              pulp_paper_mill: "Pulp/Paper Mill"
                            },
                            {
                              container_export: "Container Export"
                            },
                            {
                              electric_poles: "Electric Poles"
                            },
                            {
                              timber_transport: "Timber Transport"
                            },
                            {
                              forestry_organization: "Forestry Organization"
                            },
                            {
                              fuelwood_manufacturer: "Fuelwood Manufacturer"
                            },
                            {
                              other: "Other"
                            }
                          ].map($ => {
                            const [value, label] = Object.entries($)[0]

                            return (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Trans>Country</Trans>
                        </Form.Label>
                        <Form.Select
                          className="mb-2"
                          name="country_alpha2"
                          value={country}
                          onChange={event => setCountry(event.target.value)}
                          disabled={registering}
                          required
                        >
                          <option key="placeholder" hidden value>
                            {t`Select country...`}
                          </option>
                          {countries.map($ => {
                            const [value, label] = Object.entries($)[0]

                            return (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                      {/* <Form.Group>
                        <Form.Label>
                          <Trans>I'm interested in...</Trans>
                        </Form.Label>
                        <div className="d-flex justify-content-between flex-wrap">
                          <Form.Check
                            type="checkbox"
                            checked={dashboards?.includes("timber")}
                            className="d-flex small"
                            onChange={toggleTimber}
                            label={t`Timber`}
                          />
                          <Form.Check
                            type="checkbox"
                            checked={dashboards?.includes("container")}
                            className="d-flex small"
                            onChange={toggleContainer}
                            label={t`Container`}
                          />
                          <Form.Check
                            type="checkbox"
                            checked={dashboards?.includes("truck")}
                            className="d-flex small"
                            onChange={toggleTruck}
                            label={t`Truck`}
                          />
                          <Form.Check
                            type="checkbox"
                            checked={dashboards?.includes("lumber")}
                            className="d-flex small"
                            onChange={toggleLumber}
                            label={t`Lumber`}
                          />
                        </div>
                      </Form.Group> */}
                    </div>
                  )}
                  {nextFields && (
                    <div>
                      <Form.Group>
                        <Form.Label>
                          <Trans>Email</Trans>
                        </Form.Label>
                        <Form.Control
                          className="mb-2"
                          name="admin_email"
                          type="email"
                          onChange={event => setEmail(event.target.value)}
                          value={email}
                          disabled={registering}
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Trans>Phone</Trans>
                        </Form.Label>
                        <Form.Control
                          className="mb-2"
                          name="phone"
                          value={phone}
                          onChange={event => setPhone(event.target.value)}
                          disabled={registering}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label>
                          <Trans>Password</Trans>
                        </Form.Label>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          spellCheck={false}
                          autoCorrect="false"
                          autoCapitalize="false"
                          name="admin_password"
                          value={password}
                          onChange={event => setPassword(event.target.value)}
                          disabled={registering}
                          required
                        />
                        <Button
                          title={t`Show password`}
                          onClick={() => setShowPassword(!showPassword)}
                          variant="none"
                          tabIndex={-1}
                          style={{
                            position: "absolute",
                            right: 0,
                            marginTop: "-30px",
                            marginRight: "28px"
                          }}
                          className="p-0 border-0"
                        >
                          {password &&
                            (showPassword ? (
                              <FontAwesomeIcon
                                className="opacity-25"
                                icon={faEyeSlash}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="opacity-25"
                                icon={faEye}
                              />
                            ))}
                        </Button>
                        <Form.Text
                          as="p"
                          className="text-black-50 opacity-75 mt-1 lh-1"
                        >
                          <span
                            className={`${
                              passwordAtleast12Characters
                                ? "text-success"
                                : "text-danger"
                            } me-2 text-nowrap`}
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="xs"
                              className="me-1"
                            />
                            <Trans>Minimum length 12</Trans>
                          </span>
                          <span
                            className={`${
                              passwordContains1Uppercase
                                ? "text-success"
                                : "text-danger"
                            } me-2 text-nowrap`}
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="xs"
                              className="me-1"
                            />
                            <Trans>Contains 1 uppercase letter</Trans>
                          </span>
                          <span
                            className={`${
                              passwordContains1Lowercase
                                ? "text-success"
                                : "text-danger"
                            } me-2 text-nowrap`}
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="xs"
                              className="me-1"
                            />
                            <Trans>Contains 1 lowercase letter</Trans>
                          </span>
                          <span
                            className={`${
                              passwordContains1Number
                                ? "text-success"
                                : "text-danger"
                            } me-2 text-nowrap`}
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="xs"
                              className="me-1"
                            />
                            <Trans>Contains 1 number</Trans>
                          </span>
                          <span
                            className={`${
                              passwordContains1Symbol
                                ? "text-success"
                                : "text-danger"
                            } me-2 text-nowrap`}
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              size="xs"
                              className="me-1"
                            />
                            <Trans>Contains 1 symbol</Trans>
                          </span>
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Trans>Confirm password</Trans>
                        </Form.Label>
                        <Form.Control
                          className="mb-2"
                          name="admin_password_confirm"
                          type={showPassword ? "text" : "password"}
                          spellCheck={false}
                          autoCorrect="false"
                          autoCapitalize="false"
                          value={confirmPassword}
                          onChange={event =>
                            setConfirmPassword(event.target.value)
                          }
                          disabled={registering}
                          required
                        />
                        <Button
                          title={t`Show password`}
                          onClick={() => setShowPassword(!showPassword)}
                          variant="none"
                          style={{
                            position: "absolute",
                            right: 0,
                            marginTop: "-38px",
                            marginRight: "28px"
                          }}
                          className="p-0 border-0"
                        >
                          {confirmPassword &&
                            (showPassword ? (
                              <FontAwesomeIcon
                                className="opacity-25"
                                icon={faEyeSlash}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="opacity-25"
                                icon={faEye}
                              />
                            ))}
                        </Button>
                      </Form.Group>
                    </div>
                  )}
                  <div className="d-grid mt-3 gap-3">
                    {nextFields ? (
                      <Button
                        type="submit"
                        disabled={
                          !(
                            passwordContains1Uppercase &&
                            passwordAtleast12Characters &&
                            passwordContains1Lowercase &&
                            passwordContains1Number &&
                            passwordContains1Symbol
                          )
                        }
                      >
                        {registering ? (
                          <Spinner
                            variant="light"
                            size="sm"
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <Trans>Sign up</Trans>
                        )}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        onClick={() => setNextFields(true)}
                        disabled={
                          !name || !contactPerson || !industry || !country
                        }
                      >
                        <Trans>Next</Trans>
                      </Button>
                    )}
                  </div>
                </Form>
              )}
              {signedUp && (
                <div className="mt-4 text-center">
                  <div className="my-5">
                    <FontAwesomeIcon
                      icon={faTabletScreenButton}
                      size="3x"
                      className="opacity-25"
                    />
                    <p className="small text-muted my-2">
                      <Trans>
                        In order to start using Timbeter you will need to
                        connect a device.
                      </Trans>
                    </p>
                  </div>
                  <div className="d-grid position-relative mt-4">
                    <Button
                      type="button"
                      onClick={() =>
                        navigate("/", {
                          replace: true
                        })
                      }
                    >
                      <Trans>Continue</Trans>
                    </Button>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
          <div className="small text-center mt-3">
            {!signedUp && (
              <div>
                <span className="text-white-50">
                  <Trans>Already have an account?</Trans>
                </span>{" "}
                <Link
                  className="text-light text-decoration-none fw-medium"
                  to="/login"
                >
                  <Trans>Log In</Trans>
                </Link>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div style={{ width: "min-content", margin: "0 auto" }} className="mt-3">
        <LocaleSelector loggedIn={false} />
      </div>
    </Container>
  )
}
