import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { accountSettingsState } from "common/recoil/atoms"
import { useState } from "react"
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import { Navigate } from "react-router-dom"
import { useRecoilValue } from "recoil"

export default function Verification() {
  const account = useRecoilValue(accountSettingsState)
  const fetch = useFetch()
  const [sent, setSent] = useState(false)
  const resendVerificationEmail = () => {
    fetch("/users/resend_verification", {
      method: "post"
    }).then(response => {
      if (response) {
        setSent(true)
      }
    })
  }
  if (account?.verified_at !== null) {
    return <Navigate replace to="/" />
  }

  return (
    <Container className="position-absolute top-50 start-50 translate-middle">
      <title>
        <Trans>Verification</Trans> | Timbeter
      </title>
      <Row className="justify-content-center">
        <Col xs="auto">
          <div className="text-center mb-4 mt-2">
            <Image
              style={{ width: "16rem" }}
              fluid
              src="/logos/timbeter.svg"
              alt="Timbeter logo"
            />
          </div>
          <Card className="text-center p-3" body>
            <FontAwesomeIcon
              icon={faEnvelopeCircleCheck}
              size="3x"
              className="opacity-25 pb-2"
            />
            <h5>
              <Trans>Verify your email address</Trans>
            </h5>
            <p className="pb-3">
              <Trans>
                Timbeter Dashboard requires email address verification.
              </Trans>
            </p>
            <div className="pb-1">
              {account.verification_sent_at === null ? (
                <Button
                  disabled={sent}
                  className="rounded-pill px-3"
                  variant="info"
                  onClick={resendVerificationEmail}
                >
                  {sent ? (
                    <Trans>Email has been sent to</Trans>
                  ) : (
                    <Trans>Send email to</Trans>
                  )}{" "}
                  {account.email}
                </Button>
              ) : (
                <Button
                  disabled={sent}
                  className="rounded-pill px-3"
                  variant="info"
                  onClick={resendVerificationEmail}
                >
                  {sent ? (
                    <Trans>Email has been sent to</Trans>
                  ) : (
                    <Trans>Resend email to</Trans>
                  )}{" "}
                  {account.email}
                </Button>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
