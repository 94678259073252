import { Trans, t } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { selectorFamily, useRecoilValue, useSetRecoilState } from "recoil"
import { featureEnabled, woodQualitiesState } from "common/recoil/atoms"
import useFetch from "common/hooks/useFetch"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import ResourceNotFoundAlert from "common/other/ResourceNotFoundAlert"

const woodQualitySelector = selectorFamily({
  key: "woodQualitySelector",
  get:
    id =>
    ({ get }) =>
      id ? get(woodQualitiesState).find($ => $.id === id) : null
})

export default function EditWoodQualityCard() {
  const { id } = useParams()
  const woodQuality = useRecoilValue(woodQualitySelector(Number(id)))
  const setWoodQualities = useSetRecoilState(woodQualitiesState)
  const [woodQualityName, setWoodQualityName] = useState(woodQuality?.name)
  const fetch = useFetch()
  const navigate = useNavigate()
  const enabled = useRecoilValue(featureEnabled("timber_management"))

  return woodQuality ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit wood quality</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="edit-wood-quality" onSubmit={updateWoodQuality}>
          <Form.Group>
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              disabled={!enabled}
              value={woodQualityName}
              placeholder="Enter a name"
              onChange={event => setWoodQualityName(event?.target?.value)}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          className="float-start"
          onClick={() => navigate("/timber/qualities")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="secondary"
          className="me-3"
          onClick={deleteWoodQuality}
        >
          <Trans>Archive</Trans>
        </Button>
        <Button
          type="submit"
          form="edit-wood-quality"
          disabled={woodQualityName === woodQuality?.name}
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <ResourceNotFoundAlert />
  )

  function deleteWoodQuality() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/wood_qualities/" + id, {
        method: "DELETE"
      }).then(response => {
        navigate("/timber/qualities")
        setWoodQualities(qualities =>
          qualities.filter($ => $.id !== Number(id))
        )
        toast.success(<Trans>Archived</Trans>)
      })
    }
  }

  function updateWoodQuality() {
    const newWoodQuality = {
      name: woodQualityName
    }

    fetch("/wood_qualities/" + id, {
      method: "PATCH",
      body: newWoodQuality
    }).then(response => {
      setWoodQualities(qualities =>
        qualities.map($ =>
          $.id === Number(id)
            ? {
                ...woodQuality,
                ...newWoodQuality
              }
            : $
        )
      )
      toast.success(<Trans>Saved</Trans>)
    })
  }
}
