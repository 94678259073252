import { faEye, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { EDIT, PLANKS, VIEW } from "common/enums/constants"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from "recoil"
import { editorSettingsState } from "."
import { selectedPlankState } from "./Plank"
import useDetectChanges from "./useDetectChanges"
import { measurementEditorState, selectedLogState } from "./useEditorState"

export default function ModeControls() {
  const [mode, setMode] = useRecoilState(editorSettingsState("mode"))
  const setEdit = useSetRecoilState(editorSettingsState("edit"))
  const { id, type: measurementType } = useRecoilValue(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({ id, type: measurementType })
  )
  const resetSelectedLog = useResetRecoilState(selectedLogState)
  const resetSelectedPlank = useResetRecoilState(selectedPlankState)
  const [locked, setLocked] = useState()
  const detectChanges = useDetectChanges()

  useEffect(() => {
    setLocked(!!measurement?.locked_at)
  }, [measurement])

  return (
    <div className="d-flex align-items-center">
      <Button
        id="view-mode"
        onClick={() => {
          detectChanges(() => {
            setMode(VIEW)
            resetSelectedLog()
            resetSelectedPlank()
          })
        }}
        variant={mode === VIEW ? "light" : "outline-light"}
        className={`d-flex flex-column rounded-0 border-0 border-start border-light border-opacity-10 align-items-center ${
          mode !== VIEW && "opacity-50"
        }`}
        style={{ width: "3rem" }}
      >
        <FontAwesomeIcon icon={faEye} />
        <span className="mt-1" style={{ fontSize: "0.625rem" }}>
          <Trans>View</Trans>
        </span>
      </Button>
      <Button
        id="edit-mode"
        disabled={locked || measurement.inventory_id}
        variant={
          mode === EDIT
            ? "light"
            : measurement.inventory_id
            ? "outline-warning"
            : "outline-light"
        }
        onClick={() => {
          setMode(EDIT)
          if (measurementType === "lumber") {
            setEdit(PLANKS)
          }
        }}
        className={`d-flex flex-column rounded-0 border-0  border-end border-light border-opacity-10 align-items-center ${
          mode !== EDIT && "opacity-50"
        }`}
        style={{ width: "3rem" }}
      >
        <FontAwesomeIcon icon={faPen} />
        <span className="mt-1" style={{ fontSize: "0.625rem" }}>
          <Trans>Edit</Trans>
        </span>
      </Button>
    </div>
  )
}
