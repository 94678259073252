import { Trans } from "@lingui/macro"
import { Button, ButtonGroup, Table } from "react-bootstrap"
import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue
} from "recoil"
import LumberMeasurementRow from "./LumberMeasurementRow"
import { querySelector } from "common/recoil/selectors"

export const lumberMeasurementsState = atom({
  key: "lumberMeasurements",
  default: []
})

export const lumberStorageMeasurementsPageState = atomFamily({
  key: "lumberMeasurementsPageState",
  default: 1
})

export const lumberMeasurementsQuery = selectorFamily({
  key: "lumberMeasurementsQuery",
  get:
    ({ page, storageId }) =>
    ({ get }) => {
      const response = get(
        querySelector({
          url: "/lumber_measurements/search",
          options: {
            method: "POST",
            body: {
              storage_id: storageId,
              page: page
            }
          }
        })
      )

      if (response && response?.total_pages >= 1) {
        return {
          meta: {
            total_pages: response?.total_pages
          },
          measurements: response?.measurements
        }
      } else {
        return {
          meta: {
            total_pages: 0
          },
          measurements: []
        }
      }
    }
})

export default function LumberMeasurementsTable({ storageId }) {
  const [page, setPage] = useRecoilState(
    lumberStorageMeasurementsPageState(storageId)
  )
  const {
    measurements,
    meta: { total_pages }
  } = useRecoilValue(
    lumberMeasurementsQuery({
      storageId,
      page
    })
  )

  return measurements?.length > 0 ? (
    <Table>
      <thead>
        <tr>
          <th>
            <Trans>Measurement</Trans>
          </th>
          <th>
            <Trans>Wood type</Trans>
          </th>
          <th>
            <Trans>Product</Trans>
          </th>
          <th>
            <Trans>Length</Trans>
          </th>
          <th>
            <Trans>Count</Trans>
          </th>
          <th>
            <Trans>Volume</Trans>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {measurements.map($ => (
          <LumberMeasurementRow key={$.id} measurement={$} />
        ))}
        <tr className="bg-white">
          <td className="p-0 text-center" colSpan={5}>
            <ButtonGroup size="sm">
              <Button
                className="rounded-0 fw-medium"
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
              >
                <Trans>Previous</Trans>
              </Button>
              <Button variant="light" className="text-primary pe-none">
                <Trans>Page</Trans> {page}
              </Button>
              <Button
                className="rounded-0 fw-medium"
                onClick={() => setPage(page + 1)}
                disabled={total_pages === page}
              >
                <Trans>Next</Trans>
              </Button>
            </ButtonGroup>
            {/* <small className="ms-2 text-black-50">{total_pages} <Trans>total</Trans></small> */}
          </td>
        </tr>
      </tbody>
    </Table>
  ) : null
}
