import { faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { VIEW } from "common/enums/constants"
import useFetch from "common/hooks/useFetch"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { editorSettingsState } from "."
import { measurementEditorState } from "./useEditorState"

export default function EditingLockControls() {
  const { id: measurementId, type: measurementType } = useRecoilValue(
    measurementEditorState
  )
  const [measurement, setMeasurement] = useRecoilState(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const [locked, setLocked] = useState()
  const setMode = useSetRecoilState(editorSettingsState("mode"))
  const fetch = useFetch()
  const handleClick = () => {
    setMode(VIEW)
    fetch("/containers/measurements/" + measurementId, {
      method: "PATCH",
      body: {
        locked_at: locked ? null : new Date()
      }
    }).then(response => {
      if (response) {
        setMeasurement({
          ...measurement,
          locked_at: response.locked_at
        })
      }
    })
  }

  useEffect(() => {
    setLocked(!!measurement?.locked_at)
  }, [measurement])

  return (
    <div className="border-end border-light border-opacity-10 d-flex">
      <Button
        className="d-flex flex-column align-items-center border-0 rounded-0"
        onClick={() => handleClick()}
        variant={locked ? "light" : "outline-light"}
      >
        <FontAwesomeIcon
          icon={faLock}
          className={locked ? "text-danger" : ""}
        />
        <span className="mt-1" style={{ fontSize: "0.625rem" }}>
          {locked ? <Trans>Unlock</Trans> : <Trans>Lock</Trans>}
        </span>
      </Button>
    </div>
  )
}
