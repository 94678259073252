import {
  faFileExcel,
  faFilter,
  faLocationArrow,
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import UpgradePlanCard from "common/other/UpgradePlanCard"
import {
  featureEnabled,
  measurementsModeState,
  selectedMeasurementsState
} from "common/recoil/atoms"
import { Suspense, useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import TruckMap from "truck-measurement/map/TruckMap"
import TruckFilters from "truck-measurement/TruckFilters"
import TruckStorageRowList from "truck-measurement/TruckStorageRowList"
import Toolbar from "./Toolbar"
import useExportsState from "exports/useExportsState"

export default function TruckDashboard() {
  const [showFilters, setShowFilters] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [module, setModule] = useRecoilState(measurementsModeState)
  const selectedMeasurements = useRecoilValue(selectedMeasurementsState)
  const fetch = useFetch()
  const { createTruckDashboardExcelReport } = useExportsState()
  const truckDetectionEnabled = useRecoilValue(
    featureEnabled("truck_detection")
  )
  const navigate = useNavigate()
  useEffect(() => {
    if (module === "truck") return
    fetch("/account", {
      method: "PATCH",
      body: {
        user: {
          recent_measurement_mode: "truck"
        }
      }
    }).then(() => setModule("truck"))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="d-flex flex-wrap gap-3">
        <Button
          className="rounded-pill px-3"
          variant={showFilters ? "light" : "outline-light"}
          onClick={() => setShowFilters(!showFilters)}
        >
          <FontAwesomeIcon icon={faFilter} className="me-2" />
          <Trans>Search filters</Trans>
        </Button>
        <Button
          className="rounded-pill px-3"
          variant={showMap ? "light" : "outline-light"}
          onClick={() => setShowMap(!showMap)}
        >
          <FontAwesomeIcon className="me-2" icon={faLocationArrow} />
          <Trans>Truck logistics</Trans>
        </Button>
        <Button
          className="rounded-pill px-3"
          variant="light"
          onClick={createTruckDashboardExcelReport}
        >
          <FontAwesomeIcon icon={faFileExcel} className="me-2" />
          <Trans>Export to excel</Trans>
        </Button>
        <Button
          className="rounded-pill px-3"
          variant="light"
          onClick={() => navigate("/storage/new")}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          <Trans>New storage</Trans>
        </Button>
      </div>

      <hr className="text-light" />
      {showFilters && (
        <Card bg="light" body>
          <Suspense fallback={<Loading color="dark" />}>
            <TruckFilters />
          </Suspense>
        </Card>
      )}
      {showMap ? (
        truckDetectionEnabled ? (
          <Suspense fallback>
            <TruckMap />
          </Suspense>
        ) : (
          <UpgradePlanCard feature={t`truck logistics`} />
        )
      ) : null}
      {selectedMeasurements?.length > 0 ? <Toolbar /> : null}
      <TruckStorageRowList />
    </>
  )
}
