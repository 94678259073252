import { Col, Row } from "react-bootstrap"
import DetailsCard from "./OrganizationDetailsCard"
import AdditionalInformationCard from "./AdditionalInformationCard"
import { Suspense } from "react"
import Loading from "common/other/Loading"

export default function OrganizationPage() {
  return (
    <Row>
      <Col md={6} className="mb-4">
        <div className="mb-4">
          <DetailsCard />
        </div>
        {/* <div>
            <InvoicesCard />
          </div> */}
      </Col>
      <Col md={6} className="mb-4">
        <Suspense fallback={<Loading />}>
          <AdditionalInformationCard />
        </Suspense>
      </Col>
    </Row>
  )
}
