import { t } from "@lingui/macro"
import { referencesState } from "common/recoil/atoms"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { planksState } from "."
import { logsState, measurementEditorState } from "./useEditorState"
import useShapesActions from "./useShapesActions"

export default function useDetectChanges() {
  const { id, type: measurementType } = useRecoilValue(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id,
      type: measurementType
    })
  )
  const setLogs = useSetRecoilState(logsState)
  const setReferences = useSetRecoilState(referencesState)
  const setPlanks = useSetRecoilState(planksState)
  const { logsChanged, referenceChanged, planksChanged } = useShapesActions()

  return callback => {
    if (measurementType !== "lumber") {
      let changes = false

      if (logsChanged()) {
        changes = true
        // console.log("logs changed")
      }

      if (referenceChanged()) {
        changes = true
        // console.log("reference changed")
      }

      if (changes) {
        if (window.confirm(t`Discard unsaved changes?`)) {
          setLogs(measurement?.logs || [])
          setReferences(measurement.references)
          return callback()
        }

        return document.getElementById("view-mode").blur()
      }
    }

    if (measurementType === "lumber") {
      let changes = false

      if (planksChanged()) {
        changes = true
      }

      if (changes) {
        if (window.confirm(t`Discard unsaved changes?`)) {
          setPlanks(measurement.planks)
          return callback()
        }

        return document.getElementById("view-mode").blur()
      }
    }

    callback()
  }
}
