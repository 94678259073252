export default function formatVolumeUnit(volumeUnit) {
  return (
    {
      "m^3": "m³",
      "ft^3": "ft³",
      fbm: "FBM",
      pmt: "PMT",
      stere: "st"
    }[volumeUnit] || volumeUnit
  )
}
