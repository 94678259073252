import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Select from "common/other/Select"
import { measurementsStateFamily } from "common/recoil/atoms"
import { woodAssortmentsState } from "common/recoil/atoms"
import { volumeUnitSelector } from "common/recoil/selectors"
import { Card, Table } from "react-bootstrap"
import { toast } from "react-toastify"
import { useRecoilValue, useSetRecoilState } from "recoil"

export default function MeasurementVolumeTable({ logs, measurementId }) {
  const setMeasurement = useSetRecoilState(
    measurementsStateFamily(measurementId)
  )
  const fetch = useFetch()
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const volumeUnit = useRecoilValue(volumeUnitSelector)
  const uniqueAssortmentIds = [
    ...new Set(logs.map($ => $.wood_characteristic_id))
  ]
  const logsTotalVolume = logs.reduce((acc, val) => acc + val.v, 0).toFixed(2)
  const uniqueAssortments = uniqueAssortmentIds.map(id => {
    const woodAssortment = woodAssortments.find($ => $.id === id)
    const volume = logs
      .reduce(
        (acc, val) =>
          val.wood_characteristic_id === id && !val.cull_id ? acc + val.v : acc,
        0
      )
      .toFixed(2)
    return {
      ...woodAssortment,
      volume,
      volumePercentage: ((volume / logsTotalVolume) * 100).toFixed(2),
      count: logs.filter($ => $.wood_characteristic_id === id && !$.cull_id)
        .length
    }
  })
  const cullVolume = logs
    .reduce((acc, val) => (val.cull_id ? acc + val.v : acc), 0)
    .toFixed(2)
  const cullVolumePercentage = ((cullVolume / logsTotalVolume) * 100).toFixed(2)
  const cullCount = logs.filter($ => $.cull_id).length

  return uniqueAssortmentIds?.length > 1 ? (
    <Card>
      <Card.Body className="pb-0">
        <Card.Title className="w-full d-inline-flex justify-content-between mb-0">
          <span className="text-nowrap mb-3 align-self-center">
            <Trans>Volume</Trans>
          </span>
        </Card.Title>
      </Card.Body>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <Trans>Assortment</Trans>
            </th>
            <th>
              <Trans>Log length</Trans>
            </th>
            <th>
              <Trans>Volume</Trans>
            </th>
            <th>
              <Trans>Count</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {uniqueAssortments.map($ => {
            return (
              <tr key={$.name || t`Default assortment`}>
                <td className="w-100">
                  <Select
                    value={{
                      value: $.id || 0,
                      label: $.name || t`Default assortment`
                    }}
                    onChange={event => {
                      updateAssortment($.id, event.value)
                    }}
                    options={woodAssortments?.map($ => ({
                      value: $.id,
                      label: $.name
                    }))}
                  />
                </td>
                <td>{$.log_length}</td>
                <td>
                  {$.volume > 0 ? $.volume : 0} {volumeUnit} /{" "}
                  {$.volumePercentage > 0 ? $.volumePercentage : 0}%
                </td>
                <td>{$.count}</td>
              </tr>
            )
          })}
          <tr key="cull">
            <td>
              <Trans>Cull</Trans>
            </td>
            <td></td>
            <td>
              {cullVolume} {volumeUnit} /{" "}
              {cullVolumePercentage > 0 ? cullVolumePercentage : 0}%
            </td>
            <td>{cullCount}</td>
          </tr>
          <tr key="total" className="table-info">
            <td>
              <b>
                <Trans>Total</Trans>
              </b>
            </td>
            <td />
            <td>
              {logsTotalVolume > 0 ? logsTotalVolume : 0} {volumeUnit}
            </td>
            <td>{logs.length}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
  ) : null

  function updateAssortment(from, to) {
    if (from !== to) {
      fetch(`/measurements/${measurementId}`, {
        method: "PATCH",
        body: {
          logs: logs
            .filter($ => $.wood_characteristic_id === from)
            .map($ => ({
              ...$,
              wood_characteristic_id: to
            }))
        }
      }).then(response => {
        if (response) {
          setMeasurement($ => ({
            ...$,
            logs: response.logs
          }))
          toast.success(<Trans>Your changes have been saved</Trans>)
        }
      })
    }
  }
}
