import { useRecoilState } from "recoil"
import { languageState } from "common/recoil/atoms"
import useFetch from "common/hooks/useFetch"
import { useState } from "react"
import { Dropdown } from "react-bootstrap"
import Spinner from "common/other/Spinner"
import { Trans } from "@lingui/macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"

export function LocaleSelector({ loggedIn = true }) {
  const fetch = useFetch()
  const [language, setLanguage] = useRecoilState(languageState)
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false)

  function updateLanguage(newLanguage) {
    if (!loggedIn) {
      setLanguage(newLanguage)
      return
    }

    setIsUpdatingLanguage(true)
    fetch("/account", {
      method: "PATCH",
      body: {
        user: {
          locale: frontToBackLocaleMap[newLanguage]
        }
      }
    })
      .then(response => setLanguage(newLanguage))
      .finally(() => window.location.reload())
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        align="start"
        variant="light"
        className="py-1 px-2 border w-full"
      >
        {isUpdatingLanguage ? (
          <Spinner />
        ) : (
          <div>
            <div>
              <FontAwesomeIcon icon={faGlobe} className="me-1" />
              <Trans>Change language</Trans>
            </div>
            {loggedIn && (
              <small className="text-black-50">{flagIconMap[language]}</small>
            )}
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu align="start" className="p-0 rounded">
        <div className="text-center bg-secondary-subtle rounded d-flex flex-wrap justify-content-center">
          {[
            "en",
            "et",
            "ru",
            "pl",
            "lt",
            // "de",
            "fr",
            "pt",
            "es"
            // "ja",
            // "it",
            // "ka",
            // "hi"
          ]
            .filter($ => $ !== language)
            .map(languageCode => (
              <Dropdown.Item
                key={languageCode}
                className="px-2 w-auto"
                onClick={() => updateLanguage(languageCode)}
              >
                <span>{flagIconMap[languageCode]}</span>
              </Dropdown.Item>
            ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const flagIconMap = {
  en: "English",
  et: "Eesti",
  ru: "Русский",
  pl: "Polski",
  lt: "Lietuvių",
  de: "Deutsch",
  fr: "Français",
  pt: "Português",
  es: "Español",
  ja: "日本語",
  it: "Italiano",
  ka: "ქართული",
  hi: "हिंदी"
}

const frontToBackLocaleMap = {
  en: "en",
  et: "et",
  ru: "ru",
  pl: "pl",
  lt: "lt",
  de: "de",
  fr: "fr",
  pt: "pt_BR",
  es: "es",
  ja: "ja",
  it: "it",
  ka: "ka-GE",
  hi: "hi"
}
