import { Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Dropdown, Form, InputGroup, Modal } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil"
import { storagesState, woodAssortmentsState } from "common/recoil/atoms"
import Select from "common/other/Select"
import measurementTypes from "common/enums/measurementTypes"
import useFetch from "common/hooks/useFetch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"

export default function AddManualMeasurementModal({ storage }) {
  const refreshStorage = useRecoilRefresher_UNSTABLE(storagesState)
  const [show, setShow] = useState(false)
  const assortments = useRecoilValue(woodAssortmentsState)
  const [measurementDate, setMeasurementDate] = useState(null)
  const [measurementType, setMeasurementType] = useState(null)
  const [volume, setVolume] = useState("")
  const [assortment, setAssortment] = useState(null)
  const fetch = useFetch()

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <Trans>Add manual measurement</Trans>
        <span className="float-end">
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </span>
      </Dropdown.Item>

      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>New manual measurement</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Storage</Trans>
              </Form.Label>
              <Form.Control readOnly disabled defaultValue={storage.name} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Measured on</Trans>
              </Form.Label>
              <ReactDatePicker
                dateFormat="dd.MM.yyyy"
                className="form-control no-focus"
                selected={measurementDate}
                onChange={date => {
                  setMeasurementDate(date)
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                required
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Measurement type</Trans>
              </Form.Label>
              <Select
                onChange={event => setMeasurementType(event.value)}
                value={
                  measurementType
                    ? {
                        value: measurementType,
                        label: measurementTypes[measurementType]
                      }
                    : null
                }
                options={Object.entries(measurementTypes).map(([id, type]) => ({
                  value: id,
                  label: type
                }))}
                required={true}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Volume</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  onChange={event => setVolume(event.target.value)}
                  value={volume}
                  className="no-focus form-control-append"
                  placeholder="Enter volume"
                  required
                />
                <InputGroup.Text>m³</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Wood assortment</Trans>
              </Form.Label>
              <Select
                required
                onChange={event => setAssortment(event.value)}
                value={
                  assortment && {
                    value: assortment,
                    label: assortments?.filter($ => $.id === assortment)[0]
                      ?.name
                  }
                }
                options={assortments?.map($ => ({
                  value: $.id,
                  label: $.name
                }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <Trans>Comment</Trans>
              </Form.Label>
              <Form.Control style={{ resize: "none" }} as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" onClick={submitManualMeasurement}>
            <Trans>Save</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )

  async function submitManualMeasurement(event) {
    const manual_measurement = {
      taken_at: new Intl.DateTimeFormat("et-EE").format(measurementDate),
      measurement_type_id: Number(measurementType),
      volume: Number(volume),
      wood_characteristic_id: assortment,
      storage_id: storage.id
    }

    fetch("/manual_measurements", {
      method: "POST",
      body: {
        manual_measurement
      }
    })
      .then(response => {
        if (response) {
          toast.success(<Trans>Added</Trans>)
          refreshStorage()
        }
      })
      .finally(() => setShow(false))
  }
}
