import React, { Suspense, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Outlet, Route, Routes, useLocation } from "react-router-dom"
import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { en } from "make-plural/plurals"
import { useRecoilValue } from "recoil"
import { toast, ToastContainer } from "react-toastify"
import { accountSettingsState, languageState } from "common/recoil/atoms"
import LoadingPage from "common/other/LoadingPage.js"
import TimberView from "timber/index.js"
import AccountPage from "account/index.js"
import OrganizationPage from "organization/index.js"
import CustomFieldsRoute from "custom_fields/index.js"
import Login from "login"
import AnalyzeReportsRoute from "analyze_reports"
import ExportsRoute from "exports"
import ReportDetailView from "reports/EditReportCard"
import LumberMeasurementPage from "lumber-measurement"
import Loading from "common/other/Loading"
import NewUserCard from "users/NewUserCard"
import WoodQualitiesTable from "timber/WoodQuality/WoodQualitiesTable"
import WoodSpeciesTable from "timber/WoodSpecies/WoodSpeciesTable"
import NewWoodSpeciesCard from "timber/WoodSpecies/NewWoodSpeciesCard"
import Protected from "common/navigation/Protected"
import ExportsNotifications from "ExportsNotifications"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"
import useAuthActions from "login/useAuthActions"
import Sidebar from "dashboard/Sidebar"
import TimberDashboard from "dashboard/TimberDashboard"
import ContainerDashboard from "dashboard/ContainerDashboard"
import TruckDashboard from "dashboard/TruckDashboard"
import LumberDashboard from "dashboard/LumberDashboard"
import Index from "dashboard"
import ErrorBoundary from "common/other/ErrorBoundary"
import CustomField from "custom_fields/EditCustomFieldCard"
import NewWoodQualityCard from "timber/WoodQuality/NewWoodQualityCard"
import EditWoodQualityCard from "timber/WoodQuality/EditWoodQualityCard"
import AdditionalFeatures from "billing/AdditionalFeatures"
import NewCustomField from "custom_fields/NewCustomFieldCard"
import NewReport from "reports/NewReportCard"
import NewAnalyzeReport from "analyze_reports/NewAnalyzeReport"
import EditAnalyzeReport from "analyze_reports/EditAnalyzeReport"
import RegisterForm from "login/RegisterForm"
import Header from "dashboard/Header"
import { t, Trans } from "@lingui/macro"
import SubscriptionPlans from "billing/SubscriptionPlans"
import NotFound from "common/other/NotFound"
import TimbeterMeasurements from "billing/TimbeterMeasurements"
import Invoices from "billing/Invoices"
import Billing from "billing"
import Overview from "billing/Overview"
import Setup from "setup"
import Settings from "settings"
import Measurements from "dashboard/Measurements"
import PublicMeasurement from "public-measurement"
import PublicStorage from "public-storage"
import DeletedMeasurements from "dashboard/DeletedMeasurements"
import ResetForm from "login/ResetForm"
import Inventories from "inventories"
import WoodSpeciesIndex from "timber/WoodSpecies"
import EditWoodSpeciesCard from "timber/WoodSpecies/EditWoodSpeciesCard"
import WoodQualitiesIndex from "timber/WoodQuality"
import WoodAssortmentsIndex from "timber/WoodAssortment"
import WoodAssortmentsTable from "timber/WoodAssortment/WoodAssortmentsTable"
import EditWoodAssortmentCard from "timber/WoodAssortment/EditWoodAssortmentCard"
import NewWoodAssortmentCard from "timber/WoodAssortment/NewWoodAssortmentCard"
import DiameterProfilesIndex from "timber/DiameterProfile"
import NewDiameterProfileCard from "timber/DiameterProfile/NewDiameterProfileCard"
import EditDiameterProfileCard from "timber/DiameterProfile/EditDiameterProfileCard"
import DiameterProfilesTable from "timber/DiameterProfile/DiameterProfilesTable"
import WoodCullsIndex from "timber/WoodCull"
import NewWoodCullCard from "timber/WoodCull/NewWoodCullCard"
import EditWoodCullCard from "timber/WoodCull/EditWoodCullCard"
import WoodCullsTable from "timber/WoodCull/WoodCullsTable"
import LumberProductsIndex from "timber/LumberProducts"
import NewLumberProductCard from "timber/LumberProducts/NewLumberProductCard"
import EditLumberProductCard from "timber/LumberProducts/EditLumberProductCard"
import LumberProductsTable from "timber/LumberProducts/LumberProductsTable"
import DevicesIndex from "devices/index.js"
import DevicesTable from "devices/DevicesTable"
import EditDeviceCard from "devices/EditDeviceCard"
import NewDeviceCard from "devices/NewDeviceCard"
import EditDeviceAccessCard from "devices/EditDeviceAccessCard"
import UsersIndex from "users/index.js"
import UsersTable from "users/UsersTable"
import EditUserCard from "users/EditUserCard"
import EditUserAccessCard from "users/EditUserAccessCard"
import CustomFieldsTable from "custom_fields/CustomFieldsTable"
import ReportsIndex from "reports"
import ReportsTable from "reports/ReportsTable"
import StorageDetailView from "storage"
import AddStorage from "timber-measurement/AddStorage"
import TruckMeasurementView from "truck-measurement"
import MeasurementSingleView from "timber-measurement"
import MeasurementEditor from "timber-measurement/editor"
import AcceptInvitationForm from "login/AcceptInvitationForm"
import PublicBillOfLading from "public-bill-of-lading"
import PublicContainer from "public-container"
import PartnerLogin from "partner"
import Verification from "login/Verification"
import CMPCDashboard from "cmpc-dashboard"
import TruckDiameterMeasurementDetailView from "cmpc-dashboard/truck-diameter-measurement"
import ContainerMeasurementDetailView from "container-measurement"
import ReportsNotifications from "ReportsNotifications"

function ScrollToTop(props) {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return <div>{props.children}</div>
}

function Layout() {
  const { id, type } = useRecoilValue(measurementEditorState)
  const location = useLocation()
  const { logOut } = useAuthActions()
  const account = useRecoilValue(accountSettingsState)

  useEffect(() => {
    localStorage.setItem("last_activity", Date.now())
    const setLastActivity = () =>
      localStorage.setItem("last_activity", Date.now())
    const checkLastActivity = () => {
      if (
        Date.now() - localStorage.getItem("last_activity") > 840000 &&
        Date.now() - localStorage.getItem("last_activity") < 850000
      ) {
        toast.warn(
          <Trans>Session will expire in 1 minute due to inactivity</Trans>
        )
      }

      if (Date.now() - localStorage.getItem("last_activity") >= 900000) {
        logOut({ sessionExpired: true })
      }
    }

    const inactivityInterval = setInterval(checkLastActivity, 30000)
    document.addEventListener("click", setLastActivity)

    return () => {
      document.removeEventListener("click", setLastActivity)
      clearInterval(inactivityInterval)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (account) {
      window.Intercom("boot", {
        app_id: "zgewn51r",
        email: account.email,
        user_id: account.id,
        name: account.name
      })
    }
  }, [account])

  return (
    <Protected>
      <Header />
      <Container fluid>
        <Row className="flex-nowrap">
          <Sidebar />
          <Col
            as="main"
            xs="12"
            md="8"
            xl="9"
            xxxl="10"
            className="ms-auto p-0"
          >
            <Container fluid className="px-4 py-3">
              <ErrorBoundary key={location.pathname}>
                <Suspense fallback={<Loading text={t`Loading...`} />}>
                  <Outlet />
                </Suspense>
              </ErrorBoundary>
            </Container>
          </Col>
        </Row>
      </Container>
      {id && type && <MeasurementEditor />}
      <ExportsNotifications />
      <ReportsNotifications />
    </Protected>
  )
}

export default function App() {
  const language = useRecoilValue(languageState)
  const { logOut } = useAuthActions()

  useEffect(() => {
    const handler = () => logOut({ sessionExpired: true })
    const serverErrorHandler = () => {
      toast.error(<Trans>Something went wrong</Trans>)
      logOut({ sessionExpired: false })
    }
    window.addEventListener("expired_token", handler)
    window.addEventListener("server_error", serverErrorHandler)

    return () => {
      window.removeEventListener("server_error", serverErrorHandler)
      window.removeEventListener("expired_token", handler)
    }
  }, [logOut])

  useEffect(() => {
    async function dynamicActivate(locale) {
      try {
        const { messages } = await import(`./locales/${locale}/messages`)
        i18n.load(locale, messages)
        i18n.loadLocaleData({
          en: {
            plurals: en
          }
        })
        i18n.activate(locale)
      } catch {
        window.location.reload()
      }
    }

    dynamicActivate(language)
  }, [language])

  return (
    <I18nProvider forceRenderOnLocaleChange={false} i18n={i18n}>
      <Suspense fallback={<LoadingPage />}>
        <ScrollToTop>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/partner" element={<PartnerLogin />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/reset_password/:token" element={<ResetForm />} />
            <Route
              path="/accept_invitation/:token"
              element={<AcceptInvitationForm />}
            />
            <Route
              path="/public_measurement/:token"
              element={<PublicMeasurement />}
            />
            <Route path="/vmu_public/:token" element={<PublicMeasurement />} />
            <Route
              path="/public_bill_of_lading/:token"
              element={<PublicBillOfLading />}
            />
            <Route
              path="/public_container/:token"
              element={<PublicContainer />}
            />
            <Route path="/public_storage/:token" element={<PublicStorage />} />
            <Route
              path="/setup"
              element={
                <Protected>
                  <Setup />
                </Protected>
              }
            />
            <Route path="/" element={<Layout />}>
              <Route index element={<Index />} />
              <Route
                path="/measurements/cmpc"
                element={
                  <Protected>
                    <CMPCDashboard />
                  </Protected>
                }
              />
              <Route path="/measurements" element={<Measurements />}>
                <Route
                  path="/measurements/timber"
                  element={<TimberDashboard />}
                />
                <Route
                  path="/measurements/timber/deleted"
                  element={<DeletedMeasurements />}
                />
                <Route
                  path="/measurements/container"
                  element={<ContainerDashboard />}
                />
                <Route
                  path="/measurements/truck"
                  element={<TruckDashboard />}
                />
                <Route
                  path="/measurements/lumber"
                  element={<LumberDashboard />}
                />
              </Route>
              <Route
                path="/measurement/:id"
                element={<MeasurementSingleView />}
              />
              <Route
                path="/truck_measurement/:id"
                element={<TruckMeasurementView />}
              />
              <Route
                path="/container_measurement/:id"
                element={<ContainerMeasurementDetailView />}
              />
              <Route
                path="/lumber_measurement/:id"
                element={<LumberMeasurementPage />}
              />
              <Route
                path="/truck_diameter_measurement/:id"
                element={<TruckDiameterMeasurementDetailView />}
              />
              <Route path="/storage/:id" element={<StorageDetailView />} />
              <Route path="/storage/new" element={<AddStorage />} />
              <Route path="/inventories" element={<Inventories />} />
              <Route path="/timber" element={<TimberView />}>
                <Route path="/timber/species" element={<WoodSpeciesIndex />}>
                  <Route index element={<WoodSpeciesTable />} />
                  <Route
                    path="/timber/species/new"
                    element={<NewWoodSpeciesCard />}
                  />
                  <Route
                    path="/timber/species/:id"
                    element={<EditWoodSpeciesCard />}
                  />
                </Route>
                <Route
                  path="/timber/qualities"
                  element={<WoodQualitiesIndex />}
                >
                  <Route index element={<WoodQualitiesTable />} />
                  <Route
                    path="/timber/qualities/new"
                    element={<NewWoodQualityCard />}
                  />
                  <Route
                    path="/timber/qualities/:id"
                    element={<EditWoodQualityCard />}
                  />
                </Route>
                <Route
                  path="/timber/assortments"
                  element={<WoodAssortmentsIndex />}
                >
                  <Route index element={<WoodAssortmentsTable />} />
                  <Route
                    path="/timber/assortments/new"
                    element={<NewWoodAssortmentCard />}
                  />
                  <Route
                    path="/timber/assortments/:id"
                    element={<EditWoodAssortmentCard />}
                  />
                </Route>
                <Route path="/timber/culls" element={<WoodCullsIndex />}>
                  <Route index element={<WoodCullsTable />} />
                  <Route
                    path="/timber/culls/new"
                    element={<NewWoodCullCard />}
                  />
                  <Route
                    path="/timber/culls/:id"
                    element={<EditWoodCullCard />}
                  />
                </Route>
                <Route
                  path="/timber/lumber_products"
                  element={<LumberProductsIndex />}
                >
                  <Route index element={<LumberProductsTable />} />
                  <Route
                    path="/timber/lumber_products/new"
                    element={<NewLumberProductCard />}
                  />
                  <Route
                    path="/timber/lumber_products/:id"
                    element={<EditLumberProductCard />}
                  />
                </Route>
                <Route
                  path="/timber/diameter_profiles"
                  element={<DiameterProfilesIndex />}
                >
                  <Route index element={<DiameterProfilesTable />} />
                  <Route
                    path="/timber/diameter_profiles/new"
                    element={<NewDiameterProfileCard />}
                  />
                  <Route
                    path="/timber/diameter_profiles/:id"
                    element={<EditDiameterProfileCard />}
                  />
                </Route>
              </Route>
              <Route path="/users" element={<UsersIndex />}>
                <Route index element={<UsersTable />} />
                <Route path="/users/new" element={<NewUserCard />} />
                <Route
                  path="/users/:id"
                  element={
                    <div>
                      <EditUserCard />
                      <EditUserAccessCard />
                    </div>
                  }
                />
              </Route>
              <Route path="/devices" element={<DevicesIndex />}>
                <Route index element={<DevicesTable />} />
                <Route path="/devices/new" element={<NewDeviceCard />} />
                <Route
                  path="/devices/:id"
                  element={
                    <div>
                      <EditDeviceCard />
                      <EditDeviceAccessCard />
                    </div>
                  }
                />
              </Route>
              <Route path="/analyze_reports" element={<AnalyzeReportsRoute />}>
                <Route
                  path="/analyze_reports/new"
                  element={<NewAnalyzeReport />}
                />
                <Route
                  path="/analyze_reports/:id"
                  element={<EditAnalyzeReport />}
                />
              </Route>
              <Route path="/exports" element={<ExportsRoute />} />
              <Route path="/reports" element={<ReportsIndex />}>
                <Route index element={<ReportsTable />} />
                <Route path="/reports/new" element={<NewReport />} />
                <Route path="/reports/:id" element={<ReportDetailView />} />
              </Route>
              <Route path="/custom_fields" element={<CustomFieldsRoute />}>
                <Route index element={<CustomFieldsTable />} />
                <Route path="/custom_fields/new" element={<NewCustomField />} />
                <Route
                  path="/custom_fields/:id"
                  element={
                    <Suspense fallback={<Loading />}>
                      <CustomField />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="/billing" element={<Billing />}>
                <Route
                  index
                  element={
                    <div>
                      <Overview />
                      <SubscriptionPlans />
                      <AdditionalFeatures />
                    </div>
                  }
                />
                <Route path="/billing/invoices" element={<Invoices />} />
                <Route
                  path="/billing/measurements"
                  element={<TimbeterMeasurements />}
                />
              </Route>
              <Route path="/settings" element={<Settings />}>
                <Route path="/settings/account" element={<AccountPage />} />
                <Route
                  path="/settings/organization"
                  element={<OrganizationPage />}
                />
              </Route>
              <Route path="/*" element={<NotFound />} />
            </Route>
          </Routes>
        </ScrollToTop>
        <ToastContainer
          position="top-center"
          hideProgressBar
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme="light"
          style={{
            width: "auto"
          }}
        />
      </Suspense>
    </I18nProvider>
  )
}
