import { useEffect } from "react"
import Pusher from "pusher-js"
import { useRecoilCallback, useRecoilValue } from "recoil"
import { excelExportsState, pdfExportsState } from "exports/useExportsState"
import { useNavigate } from "react-router-dom"
import { accountSettingsState } from "common/recoil/atoms"
import { Trans } from "@lingui/macro"
import { toast } from "react-toastify"

const pusherAppKey =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENV === "staging"
    ? "e67995f91093b97e0841"
    : process.env.NODE_ENV === "production"
    ? "f33f23d9d0260c105bd2"
    : ""
const pusher = new Pusher(pusherAppKey, {
  cluster: "eu"
})
const channel = pusher.subscribe("export-channel")

export default function ExportsNotifications() {
  const navigate = useNavigate()
  const account = useRecoilValue(accountSettingsState)
  const getExcelReports = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(excelExportsState).contents
  )
  const getPdfReports = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(pdfExportsState).contents
  )
  const updatePdfReport = useRecoilCallback(
    ({ snapshot, set }) =>
      report =>
        set(
          pdfExportsState,
          snapshot.getLoadable(pdfExportsState).contents.map($ =>
            $.id === report.id
              ? {
                  ...$,
                  ...report
                }
              : $
          )
        )
  )
  const updateExcelReport = useRecoilCallback(
    ({ snapshot, set }) =>
      report =>
        set(
          excelExportsState,
          snapshot.getLoadable(excelExportsState).contents.map($ =>
            $.id === report.id
              ? {
                  ...$,
                  ...report
                }
              : $
          )
        )
  )

  const addPdfReport = useRecoilCallback(
    ({ snapshot, set }) =>
      report =>
        set(pdfExportsState, [
          ...(snapshot.getLoadable(pdfExportsState)?.contents || []),
          report
        ])
  )
  const addExcelReport = useRecoilCallback(
    ({ snapshot, set }) =>
      report =>
        set(excelExportsState, [
          ...(snapshot.getLoadable(excelExportsState)?.contents || []),
          report
        ])
  )

  function excelExportCreated(data) {
    const excelExports = getExcelReports()
    if (data?.excel_export.user_id !== account.id) return
    toast.info(
      <p onClick={() => navigate("/exports")}>
        <b>{data.excel_export.name}</b>{" "}
        <Trans>has finished generating (Excel Export)</Trans>
      </p>,
      {
        autoClose: 15000,
        closeButton: true
      }
    )
    const existing = excelExports?.find($ => $.id === data.excel_export.id)
    if (existing) {
      // setExcelExports($$ =>
      //   $$.map($ => {
      //     if ($.id !== data.excel_export.id) return $
      //     return data.excel_export
      //   })
      // )
      updateExcelReport(data.excel_export)
    } else {
      addExcelReport(data.excel_export)
    }
  }

  function pdfExportCreated(data) {
    const pdfExports = getPdfReports()
    if (data?.pdf_export.user_id !== account.id) return
    toast.info(
      <p onClick={() => navigate("/exports")}>
        <b>{data.pdf_export.name}</b>{" "}
        <Trans>has finished generating (PDF Export)</Trans>
      </p>,
      {
        autoClose: 15000,
        closeButton: true
      }
    )
    const existing =
      pdfExports?.length > 0
        ? pdfExports.find($ => $.id === data.pdf_export.id)
        : false
    if (existing) {
      // setPdfExports($$ =>
      //   $$.map($ => {
      //     if ($.id !== data.pdf_export.id) return $
      //     return data.pdf_export
      //   })
      // )
      updatePdfReport(data.pdf_export)
    } else {
      addPdfReport(data.pdf_export)
    }
  }

  useEffect(() => {
    channel.bind("pdf-export-created", pdfExportCreated)
    channel.bind("excel-export-created", excelExportCreated)

    return () => {
      channel.unbind("pdf-export-created")
      channel.unbind("export-export-created")
    }
    // eslint-disable-next-line
  }, [])

  return <div></div>
}
