import { Trans } from "@lingui/macro"

const measurementTypes = {
  0: <Trans>Inventory</Trans>,
  1: <Trans>Incoming</Trans>,
  2: <Trans>Outgoing</Trans>,
  3: <Trans>Uncategorized</Trans>
}

export default measurementTypes
