import { Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Dropdown, Form, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"
import { useRecoilValue } from "recoil"
import { billsOfLadingState } from "common/recoil/atoms"
import Select from "common/other/Select"

export default function EditContainerModal({ container }) {
  const [show, setShow] = useState(false)
  const [name, setName] = useState(container?.name || "")
  const [billOfLading, setBillOfLading] = useState(
    container?.bill_of_lading_id || ""
  )
  const [sealNumber, setSealNumber] = useState(container?.seal_number || "")
  const [weight, setWeight] = useState(container?.weight || "")
  const [width, setWidth] = useState(container?.width || "")
  const [height, setHeight] = useState(container?.height || "")
  const bills = useRecoilValue(billsOfLadingState)

  const fetch = useFetch()

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <Trans>Edit</Trans>
        <span className="float-end">
          <FontAwesomeIcon icon={faEdit} />
        </span>
      </Dropdown.Item>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faEdit} className="me-2" />
            <Trans>Edit Container</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="edit-container" onSubmit={submitHandler}>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Name</Trans>
              </Form.Label>
              <Form.Control
                value={name}
                onChange={event => setName(event?.target?.value)}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Bill of lading</Trans>
              </Form.Label>
              <Select
                value={{
                  value: billOfLading,
                  label: bills?.find($ => $.id === billOfLading)?.name
                }}
                onChange={event => setBillOfLading(event.value)}
                options={bills?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Seal number</Trans>
              </Form.Label>
              <Form.Control
                value={sealNumber}
                onChange={event => setSealNumber(event?.target?.value)}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Weight</Trans>
              </Form.Label>
              <Form.Control
                value={weight}
                onChange={event => setWeight(event?.target?.value)}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Width</Trans>
              </Form.Label>
              <Form.Control
                value={width}
                onChange={event => setWidth(event?.target?.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <Trans>Height</Trans>
              </Form.Label>
              <Form.Control
                value={height}
                onChange={event => setHeight(event?.target?.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" type="submit" form="edit-container">
            <Trans>Save</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )

  async function submitHandler(event) {
    event.preventDefault()

    const newContainer = {
      ...container,
      name: name,
      seal_number: sealNumber,
      bill_of_lading_id: billOfLading,
      weight: weight,
      width: width,
      height: height
    }

    fetch(`/containers/containers/${container.id}`, {
      method: "PATCH",
      body: {
        container: newContainer
      }
    })
      .then(response => {
        if (!response) {
          return toast.error(<Trans>Failed to save changes</Trans>)
        }
        // setContainer(newContainer)
        toast.success(<Trans>Saved changes!</Trans>)
      })
      .finally(() => setShow(false))
  }
}
