import {
  Alert,
  Button,
  Card,
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { deviceState } from "devices/EditDeviceCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCog,
  faExclamationCircle,
  faTablet
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "@lingui/macro"
import { useNavigate } from "react-router-dom"

export default function MeasurementDeviceCard({ deviceId }) {
  const device = useRecoilValue(deviceState(deviceId))
  const navigate = useNavigate()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faTablet} className="me-2" />
        <Trans>Measurement device</Trans>
      </Card.Header>
      {device ? (
        <Card.Body>
          <FormGroup className="mb-3">
            <FormLabel>
              <Trans>Name</Trans>
            </FormLabel>
            <FormControl defaultValue={device.name || "–"} disabled readOnly />
          </FormGroup>
          <FormGroup className="mb-4">
            <FormLabel>
              <Trans>Email</Trans>
            </FormLabel>
            <FormControl
              defaultValue={device.user_email || "–"}
              disabled
              readOnly
            />
          </FormGroup>
          {/* <FormGroup className="mb-4">
            <FormLabel>
              <Trans>Hardware ID</Trans>
            </FormLabel>
            <FormControl defaultValue={device.hw_id} disabled readOnly />
          </FormGroup> */}
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => navigate("/devices/" + deviceId)}
          >
            <FontAwesomeIcon icon={faCog} className="me-2 opacity-50" />
            <Trans>Device settings</Trans>
          </Button>
        </Card.Body>
      ) : (
        <Alert variant="warning" className="m-0 rounded">
          <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
          <Trans>Device not found</Trans>{" "}
          <small className="opacity-50">ID: {deviceId}</small>
        </Alert>
      )}
    </Card>
  )
}
