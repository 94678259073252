import { t, Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { selectorFamily, useRecoilValue, useSetRecoilState } from "recoil"
import { featureEnabled, woodTypesState } from "common/recoil/atoms"
import useFetch from "common/hooks/useFetch"
import { useNavigate, useParams } from "react-router-dom"
import UpgradePlanCard from "common/other/UpgradePlanCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import ResourceNotFoundAlert from "common/other/ResourceNotFoundAlert"

const woodSpeciesSelector = selectorFamily({
  key: "woodSpeciesSelector",
  get:
    id =>
    ({ get }) =>
      id ? get(woodTypesState).find(species => species.id === id) : null
})

export default function EditWoodSpeciesCard() {
  const { id } = useParams()
  const woodSpecies = useRecoilValue(woodSpeciesSelector(Number(id)))
  const setWoodSpecies = useSetRecoilState(woodTypesState)
  const [woodSpeciesName, setWoodSpeciesName] = useState(woodSpecies?.name)
  const fetch = useFetch()
  const navigate = useNavigate()
  const enabled = useRecoilValue(featureEnabled("timber_management"))

  return woodSpecies ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit wood species</Trans>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              disabled={!enabled}
              value={woodSpeciesName}
              onChange={event => setWoodSpeciesName(event?.target?.value)}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        {enabled ? (
          <div className="text-end">
            <Button
              className="float-start"
              variant="outline-secondary"
              style={{ borderColor: "transparent" }}
              onClick={() => navigate("/timber/species")}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              className="me-3"
              variant="secondary"
              onClick={deleteWoodSpecies}
            >
              <Trans>Archive</Trans>
            </Button>
            <Button
              onClick={updateWoodSpecies}
              disabled={woodSpeciesName === woodSpecies?.name}
            >
              <Trans>Save</Trans>
            </Button>
          </div>
        ) : (
          <UpgradePlanCard feature={t`timber management`} />
        )}
      </Card.Footer>
    </Card>
  ) : (
    <ResourceNotFoundAlert />
  )

  function updateWoodSpecies() {
    const updatedWoodSpecies = {
      name: woodSpeciesName
    }

    fetch("/wood_types/" + id, {
      method: "PATCH",
      body: updatedWoodSpecies
    }).then(response => {
      setWoodSpecies(species =>
        species.map($ =>
          $.id === Number(id)
            ? {
                ...woodSpecies,
                ...updatedWoodSpecies
              }
            : $
        )
      )
      toast.success(<Trans>Saved</Trans>)
    })
  }

  function deleteWoodSpecies() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/wood_types/" + id, {
        method: "DELETE"
      }).then(response => {
        navigate("/timber/species")
        setWoodSpecies(species => species.filter($ => $.id !== Number(id)))
        toast.success(<Trans>Archived</Trans>)
      })
    }
  }
}
