import useFetch from "common/hooks/useFetch"
import { measurementsModeState } from "common/recoil/atoms"
import { querySelector } from "common/recoil/selectors"
import LumberStoragesTable from "lumber-measurement/LumberStoragesTable"
import { useEffect } from "react"
import { selector, useRecoilState, useRecoilValue } from "recoil"

export const lumberStoragesQuery = selector({
  key: "lumberStoragesQuery",
  get: async ({ get }) => {
    try {
      const response = await get(
        querySelector("/dashboard_filter?lumber_measurements=true")
      )
      return response?.storages?.length > 0 ? response.storages : []
    } catch {
      return []
    }
  }
})

export default function LumberDashboard() {
  const lumberStorages = useRecoilValue(lumberStoragesQuery)
  const [module, setModule] = useRecoilState(measurementsModeState)
  const fetch = useFetch()
  useEffect(() => {
    if (module === "lumber") return
    fetch("/account", {
      method: "PATCH",
      body: {
        user: {
          recent_measurement_mode: "lumber"
        }
      }
    }).then(() => setModule("lumber"))
    // eslint-disable-next-line
  }, [])

  return <LumberStoragesTable storages={lumberStorages} />
}
