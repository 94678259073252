import { faFolderOpen, faRotate } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { billingCurrencySelector } from "billing"
import {
  Badge,
  Button,
  Card,
  OverlayTrigger,
  Table,
  Tooltip
} from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { invoicesState } from "./Overview"

export default function Invoices() {
  const invoices = useRecoilValue(invoicesState)
  return (
    <Card>
      <Table responsive>
        <thead>
          <tr className="initialism table-active">
            <th>
              <Trans>Invoice Number</Trans>
            </th>
            <th>
              <Trans>Amount</Trans>
            </th>
            <th>
              <Trans>Details</Trans>
            </th>
            <th>
              <Trans>Created</Trans>
            </th>
            <th>
              <Trans>Status</Trans>
            </th>
            <th className="text-center">
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <Trans>Refresh payments</Trans>
                  </Tooltip>
                }
              >
                <Button
                  onClick={() => document.location.reload()}
                  size="sm"
                  variant=""
                  className="opacity-50 p-0 lh-1 border-0"
                >
                  <FontAwesomeIcon icon={faRotate} className="opacity-50" />
                </Button>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {invoices.length > 0 ? (
            invoices
              .filter($ => $.total !== 0)
              .map(invoice => (
                <InvoiceRow key={invoice.number} invoice={invoice} />
              ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                <div className="my-3">
                  <FontAwesomeIcon
                    icon={faFolderOpen}
                    size="3x"
                    className="opacity-25 mb-2"
                  />
                  <p className="h6 text-black-50">
                    <Trans>No invoices found</Trans>
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  )
}

function InvoiceRow({ invoice }) {
  const paid = invoice.status === "paid"
  const open = invoice.status === "open"
  const canceled = invoice.status === "void"
  const billingCurrency = useRecoilValue(billingCurrencySelector)

  return (
    <tr>
      <td>{invoice.number}</td>
      <td>
        {billingCurrency}
        {invoice.total}
      </td>
      <td>{invoice.lines.join()}</td>
      <td>
        {new Date(invoice.created_at * 1000).toLocaleString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric"
        })}
      </td>
      <td>
        {paid && (
          <Badge>
            <Trans>Paid</Trans>
          </Badge>
        )}
        {open && (
          <Badge bg="info">
            <Trans>Pending</Trans>
          </Badge>
        )}
        {canceled && (
          <Badge bg="secondary">
            <Trans>Void</Trans>
          </Badge>
        )}
      </td>
      <td>
        {!open ? (
          <Button
            onClick={() => window.open(invoice.url, "_blank").focus()}
            size="sm"
            variant="secondary"
            className="w-full opacity-50"
          >
            <Trans>View</Trans>
          </Button>
        ) : (
          <Button
            onClick={() => window.open(invoice.url, "_blank").focus()}
            size="sm"
            variant="warning"
            className="w-full"
          >
            <Trans>Pay</Trans>
          </Button>
        )}
      </td>
    </tr>
  )
}
