import {
  faCircle,
  faEye,
  faEyeSlash,
  faUserLock
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { accountSettingsState } from "common/recoil/atoms"
import {
  INVALID_EMAIL_OR_PASSWORD,
  PASSWORD_ALREADY_USED,
  PASSWORD_DOES_NOT_MEET_REQUIREMENTS
} from "login/LoginForm"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"

export default function PasswordCard() {
  const account = useRecoilValue(accountSettingsState)
  const [passwordExpiry, setPasswordExpiry] = useState(account.password_expiry)
  const [passwordExpiresToggle, setPasswordExpiresToggle] = useState(
    passwordExpiry !== null ? true : false
  )
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const fetch = useFetch()

  const changed =
    (currentPassword.length &&
      newPassword === confirmNewPassword &&
      newPassword.length) ||
    !!passwordExpiry !== passwordExpiresToggle

  function updatePassword(event) {
    event.preventDefault()

    const currentTime = new Date().getTime()
    const in30Days = currentTime + 2592000000
    fetch("/users/" + account.id, {
      method: "PATCH",
      body: {
        user: {
          current_password: currentPassword,
          password: newPassword,
          password_confirmation: confirmNewPassword,
          password_expiry: passwordExpiry
            ? null
            : new Date(in30Days).toISOString()
        }
      }
    })
      .then(response => {
        if (response && !response.error) {
          toast.success(<Trans>Your changes have been saved</Trans>)
          if (!passwordExpiry) {
            setPasswordExpiry(new Date(in30Days).toISOString())
          } else {
            setPasswordExpiry(null)
          }
        }

        if (response.error === INVALID_EMAIL_OR_PASSWORD) {
          return toast.error(<Trans>Invalid current password</Trans>)
        }

        if (
          response.error === PASSWORD_ALREADY_USED ||
          response.error === PASSWORD_DOES_NOT_MEET_REQUIREMENTS
        ) {
          return toast.error(
            <Trans>
              New password must not match current password. New password must
              not contain any of the previous passwords
            </Trans>
          )
        }
      })
      .catch(error => {
        toast.error(<Trans>Failed to save changes</Trans>)
      })
      .finally(() => {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmNewPassword("")
      })
  }

  const passwordAtleast12Characters = newPassword?.length >= 12
  const passwordContains1Uppercase = /[A-Z]/.test(newPassword)
  const passwordContains1Lowercase = /[a-z]/.test(newPassword)
  const passwordContains1Number = /[0-9]/.test(newPassword)
  const passwordContains1Symbol = /[`!@#€$%^&*() _+\-={};':"|,.<>?~]/.test(
    newPassword
  )

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faUserLock} className="me-2" />
        <Trans>Password settings</Trans>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={updatePassword} id="change-password">
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Current password</Trans>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              spellCheck={false}
              autoCorrect="false"
              autoCapitalize="false"
              value={currentPassword}
              onChange={event => setCurrentPassword(event.target.value)}
            />
            <Button
              title={t`Show password`}
              onClick={() => setShowPassword(!showPassword)}
              variant="none"
              tabIndex={-1}
              style={{
                position: "absolute",
                right: 0,
                marginTop: "-30px",
                marginRight: "28px"
              }}
              className="p-0 border-0"
            >
              {currentPassword &&
                (showPassword ? (
                  <FontAwesomeIcon className="opacity-25" icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon className="opacity-25" icon={faEye} />
                ))}
            </Button>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>New password</Trans>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              spellCheck={false}
              autoCorrect="false"
              autoCapitalize="false"
              value={newPassword}
              onChange={event => setNewPassword(event.target.value)}
            />
            <Button
              title={t`Show password`}
              onClick={() => setShowPassword(!showPassword)}
              variant="none"
              style={{
                position: "absolute",
                right: 0,
                marginTop: "-30px",
                marginRight: "28px"
              }}
              className="p-0 border-0"
            >
              {newPassword &&
                (showPassword ? (
                  <FontAwesomeIcon className="opacity-25" icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon className="opacity-25" icon={faEye} />
                ))}
            </Button>
            <Form.Text as="p" className="text-black-50 opacity-75 mt-1 lh-1">
              <span
                className={`${
                  newPassword?.length
                    ? passwordAtleast12Characters
                      ? "text-success"
                      : "text-danger"
                    : "text-secondary"
                } me-2 text-nowrap`}
              >
                <FontAwesomeIcon icon={faCircle} size="xs" className="me-1" />
                <Trans>Minimum length 12</Trans>
              </span>
              <br />
              <span
                className={`${
                  newPassword?.length
                    ? passwordContains1Uppercase
                      ? "text-success"
                      : "text-danger"
                    : "text-secondary"
                } me-2 text-nowrap`}
              >
                <FontAwesomeIcon icon={faCircle} size="xs" className="me-1" />
                <Trans>Contains 1 uppercase letter</Trans>
              </span>
              <br />
              <span
                className={`${
                  newPassword?.length
                    ? passwordContains1Lowercase
                      ? "text-success"
                      : "text-danger"
                    : "text-secondary"
                } me-2 text-nowrap`}
              >
                <FontAwesomeIcon icon={faCircle} size="xs" className="me-1" />
                <Trans>Contains 1 lowercase letter</Trans>
              </span>
              <br />
              <span
                className={`${
                  newPassword?.length
                    ? passwordContains1Number
                      ? "text-success"
                      : "text-danger"
                    : "text-secondary"
                } me-2 text-nowrap`}
              >
                <FontAwesomeIcon icon={faCircle} size="xs" className="me-1" />
                <Trans>Contains 1 number</Trans>
              </span>
              <br />
              <span
                className={`${
                  newPassword?.length
                    ? passwordContains1Symbol
                      ? "text-success"
                      : "text-danger"
                    : "text-secondary"
                } me-2 text-nowrap`}
              >
                <FontAwesomeIcon icon={faCircle} size="xs" className="me-1" />
                <Trans>Contains 1 symbol</Trans>
              </span>
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Confirm new password</Trans>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              spellCheck={false}
              autoCorrect="false"
              autoCapitalize="false"
              value={confirmNewPassword}
              onChange={event => setConfirmNewPassword(event.target.value)}
            />
            <Button
              title={t`Show password`}
              onClick={() => setShowPassword(!showPassword)}
              variant="none"
              style={{
                position: "absolute",
                right: 0,
                marginTop: "-30px",
                marginRight: "28px"
              }}
              className="p-0 border-0"
            >
              {confirmNewPassword &&
                (showPassword ? (
                  <FontAwesomeIcon className="opacity-25" icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon className="opacity-25" icon={faEye} />
                ))}
            </Button>
          </Form.Group>
          <Form.Group>
            <div className="form-check form-switch d-flex align-items-center">
              <input
                className="form-check-input me-2"
                checked={passwordExpiresToggle}
                onChange={() => {
                  setPasswordExpiresToggle(!passwordExpiresToggle)
                }}
                type="checkbox"
                role="switch"
              />
              <span className="small text-muted">
                {passwordExpiresToggle
                  ? t`Password will expire on` +
                    " " +
                    new Date(
                      passwordExpiry
                        ? passwordExpiry
                        : new Date(Date.now() + 2592000000)
                    ).toLocaleDateString()
                  : t`Password never expires`}
              </span>
            </div>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button disabled={!changed} type="submit" form="change-password">
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )
}
