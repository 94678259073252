import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Select from "common/other/Select"
import { customFieldsState } from "common/recoil/atoms"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilState } from "recoil"

export default function NewCustomFieldCard() {
  const [name, setName] = useState("")
  const [key, setKey] = useState("")
  const [dataType, setDataType] = useState("")
  const [searchable, setSearchable] = useState(false)
  const [sourceKey, setSourceKey] = useState("measurement")
  const [customFields, setCustomFields] = useRecoilState(customFieldsState)
  const [loading, setLoading] = useState(false)
  const fetch = useFetch()
  const navigate = useNavigate()

  function handleSubmit(event) {
    setLoading(true)
    event.preventDefault()

    fetch("/custom_fields", {
      method: "POST",
      body: {
        custom_field: {
          name,
          source_key: sourceKey,
          data_type: dataType.value,
          key,
          searchable
        }
      }
    })
      .then(addedCustomField => {
        if (addedCustomField) {
          setCustomFields([...customFields, addedCustomField])
        }
        toast.success(<Trans>Added</Trans>)
        navigate("/custom_fields")
      })
      .finally(() => setLoading(false))
  }

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New custom field</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="add-custom-field" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Field name</Trans>
            </Form.Label>
            <Form.Control
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Key</Trans>
            </Form.Label>
            <Form.Control
              value={key}
              onChange={event => {
                const re = new RegExp(/^[a-z0-9_]*$/g)
                if (re.test(event.target.value)) {
                  setKey(event.target.value)
                }
              }}
            />
            <Form.Text muted>
              Only lower case letters, underscores and numbers allowed.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Source key</Trans>
            </Form.Label>
            <Form.Check // prettier-ignore
              type="radio"
              id="type"
              label={t`Measurement`}
              checked={sourceKey === "measurement"}
              onChange={$ => setSourceKey("measurement")}
            />
            <Form.Check // prettier-ignore
              type="radio"
              id="type"
              label={t`Storage`}
              checked={sourceKey === "storage"}
              onChange={$ => setSourceKey("storage")}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Data type</Trans>
            </Form.Label>
            <Select
              onChange={setDataType}
              value={dataType}
              options={[
                { translated: t`datetime`, original: "datetime" },
                { translated: t`integer`, original: "integer" },
                { translated: t`boolean`, original: "boolean" },
                { translated: t`string`, original: "string" },
                { translated: t`decimal`, original: "decimal" },
                { translated: t`date`, original: "date" }
              ].map($ => ({
                value: $?.original,
                label: $?.translated
              }))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              onChange={() => setSearchable(!searchable)}
              checked={searchable}
              label={<Trans>Searchable</Trans>}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          className="float-start"
          onClick={() => navigate("/custom_fields")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          form="add-custom-field"
          disabled={name === "" || key === "" || dataType === "" || loading}
        >
          <Trans>Add custom field</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )
}
