import { faWarehouse } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loading from "common/other/Loading"
import { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  NavbarBrand,
  Row,
  Table
} from "react-bootstrap"
import { useParams } from "react-router-dom"

export default function PublicStorage() {
  const { token } = useParams()
  const [storage, setStorage] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [measurements, setMeasurements] = useState([])

  useEffect(() => {
    if (token) {
      fetch(
        `${process.env.REACT_APP_TIMBETER_API}/storages/public/${token}/${page}`
      )
        .then(response => {
          return response.json()
        })
        .then(json => {
          setTotalPages(json?.total_pages)
          setStorage(json?.storage)
          setMeasurements($ => [...$, ...json.storage.measurements])
        })
    }
  }, [token, page])

  return storage ? (
    <Container className="d-flex flex-column gap-3 p-3">
      <div className="d-flex">
        <NavbarBrand className="d-flex align-items-center">
          <img src="/logos/timbeter.svg" className="logo ms-3" alt="Timbeter" />
        </NavbarBrand>
      </div>
      <Card body>
        <Row>
          <Col xs="auto" className="align-self-center">
            <FontAwesomeIcon icon={faWarehouse} className="" size="2x" />
          </Col>
          <Col xs="auto">
            <small className="text-muted">Storage name</small>
            <br />
            {storage.name}
          </Col>
          {/* <Col xs="auto">
            <small className="text-muted">Coordinates</small>
            <br />
            {storage.latitude}, {storage.longitude}
          </Col> */}
          {/* <Col xs="auto">
            <small className="text-muted">Last updated</small>
            <br />
            {new Date(storage.updated_at).toLocaleString()}
          </Col> */}
          {/* <Col xs="auto">
            <small className="text-muted">Status</small>
            <br />
            {storage.storage_state_id
              ? capitalize(storage.storage_state_id.replaceAll("_", " "))
              : "-"}
          </Col> */}
          <Col xs="auto">
            <small className="text-muted">Total volume</small>
            <br />
            {storage.volume.scalar} {unitToSymbol(storage.volume.unit)}
          </Col>
          <Col xs="auto">
            <small className="text-muted">Logs count</small>
            <br />
            {storage.logs_count}
          </Col>
        </Row>
      </Card>
      <Table responsive borderless striped>
        <thead>
          <tr>
            <th>
              <small className="text-muted">ID / Measured on</small>
            </th>
            <th>
              <small className="text-muted">Measurement type</small>
            </th>
            <th>
              <small className="text-muted">Coordinates</small>
            </th>
            <th>
              <small className="text-muted">Timber</small>
            </th>
            <th>
              <small className="text-muted">Log length</small>
            </th>
            <th>
              <small className="text-muted">Volume / coefficient</small>
            </th>
          </tr>
        </thead>
        <tbody>
          {measurements.map(measurement => (
            <tr key={measurement.id}>
              <td>
                <Row>
                  <Col xs="auto">
                    <img
                      alt="Measurement"
                      src={measurement.photo_url}
                      height="64"
                      width="64"
                    />
                  </Col>
                  <Col className="p-0 align-self-center">
                    <Row>
                      <a
                        href={
                          window.location.origin +
                          "/public_measurement/" +
                          measurement.token
                        }
                      >
                        {measurement.photo_file_name}
                      </a>
                    </Row>
                    <Row>
                      <span className="text-muted">
                        {new Date(measurement.created_at).toLocaleString()}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </td>
              <td>{measurement.measurement_type_id}</td>
              <td>
                {measurement.latitude}
                <br />
                {measurement.longitude}
              </td>
              <td>{measurement.wood_type}</td>
              <td>{measurement.log_length}</td>
              <td>{measurement.volume} m³</td>
            </tr>
          ))}
          {page < totalPages && (
            <tr>
              <td colSpan={6} className="text-center ">
                <Button
                  className="rounded-pill px-3"
                  onClick={() => {
                    setPage($ => $ + 1)
                  }}
                >
                  Show more
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  ) : (
    <Loading />
  )

  function unitToSymbol(unit) {
    return (
      {
        m: "m",
        "m^3": "m³"
      }[unit] || unit
    )
  }
}
