import { volumeFormulasState, woodTypesState } from "common/recoil/atoms"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"
import { truckDiameterMeasurement } from "./MeasurementsList"
import useConvertTimezone from "common/hooks/useConvertTimezone"
import { useEffect } from "react"
import { Button, Card, Image } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "@lingui/macro"
import { useNavigate } from "react-router-dom"

export default function MeasurementCard({ measurement: m }) {
  const woodTypes = useRecoilValue(woodTypesState)
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const [measurement, setMeasurement] = useRecoilState(
    truckDiameterMeasurement(m.id)
  )
  const convertTimezone = useConvertTimezone()
  const navigate = useNavigate()

  useEffect(() => {
    if (!measurement) {
      setMeasurement(m)
    }
  }, [setMeasurement, measurement, m])

  return (
    <Card className="d-flex flex-column p-3" style={{ height: "fit-content" }}>
      <div className="pb-3 lh-1">
        <div className="fw-semibold">{measurement?.shown_name}</div>
        <small className="text-muted">
          {convertTimezone(measurement?.created_at)}
        </small>
      </div>
      <div className="d-flex flex-row pb-3">
        <Image
          height={128}
          width={192}
          thumbnail
          style={{ height: "fit-content" }}
          className="shadow cursor-pointer flex-shirnk-1"
          onClick={() => {
            window.history.pushState("forward", null, "#editor")
            measurementEditor({
              id: measurement.id,
              type: "truck_diameter",
              shipment: {
                id: measurement.shipment_id
              }
            })
          }}
          src={measurement?.image}
        />
        <div className="d-flex ps-3">
          <div className="pe-2">
            <div>
              <small className="text-muted">Wood type</small>
            </div>
            <div>
              <small className="text-muted">Log count</small>
            </div>
            <div>
              <small className="text-muted">Log length</small>
            </div>
            <div>
              <small className="text-muted">Volume</small>
            </div>
            <div>
              <small className="text-muted">Formula</small>
            </div>
            <div>
              <small className="text-muted">Coefficient</small>
            </div>
          </div>
          <div>
            <div
              className="text-truncate"
              style={{ maxWidth: "6rem" }}
              title={
                woodTypes?.find($ => $.id === measurement?.wood_type_id)?.name
              }
            >
              {woodTypes?.find($ => $.id === measurement?.wood_type_id)?.name}
            </div>
            <div>{measurement?.logs?.length}</div>
            <div>{measurement?.log_length} m</div>
            <div>{measurement?.volume} m³</div>
            <div>
              {volumeFormulas[measurement?.volume_formula] ||
                measurement?.volume_formula}
            </div>
            <div>{measurement?.coefficient || "-"}</div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-2 flex-wrap">
        <Button
          variant="light"
          className="border w-full"
          size="sm"
          onClick={() =>
            navigate("/truck_diameter_measurement/" + measurement.id)
          }
        >
          <FontAwesomeIcon icon={faList} className="me-1" />
          <Trans>Details</Trans>
        </Button>
      </div>
    </Card>
  )
}
