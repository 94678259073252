import {
  faChevronRight,
  faExclamationTriangle,
  faHashtag,
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { deviceKeySelector, deviceKeysSelector } from "common/recoil/atoms"
import { useState } from "react"
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Row
} from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"

export default function NewDeviceCard() {
  const [email, setEmail] = useState("")
  const fetch = useFetch()
  const deviceKey = useRecoilValue(deviceKeySelector)
  const deviceKeys = useRecoilValue(deviceKeysSelector)
  const navigate = useNavigate()
  const [deviceLinkMethod, setDeviceLinkMethod] = useState()

  function handleSubmit(event) {
    event.preventDefault()

    fetch("/devices/send_invite", {
      method: "POST",
      body: {
        invite: {
          email
        }
      }
    }).then(json => {
      if (json?.message?.includes("Invitation sent")) {
        toast.success(<Trans>Invitation sent</Trans>)
      }
    })
  }

  return deviceKey ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>Connect a device</Trans>
      </Card.Header>

      <Card.Body>
        {!deviceLinkMethod && (
          <ListGroup>
            {/* <ListGroupItem
                  className="d-flex align-items-center"
                  action
                  onClick={() => setDeviceLinkMethod("email")}
                >
                  <Col xs="auto">
                    <FontAwesomeIcon
                      style={{ width: "2rem" }}
                      size="3x"
                      icon={faEnvelope}
                      className="me-3 opacity-25"
                    />
                  </Col>
                  <Col>
                    <Row>
                      <span className="fw-semibold">
                        <Trans>Send email</Trans>
                      </span>
                    </Row>
                    <Row>
                      <p className="text-muted small">
                        The easiest way to add a device
                      </p>
                    </Row>
                  </Col>
                  <Col xs="auto">
                    <FontAwesomeIcon
                      style={{ width: "2rem" }}
                      size="lg"
                      icon={faChevronRight}
                      className="ms-3 opacity-25"
                    />
                  </Col>
                </ListGroupItem> */}
            <ListGroupItem
              className="d-flex align-items-center"
              action
              onClick={() => setDeviceLinkMethod("code")}
            >
              <Col xs="auto">
                <FontAwesomeIcon
                  size="3x"
                  icon={faHashtag}
                  style={{ width: "2rem" }}
                  className="me-3 opacity-25"
                />
              </Col>
              <Col>
                <Row>
                  <span className="fw-semibold">
                    <Trans>Enter code</Trans>
                  </span>
                </Row>
                <Row>
                  <p className="text-muted small">
                    If you have the Timbeter app
                  </p>
                </Row>
              </Col>
              <Col xs="auto">
                <FontAwesomeIcon
                  style={{ width: "2rem" }}
                  size="lg"
                  icon={faChevronRight}
                  className="ms-3 opacity-25"
                />
              </Col>
            </ListGroupItem>
          </ListGroup>
        )}
        {deviceLinkMethod === "email" && (
          <div className="px-4 py-4 bg-light rounded border">
            <p className="fw-semibold mb-1">
              <Trans>Email invitation</Trans>
            </p>
            <Form id="new-device" onSubmit={handleSubmit}>
              <p className="text-muted small mb-2">
                Enter an email address below that is connected to the device and
                we will send you the code to activate Timbeter.
              </p>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  required
                />
              </Form.Group>
            </Form>
            <Button
              type="submit"
              form="new-device"
              className="w-100"
              disabled={!email}
            >
              <Trans>Send</Trans>
            </Button>
          </div>
        )}
        {/* <div className="d-flex align-items-center text-center my-4 text-black-50">
              <div
                className="bg-secondary opacity-50"
                style={{ height: "1px", width: "50%" }}
              />
              <span className="px-3">
                <Trans>or</Trans>
              </span>
              <div
                className="bg-secondary opacity-50"
                style={{ height: "1px", width: "50%" }}
              />
            </div> */}
        {deviceLinkMethod === "code" && (
          <div className="px-4 py-4 bg-light rounded border">
            <p className="fw-semibold">
              <Trans>Enter code</Trans>
            </p>
            <p className="text-muted small mb-2">
              <Trans>
                If you already have the Timbeter app you can enter the code
                below:
              </Trans>
            </p>
            <Form.Control
              className="font-monospace"
              defaultValue={deviceKey || t`No key available`}
              readOnly
              disabled
            />
            <p className="text-muted small my-2">
              <Trans>
                After you have entered the key, click confirm below:
              </Trans>
            </p>
            <Button
              variant="secondary"
              className="w-100"
              size="sm"
              onClick={() => window.location.assign("/devices")}
            >
              <Trans>Confirm</Trans>
            </Button>
          </div>
        )}
      </Card.Body>

      {deviceKeys?.length > 0 && (
        <>
          <h6>
            <Trans>Sent invitations</Trans>
          </h6>
          <ListGroup variant="flush">
            {deviceKeys.map(key => (
              <ListGroup.Item
                key={key.id}
                className="d-flex align-items-center"
              >
                <Badge bg="warning" className="me-3">
                  <Trans>Pending</Trans>
                </Badge>
                <span className="flex-grow-1 me-3">{key.sent_to}</span>
                <span className="small text-black-50">
                  {getDaysAgo(key.sent_at) > 0 ? (
                    <>
                      {getDaysAgo(key.sent_at)} <Trans>days ago</Trans>
                    </>
                  ) : (
                    <Trans>Today</Trans>
                  )}
                </span>
                {/* <FontAwesomeIcon
                    title={t`Resending currently unavailable`}
                    className="ms-3 text-muted"
                    icon={faRotateRight}
                  /> */}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      )}
      <Card.Footer>
        <Button
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          onClick={() => navigate("/devices")}
        >
          <Trans>Cancel</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <Alert variant="warning">
      <Alert.Heading>
        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
        <Trans>No available device licenses</Trans>
      </Alert.Heading>
      <p>
        <Trans>
          In order to add more devices you will need to unlink some current
          devices or buy more licenses.
        </Trans>{" "}
        <Alert.Link>Learn more</Alert.Link>
      </p>
    </Alert>
  )
}

function getDaysAgo(date) {
  return Math.floor((Date.now() - new Date(date).getTime()) / 86400000)
}
