import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { Col, Form, InputGroup, Row, Collapse } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  showAdditionalTimberFiltersState,
  timberFilterState,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import Select from "common/other/Select"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import CustomFieldsFilters from "../custom_fields/FilterCustomFields"
import { devicesListQuery } from "common/recoil/selectors"

export default function TimberAdditionalFilters() {
  const show = useRecoilValue(showAdditionalTimberFiltersState)
  const [woodAssortmentFilters, setWoodAssortmentFilters] = useRecoilState(
    timberFilterState("woodAssortments")
  )
  const [minLengthFilter, setMinLengthFilter] = useRecoilState(
    timberFilterState("minLength")
  )
  const [maxLengthFilter, setMaxLengthFilter] = useRecoilState(
    timberFilterState("maxLength")
  )
  const [minVolumeFilter, setMinVolumeFilter] = useRecoilState(
    timberFilterState("minVolume")
  )
  const [maxVolumeFilter, setMaxVolumeFilter] = useRecoilState(
    timberFilterState("maxVolume")
  )
  const [woodTypeFilters, setWoodTypeFilters] = useRecoilState(
    timberFilterState("woodTypes")
  )
  const [woodQualityFilters, setWoodQualityFilters] = useRecoilState(
    timberFilterState("woodQualities")
  )
  const [deviceFilters, setDeviceFilters] = useRecoilState(
    timberFilterState("devices")
  )
  const [shipmentNumberFilter, setShipmentNumberFilter] = useRecoilState(
    timberFilterState("shipmentNumber")
  )
  const woodAssortmentList = useRecoilValue(woodAssortmentsState)
  const woodTypeList = useRecoilValue(woodTypesState)
  const woodQualityList = useRecoilValue(woodQualitiesState)
  const deviceList = useRecoilState(devicesListQuery)

  return (
    <Collapse in={show}>
      <>
        <Row>
          <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
            <Form.Label>
              <Trans>Wood assortment</Trans>
            </Form.Label>
            <Select
              placeholder={<Trans>All</Trans>}
              isMulti
              value={woodAssortmentFilters}
              onChange={event => setWoodAssortmentFilters(event)}
              options={woodAssortmentList?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
            <Form.Label>
              <Trans>Length range</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                className="no-focus"
                onChange={event => setMinLengthFilter(event?.target?.value)}
                value={minLengthFilter}
                placeholder={t`Minimum`}
              />
              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="xs"
                  className="text-muted opacity-50"
                />
              </InputGroup.Text>
              <Form.Control
                className="no-focus"
                onChange={event => setMaxLengthFilter(event?.target?.value)}
                value={maxLengthFilter}
                placeholder={t`Maximum`}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
            <Form.Label>
              <Trans>Volume range</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                className="no-focus"
                onChange={event => setMinVolumeFilter(event?.target?.value)}
                value={minVolumeFilter}
                placeholder={t`Minimum`}
              />

              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="xs"
                  className="text-muted opacity-50"
                />
              </InputGroup.Text>
              <Form.Control
                className="no-focus"
                onChange={event => setMaxVolumeFilter(event?.target?.value)}
                value={maxVolumeFilter}
                placeholder={t`Maximum`}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
            <Form.Label>
              <Trans>Wood type</Trans>
            </Form.Label>
            <Select
              placeholder={<Trans>All</Trans>}
              isMulti
              onChange={event => setWoodTypeFilters(event)}
              value={woodTypeFilters}
              options={woodTypeList?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
            <Form.Label>
              <Trans>Wood quality</Trans>
            </Form.Label>
            <Select
              placeholder={<Trans>All</Trans>}
              isMulti
              onChange={event => setWoodQualityFilters(event)}
              value={woodQualityFilters}
              options={woodQualityList?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
            <Form.Label>
              <Trans>Device</Trans>
            </Form.Label>
            <Select
              isMulti
              placeholder={t`Select device`}
              onChange={event => setDeviceFilters(event)}
              value={deviceFilters}
              options={deviceList?.map(({ id, hw_id, name, user_email }) => ({
                value: id,
                label: `${name} ${user_email ? `(${user_email})` : ``}`
              }))}
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
            <Form.Label>
              <Trans>Shipment number</Trans>
            </Form.Label>
            <Form.Control
              value={shipmentNumberFilter}
              onChange={event => setShipmentNumberFilter(event.target.value)}
              placeholder={t`Enter number`}
            />
          </Form.Group>
        </Row>
        <CustomFieldsFilters />
      </>
    </Collapse>
  )
}
