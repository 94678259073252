export function formatDate(date, style) {
  return date
    ? new Intl.DateTimeFormat("en-GB", { dateStyle: style || "medium" }).format(
        new Date(date)
      )
    : "–"
}

export function formatTime(date) {
  return date
    ? new Intl.DateTimeFormat("en-GB", {
        hour12: false,
        hour: "numeric",
        minute: "numeric"
      }).format(new Date(date))
    : ""
}
