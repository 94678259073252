import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { Alert, Button, Card } from "react-bootstrap"
import QRCode from "qrcode.react"
import { useState } from "react"
import { accountSettingsState } from "common/recoil/atoms"
import { selector, useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShieldHalved, faUserShield } from "@fortawesome/free-solid-svg-icons"
import { accountSettingsQuery } from "common/recoil/selectors"

const twoFactorAuthEnabledSelector = selector({
  key: "twoFactorAuthEnabled",
  get: ({ get }) => get(accountSettingsState)?.otp_enabled || false
})

export default function TwoFactorAuthCard() {
  const twoFactorAuthEnabled = useRecoilValue(twoFactorAuthEnabledSelector)
  const [backupCodes, setBackupCodes] = useState([])
  const fetch = useFetch()
  const [qrCode, setQrCode] = useState(null)
  const refreshAccountQuery = useRecoilRefresher_UNSTABLE(accountSettingsQuery)

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faUserShield} className="me-2" />
        <Trans>Two-Factor Authentication</Trans>
      </Card.Header>
      <Card.Body>
        {qrCode && (
          <div>
            <p>
              1. Scan the QR code below (use Google Authenticator or Authy):
            </p>
            <div className="text-center py-3">
              <QRCode value={qrCode} />
            </div>
            <p>2. Save your backup codes</p>
            <p className="text-center py-3 fw-bold">
              {backupCodes.map($ => (
                <div>
                  {$}
                  <br />
                </div>
              ))}
            </p>
          </div>
        )}
        {twoFactorAuthEnabled ? (
          <Alert variant="success" className="text-center">
            <FontAwesomeIcon className="mb-2 fs-1" icon={faShieldHalved} />
            <p className="fw-medium">2FA is enabled</p>
          </Alert>
        ) : (
          <Alert variant="secondary" className="text-center">
            <FontAwesomeIcon className="mb-2 fs-1" icon={faShieldHalved} />
            <p>
              <Trans>2FA is disabled</Trans>
            </p>
          </Alert>
        )}
        <div className="d-flex mt-3">
          {twoFactorAuthEnabled ? (
            <Button
              variant="link"
              className="w-full me-2 text-danger"
              onClick={disable2FA}
            >
              <Trans>Disable</Trans>
            </Button>
          ) : (
            <Button variant="link" onClick={enable2FA} className="w-full ms-2">
              <Trans>Enable</Trans>
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  )

  function enable2FA() {
    fetch("/two_factors", {
      method: "POST"
    }).then(response => {
      if (response) {
        setQrCode(response.url)
        setBackupCodes(response.backup_codes)
        refreshAccountQuery()
      }
    })
  }

  function disable2FA() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/two_factors", {
        method: "DELETE"
      }).then(() => {
        setQrCode("")
        refreshAccountQuery()
      })
    }
  }
}
