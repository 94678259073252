import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { useState } from "react"
import { Button, Card, Form, InputGroup } from "react-bootstrap"
import { useSetRecoilState } from "recoil"
import { lumberProductsState } from "."
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"

export default function NewLumberProductCard() {
  const setLumberProducts = useSetRecoilState(lumberProductsState)
  const [name, setName] = useState("")
  const [length, setLength] = useState("")
  const [width, setWidth] = useState("")
  const [height, setHeight] = useState("")
  const [saving, setSaving] = useState(false)
  const fetch = useFetch()
  const navigate = useNavigate()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New lumber product</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="new-lumber-product" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              required
              value={name}
              onChange={$ => setName($.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Length</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                required
                value={length}
                onChange={$ => setLength($.target.value)}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Width</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                value={width}
                required
                onChange={$ => setWidth($.target.value)}
              />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Trans>Height</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                value={height}
                required
                onChange={$ => setHeight($.target.value)}
              />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          style={{ borderColor: "transparent" }}
          onClick={() => navigate("/timber/lumber_products")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          form="new-lumber-product"
          type="submit"
          disabled={saving}
        >
          <Trans>Add product</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function handleSubmit(event) {
    event.preventDefault()

    if (length <= 0 || width <= 0 || height <= 0) {
      toast.warn(<Trans>Length, width and height must be bigger than 0</Trans>)
      return
    }

    setSaving(true)
    fetch("/lumber_products", {
      method: "POST",
      body: {
        name,
        plank_length: Number(length),
        plank_width: Number(width),
        plank_height: Number(height)
      }
    })
      .then(response => {
        if (response) {
          const newLumberProduct = {
            ...response,
            plank_length: Number(length).toFixed(1)
          }
          setLumberProducts($ => [newLumberProduct, ...$])
          toast.success(<Trans>Added</Trans>)
          navigate("/timber/lumber_products")
        }
      })
      .finally(() => {
        setName("")
        setLength("")
        setWidth("")
        setHeight("")
        setSaving(false)
      })
  }
}
