import Logs from "public-measurement/Logs"
import { useState } from "react"
import { Card, Col, Image, Modal, Row } from "react-bootstrap"

export default function ContainerMeasurementCard({ measurement }) {
  const [showViewer, setShowViewer] = useState(false)
  return (
    <>
      <Card className="gap-1 p-2">
        <Row>
          <p className="fw-medium">{measurement?.shown_name}</p>
        </Row>
        <Row>
          <Col xs="auto" className="pe-0">
            <div>
              <Image
                // onError={e => (e.target.src = "/android-chrome-192x192.png")}
                height={128}
                width={128}
                src={measurement?.image}
                alt="Image"
                className="cursor-pointer"
                thumbnail
                onClick={() => setShowViewer(true)}
              />
            </div>
          </Col>
          <Col>
            <div>
              <div className="d-flex flex-column">
                <div>
                  <small className="text-muted text-nowrap">Log count</small>
                </div>
                <div>
                  <small className="text-muted text-nowrap">Log length</small>
                </div>
                <div>
                  <small className="text-muted text-nowrap">Volume</small>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div className="d-flex flex-column">
                <div>
                  <span>{measurement?.logs_count || 0}</span>
                </div>
                <div>
                  <span>{measurement?.log_length || "-"}</span>
                </div>
                <div>
                  <span>{Number(measurement?.volume || 0).toFixed(2)} m³</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <Modal size="xl" show={showViewer} onHide={() => setShowViewer(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{measurement?.shown_name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Logs imgUrl={measurement?.image} logs={measurement?.logs} />
        </Modal.Body>
      </Modal>
    </>
  )
}
