import { Badge } from "react-bootstrap"
import useDiameterProfilesState from "./useDiameterProfileState"
import { useNavigate } from "react-router-dom"

export default function DiameterProfileRow({ diameterProfileId }) {
  const [diameterProfile] = useDiameterProfilesState(diameterProfileId)

  // const culls = useRecoilValue(woodCullsState)
  // const cull = culls?.find(
  //   cull => cull.id === diameterProfile.default_cull_id
  // )?.reason

  // const woodAssortments = useRecoilValue(woodAssortmentsState)
  const navigate = useNavigate()
  return (
    <tr onClick={() => navigate(diameterProfileId.toString())}>
      <td title={diameterProfile.name}>
        {diameterProfile.name}{" "}
        {diameterProfile.archived && <Badge bg="secondary">Archived</Badge>}
      </td>
      {/* <td>
        {woodAssortments
          .filter($ => diameterProfile.wood_characteristic_ids.includes($.id))
          .map($ => (
            <Badge key={$.id} pill className="me-1 mb-1">
              {$.name}
            </Badge>
          ))}
      </td>
      <td>{cull}</td> */}
    </tr>
  )
}
