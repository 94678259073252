import Button from "react-bootstrap/Button"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  measurementsStateFamily,
  roundingPrecisionState,
  storagesState,
  woodTypesState
} from "common/recoil/atoms"
import { Trans } from "@lingui/macro"
import { setStorageMarkers, storagesListQuery } from "common/recoil/selectors"
import StorageMarker from "./StorageMarker"
import { Marker, Popup, useMap } from "react-leaflet"
import { markerIcon } from "./StorageMarker"
import { useRef, useState } from "react"
import useFetch from "common/hooks/useFetch"
import normalizeJson from "common/utils/normalizeJson"
import { Image } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCamera,
  faCube,
  faMapMarkerAlt,
  faRuler,
  faTree,
  faWarehouse
} from "@fortawesome/free-solid-svg-icons"
import measurementTypes from "common/enums/measurementTypes"
import Spinner from "common/other/Spinner"
import { useNavigate } from "react-router-dom"

export default function MeasurementMarker({
  id,
  storageId,
  longitude,
  latitude
}) {
  const map = useMap()
  const [open] = useState(false)
  const storages = useRecoilValue(storagesState)
  const markerRef = useRef(null)
  const setMarkers = useSetRecoilState(setStorageMarkers)
  const navigate = useNavigate()
  const woodTypes = useRecoilValue(woodTypesState)
  const storagesList = useRecoilValue(storagesListQuery)
  const fetch = useFetch()
  const roundingPrecision = useRecoilValue(roundingPrecisionState)
  const [measurement, setMeasurement] = useRecoilState(
    measurementsStateFamily(id)
  )

  return (
    latitude &&
    longitude && (
      <Marker
        ref={markerRef}
        name={id}
        position={[latitude, longitude]}
        eventHandlers={{
          click: event => {
            fetch(
              `/measurements/${id}?${new URLSearchParams({
                fields:
                  "storage_id,weight,weight_density,photo,photo_optimized,latitude,longitude,photo_thumbnail,volume,coefficient,wood_type_id,log_length,shipment_number,measurement_type_id,wood_quality_id,assortment_id,volume_formula,arrival_date,photo_file_name,load_lines,measurement_area,device_id,poor_detection",
                extra_fields:
                  "logs,references,load_json,custom_fields,logs_sheet"
              })}`
            )
              .then(response => {
                if (!measurement) {
                  // console.log(markerRef?.current)
                  setMeasurement(normalizeJson(response.data))
                  // console.log(normalizeJson(response.data))
                }
              })
              .finally(() => {
                // setLoading(false)
                // setOpen(true)
              })
          }
        }}
        icon={markerIcon}
        // ref={markerRef}
      >
        <Popup open={open}>
          <div>
            {!measurement && <Spinner variant="dark" />}
            {measurement && (
              <div style={{ minWidth: "280px" }}>
                <span
                  className="marker-header fw-semibold text-primary link cursor-pointer marker-header fs-6"
                  onClick={event => {
                    navigate("/measurement/" + measurement.id)
                  }}
                >
                  {measurement.photo_file_name}
                </span>
                <table className="mb-2">
                  <tbody>
                    <tr>
                      <td rowSpan={7}>
                        <Image
                          fluid
                          rounded
                          style={{ height: "96px", margin: "8px 16px 8px 0" }}
                          src={measurement.photo_optimized}
                          onError={e =>
                            (e.target.src = "/android-chrome-192x192.png")
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          icon={faWarehouse}
                          className="me-2 text-primary cursor-pointer hover-dark"
                        />
                      </td>
                      <td>
                        <span className="small">
                          {
                            storagesList?.find(
                              $ => $.id === measurement.storage_id
                            )?.name
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="me-2 text-primary cursor-pointer hover-dark"
                        />
                      </td>
                      <td>
                        <span className="small">
                          {measurement.latitude}, {measurement.longitude}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          icon={faCamera}
                          className="me-2 text-primary"
                        />
                      </td>
                      <td>
                        <span className="small">
                          {measurementTypes[measurement.measurement_type_id] ||
                            "ENUM_ERROR"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          icon={faRuler}
                          className="me-2 text-primary"
                          style={{ width: "12px" }}
                        />
                      </td>
                      <td>
                        <span className="small">
                          {measurement.log_length} m
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          icon={faTree}
                          className="me-2 text-primary"
                        />
                      </td>
                      <td>
                        <span className="small">
                          {measurement.logs_count}{" "}
                          {
                            woodTypes.find(
                              $ => $.id === measurement.wood_type_id
                            )?.name
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          icon={faCube}
                          className="me-2 text-primary"
                        />
                      </td>
                      <td>
                        <span className="small">
                          {measurement.volume.toFixed(roundingPrecision)} m³
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* <Image
                      src={measurement.photo_optimized}
                      thumbnail
                      onError={e => (e.target.src = "android-chrome-192x192.png")}
                      className="me-2"
                      style={{
                        cursor: "pointer",
                        margin: "1rem auto"
                      }}
                    /> */}
                {setMarkers && (
                  <>
                    <div className="d-grid">
                      <Button
                        size="sm"
                        onClick={() => {
                          setMarkers(
                            storages.map((storage, index) => (
                              <StorageMarker
                                key={index}
                                storage={storage}
                                open={storageId === storage.id}
                              />
                            ))
                          )
                          map.setView([latitude, longitude], 2)
                        }}
                      >
                        <Trans>Back to storage map</Trans>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Popup>
      </Marker>
    )
  )
}
