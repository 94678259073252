import { Spinner as BootstrapSpinner } from "react-bootstrap"

export default function Spinner({ variant, size }) {
  return (
    <BootstrapSpinner
      as="span"
      animation="border"
      size={size || "sm"}
      variant={variant || "light"}
      role="status"
      aria-hidden="true"
    />
  )
}
