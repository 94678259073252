import {
  faArrowLeft,
  faArrowRight,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { querySelector } from "common/recoil/selectors"
import { useState } from "react"
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Table
} from "react-bootstrap"
import DatePicker from "react-datepicker"
import { useNavigate } from "react-router-dom"
import { selectorFamily, useRecoilValue } from "recoil"

const deletedMeasurementsQuery = selectorFamily({
  key: "deletedMeasurementsQuery",
  get:
    ({ createdAtFrom, createdAtTo }) =>
    ({ get }) => {
      if (!createdAtFrom && !createdAtTo) {
        return get(
          querySelector({
            url: "/measurements/deleted",
            options: {
              method: "POST"
            }
          })
        )
      }
      return get(
        querySelector({
          url: "/measurements/deleted",
          options: {
            method: "POST",
            body: {
              ...(createdAtFrom && {
                created_at_from: new Intl.DateTimeFormat("et-EE").format(
                  createdAtFrom
                )
              }),
              ...(createdAtTo && {
                created_at_until: new Intl.DateTimeFormat("et-EE").format(
                  createdAtTo
                )
              })
              // created_at_from: createdAtFrom?.slice(0, 7),
              // created_at_until: createdAtTo?.slice(0, 7)
            }
          }
        })
      )
    }
})

export default function DeletedMeasurements() {
  const [createdAtFrom, setCreatedAtFrom] = useState("")
  const [createdAtTo, setCreatedAtTo] = useState("")
  const storages = useRecoilValue(
    deletedMeasurementsQuery({
      createdAtFrom,
      createdAtTo
    })
  )
  const navigate = useNavigate()
  const formatVolume = useVolumeFormatter()

  return (
    <>
      <Button
        variant="light"
        className="rounded-pill mb-2"
        onClick={() => navigate("/measurements/timber")}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="me-2 opacity-50" />
        <Trans>Back to dashboard</Trans>
      </Button>
      <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
        <Form.Label>
          <Trans>Synced between</Trans>
        </Form.Label>
        <InputGroup style={{ flexWrap: "nowrap" }}>
          <DatePicker
            placeholderText={t`From`}
            wrapperClassName="datepicker"
            dateFormat="dd.MM.yyyy"
            className="form-control no-focus cursor-pointer rounded-0 rounded-start"
            selected={createdAtFrom}
            onChange={date => setCreatedAtFrom(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
          />
          <InputGroup.Text>
            <FontAwesomeIcon
              icon={faArrowRight}
              size="xs"
              className="text-muted opacity-50"
            />
          </InputGroup.Text>
          <DatePicker
            placeholderText={t`To`}
            wrapperClassName="datepicker"
            dateFormat="dd.MM.yyyy"
            className="form-control no-focus cursor-pointer rounded-0 rounded-end"
            selected={createdAtTo}
            onChange={date => setCreatedAtTo(date)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </InputGroup>
      </Form.Group>
      {createdAtFrom || createdAtTo ? (
        <Button
          variant="secondary"
          className="mb-2"
          size="sm"
          onClick={() => {
            setCreatedAtFrom("")
            setCreatedAtTo("")
          }}
        >
          <Trans>Clear</Trans>
        </Button>
      ) : null}
      <Alert variant="warning">
        <FontAwesomeIcon icon={faInfoCircle} className="me-2 opacity-50" />
        <Trans>These measurements have been deleted</Trans>
      </Alert>
      {storages?.length > 1 && (
        <p className="fw-medium text-light mb-3">
          {formatVolume(
            storages
              .reduce((acc, val) => Number(acc) + Number(val.volume), 0)
              .toFixed(3)
          )}{" "}
          total volume
        </p>
      )}
      <Card>
        <Table size="sm" hover>
          <thead>
            <tr>
              <th>
                <Trans>Storage name</Trans>
              </th>
              <th>
                <Trans>Last updated</Trans>
              </th>
              <th>
                <Trans>Measurements</Trans>
              </th>
              <th>
                <Trans>Total volume</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {storages.map(storage => (
              <StorageRow storage={storage} />
            ))}
            {storages?.length < 1 && (
              <tr>
                <td colSpan={5}>No measurements found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  )
}

function StorageRow({ storage }) {
  const formatVolume = useVolumeFormatter()
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <tr
        onClick={() => setExpanded(!expanded)}
        className={expanded ? "bg-secondary" : ""}
      >
        <td>{storage.name}</td>
        <td>{new Date(storage.updated_at).toLocaleString()}</td>
        <td>{storage.measurements_count}</td>
        <td>{formatVolume(storage.volume)}</td>
      </tr>
      {expanded ? (
        <tr>
          <td colSpan={5} className="p-0">
            <Table size="sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>
                    <Trans>Measured at</Trans>
                  </th>
                  <th>
                    <Trans>Synced at</Trans>
                  </th>
                  <th>
                    <Trans>Deleted at</Trans>
                  </th>
                  <th>
                    <Trans>Total volume</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {storage.measurements.map(measurement => (
                  <tr>
                    <td>{measurement.id}</td>
                    <td>{new Date(measurement.taken_at).toLocaleString()}</td>
                    <td>{new Date(measurement.created_at).toLocaleString()}</td>
                    <td>{new Date(measurement.deleted_at).toLocaleString()}</td>
                    <td>{formatVolume(measurement.volume)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      ) : null}
    </>
  )
}
