import { Trans } from "@lingui/macro"
import { querySelector } from "common/recoil/selectors"
import { atom, selector, useRecoilValue } from "recoil"
import { NavLink, Outlet } from "react-router-dom"
import { Suspense } from "react"
import Loading from "common/other/Loading"
import { invoicesState } from "./Overview"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import currencyToSymbol from "common/utils/currencyToSymbol"
import ErrorBoundary from "common/other/ErrorBoundary"

export const pricingState = atom({
  key: "pricing",
  default: selector({
    key: "pricing/default",
    get: ({ get }) => get(querySelector("/pricing"))
  })
})

export const subscriptionItemsQuery = selector({
  key: "subscriptionItemsQuery",
  get: async ({ get }) => {
    let subscriptionItems = []
    try {
      subscriptionItems = await get(querySelector("/subscription/items"))
    } catch {
      return []
    } finally {
      return subscriptionItems
    }
  }
})

export const subscriptionItemsState = atom({
  key: "subscriptionItems",
  default: subscriptionItemsQuery
})

export const openInvoicesSelector = selector({
  key: "openInvoices",
  get: ({ get }) =>
    get(invoicesState).filter(invoice => invoice?.status === "open")
})

export const billingCurrencySelector = selector({
  key: "billingCurrency",
  get: ({ get }) =>
    currencyToSymbol(
      get(currentOrganizationState).billing_currency?.toUpperCase() || "USD"
    )
})

export default function Billing() {
  const openInvoices = useRecoilValue(openInvoicesSelector)
  // const billingCurrency = useRecoilValue(billingCurrencySelector)

  return (
    <>
      <h4 className="text-light mb-3">
        <Trans>Billing</Trans>
      </h4>
      <hr className="text-light" />
      <div className="d-flex flex-column flex-sm-row">
        <NavLink
          to="/billing"
          end
          className={({ isActive }) =>
            `text-light me-4 text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Overview</Trans>
        </NavLink>
        <NavLink
          to="/billing/invoices"
          end
          className={({ isActive }) =>
            `text-light me-4 position-relative text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Invoices</Trans>
          {openInvoices.length > 0 && (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <Trans>Unpaid invoices</Trans>
                </Tooltip>
              }
            >
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {openInvoices.length}
              </span>
            </OverlayTrigger>
          )}
        </NavLink>
        <NavLink
          to="/billing/measurements"
          className={({ isActive }) =>
            `text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Buy measurements</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}
