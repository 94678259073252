import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { measurementsModeState } from "common/recoil/atoms"
import { useEffect, useState } from "react"
import { Button, Card, Image } from "react-bootstrap"
import { useRecoilValue, useSetRecoilState } from "recoil"
import ShipmentsTable from "./ShipmentsTable"
import Filters, { shipmentsLoadingState } from "./Filters"
import Loading from "common/other/Loading"

export default function CMPCDashboard() {
  const setModule = useSetRecoilState(measurementsModeState)
  const [showFilters, setShowFilters] = useState(true)
  const shipmentsLoading = useRecoilValue(shipmentsLoadingState)

  useEffect(() => {
    setModule("cmpc")
  }, [setModule])

  return (
    <div className="">
      <h4 className="text-light m-0">
        <Image width={24} height={24} src="/logos/cmpc.png" className="me-2" />
        <Trans>CMPC dashboard</Trans>
      </h4>
      <hr className="text-light" />
      <div className="d-flex gap-3 flex-wrap">
        <Button
          variant={showFilters ? "light" : "outline-light"}
          className="rounded-pill px-3"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FontAwesomeIcon icon={faFilter} className="me-2" />
          <Trans>Search filters</Trans>
        </Button>
      </div>
      <hr className="text-light" />
      {showFilters && <Filters />}
      {shipmentsLoading ? (
        <Loading text={t`Loading shipments...`} />
      ) : (
        <ShipmentsTable />
      )}
    </div>
  )
}

export function ExtraImagesCard() {
  return (
    <Card
      className="d-flex flex-column p-3 border-0 bg-secondary-subtle"
      style={{ height: "fit-content" }}
    >
      <div className="pb-3 lh-1">
        <div className="fw-semibold">Extra images (3)</div>
        <small className="text-muted">
          {new Intl.DateTimeFormat(undefined, {
            dateStyle: "medium"
          }).format(new Date())}
        </small>
      </div>
      <div className="d-flex flex-row">
        <Image
          height={128}
          width={128}
          thumbnail
          style={{ height: "fit-content" }}
          className="shadow cursor-pointer"
          src="https://d1mpj4dy3innaj.cloudfront.net/truck_measurements/photos/000/075/938/original/Manual_Density_Sample__2022-03-28_11-41-02_-_2.jpeg?1649159280"
        />
        <Image
          height={128}
          width={128}
          thumbnail
          style={{
            height: "fit-content",
            position: "absolute",
            rotate: "5deg"
          }}
          className="shadow cursor-pointer"
          src="https://d1mpj4dy3innaj.cloudfront.net/truck_measurements/photos/000/075/938/original/Manual_Density_Sample__2022-03-28_11-41-02_-_2.jpeg?1649159280"
        />
        <Image
          height={128}
          width={128}
          thumbnail
          style={{
            height: "fit-content",
            position: "absolute",
            rotate: "-5deg"
          }}
          className="shadow cursor-pointer"
          src="https://d1mpj4dy3innaj.cloudfront.net/truck_measurements/photos/000/075/938/original/Manual_Density_Sample__2022-03-28_11-41-02_-_2.jpeg?1649159280"
        />
      </div>
    </Card>
  )
}

export function PulsatingDot() {
  return (
    <div className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1 dot">
      <span className="visually-hidden">unread messages</span>
    </div>
  )
}
