import { NavLink, Outlet } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import LicensesOverview from "./LicensesOverview"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTabletAlt } from "@fortawesome/free-solid-svg-icons"

export default function DevicesIndex() {
  return (
    <>
      <h4 className="text-light">
        <FontAwesomeIcon icon={faTabletAlt} className="me-2" />
        <Trans>Devices</Trans>
      </h4>
      <hr className="text-light" />
      <LicensesOverview />
      <div className="d-flex flex-wrap">
        <NavLink
          to="/devices"
          end
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>All devices</Trans>
        </NavLink>
        <NavLink
          to="/devices/new"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Link new device</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <Row>
        <Col xs="12" lg="8">
          <Outlet />
        </Col>
      </Row>
    </>
  )
}
