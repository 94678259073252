import { useParams } from "react-router-dom"
import StorageLocation from "./StorageLocation"
import { useEffect, useState } from "react"
import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import { t } from "@lingui/macro"

export default function StorageDetailView() {
  const { id } = useParams()
  const [storage, setStorage] = useState()
  const fetch = useFetch()

  useEffect(() => {
    fetch("/storages/" + id).then(response => {
      if (response) {
        setStorage(response?.storage)
      }
    })
    // eslint-disable-next-line
  }, [id])

  return storage ? (
    <StorageLocation storage={storage} />
  ) : (
    <Loading text={t`Loading...`} />
  )
}
