import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, NavbarBrand } from "react-bootstrap"
import { atom, useRecoilState } from "recoil"
import BillingNotification from "./BillingNotification"
import UpgradeNotification from "./UpgradeNotification"

export const offcanvasSidebarState = atom({
  key: "offcanvasSidebar",
  default: false
})

export default function Header() {
  const [offcanvasSidebar, setOffcanvasSidebar] = useRecoilState(
    offcanvasSidebarState
  )

  return (
    <header className="sticky-top">
      <BillingNotification />
      <UpgradeNotification />
      {/* <MaintenanceBanner /> */}
      <div className="bg-primary d-flex px-3 py-2 d-md-none">
        <NavbarBrand className="d-flex align-items-center">
          <Button
            className="px-0"
            onClick={() => setOffcanvasSidebar(!offcanvasSidebar)}
          >
            <FontAwesomeIcon
              className="mx-2 d-block d-md-none text-light"
              size="lg"
              icon={faBars}
            />
          </Button>
          <img src="/logos/timbeter.svg" className="logo ms-3" alt="Timbeter" />
        </NavbarBrand>
      </div>
    </header>
  )
}
