import { Marker, Tooltip } from "react-leaflet"
import L from "leaflet"
import { useEffect, useRef, useState } from "react"
import { atomFamily, useRecoilState, useRecoilValue } from "recoil"
import { Col, Image, Row } from "react-bootstrap"
import { selectedTruckRouteIdState } from "truck-measurement/map/TruckMap"
import { volumeUnitSelector } from "common/recoil/selectors"
import { truckMeasurementsStateFamily } from "common/recoil/atoms"
const truckIcon = L.icon({
  iconUrl: "/logos/truck.svg",
  iconSize: [32, 32],
  className: "truck-marker hover-dark bg-primary rounded p-1 shadow-sm"
})

const truckIconSec = L.icon({
  iconUrl: "/logos/truck.svg",
  iconSize: [32, 32],
  className: "truck-marker hover-dark bg-secondary rounded p-1 shadow-sm"
})

export const truckMarkerState = atomFamily({
  key: "truckMarker",
  default: null
})

export default function TruckMarker({ truckRoute }) {
  // in reality, the coordinates are fetched via API by measurementId
  const [truckMarker, setTruckMarker] = useRecoilState(
    truckMarkerState(truckRoute.id)
  )
  const [position, setPosition] = useState([0, 0])
  const [selectedTruckRouteId, setselectedTruckRouteId] = useRecoilState(
    selectedTruckRouteIdState
  )
  const measurement = useRecoilValue(
    truckMeasurementsStateFamily(truckRoute.truck_measurement_id)
  )
  const volumeUnit = useRecoilValue(volumeUnitSelector)
  const ref = useRef(null)

  useEffect(() => {
    if (!truckMarker) {
      setTruckMarker(truckRoute)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (truckMarker?.last_checkpoint) {
      setPosition([
        truckMarker.last_checkpoint.latitude,
        truckMarker.last_checkpoint.longitude
      ])
    }
  }, [truckMarker])

  useEffect(() => {
    if (selectedTruckRouteId === truckRoute.id) {
      ref.current.setIcon(truckIcon)
    } else {
      ref.current.setIcon(truckIconSec)
    }
  }, [selectedTruckRouteId, truckRoute.id])

  return (
    <Marker
      icon={truckIconSec}
      position={position}
      draggable={false}
      eventHandlers={{
        click: () => {
          setselectedTruckRouteId(truckRoute.id)
        }
      }}
      ref={ref}
      interactive
    >
      <Tooltip direction="bottom" offset={[0, 20]} opacity={1}>
        {measurement && (
          <div style={{ width: "24rem" }}>
            <Row>
              <Col xs="auto" className="align-self-center">
                <Image src={measurement?.photo_thumb} thumbnail />
              </Col>
              <Col xs={8}>
                <Row className="text-start">
                  <Col>License plate</Col>
                  <Col>{measurement?.truck?.license_plate || "–"}</Col>
                </Row>
                <Row className="text-start">
                  <Col>Shipment number</Col>
                  <Col>{measurement?.truck?.id || "–"}</Col>
                </Row>
                <Row className="text-start text-nowrap">
                  <Col>Started at</Col>
                  <Col>
                    {truckRoute.started_at
                      ? new Date(truckRoute.started_at).toLocaleString()
                      : "–"}
                  </Col>
                </Row>
                <Row className="text-start">
                  <Col>Finished at</Col>
                  <Col>
                    {truckRoute.finished_at
                      ? new Date(truckRoute.finished_at).toLocaleString()
                      : "–"}
                  </Col>
                </Row>
                <Row className="text-start">
                  <Col>Volume</Col>
                  <Col>
                    {measurement.loads.reduce(
                      (acc, val) => acc + Number(val.volume),
                      0
                    )}{" "}
                    {volumeUnit}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Tooltip>
    </Marker>
  )
}
