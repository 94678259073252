import { Trans } from "@lingui/macro"
import { querySelector } from "common/recoil/selectors"
import { useState } from "react"
import { atomFamily, selectorFamily, useRecoilValue } from "recoil"
import RouteCard from "./RouteCard"
import MeasurementCard from "./MeasurementCard"

export const truckDiameterMeasurement = atomFamily({
  key: "truckDiameterMeasurement",
  default: null
})

export const truckDiameterMeasurementsQuery = selectorFamily({
  key: "truckDiameterMeasurementsQuery",
  get:
    ({ pagination, shipment }) =>
    ({ get }) => {
      const response = get(
        querySelector({
          url: "/truck_diameter_measurements/search",
          options: {
            method: "POST",
            body: {
              q: {
                f: {
                  shipment_id: shipment.id
                }
              },
              paginate: {
                per_page: 1000,
                page: pagination.page
              }
            }
          }
        })
      )

      return {
        meta: response.meta,
        measurements: response.measurements
      }
    }
})

export default function MeasurementsList({ shipment }) {
  const [page] = useState(1)
  const { measurements } = useRecoilValue(
    truckDiameterMeasurementsQuery({
      pagination: {
        page
      },
      shipment
    })
  )

  return (
    <tr className={"table-active"}>
      <td colSpan={6} className="p-0">
        <div className="d-flex gap-2">
          {/* <Button size="sm" variant="light" className="border">
          <FontAwesomeIcon className="me-1" icon={faRoute} />
          <Trans>Show shipment route</Trans>
        </Button> */}
          {/* <Button size="sm" variant="light" className="border">
        <FontAwesomeIcon className="me-1" icon={faCheckDouble} />
        <Trans>Select all measurements</Trans>
      </Button> */}
        </div>
        {measurements?.length > 0 && (
          <>
            {/* <div className="d-flex px-3 pt-2 gap-4 bg-secondary-subtle ">
              <Button size="sm" variant="light" className="border">Select all</Button>
            </div> */}
            <div
              className="d-flex p-3 flex-row gap-3 flex-wrap"
              style={{ overflowY: "scroll", height: "32rem" }}
            >
              {shipment?.truck_routes?.length > 0
                ? shipment.truck_routes
                    .filter($ => $?.checkpoints?.length > 0)
                    .map($ => <RouteCard key={$.id} route={$} />)
                : null}
              {measurements.map($ => (
                <MeasurementCard key={$.id} measurement={$} />
              ))}
              {/* <Button style={{height: "fit-content"}}>
            <Trans>Load more</Trans>
          </Button> */}
            </div>
          </>
        )}
        {measurements?.length === 0 && (
          <div className="p-3">
            <Trans>No measurements found</Trans>
          </div>
        )}
      </td>
    </tr>
  )
}
