import useFetch from "common/hooks/useFetch"
import { useRecoilCallback, useRecoilState } from "recoil"
import { accountSettingsState } from "common/recoil/atoms"
import { toast } from "react-toastify"
import { Trans } from "@lingui/macro"

export default function useAccountState() {
  const [account, setAccount] = useRecoilState(accountSettingsState)

  const fetch = useFetch()

  const updateAccount = useRecoilCallback(
    () => async newAccountState => {
      fetch("/account", {
        method: "PATCH",
        body: {
          user: newAccountState
        }
      }).then(response => {
        if (response) {
          setAccount({
            ...account,
            ...newAccountState
          })
          toast.success(<Trans>Your changes have been saved</Trans>)
          window.location.reload()
        }
      })
    },
    []
  )

  return [account, updateAccount]
}
