import { Trans } from "@lingui/macro"
import { Button, Card, Table } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { getReportById } from "./useReportsState"
import MeasurementSquare, {
  MeasurementSquareError,
  MeasurementSquareLoading
} from "analyze_reports/MeasurementSquare"
import { Suspense, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { volumeFormulasState } from "common/recoil/atoms"
import useExportsState from "exports/useExportsState"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"
import { volumeUnitSelector } from "common/recoil/selectors"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import currencyToSymbol from "common/utils/currencyToSymbol"
import ErrorBoundary from "common/other/ErrorBoundary"
import Loading from "common/other/Loading"

export default function EditReportCard() {
  const { id } = useParams()
  const [report, setReport] = useRecoilState(getReportById(id))
  // const reportData = useRecoilValue(reportDataByIdQuery(id))
  const [reportData, setReportData] = useState()
  const { createReportsExcel } = useExportsState()
  const fetch = useFetch()
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(report.measurement_ids?.length / 5)
  const hasNextPage = currentPage < totalPages
  const confirmReport = () => {
    fetch(`/reports/${id}/confirm`, {
      method: "PATCH"
    }).then(response => {
      if (response) {
        setReport({
          confirmed: true
        })
        toast.success(<Trans>Report confirmed</Trans>)
      }
    })
  }

  useEffect(() => {
    fetch("/reports/" + id).then(response => {
      setReportData(response)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (report?.seen_at === null) {
      setReport($ => ({ ...$, seen_at: new Date() }))
    }
    // eslint-disable-next-line
  }, [])

  return reportData ? (
    <Card>
      <Card.Body className="d-flex gap-3 flex-column">
        <Card.Title>
          <div className="d-flex fw-semibold flex-wrap gap-3">
            <div className="d-flex flex-column">
              {report.name}
              <span className="small text-muted fw-normal">
                <Trans>Reported at</Trans>{" "}
                {new Date(report.reported_at).toLocaleDateString()}
              </span>
            </div>
            <Button
              size="sm"
              variant={report.confirmed ? "primary" : "secondary"}
              disabled={report.confirmed}
              style={{ height: "fit-content" }}
              onClick={confirmReport}
            >
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              {report.confirmed ? (
                <Trans>Confirmed</Trans>
              ) : (
                <Trans>Confirm report</Trans>
              )}
            </Button>
            {report.measurement_ids?.length > 0 ? (
              <Button
                onClick={() => createReportsExcel(report.id)}
                size="sm"
                style={{ height: "fit-content" }}
              >
                <FontAwesomeIcon icon={faFileExcel} className="me-2" />
                <Trans>Export to excel</Trans>
              </Button>
            ) : null}
          </div>
        </Card.Title>
        {report.measurement_ids?.length > 0 && (
          <h5>
            <Trans>Included measurements</Trans>{" "}
            <span className="text-black-50 small">
              ({report.measurement_ids?.length})
            </span>
          </h5>
        )}
        <div className="rounded d-flex flex-wrap gap-3 bg-whitesmoke p-3 border">
          {report.measurement_ids?.length ? (
            <div className="d-flex flex-column">
              <div className="d-flex gap-3 flex-wrap">
                <Button
                  className="border"
                  variant="light"
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage($ => ($ !== 1 ? $ - 1 : 1))}
                >
                  <Trans>Previous 5</Trans>
                </Button>
                {report.measurement_ids
                  ?.slice(
                    currentPage === 1 ? 0 : (currentPage - 1) * 5,
                    currentPage === 1 ? 5 : currentPage * 5
                  )
                  .map(id => (
                    <ErrorBoundary
                      key={id}
                      fallback={<MeasurementSquareError id={id} />}
                    >
                      <Suspense
                        fallback={<MeasurementSquareLoading key={id} />}
                      >
                        <MeasurementSquare key={id} id={id} />
                      </Suspense>
                    </ErrorBoundary>
                  ))}
                <Button
                  className="border"
                  variant="light"
                  onClick={() => setCurrentPage($ => $ + 1)}
                  disabled={!hasNextPage}
                >
                  <Trans>Next 5</Trans>
                </Button>
              </div>
              <div className="text-black-50 small mt-3 text-center">
                <Trans>Page</Trans> {currentPage}
              </div>
            </div>
          ) : (
            <>
              <p>There are no measurements in this report.</p>
              <p>
                You can add measurements to reports by selecting them in the{" "}
                <Link to="/">dashboard</Link>
              </p>
            </>
          )}
        </div>
        {reportData.logs?.length > 0 ? (
          <div className="d-flex flex-column gap-4">
            <ReportLogs
              logs={reportData.logs}
              assortments={reportData.characteristics}
              culls={reportData.culls}
              report={report}
            />
            <ReportSummary
              logs={reportData.logs}
              assortments={reportData.characteristics}
              culls={reportData.culls}
            />
            <ReportCulls culls={reportData.culls} logs={reportData.logs} />
          </div>
        ) : null}
      </Card.Body>
    </Card>
  ) : (
    <Loading />
  )
}

function ReportLogs({ logs, assortments, culls, report }) {
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  let diameters = [...new Set(logs.map(log => log.diameter))]
  diameters.sort()

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="h5">
          <Trans>Logs</Trans>
        </div>
        <div className="border border-0 translate-middle-y mx-2 border-bottom d-inline flex-grow-1"></div>
        <span className="text-black-50 small">
          <Trans>Volume formula</Trans>: {volumeFormulas[report.volume_formula]}
        </span>
      </div>
      <Table bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>
              <Trans>Diameter</Trans>
            </th>
            {assortments.map(a => (
              <th key={a.id}>{a.name}</th>
            ))}
            <th>
              <Trans>Cull</Trans>
            </th>
            <th>
              <Trans>Total</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {diameters.map(d => {
            const count = logs
              .filter(log => log.diameter === d)
              .reduce((acc, val) => acc + val.log_count, 0)
            const totalVolume = logs
              .filter(log => log.diameter === d)
              .reduce((acc, val) => acc + Number(val.volume), 0)
              .toFixed(4)

            return (
              <tr key={d}>
                <td>{d}</td>
                {assortments.map(a => {
                  const found = logs.find(
                    $ =>
                      $.characteristic_id === a.id &&
                      $.diameter === d &&
                      !$.cull &&
                      !$.cull_id
                  )
                  return (
                    <td key={a.id}>
                      <span className=" small fw-bold me-1">
                        {found && `${found.log_count}×`}
                      </span>
                      <span>{found && Number(found?.volume).toFixed(4)}</span>
                    </td>
                  )
                })}
                <td>
                  <span className="small fw-bold me-1">
                    {logs.filter($ => $.diameter === d && ($.cull || $.cull_id))
                      ?.length > 0
                      ? `${logs
                          .filter(
                            $ => $.diameter === d && ($.cull || $.cull_id)
                          )
                          ?.reduce(
                            (acc, val) => acc + Number(val.log_count),
                            0
                          )}×`
                      : null}
                  </span>
                  {logs.filter($ => $.diameter === d && ($.cull || $.cull_id))
                    ?.length > 0
                    ? logs
                        .filter($ => $.diameter === d && ($.cull || $.cull_id))
                        ?.reduce((acc, val) => acc + Number(val.volume), 0)
                        .toFixed(4)
                    : null}
                </td>
                <td>
                  <span className="small fw-bold me-1">{count}×</span>
                  {totalVolume}
                </td>
              </tr>
            )
          })}
          <tr className="table-info">
            <td className="fw-bold">
              <Trans>Total</Trans>
            </td>
            {assortments.map(a => {
              return (
                <td key={a.id}>
                  <small className="fw-bold">
                    {logs
                      .filter($ => $.characteristic_id === a.id && !$?.cull_id)
                      .reduce((acc, val) => acc + Number(val.log_count), 0)}
                    ×
                  </small>{" "}
                  {logs
                    .filter($ => $.characteristic_id === a.id && !$?.cull_id)
                    .reduce((acc, val) => acc + Number(val.volume), 0)
                    .toFixed(4)}
                </td>
              )
            })}
            <td>
              <span className="small fw-bold me-1">
                {logs
                  .filter($ => $.cull && $.cull_id)
                  ?.reduce((acc, val) => acc + Number(val.log_count), 0)}
                ×{" "}
              </span>
              <span>
                {logs
                  .filter($ => $.cull && $.cull_id)
                  ?.reduce((acc, val) => acc + Number(val.volume), 0)
                  .toFixed(4)}
              </span>
            </td>
            {/* <td>
            <span className=" small fw-bold me-1">
              {logs.filter($ => $.cull_id || $.cull)?.length > 0
                ? logs
                    .filter($ => $.cull_id || $.cull)
                    ?.reduce((acc, val) => acc + Number(val.log_count), 0)
                : null}{" "}
            </span>
            <span>
              {logs.filter($ => $.cull_id || $.cull)?.length > 0
                ? logs
                    .filter($ => $.cull_id || $.cull)
                    ?.reduce((acc, val) => acc + Number(val.volume), 0)
                    .toFixed(4)
                : null}
            </span>
          </td> */}
            <td>
              <span className=" small fw-bold me-1">
                {logs?.reduce((acc, val) => acc + Number(val.log_count), 0)}×{" "}
              </span>
              <span>
                {logs
                  ?.reduce((acc, val) => acc + Number(val.volume), 0)
                  .toFixed(4)}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

function ReportSummary({ logs, assortments, culls }) {
  // let diameters = [...new Set(logs.map(log => log.diameter))]
  // diameters.sort()
  const volumeUnit = useRecoilValue(volumeUnitSelector)
  const org = useRecoilValue(currentOrganizationState)
  const totalCullVolume = logs
    .filter($ => $.cull_id || $.cull)
    ?.reduce((acc, val) => acc + Number(val.volume), 0)
  const totalVolume = logs?.reduce((acc, val) => acc + Number(val.volume), 0)
  const cullPercentage = ((totalCullVolume / totalVolume) * 100).toFixed(2)
  let totalAssortmentSum = 0

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="h5">
          <Trans>Summary</Trans>
        </div>
        <div className="border border-0 translate-middle-y mx-2 border-bottom d-inline flex-grow-1"></div>
        <span className="text-black-50 small">
          <Trans>Volume unit</Trans>: {volumeUnit}
        </span>
      </div>
      <Table bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>
              <Trans>Wood assortment</Trans>
            </th>
            <th>
              <Trans>Volume</Trans>
            </th>
            <th>
              <Trans>Percentage</Trans>
            </th>
            <th>
              <Trans>Price per</Trans> {volumeUnit}
            </th>
            <th>
              <Trans>Sum</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {assortments.map(a => {
            const volume = logs
              .filter($ => !$.cull && !$.cull_id)
              .filter($ => $.characteristic_id === a.id)
              ?.reduce(
                (acc, val) => acc + Number(Number(val.volume)?.toFixed(4)),
                0
              )
            const percentage = (volume / totalVolume) * 100
            totalAssortmentSum = totalAssortmentSum + volume * a.price
            return (
              <tr key={a.id}>
                <td>{a.name}</td>
                <td>
                  {volume.toFixed(4)} {volumeUnit}
                </td>
                <td>{percentage.toFixed(2)}%</td>
                <td>
                  {a.price}
                  {currencyToSymbol(org.currency)}
                </td>
                <td>
                  {(volume * a.price).toFixed(2)}
                  {currencyToSymbol(org.currency)}
                </td>
              </tr>
            )
          })}
          <tr>
            <td>
              <Trans>Cull</Trans>
            </td>
            <td>
              {totalCullVolume.toFixed(4)} {volumeUnit}
            </td>
            <td>{cullPercentage}%</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr className="table-info">
            <td colSpan={4}>
              <b>
                <Trans>Sum total</Trans>
              </b>
            </td>
            <td className="fw-bold">
              {Number(totalAssortmentSum).toFixed(2)}
              {currencyToSymbol(org.currency)}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

function ReportCulls({ culls, logs }) {
  const volumeUnit = useRecoilValue(volumeUnitSelector)

  return (
    <div>
      <h5>Culls</h5>
      <Table size="sm" bordered hover responsive>
        <thead>
          <tr>
            <th>
              <Trans>Reason</Trans>
            </th>
            <th>
              <Trans>Volume</Trans>
            </th>
            <th>
              <Trans>Count</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {culls.map(c => (
            <tr key={c.name}>
              <td>{c.name}</td>
              <td>
                {logs
                  .filter(
                    l =>
                      l.cull === c.name ||
                      (l.cull_id === c.id && !l.characteristic_id)
                  )
                  .reduce((acc, val) => acc + Number(val.volume), 0)
                  .toFixed(4)}{" "}
                {volumeUnit}
              </td>
              <td>
                {logs
                  .filter(
                    l =>
                      l.cull === c.name ||
                      (l.cull_id === c.id && !l.characteristic_id)
                  )
                  .reduce((acc, val) => acc + Number(val.log_count), 0)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
