import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { openInvoicesSelector } from "billing"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"

export default function BillingNotification() {
  const navigate = useNavigate()
  const openInvoices = useRecoilValue(openInvoicesSelector)
  const [show, setShow] = useState(true)

  return openInvoices.length > 0 && show ? (
    <div className="d-flex align-items-center bg-info py-1">
      <div className="flex-grow-1 text-center">
        <Button
          onClick={() => navigate("/billing/invoices")}
          variant="info"
          style={{
            cursor: "pointer"
          }}
          className="fw-normal py-0 px-2 link-light"
        >
          <span className="small fw-semibold">
            <Trans>You have unpaid invoices.</Trans>{" "}
            <u style={{ textDecorationThickness: 2 }}>
              <Trans>Pay now</Trans>
            </u>
          </span>
        </Button>
      </div>
      <Button
        onClick={() => setShow(false)}
        variant="info"
        size="sm"
        className="px-1 py-0 me-3"
      >
        <FontAwesomeIcon icon={faTimes} className="opacity-50" />
      </Button>
    </div>
  ) : null
}
