import useFetch from "common/hooks/useFetch"
import { selector, useRecoilValue } from "recoil"
import { accountSettingsState, organizationsState } from "common/recoil/atoms"
import { Trans } from "@lingui/macro"
import { Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons"
import Select from "common/other/Select"

export const currentOrganizationState = selector({
  key: "currentOrganization",
  get: ({ get }) => {
    const organizations = get(organizationsState)
    const currentOrganizationId =
      get(accountSettingsState)?.recent_organization_id
    const organization =
      organizations?.find &&
      organizations.find(org => org.id === currentOrganizationId)

    return organization
  }
})

export default function OrganizationSelector() {
  const fetch = useFetch()
  const organizations = useRecoilValue(organizationsState)
  const currentOrganization = useRecoilValue(currentOrganizationState)

  return organizations?.length > 1 ? (
    <Dropdown>
      <Dropdown.Toggle
        variant="none"
        className="fw-normal dropdown-item border-0"
        style={{
          paddingLeft: "12px",
          paddingRight: "12px",
          lineHeight: "20px"
        }}
      >
        <span className="small">
          <FontAwesomeIcon
            icon={faArrowsRotate}
            className="me-2 opacity-50"
            style={{ width: "1rem" }}
          />
          <Trans>Switch organization</Trans>
        </span>
      </Dropdown.Toggle>

      {organizations?.length > 1 && (
        <Dropdown.Menu>
          <Select
            menuPlacement="top"
            onChange={option => switchOrganization(option.value)}
            value={{
              value: currentOrganization.id,
              label: currentOrganization.name
            }}
            options={organizations.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
          />
        </Dropdown.Menu>
      )}
    </Dropdown>
  ) : null

  function switchOrganization(organizationId) {
    fetch(`/users/switch_organization/${organizationId}`, {
      method: "PATCH"
    }).then(() => {
      window.location = "/"
    })
  }
}
