import { Col, Container, Image, Row, Spinner } from "react-bootstrap"

export default function LoadingPage({ message }) {
  return (
    <Container className="position-absolute top-50 start-50 translate-middle text-center">
      <Row className="mb-4">
        <Col>
          <Image
            style={{ width: "12rem" }}
            fluid
            src="/logos/timbeter.svg"
            alt="Timbeter"
          />
        </Col>
      </Row>
      <Row className="mb-4 text-white text-center">
        <Col>
          <Spinner
            className="me-2"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
          <p>{message}</p>
        </Col>
      </Row>
    </Container>
  )
}
