import { Trans, t } from "@lingui/macro"
import { Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { woodQualitiesState } from "common/recoil/atoms"
import { useNavigate } from "react-router-dom"
import DefaultTooltip from "timber/DefaultTooltip"

export default function WoodQualitiesTable() {
  const woodQualities = useRecoilValue(woodQualitiesState)
  const navigate = useNavigate()

  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <Trans>Wood quality</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...woodQualities]
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map($ => (
            <tr
              key={$.id}
              onClick={
                $.organization_id === null
                  ? null
                  : () => navigate(`/timber/qualities/${$.id}`)
              }
            >
              <td
                className={`d-flex align-items-center ${
                  $.archived ? "text-decoration-strike-through text-muted" : ""
                }`}
              >
                <span className="flex-grow-1">{$.name}</span>
                {$.organization_id === null && (
                  <DefaultTooltip
                    text={t`Default wood qualities cannot be edited`}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
