import { useState } from "react"
import { Form, Button, Card, InputGroup, Spinner } from "react-bootstrap"
import Select from "common/other/Select"
import useFetch from "common/hooks/useFetch"
import measurementTypes from "common/enums/measurementTypes"
import woodTypes from "common/enums/woodTypes"
import { Trans } from "@lingui/macro"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  measurementsStateFamily,
  volumeFormulasState,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import { lengthUnitSelector } from "common/recoil/selectors"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList } from "@fortawesome/free-solid-svg-icons"

export default function MeasurementDetails({ measurement }) {
  const setMeasurement = useSetRecoilState(
    measurementsStateFamily(Number(measurement.id))
  )
  const lengthUnit = useRecoilValue(lengthUnitSelector)
  const [measurementType, setMeasurementType] = useState(
    measurement?.measurement_type_id || ""
  )
  const [woodType, setWoodType] = useState(measurement?.wood_type_id || "")
  const [woodQuality, setWoodQuality] = useState(
    measurement?.wood_quality_id || ""
  )
  const [woodAssortment, setWoodAssortment] = useState(
    measurement?.assortment_id || ""
  )
  const [logLength, setLogLength] = useState(measurement?.log_length || "")
  const [volumeFormula, setVolumeFormula] = useState(
    measurement?.volume_formula || ""
  )
  const assortments = useRecoilValue(woodAssortmentsState)
  const woodQualities = useRecoilValue(woodQualitiesState)
  const woodSpecies = useRecoilValue(woodTypesState)
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const fetch = useFetch()
  const [saving, setSaving] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    updateMeasurement()
  }

  const updateMeasurement = () => {
    setSaving(true)
    fetch(`/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        measurement_type_id: Number(measurementType) ?? undefined,
        wood_type_id: woodType || undefined,
        wood_quality_id: woodQuality || undefined,
        wood_characteristic_id: woodAssortment || undefined,
        log_length: logLength || undefined,
        volume_formula: volumeFormula || undefined
      }
    })
      .then(updatedMeasurement => {
        // console.log(updatedMeasurement)
        if (updatedMeasurement) {
          setMeasurement({
            ...measurement,
            ...updatedMeasurement,
            assortment_id: woodAssortment
          })
          toast.success(<Trans>Your changes have been saved</Trans>)
        } else {
          toast.error(<Trans>Your changes could not be saved</Trans>)
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  return (
    measurement && (
      <Card>
        <Card.Header>
          <FontAwesomeIcon icon={faList} className="me-2" />
          <Trans>Measurement details</Trans>
        </Card.Header>
        <Card.Body>
          <Form id="update-measurement-details" onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Measurement type</Trans>
              </Form.Label>
              <Select
                onChange={event => setMeasurementType(event.value)}
                value={{
                  value: measurementType,
                  label: measurementTypes[measurementType]
                }}
                options={Object.entries(measurementTypes).map(([id, type]) => ({
                  value: id,
                  label: type
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Wood type</Trans>
              </Form.Label>
              <Select
                onChange={event => setWoodType(event.value)}
                value={{ value: woodType, label: woodTypes[woodType] }}
                options={woodSpecies.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Wood quality</Trans>
              </Form.Label>
              <Select
                onChange={event => setWoodQuality(event.value)}
                value={
                  woodQuality && {
                    value: woodQuality,
                    label: woodQualities?.filter($ => $.id === woodQuality)[0]
                      ?.name
                  }
                }
                options={woodQualities?.map($ => ({
                  value: $.id,
                  label: $.name
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Wood assortment</Trans>
              </Form.Label>
              <Select
                onChange={event => setWoodAssortment(event.value)}
                value={
                  woodAssortment && {
                    value: woodAssortment,
                    label: assortments?.filter($ => $.id === woodAssortment)[0]
                      ?.name
                  }
                }
                options={assortments?.map($ => ({
                  value: $.id,
                  label: $.name
                }))}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Log length</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className="form-control-append"
                  onChange={event => setLogLength(event.target.value)}
                  value={`${logLength}`}
                />
                <InputGroup.Text>{lengthUnit}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>
                <Trans>Volume formula</Trans>
              </Form.Label>
              <Select
                onChange={event => setVolumeFormula(event.value)}
                value={{
                  value: volumeFormula,
                  label: volumeFormulas
                    ? volumeFormulas[volumeFormula]
                    : "Loading..."
                }}
                options={Object.entries(volumeFormulas || {}).map(
                  ([value, label]) => ({
                    value,
                    label
                  })
                )}
              />
            </Form.Group>
            <div
              className={`d-grid mt-3 ${measurement.inventory_id && "d-none"}`}
            >
              <Button
                form="update-measurement-details"
                type="submit"
                disabled={saving}
              >
                {saving ? (
                  <Spinner
                    size="sm"
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <Trans>Save</Trans>
                )}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    )
  )
}
