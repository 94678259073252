import { useState } from "react"
import { Card, Form, Button, Row, Col } from "react-bootstrap"
import Select from "common/other/Select"
import { storageStatuses } from "common/enums/storageStatuses"
import useFetch from "common/hooks/useFetch"
import LocationSelector from "dashboard/LocationSelector"
import { Trans } from "@lingui/macro"
import { toast } from "react-toastify"
import { useRecoilState, useRecoilValue } from "recoil"
import { storagesState } from "common/recoil/atoms"
import { faArrowLeft, faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import StorageCustomFields from "custom_fields/StorageCustomFields"
import { storageCustomFields } from "custom_fields/StorageCustomField"
import { useNavigate } from "react-router-dom"

export default function StorageLocation({ storage }) {
  const fetch = useFetch()
  const [name, setName] = useState(storage.name)
  const [latitude, setLatitude] = useState(storage.latitude || 0)
  const [longitude, setLongitude] = useState(storage.longitude || 0)
  const getStorageCustomFields = useRecoilValue(storageCustomFields)
  const navigate = useNavigate()
  const [status, setStatus] = useState(
    Object.entries(storageStatuses)
      .filter(([id, label]) => Number(id) === Number(storage?.storage_state_id))
      .map(([id, label]) => ({
        value: id,
        label
      }))[0]
  )
  const [saving, setSaving] = useState(false)
  const [storages, setStorages] = useRecoilState(storagesState)

  async function updateStorage(event) {
    event.preventDefault()
    setSaving(true)

    fetch(`/storages/${storage.id}`, {
      method: "PATCH",
      body: {
        storage: {
          name,
          latitude: Number(latitude),
          longitude: Number(longitude),
          storage_state_id: Number(status.value),
          custom_fields: getStorageCustomFields
        }
      }
    })
      .then(response => {
        if (response) {
          if (storages) {
            setStorages(storages =>
              storages.map($ =>
                $.id === storage.id
                  ? {
                      ...$,
                      name,
                      latitude: Number(latitude),
                      longitude: Number(longitude),
                      storage_state_id: Number(status.value)
                    }
                  : $
              )
            )
          }
          toast.success(<Trans>Storage updated</Trans>)
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  function setPosition({ lat, lng }) {
    setTimeout(() => setLatitude(lat.toFixed(6)))
    setTimeout(() => setLongitude(lng.toFixed(6)))
  }

  return (
    <Col xs="12" lg="8" xxl="6" xl="6">
      <span
        className="fw-medium text-white-50 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
        <Trans>Back to dashboard</Trans>
      </span>
      <hr className="text-light" />
      <Card>
        <Card.Header>
          <FontAwesomeIcon icon={faEdit} className="me-2" />
          <Trans>Edit storage</Trans>
        </Card.Header>
        <Card.Body>
          <LocationSelector
            position={[latitude, longitude]}
            setPosition={setPosition}
          />
          <Form onSubmit={updateStorage}>
            <Row className="my-3">
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Trans>Storage name</Trans>
                  </Form.Label>
                  <Form.Control
                    onChange={event => setName(event.target.value)}
                    value={name}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Trans>Storage status</Trans>
                  </Form.Label>
                  <Select
                    onChange={event => setStatus(event)}
                    value={status}
                    options={Object.entries(storageStatuses).map(
                      ([id, status]) => ({
                        value: id,
                        label: status
                      })
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <Trans>Latitude</Trans>
                  </Form.Label>
                  <input
                    className="form-control"
                    type="number"
                    step={0.000001}
                    max={90}
                    min={-90}
                    onChange={event => setLatitude(event.target.value)}
                    value={latitude || "00.000000"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <Trans>Longitude</Trans>
                  </Form.Label>
                  <input
                    className="form-control"
                    type="number"
                    step={0.000001}
                    max={180}
                    min={-180}
                    onChange={event => setLongitude(event.target.value)}
                    value={longitude || "00.000000"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <StorageCustomFields storage={storage} />
            <Form.Group className="d-grid mt-3">
              <Button type="submit" disabled={saving}>
                <Trans>Save</Trans>
              </Button>
            </Form.Group>
            {storage.updated_at && (
              <small className="small text-muted text-center">
                Last updated {new Date(storage.updated_at).toLocaleString()}
              </small>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Col>
  )
}
