import {
  faCaretDown,
  faSort,
  faCaretUp,
  faSearch,
  faCheck
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import { customFieldsState } from "common/recoil/atoms"
import { useMemo, useState } from "react"
import { Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"

const dataTypeMap = {
  datetime: t`datetime`,
  integer: t`integer`,
  boolean: t`boolean`,
  string: t`string`,
  decimal: t`decimal`,
  date: t`date`
}

export default function CustomFieldsTable() {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState()
  const customFields = useRecoilValue(customFieldsState)
  const columnHelper = createColumnHelper()
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: <Trans>Name</Trans>,
        cell: info => (
          <div style={{ maxWidth: "240px" }}>
            <span>{info.getValue()}</span>
          </div>
        ),
        footer: info => info.column.id
      }),
      columnHelper.accessor("source_key", {
        header: <Trans>Source key</Trans>,
        cell: info => (
          <div style={{ maxWidth: "240px" }}>
            <span>{info.getValue()}</span>
          </div>
        ),
        footer: info => info.column.id
      }),
      columnHelper.accessor("data_type", {
        header: <Trans>Data type</Trans>,
        cell: info => dataTypeMap[info.getValue()],
        footer: info => info.column.id
      }),
      columnHelper.accessor("searchable", {
        header: <Trans>Searchable</Trans>,
        cell: info => info.getValue() && <FontAwesomeIcon icon={faCheck} />,
        footer: info => info.column.id
      }),
      columnHelper.accessor("archived", {
        header: <Trans>Archived</Trans>,
        cell: info => info.getValue() && <FontAwesomeIcon icon={faCheck} />,
        footer: info => info.column.id
      })
    ],
    [columnHelper]
  )
  const table = useReactTable({
    data: customFields,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true
  })

  return (
    <>
      <Table hover responsive>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  <div
                    {...{
                      style: header.column.getCanSort()
                        ? {
                            cursor: "pointer",
                            userSelect: "none"
                          }
                        : null,
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: (
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          className="ms-2 opacity-50"
                        />
                      ),
                      desc: (
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          className="ms-2 opacity-50"
                        />
                      )
                    }[header.column.getIsSorted()] ?? (
                      <FontAwesomeIcon
                        icon={faSort}
                        className="ms-2 opacity-50"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              onClick={() => navigate("/custom_fields/" + row.original.id)}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <tr className="text-center text-black-50">
              <td colSpan="5" className="py-3">
                <FontAwesomeIcon
                  icon={faSearch}
                  size="2x"
                  className="mb-2 opacity-50"
                />
                <p>
                  <Trans>Nothing to show</Trans>
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
