import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { filteredTruckStoragesState } from "common/recoil/atoms"
import TruckStorageRow from "./TruckStorageRow"

export default function TruckStorageRowList() {
  const storages = useRecoilValue(filteredTruckStoragesState)

  return (
    <Table
      className="storagesTable"
      borderless
      responsive="xxl"
      style={{ borderSpacing: "0 8px" }}
    >
      <thead className="text-light">
        <tr>
          <td style={{ width: 0 }} className="bg-transparent text-light" />
          <td className="bg-transparent text-light">
            <div className="d-flex align-items-center">
              <Trans>Storage</Trans>
            </div>
          </td>
          <td className="bg-transparent text-light">
            <Trans>Coordinates</Trans>
          </td>
          <td className="bg-transparent text-light">
            <Trans>Last updated</Trans>
          </td>
          <td className="bg-transparent text-light">
            <Trans>Status</Trans>
          </td>
          <td className="bg-transparent text-light">
            <Trans>Log count</Trans>
          </td>
          <td className="bg-transparent text-light">
            <Trans>Volume</Trans>
          </td>
        </tr>
      </thead>
      <tbody className="storages">
        {storages?.length ? (
          [...storages]
            .sort((a, b) =>
              new Date(a.updated_at) > new Date(b.updated_at) ? -1 : 1
            )
            .map((storage, index) => (
              <TruckStorageRow key={index} storage={storage} />
            ))
        ) : (
          <tr style={{ background: "white" }}>
            <td colSpan="8" style={{ textAlign: "center" }}>
              <FontAwesomeIcon icon={faTimes} className="hover-dark me-2" />
              <Trans>No measurements found that match your criteria</Trans>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
