import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { truckDiameterMeasurement } from "cmpc-dashboard/MeasurementsList"
import useFetch from "common/hooks/useFetch"
import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import Timber from "./Timber"

export default function TruckDiameterMeasurementDetailView() {
  const { id: measurementId } = useParams()
  const [measurement, setMeasurement] = useRecoilState(
    truckDiameterMeasurement(Number(measurementId))
  )
  const fetch = useFetch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!measurement) {
      fetch("/truck_diameter_measurements/" + measurementId).then(response => {
        setMeasurement(response)
      })
    }
    // eslint-disable-next-line
  }, [])

  return measurement ? (
    <>
      <span
        className="fw-medium text-white-50 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
        <Trans>Back to dashboard</Trans>
      </span>
      <hr className="text-light" />
      <Row>
        <Col xs="12" className="mb-3">
          <h4 className="text-light mb-0">{measurement.photo_file_name}</h4>
          <div className="text-white-50">
            <small>ID: {measurement.id}</small>
          </div>
        </Col>
        <Col md={6} lg={6} xxl={4} className="mt-4 mt-md-0">
          <Row>
            <Col>
              <Timber measurement={measurement} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : null
}
