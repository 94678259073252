import "react-datepicker/dist/react-datepicker.css"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import Select from "common/other/Select"
import DatePicker from "react-datepicker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import useFetch from "common/hooks/useFetch"
import measurementTypes from "common/enums/measurementTypes"
import { storageStatuses } from "common/enums/storageStatuses"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  showAdditionalTimberFiltersState,
  truckFilterState,
  truckStoragesState,
  filteredTruckStoragesState
} from "common/recoil/atoms"
import { Trans, t } from "@lingui/macro"
import TimberAdditionalFilters from "../timber-measurement/TimberAdditionalFilters"
import { clearTruckFilterSelector } from "common/recoil/selectors"
import { useState } from "react"

export default function TruckFilters() {
  const fetch = useFetch()
  const [storageNameFilters, setStorageNameFilters] = useRecoilState(
    truckFilterState("storageNames")
  )
  const [measurementFromFilter, setMeasurementFromFilter] = useRecoilState(
    truckFilterState("from")
  )
  const [measurementToFilter, setMeasurementToFilter] = useRecoilState(
    truckFilterState("to")
  )
  const [measurementTypeFilters, setMeasurementTypeFilters] = useRecoilState(
    truckFilterState("measurementTypes")
  )
  const [storageStatusFilters, setStorageStatusFilters] = useRecoilState(
    truckFilterState("storageStatuses")
  )
  const woodAssortmentFilters = useRecoilValue(
    truckFilterState("woodAssortments")
  )
  const minLengthFilter = useRecoilValue(truckFilterState("minLength"))
  const maxLengthFilter = useRecoilValue(truckFilterState("maxLength"))
  const minVolumeFilter = useRecoilValue(truckFilterState("minVolume"))
  const maxVolumeFilter = useRecoilValue(truckFilterState("maxVolume"))
  const woodTypeFilters = useRecoilValue(truckFilterState("woodTypes"))
  const woodQualityFilters = useRecoilValue(truckFilterState("woodQualities"))
  const deviceFilters = useRecoilValue(truckFilterState("devices"))
  const shipmentNumberFilter = useRecoilValue(
    truckFilterState("shipmentNumber")
  )
  const storages = useRecoilValue(truckStoragesState)
  const setFilteredStorages = useSetRecoilState(filteredTruckStoragesState)
  const [showAdditionalFilters, setShowAdditionalFilters] = useRecoilState(
    showAdditionalTimberFiltersState
  )
  const clearFilters = useSetRecoilState(clearTruckFilterSelector)
  const [filtering, setFiltering] = useState(false)

  return (
    <>
      <Row>
        <Form.Group as={Col} md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Storage</Trans>
          </Form.Label>
          <Select
            placeholder={t`All`}
            isMulti
            value={storageNameFilters}
            onChange={event => setStorageNameFilters(event)}
            options={storages?.map(storage => ({
              value: storage.id,
              label: storage.name
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Storage status</Trans>
          </Form.Label>
          <Select
            placeholder={t`All`}
            isMulti
            value={storageStatusFilters}
            onChange={event => setStorageStatusFilters(event)}
            options={Object.entries(storageStatuses).map(([id, status]) => ({
              value: id,
              label: status
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Measured between</Trans>
          </Form.Label>
          <InputGroup style={{ flexWrap: "nowrap" }}>
            <DatePicker
              placeholderText={t`...`}
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-start"
              selected={measurementFromFilter}
              onChange={date => {
                setMeasurementFromFilter(date)
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
            />
            <InputGroup.Text>
              <FontAwesomeIcon
                icon={faArrowRight}
                size="xs"
                className="text-muted opacity-50"
              />
            </InputGroup.Text>
            <DatePicker
              placeholderText={t`Today`}
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-end"
              selected={measurementToFilter}
              onChange={date => setMeasurementToFilter(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Measurement type</Trans>
          </Form.Label>
          <Select
            placeholder={t`All`}
            isMulti
            value={measurementTypeFilters}
            onChange={event => setMeasurementTypeFilters(event)}
            options={Object.entries(measurementTypes).map(([id, type]) => ({
              value: id,
              label: type
            }))}
          />
        </Form.Group>
      </Row>
      {showAdditionalFilters && <TimberAdditionalFilters />}
      <Col
        xs="6"
        md="6"
        lg="6"
        className="d-inline-flex"
        style={{ alignSelf: "flex-end" }}
      >
        <Button
          className="border-0"
          variant="outline-secondary"
          disabled={filtering}
          onClick={clearFilters}
        >
          <Trans>Clear</Trans>
        </Button>
      </Col>
      <Col
        xs="6"
        md="6"
        lg="6"
        className="justify-content-end mt-3 d-inline-flex"
        style={{ textAlign: "end" }}
      >
        <Button
          className="me-3"
          variant="secondary"
          onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
        >
          <Trans>Show additional filters</Trans>
        </Button>
        <Button
          variant="dark"
          type="submit"
          disabled={filtering}
          className="text-nowrap"
          onClick={filterStorages}
        >
          <Trans>Search</Trans>
        </Button>
      </Col>
    </>
  )

  function filterStorages(event) {
    event && event.preventDefault()

    setFiltering(true)
    fetch(
      `/dashboard_filter?${new URLSearchParams({
        ...(storageNameFilters?.length && {
          storage_ids: storageNameFilters.map($ => $.value)
        }),
        ...(storageStatusFilters?.length && {
          storage_state_ids: storageStatusFilters.map($ => $.value)
        }),
        ...(measurementTypeFilters?.length && {
          measurement_type_ids: measurementTypeFilters.map($ => $.value)
        }),
        ...(woodTypeFilters?.length && {
          wood_type_ids: woodTypeFilters.map($ => $.value)
        }),
        ...(woodQualityFilters?.length && {
          wood_quality_ids: woodQualityFilters.map($ => $.value)
        }),
        ...(woodAssortmentFilters?.length && {
          wood_characteristic_ids: woodAssortmentFilters.map($ => $.value)
        }),
        ...(deviceFilters?.length && {
          device_ids: deviceFilters.map($ => $.value)
        }),
        ...(minVolumeFilter && { volume_range_start: minVolumeFilter }),
        ...(maxVolumeFilter && { volume_range_end: maxVolumeFilter }),
        ...(minLengthFilter && { length_range_start: minLengthFilter }),
        ...(maxLengthFilter && { length_range_end: maxLengthFilter }),
        ...(measurementFromFilter && {
          date_range_start: new Intl.DateTimeFormat("et-EE").format(
            measurementFromFilter
          )
        }),
        ...(measurementToFilter && {
          date_range_end: new Intl.DateTimeFormat("et-EE").format(
            measurementToFilter
          )
        }),
        ...(shipmentNumberFilter && {
          shipment_number: shipmentNumberFilter
        }),
        measurements_included: true,
        truck_measurements: true
      })}`
    )
      .then(response => setFilteredStorages(response?.storages))
      .finally(() => setFiltering(false))
  }
}
