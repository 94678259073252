import { Col, Row } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { Trans } from "@lingui/macro"
import { featureEnabled } from "common/recoil/atoms"
import { faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink, Outlet } from "react-router-dom"
import UpgradePlanCard from "common/other/UpgradePlanCard"

export default function CustomFieldsIndex() {
  const enabled = useRecoilValue(featureEnabled("custom_fields"))

  return enabled ? (
    <>
      <h4 className="text-light">
        <FontAwesomeIcon icon={faList} className="me-2" />
        <Trans>Custom fields</Trans>
      </h4>
      <hr className="text-light" />
      <div className="d-flex flex-wrap">
        <NavLink
          to="/custom_fields"
          end
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>All custom fields</Trans>
        </NavLink>
        <NavLink
          to="/custom_fields/new"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Create new custom field</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <Row>
        <Col xs="12" xxl="8">
          <Outlet />
        </Col>
      </Row>
    </>
  ) : (
    <UpgradePlanCard feature="custom fields" />
  )

  // return customFieldsEnabled ? (
  //   <>
  //     <CustomFieldsCard />
  //     <Suspense fallback>
  //       <EditCustomFieldModal />
  //     </Suspense>
  //   </>
  // ) : (
  //   <Alert variant="light" className="text-center">
  //     <p className="mb-2">
  //       <b>Custom fields</b> feature is not enabled for this organization.
  //     </p>
  //     <small>
  //       Click <Alert.Link href="#">here</Alert.Link> to upgrade your plan.
  //     </small>
  //   </Alert>
  // )
}
