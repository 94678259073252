import { Trans } from "@lingui/macro"
import { Button, Card, Form } from "react-bootstrap"
import { useState } from "react"
import formatVolumeUnit from "common/utils/formatVolumeUnit"
import { useNavigate, useParams } from "react-router-dom"
import { selectorFamily, useRecoilState, useSetRecoilState } from "recoil"
import { usersState } from "common/recoil/atoms"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

export const userState = selectorFamily({
  key: "user",
  get:
    id =>
    ({ get }) =>
      get(usersState).find(user => user.id === id),
  set:
    id =>
    ({ get, set }, updatedUser) => {
      set(
        usersState,
        get(usersState).map(user =>
          user.id === id
            ? {
                ...user,
                ...updatedUser
              }
            : user
        )
      )
    }
})

export default function EditUserCard() {
  const { id } = useParams()
  const userId = Number(id)
  const [user, setUser] = useRecoilState(userState(userId))
  const setUsers = useSetRecoilState(usersState)
  const [name, setName] = useState(user?.name || "")
  const fetch = useFetch()
  const navigate = useNavigate()

  function update() {
    fetch(`/users/${userId}`, {
      method: "PATCH",
      body: {
        user: {
          name
        }
      }
    }).then(response => {
      if (!response) {
        return toast.error(<Trans>Failed to update user</Trans>)
      }
      setUser({
        name
      })
      toast.success(<Trans>Updated user</Trans>)
    })
  }

  function deleteUser() {
    fetch(`/users/${userId}`, {
      method: "DELETE"
    }).then(response => {
      if (!response) {
        return toast.error(<Trans>Failed to delete user</Trans>)
      }
      navigate("/users")
      setUsers(users => users.filter($ => $.id !== userId))
      toast.success(<Trans>Deleted user</Trans>)
    })
  }

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit user</Trans>
      </Card.Header>
      <Card.Body>
        <Card.Title>{user.email}</Card.Title>
        <Form
          id="edit-user"
          onSubmit={event => {
            event.preventDefault()
            update()
          }}
        >
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              value={name}
              onChange={event => setName(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Timezone</Trans>
            </Form.Label>
            <Form.Control
              value={user.time_zone || "–"}
              disabled
              // onChange={event => setName(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Volume unit</Trans>
            </Form.Label>
            <Form.Control
              value={formatVolumeUnit(user.volume_unit) || "–"}
              disabled
              // onChange={event => setName(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <Trans>Rounding precision</Trans>
            </Form.Label>
            <Form.Control
              value={user.rounding_precision || "–"}
              disabled
              // onChange={event => setName(event?.target?.value)}
            />
          </Form.Group>
        </Form>
        {/* <UserAccess userId={userId} /> */}
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          style={{ borderColor: "transparent" }}
          onClick={() => navigate("/users")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="danger"
          className="me-3"
          onClick={() => {
            if (window.confirm(`Are you sure?`)) {
              deleteUser()
            }
          }}
        >
          <Trans>Delete</Trans>
        </Button>
        <Button type="submit" form="edit-user">
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )
}
