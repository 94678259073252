import { Trans, t } from "@lingui/macro"
import { capitalize } from "lodash"
import { organizationPlanInterval } from "billing/AdditionalFeatures"
import { Alert, Row } from "react-bootstrap"
import { atom, useRecoilState, useRecoilValue } from "recoil"
import MoreProducts from "./MoreProducts"
import TimbeterEnterprise from "./TimbeterEnterprise"
import TimbeterPro, {
  subscriptionCanceledSelector,
  subscriptionStatusSelector
} from "./TimbeterPro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import StartTrial from "./StartTrial"
import TimbeterStereo from "./TimbeterStereo"

export const pricePreviewBillingCycle = atom({
  key: "pricePreviewBillingCycle",
  default: "yearly"
})

const subscriptionInterval = {
  monthly: t`monthly`,
  yearly: t`yearly`
}

export default function SubscriptionPlans() {
  const orgPlanInterval = useRecoilValue(organizationPlanInterval)
  const [billing, setBilling] = useRecoilState(pricePreviewBillingCycle)
  const subscriptionWillCancel = useRecoilValue(subscriptionCanceledSelector)
  const subscriptionStatus = useRecoilValue(subscriptionStatusSelector)

  return (
    <>
      <h4 className="text-light mt-3" id="subscription-plans">
        <Trans>Subscription plans</Trans>
        <StartTrial />
      </h4>
      <hr className="text-light" />
      {subscriptionStatus === "canceled" ? (
        <Alert variant="secondary mt-3">
          <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
          <Trans>
            Your subscription is canceled. To make any changes you need to renew
            your subscription.
          </Trans>
        </Alert>
      ) : null}
      {subscriptionWillCancel ? (
        <Alert variant="secondary mt-3">
          <Trans>
            Your subscription will be canceled. To make any changes you need to
            renew your subscription.
          </Trans>
        </Alert>
      ) : null}
      <div
        className={
          subscriptionStatus === "canceled" || subscriptionWillCancel
            ? "pe-none opacity-50"
            : ""
        }
      >
        <div className="d-flex mt-3">
          {orgPlanInterval ? (
            <div
              className="px-2 py-1 rounded bg-warning fw-semibold mb-3 text-body"
              style={{ height: "fit-content", width: "fit-content" }}
            >
              {capitalize(subscriptionInterval[orgPlanInterval])}{" "}
              <Trans>billing</Trans>
            </div>
          ) : (
            <div className="d-flex align-items-center pb-3">
              <label
                className={`form-check-label fw-semibold text-light small me-2 ${
                  billing !== "monthly" && "opacity-25"
                }`}
              >
                <Trans>Monthly billing</Trans>
              </label>
              <div className="form-check form-switch d-flex align-items-center">
                <input
                  className="form-check-input"
                  checked={billing === "yearly"}
                  onChange={() =>
                    setBilling(billing === "monthly" ? "yearly" : "monthly")
                  }
                  type="checkbox"
                  role="switch"
                />
              </div>
              <label
                className={`form-check-label fw-semibold small text-light ${
                  billing !== "yearly" && "opacity-25"
                }`}
              >
                <Trans>Yearly billing</Trans>{" "}
                <span
                  style={{ fontSize: "0.75rem" }}
                  className="ms-1 text-body initialism fw-bold rounded-1 px-2 py-1 bg-warning shadow-sm bg-gradient"
                >
                  <Trans>save 20%</Trans>
                </span>
              </label>
            </div>
          )}
        </div>
        <Row xs="1" xxl="3" className="text-center mb-4 d-flex gap-4 gap-xxl-0">
          <TimbeterPro />
          <TimbeterEnterprise />
          {process.env.REACT_APP_ENV !== "production" && <TimbeterStereo />}
        </Row>
        <MoreProducts />
      </div>
    </>
  )
}
