import {
  diameterProfilesState,
  woodAssortmentsState,
  woodCullsState
} from "common/recoil/atoms"
import { Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import useFetch from "common/hooks/useFetch"
import CustomSelect from "common/other/Select"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

export default function NewDiameterProfileCard() {
  const [diameterProfiles, setDiameterProfiles] = useRecoilState(
    diameterProfilesState
  )
  const [diameterProfileName, setDiameterProfileName] = useState("")
  const [diameterProfileCull, setDiameterProfileCull] = useState("")
  const [diameterProfileAssortments, setDiameterProfileAssortments] = useState(
    []
  )
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const woodCulls = useRecoilValue(woodCullsState)
  const addDiameterProfile = useAddDiameterProfile()
  const fetch = useFetch()
  const navigate = useNavigate()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New diameter profile</Trans>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              value={diameterProfileName}
              onChange={event => setDiameterProfileName(event?.target?.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Assortments</Trans>
            </Form.Label>
            <CustomSelect
              isMulti
              required
              value={diameterProfileAssortments?.map(id => {
                const assortment = woodAssortments?.find($ => $.id === id)
                return {
                  value: id,
                  label: assortment.name
                }
              })}
              onChange={event =>
                setDiameterProfileAssortments(event.map($ => $.value))
              }
              options={woodAssortments.filter(checkCollision).map($ => ({
                value: $.id,
                label: $.name + ` (${$.min}–${$.max})`
              }))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <Trans>Cull</Trans>
            </Form.Label>
            <Form.Select
              value={diameterProfileCull}
              onChange={event => setDiameterProfileCull(event?.target?.value)}
            >
              <option key="placeholder" hidden value>
                Choose cull
              </option>
              {woodCulls
                ?.filter($ => !$.min && !$.max)
                .map($ => (
                  <option key={$.id} value={$.id}>
                    {$.reason}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          className="float-start"
          onClick={() => navigate("/timber/diameter_profiles")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          onClick={() => {
            addDiameterProfile({
              ...(diameterProfileName && { name: diameterProfileName }),
              ...(diameterProfileCull && {
                default_cull_id: Number(diameterProfileCull)
              }),
              ...(diameterProfileAssortments && {
                wood_characteristic_ids: diameterProfileAssortments
              })
            })
          }}
          disabled={
            diameterProfileName.trim() === "" ||
            diameterProfileAssortments?.length === 0
          }
        >
          <Trans>Add profile</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function useAddDiameterProfile() {
    const navigate = useNavigate()

    return useRecoilCallback(
      () => newDiameterProfile => {
        fetch("/diameter_profiles", {
          method: "POST",
          body: {
            diameter_profile: newDiameterProfile
          }
        }).then(response => {
          if (response) {
            setDiameterProfiles([...diameterProfiles, response])
            navigate("/timber/diameter_profiles")
            toast.success(<Trans>Added</Trans>)
          }
        })
      },
      []
    )
  }

  function checkCollision(assortment) {
    let ok = true

    if (assortment.min === null || assortment.max === null) {
      return false
    }

    if (diameterProfileAssortments?.length === 0) {
      return true
    }

    const selectedDiameterProfileAssortments = diameterProfileAssortments?.map(
      id => {
        const assortment = woodAssortments?.find($ => $.id === id)
        return assortment
      }
    )

    for (let $ of selectedDiameterProfileAssortments) {
      if (
        Number(assortment.min) > Number($.min) &&
        Number(assortment.min) < Number($.max)
      ) {
        ok = false
        break
      }

      if (
        Number(assortment.min) < Number($.min) &&
        Number(assortment.max) > Number($.min)
      ) {
        ok = false
        break
      }

      if (
        Number(assortment.min) === Number($.min) ||
        Number(assortment.max) === Number($.max)
      ) {
        ok = false
        break
      }
    }

    return ok
  }
}
