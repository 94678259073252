import { faUsersCog } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import { useState } from "react"
import { Card, Form, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"

export default function DetailsCard() {
  const organization = useRecoilValue(currentOrganizationState)
  const [name, setName] = useState(organization.name || "")
  const [registrationCode] = useState(organization.registration_code || "")
  const [country] = useState(organization.country || "")
  const [city, setCity] = useState(organization.city || "")
  const [address, setAddress] = useState(organization.address || "")
  const [postalCode, setPostalCode] = useState(organization.postal_code || "")
  const [vatCode, setVatCode] = useState(organization.vat_code || "")
  const [invoiceEmail, setInvoiceEmail] = useState(
    organization.invoice_email || ""
  )
  const fetch = useFetch()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faUsersCog} className="me-2" />
        <Trans>Organization settings</Trans>
      </Card.Header>
      <Card.Body>
        <Form name="organization-settings" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Organization name</Trans>
            </Form.Label>
            <Form.Control
              value={name}
              onChange={event => setName(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Registration code</Trans>
            </Form.Label>
            <Form.Control disabled readOnly value={registrationCode} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Country</Trans>
            </Form.Label>
            <Form.Control disabled readOnly value={country} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>City</Trans>
            </Form.Label>
            <Form.Control
              value={city}
              onChange={event => setCity(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Address</Trans>
            </Form.Label>
            <Form.Control
              onChange={event => setAddress(event?.target?.value)}
              value={address}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Postal code</Trans>
            </Form.Label>
            <Form.Control
              onChange={event => setPostalCode(event?.target?.value)}
              value={postalCode}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>VAT code</Trans>
            </Form.Label>
            <Form.Control
              pattern="^(ATU[0-9]{8}|BE0[0-9]{9}|BG[0-9]{9,10}|CY[0-9]{8}L|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|(EL|GR)[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|GB([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|HU[0-9]{8}|IE[0-9]S[0-9]{5}L|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10})$"
              onChange={event => setVatCode(event?.target?.value)}
              value={vatCode}
            />
            <Form.Text muted>
              <Trans>Must match the organization country</Trans>
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <Trans>Invoice email</Trans>
            </Form.Label>
            <Form.Control
              type="email"
              onChange={event => setInvoiceEmail(event?.target?.value)}
              value={invoiceEmail}
            />
            <Form.Text muted>
              <Trans>If left empty, the admins will receive invoices</Trans>
            </Form.Text>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          type="submit"
          form="organization-settings"
          disabled={
            name === organization.name &&
            registrationCode === organization.registration_code
          }
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function handleSubmit(event) {
    event.preventDefault()
    fetch("/organizations/" + organization.id, {
      method: "PATCH",
      body: {
        name: name,
        address: address,
        vat_code: vatCode,
        city: city,
        postal_code: postalCode,
        invoice_email: invoiceEmail
      }
    })
      .then(response => {
        if (response) {
          toast.success(<Trans>Organization has been updated</Trans>)
        }
      })
      .catch(error => {
        toast.error(<Trans>Failed to save</Trans>)
      })
  }
}
