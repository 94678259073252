import { Suspense } from "react"
import MeasurementDeviceCard from "./MeasurementDevice"
import { Trans } from "@lingui/macro"
import { Card } from "react-bootstrap"
import Spinner from "../Spinner"

export default function MeasurementDevice({ deviceId }) {
  return (
    <Suspense fallback={<LoadingDeviceCard />}>
      <MeasurementDeviceCard deviceId={deviceId} />
    </Suspense>
  )
}

function LoadingDeviceCard() {
  return (
    <Card body>
      <Spinner variant="dark" /> <Trans>Loading device...</Trans>
    </Card>
  )
}
