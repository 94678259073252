import { faChartSimple } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Spinner from "common/other/Spinner"
import { reportsState, volumeFormulasState } from "common/recoil/atoms"
import { useState } from "react"
import { Button, Dropdown, Form, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

export default function CreateStorageReportModal({ storage }) {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [createdReportId, setCreatedReportId] = useState(null)
  const [responseMessage, setResponseMessage] = useState("")
  const fetch = useFetch()
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const [selectedFormula, setSelectedFormula] = useState("")
  const setReports = useSetRecoilState(reportsState)
  const onHide = () => {
    setShow(false)
    setSelectedFormula("")
    setLoading(false)
    setCreatedReportId(null)
    setResponseMessage("")
  }
  const navigate = useNavigate()

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <Trans>Create storage report</Trans>
        <span className="float-end">
          <FontAwesomeIcon icon={faChartSimple} />
        </span>
      </Dropdown.Item>
      <Modal
        show={show}
        centered
        backdrop="static"
        keyboard={false}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faChartSimple} className="me-2" />
            <Trans>Create storage report</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseMessage ? (
            <>{responseMessage}</>
          ) : (
            <>
              <Form.Label className="small text-muted">
                <Trans>Select a volume formula</Trans>
              </Form.Label>
              <Form.Select
                value={selectedFormula}
                onChange={$ => setSelectedFormula($?.target?.value)}
                aria-label={t`Select a volume formula`}
              >
                <option value="" disabled defaultValue>
                  {t`No formula selected`}
                </option>
                {Object.entries(volumeFormulas)?.map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-column">
          {createdReportId ? (
            <>
              <Button
                variant="primary"
                className="w-100"
                onClick={() => navigate("/reports")}
              >
                <Trans>Show all reports</Trans>
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="primary"
                className="w-100"
                disabled={selectedFormula === "" || loading}
                onClick={() => createReport(selectedFormula)}
              >
                {loading ? <Spinner /> : <Trans>Create report</Trans>}
              </Button>
              <Button variant="secondary" className="w-100" onClick={onHide}>
                <Trans>Cancel</Trans>
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )

  function createReport(volumeFormula) {
    setLoading(true)
    fetch("/reports/storage/" + storage.id, {
      method: "POST",
      body: {
        volume_formula: volumeFormula
      }
    })
      .then(response => {
        if (response?.generating_in_progress) {
          setReports(reports => [response, ...reports])
          setCreatedReportId(response?.id)
          setResponseMessage(
            <>
              <Trans>Report</Trans> <b>{response?.name}</b>{" "}
              <Trans>is now being generated</Trans>.{" "}
              <Trans>You will be notified when it has completed.</Trans>
            </>
          )
          return
        }
      })
      .finally(() => setLoading(false))
  }
}
