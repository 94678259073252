import { Trans } from "@lingui/macro"
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet"
import { markerIcon } from "timber-measurement/map/StorageMarker"

export default function Location({ latitude, longitude, storage }) {
  return latitude || longitude ? (
    <MapContainer
      zoom={18}
      center={[latitude, longitude]}
      minZoom={1}
      scrollWheelZoom={true}
      className="rounded mb-3"
      style={{ height: 400, zIndex: 0 }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker
        position={[latitude, longitude]}
        draggable={false}
        icon={markerIcon}
      >
        <Tooltip permanent direction="top" offset={[0, -40]}>
          <b>
            <Trans>Storage</Trans>
          </b>
          <br />
          {storage}
          <br />
          <hr />
          <b>
            <Trans>Measurement coordinates</Trans>
          </b>
          {latitude === "0.0" && longitude === "0.0" ? (
            <>
              <br />-
            </>
          ) : (
            <>
              <br />
              {latitude}
              <br />
              {longitude}
            </>
          )}
        </Tooltip>
      </Marker>
    </MapContainer>
  ) : (
    <div className="bg-light rounded border text-muted px-2 text-small">
      No coordinates
    </div>
  )
}
