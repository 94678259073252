import "react-datepicker/dist/react-datepicker.css"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import Select from "common/other/Select"
import DatePicker from "react-datepicker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from "recoil"
import {
  containerFilterState,
  billsOfLadingState,
  contractsState,
  containersState,
  showAdditionalContainerFiltersState
} from "common/recoil/atoms"
import { t, Trans } from "@lingui/macro"
import { useState } from "react"
import ContainerAdditionalFilters from "./ContainerAdditionalFilters"
import useFetch from "common/hooks/useFetch"
import useExportsState from "exports/useExportsState"
import { querySelector } from "common/recoil/selectors"

export const billsOfLadingOptionsQuery = selector({
  key: "billsOfLadingOptionsQuery",
  get: ({ get }) => get(querySelector("/containers/bill_of_ladings/dropdown"))
})

const billsOfLadingOptions = atom({
  key: "billsOfLadingOptions",
  default: billsOfLadingOptionsQuery
})

export default function ContainerFilters() {
  const setBills = useSetRecoilState(billsOfLadingState)
  const billsOptions = useRecoilValue(billsOfLadingOptions)
  const [billsOfLading, setBillsOfLading] = useRecoilState(
    containerFilterState("billsOfLading")
  )
  const [contract, setContract] = useRecoilState(
    containerFilterState("contracts")
  )
  const [from, setFrom] = useRecoilState(containerFilterState("from"))
  const [to, setTo] = useRecoilState(containerFilterState("to"))
  const [container, setContainer] = useRecoilState(
    containerFilterState("container")
  )
  const woodType = useRecoilValue(containerFilterState("species"))
  const minLength = useRecoilValue(containerFilterState("minLength"))
  const maxLength = useRecoilValue(containerFilterState("maxLength"))
  const minVolume = useRecoilValue(containerFilterState("minVolume"))
  const maxVolume = useRecoilValue(containerFilterState("maxVolume"))
  const assortment = useRecoilValue(containerFilterState("assortment"))
  const includeArchived = useRecoilValue(
    containerFilterState("includeArchived")
  )
  const reset = useResetRecoilState(billsOfLadingState)
  const contracts = useRecoilValue(contractsState)
  const containers = useRecoilValue(containersState)
  const [showAdditionalFilters, setShowAdditionalFilters] = useRecoilState(
    showAdditionalContainerFiltersState
  )
  const fetch = useFetch()
  const [filtering, setFiltering] = useState(false)

  const clearBillsOfLading = useResetRecoilState(
    containerFilterState("billsOfLading")
  )
  const clearContracts = useResetRecoilState(containerFilterState("contracts"))
  const clearFrom = useResetRecoilState(containerFilterState("from"))
  const clearTo = useResetRecoilState(containerFilterState("to"))
  const clearContainer = useResetRecoilState(containerFilterState("container"))
  const clearWoodType = useResetRecoilState(containerFilterState("species"))
  const clearMinLength = useResetRecoilState(containerFilterState("minLength"))
  const clearMaxLength = useResetRecoilState(containerFilterState("maxLength"))
  const clearMinVolume = useResetRecoilState(containerFilterState("minVolume"))
  const clearMaxVolume = useResetRecoilState(containerFilterState("maxVolume"))
  const clearAssortment = useResetRecoilState(
    containerFilterState("assortment")
  )
  const clearIncludeArchived = useResetRecoilState(
    containerFilterState("includeArchived")
  )
  function clear() {
    clearBillsOfLading()
    clearContracts()
    clearTo()
    clearFrom()
    clearContainer()
    clearWoodType()
    clearMinLength()
    clearMaxLength()
    clearMinVolume()
    clearMaxVolume()
    clearAssortment()
    clearIncludeArchived()
    reset()
  }
  const { createContainerDashboardExcelReport } = useExportsState()

  return (
    <>
      <Row>
        <Form.Group as={Col} md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Bill of lading</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            value={billsOfLading}
            onChange={event => setBillsOfLading(event)}
            options={billsOptions?.map(bill => ({
              value: bill.id,
              label: bill.name
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Contract</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            value={contract}
            onChange={event => setContract(event)}
            options={contracts?.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Container</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            value={container}
            onChange={event => setContainer(event)}
            options={containers?.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Measured between</Trans>
          </Form.Label>
          <InputGroup style={{ flexWrap: "nowrap" }}>
            <DatePicker
              placeholderText="..."
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-start"
              selected={from}
              onChange={date => setFrom(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
            />
            <InputGroup.Text>
              <FontAwesomeIcon
                icon={faArrowRight}
                size="xs"
                className="text-muted opacity-50"
              />
            </InputGroup.Text>
            <DatePicker
              placeholderText={t`Today`}
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-end"
              selected={to}
              onChange={date => setTo(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </InputGroup>
        </Form.Group>
      </Row>
      {showAdditionalFilters && <ContainerAdditionalFilters />}
      <Col
        xs="6"
        md="6"
        lg="6"
        className="d-inline-flex"
        style={{ alignSelf: "flex-end" }}
      >
        <Button
          className="me-1"
          variant={showAdditionalFilters ? "secondary" : "outline-secondary"}
          onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
        >
          <Trans>Additional filters</Trans>
        </Button>
        <Button
          disabled
          variant="secondary"
          onClick={() =>
            createContainerDashboardExcelReport({
              ...(billsOfLading?.length && {
                bill_of_lading_ids: billsOfLading.map($ => $.value)
              }),
              ...(contract?.length && {
                contract_ids: contract.map($ => $.value)
              }),
              ...(container?.length && {
                container_ids: container.map($ => $.value)
              }),
              ...(assortment?.length && {
                wood_characteristic_ids: assortment.map($ => $.value)
              }),
              ...(minVolume && { volume_range_start: minVolume }),
              ...(maxVolume && { volume_range_end: maxVolume }),
              ...(minLength && { length_range_start: minLength }),
              ...(maxLength && { length_range_end: maxLength }),
              ...(from && {
                date_range_start: new Intl.DateTimeFormat("et-EE").format(from)
              }),
              ...(to && {
                date_range_end: new Intl.DateTimeFormat("et-EE").format(to)
              }),
              ...(woodType?.length && {
                wood_type_ids: woodType.map($ => $.value)
              })
            })
          }
        >
          <Trans>Export</Trans>
        </Button>
      </Col>
      <Col
        xs="12"
        md="6"
        lg="6"
        className="justify-content-end mt-3 d-inline-flex"
        style={{ textAlign: "end" }}
      >
        <Button className="me-1" variant="outline-secondary" onClick={clear}>
          <Trans>Clear</Trans>
        </Button>
        <Button variant="secondary" onClick={filterBills} disabled={filtering}>
          <Trans>Filter</Trans>
        </Button>
      </Col>
    </>
  )

  function filterBills(event) {
    event?.preventDefault()

    const filters = {
      ...(billsOfLading?.length && {
        bill_of_lading_ids: billsOfLading.map($ => $.value)
      }),
      ...(contract?.length && {
        contract_ids: contract.map($ => $.value)
      }),
      ...(container?.length && {
        container_ids: container.map($ => $.value)
      }),
      ...(assortment?.length && {
        wood_characteristic_ids: assortment.map($ => $.value)
      }),
      // ...(minVolume && { volume_range_start: minVolume }),
      // ...(maxVolume && { volume_range_end: maxVolume }),
      ...(minLength && { length_range_start: Number(minLength) }),
      ...(maxLength && { length_range_end: Number(maxLength) }),
      ...(from && {
        date_range_start: new Intl.DateTimeFormat("et-EE").format(from)
      }),
      ...(to && {
        date_range_end: new Intl.DateTimeFormat("et-EE").format(to)
      }),
      ...(woodType?.length && {
        wood_type_ids: woodType.map($ => $.value)
      }),
      ...(includeArchived && {
        archived: true
      })
    }

    if (!Object.keys(filters).length) return

    setFiltering(true)
    fetch("/containers/container_filter", {
      method: "POST",
      body: filters
    })
      .then(setBills)
      .finally(() => setFiltering(false))
  }
}
