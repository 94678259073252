import { Trans } from "@lingui/macro"
import useConvertTimezone from "common/hooks/useConvertTimezone"
import { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import {
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  Tooltip,
  useMap
} from "react-leaflet"
import { finish, start } from "truck-measurement/map/markers"
// import coords from "./route.json"

export default function RouteCard({ route }) {
  const [ready, setReady] = useState(false)
  const convertTimezone = useConvertTimezone()
  const [center, setCenter] = useState()

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
      setReady(true)
    }, 2000)

    if (route?.checkpoints?.length > 0) {
      setCenter([
        route?.checkpoints[Math.floor(route?.checkpoints.length / 2)].latitude,
        route?.checkpoints[Math.floor(route?.checkpoints.length / 2)].longitude
      ])
      // setCenter([
      //   coords[Math.floor(route?.checkpoints.length / 2)].latitude,
      //   coords[Math.floor(route?.checkpoints.length / 2)].longitude
      // ])
    }
    return () => {
      clearTimeout(timeout)
      setReady(false)
    }
  }, [route])

  return ready && center ? (
    <Card className="d-flex flex-column p-3" style={{ height: "fit-content" }}>
      <div className="pb-3 lh-1">
        <div className="fw-semibold">Shipment tracking</div>
        <small className="text-muted">
          {convertTimezone(route?.started_at)}
          {route?.finished_at
            ? " — " + convertTimezone(route?.finished_at)
            : ""}
        </small>
      </div>
      <div className="d-flex flex-row img-thumbnail">
        <MapContainer
          zoom={8}
          center={center || [0, 0]}
          scrollWheelZoom={true}
          style={{ height: "180px", width: "280px" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <RouteLine positions={mapToCoordinates(route?.checkpoints)} />
          {/* <RouteLine positions={mapToCoordinates(coords)} /> */}
        </MapContainer>
      </div>
      {/* <div className="d-flex gap-2 flex-wrap">
        <Button variant="light" className="border w-full" size="sm">
          <FontAwesomeIcon icon={faList} className="me-1" />
          <Trans>Details</Trans>
        </Button>
      </div> */}
    </Card>
  ) : (
    <Card body style={{ height: "fit-content" }}>
      <Trans>Loading route...</Trans>
    </Card>
  )
}

function mapToCoordinates(positions) {
  return positions?.length > 0
    ? positions?.map($ => [$.latitude, $.longitude])
    : []
}

function RouteLine({ positions }) {
  const map = useMap()

  useEffect(() => {
    if (positions.length) {
      map.flyToBounds([positions[0], positions.at(-1)])
    }
  }, [positions, map])
  return (
    <>
      <Polyline
        pathOptions={{
          color: "tomato",
          opacity: 0.75
        }}
        positions={positions}
      />
      <Marker icon={start} position={positions[0]}>
        <Tooltip>
          <Trans>Starting point</Trans>
        </Tooltip>
      </Marker>
      <Marker icon={finish} position={positions[positions.length - 1]}>
        <Tooltip>
          <Trans>Destination</Trans>
        </Tooltip>
      </Marker>
    </>
  )
}
