import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Alert } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default function ResourceNotFoundAlert({ text }) {
  const navigate = useNavigate()

  return (
    <Alert variant="secondary">
      <Alert.Heading>
        <FontAwesomeIcon icon={faCircleExclamation} className="me-2" />
        <Trans>Not found</Trans>
      </Alert.Heading>
      <Trans>The requested resource is not found.</Trans>{" "}
      <Alert.Link onClick={() => navigate(-1)}>Go back</Alert.Link>
    </Alert>
  )
}
