import { atom, atomFamily, selector, selectorFamily } from "recoil"
import {
  storagesQuery,
  volumeFormulasQuery,
  woodAssortmentsQuery,
  woodQualitiesQuery,
  woodTypesQuery,
  enabledFeaturesQuery,
  reportsQuery,
  accountSettingsQuery,
  organizationsQuery,
  truckStoragesQuery,
  contractsQuery,
  containersQuery,
  woodCullsQuery,
  diameterProfilesQuery,
  usersQuery,
  querySelector,
  customFieldsQuery,
  shortestDiameterLogState,
  longestDiameterLogState
} from "common/recoil/selectors"
import { subscriptionItemsState } from "billing"

export const storagesState = atom({
  key: "storages",
  default: storagesQuery
})

export const truckStoragesState = atom({
  key: "truckStorages",
  default: truckStoragesQuery
})

export const storageExpandedState = atomFamily({
  key: "storageExpanded",
  default: false
})

export const truckStorageExpandedState = atomFamily({
  key: "truckStorageExpanded",
  default: false
})

export const billExpandedState = atomFamily({
  key: "billExpanded",
  default: false
})

export const containerExpandedState = atomFamily({
  key: "containerExpanded",
  default: false
})

export const billsOfLadingQuery = selector({
  key: "billsOfLadingQuery",
  get: ({ get }) =>
    get(
      querySelector({
        url: "/containers/container_filter",
        options: {
          method: "POST"
        }
      })
    )
})

export const billsOfLadingState = atom({
  key: "billsOfLading",
  default: billsOfLadingQuery
})

export const billOfLadingState = selectorFamily({
  key: "billOfLading",
  get:
    id =>
    ({ get }) =>
      get(billsOfLadingState).find($ => $.id === id),
  set:
    id =>
    ({ get, set }, updatedBillOfLading) => {
      set(
        billsOfLadingState,
        get(billsOfLadingState).map(billOfLading =>
          billOfLading.id === id
            ? {
                ...billOfLading,
                ...updatedBillOfLading
              }
            : billOfLading
        )
      )
    }
})

export const billOfLadingQuery = selectorFamily({
  key: "billOfLadingQuery",
  get:
    id =>
    ({ get }) =>
      get(querySelector("/containers/bill_of_ladings/" + id))
})

export const containersState = atom({
  key: "containers",
  default: containersQuery
})

export const truckMeasurementLoadsState = atomFamily({
  key: "truckMeasurementLoads",
  default: null
})

export const filteredStoragesState = atom({
  key: "filteredStorages",
  default: storagesState
})

export const filteredTruckStoragesState = atom({
  key: "filteredTruckStorages",
  default: truckStoragesState
})

export const openStorageIdsState = atom({
  key: "openStorageIds",
  default: []
})

export const measurementsFilterState = atom({
  key: "measurementsFilter",
  default: {}
})

export const truckStorageMeasurementsState = atomFamily({
  key: "truckStorageMeasurements",
  default:
    storageId =>
    async ({ get }) =>
      get(truckStorageMeasurementsState).filter($ => $.storage_id === storageId)
})

export const measurementsStateFamily = atomFamily({
  key: "measurements",
  default: null
})

export const truckMeasurementsStateFamily = atomFamily({
  key: "truckMeasurements",
  default: null
})

export const woodQualitiesState = atom({
  key: "woodQualities",
  default: woodQualitiesQuery
})

export const woodCullsState = atom({
  key: "woodCulls",
  default: woodCullsQuery
})

export const usersState = atom({
  key: "users",
  default: usersQuery
})

export const diameterProfilesState = atom({
  key: "diameterProfiles",
  default: diameterProfilesQuery
})

export const woodAssortmentsState = atom({
  key: "woodAssortments",
  default: woodAssortmentsQuery
})

export const navigationState = atom({
  key: "navigation",
  default: null
})

export const reportsState = atom({
  key: "reports",
  default: reportsQuery
})

export const enabledFeaturesState = atom({
  key: "enabledFeatures",
  default: enabledFeaturesQuery
})

export const customFieldsState = atom({
  key: "customFields",
  default: customFieldsQuery
})

export const measurementCustomFieldsSelector = selector({
  key: "measurementCustomFieldsSelector",
  get: ({ get }) =>
    get(customFieldsState)?.filter($ => $.source_key === "measurement") ?? []
})

export const storageCustomFieldsSelector = selector({
  key: "storageCustomFieldsSelector",
  get: ({ get }) =>
    get(customFieldsState)?.filter($ => $.source_key === "storage") ?? []
})

export const featureEnabled = selectorFamily({
  key: "featureEnabled",
  get:
    feature =>
    ({ get }) =>
      get(subscriptionItemsState)
        ? get(subscriptionItemsState)?.some(item =>
            item.features.includes(feature)
          )
        : false
})

export const languageState = atom({
  key: "language",
  default: selector({
    key: "language/default",
    get: ({ get }) => {
      if (!localStorage.getItem("access_token")) return "en"
      const accountSettings = get(accountSettingsState)
      return (
        {
          en: "en",
          et: "et",
          ru: "ru",
          pl: "pl",
          lt: "lt",
          de: "de",
          fr: "fr",
          pt_BR: "pt",
          es: "es",
          ja: "ja",
          it: "it",
          "ka-GE": "ka",
          hi: "hi"
        }[accountSettings?.locale] || "en"
      )
    }
  })
})

export const accountSettingsState = atom({
  key: "accountSettings",
  default: accountSettingsQuery
})

export const organizationsState = atom({
  key: "organizations",
  default: organizationsQuery
})

export const referencesState = atom({
  key: "references",
  default: []
})

export const measurementAreaState = atom({
  key: "measurementArea",
  default: null
})

export const logsRangeState = atom({
  key: "logsRange",
  default: selector({
    key: "logsRange/default",
    get: ({ get }) => {
      return [
        Number(get(shortestDiameterLogState)),
        Number(get(longestDiameterLogState))
      ]
    }
  })
})

export const selectedMeasurementsState = atom({
  key: "MEASUREMENTS_SELECTED",
  default: []
})

export const upgradeState = atom({
  key: "IS_UPGRADED",
  default: false
})
export const upgradeModalState = atom({
  key: "UPGRADE_MODAL",
  default: false
})

export const roundingPrecisionState = atom({
  key: "roundingPrecision",
  // default: 3
  default: selector({
    key: "roundingPrecision/default",
    get: ({ get }) => get(accountSettingsState).rounding_precision
  })
})

export const volumeFormulasState = atom({
  key: "volumeFormulas",
  default: volumeFormulasQuery
})

export const volumeUnitsState = atom({
  key: "volumeUnits",
  default: ["m^3", "ft^3", "fbm", "pmt", "stere"]
})

export const woodTypesState = atom({
  key: "woodTypes",
  default: woodTypesQuery
})

export const devicesState = atom({
  key: "devices",
  default: selector({
    key: "devices/default",
    get: async ({ get }) => {
      const devices = (await get(devicesQuery)).devices
      return devices.length ? devices : []
    }
  })
})

export const lockedDevicesSelector = selector({
  key: "lockedDevices",
  get: ({ get }) => get(devicesState).filter($ => $.locked)
})

export const deviceKeySelector = selector({
  key: "deviceKey",
  get: async ({ get }) => {
    return await get(devicesQuery).key
  }
})

export const deviceKeysSelector = selector({
  key: "deviceKeys",
  get: async ({ get }) => {
    return await get(devicesQuery).keys
  }
})

export const devicesQuery = selector({
  key: "devicesQuery",
  get: ({ get }) => get(querySelector("/devices"))
})

export const fetchingMeasurementsStateFamily = atomFamily({
  key: "fetchingMeasurements",
  default: false
})

export const showAdditionalTimberFiltersState = atom({
  key: "showAdditionalTimberFilters",
  default: false
})

export const showAdditionalTruckFiltersState = atom({
  key: "showAdditionalTruckFilters",
  default: false
})

export const showAdditionalContainerFiltersState = atom({
  key: "showAdditionalContainerFilters",
  default: false
})

export const showMapState = atom({
  key: "showMap",
  default: false
})

export const timberFilterState = atomFamily({
  key: "timberFilter",
  default: null
})

export const containerFilterState = atomFamily({
  key: "containerFilter",
  default: null
})

export const truckFilterState = atomFamily({
  key: "truckFilter",
  default: null
})

export const contractsState = atom({
  key: "contracts",
  default: contractsQuery
})

export const measurementsModeState = atom({
  key: "measurementsMode",
  default: selector({
    key: "measurementMode/default",
    get: ({ get }) =>
      get(accountSettingsState)?.recent_measurement_mode || "timber"
  })
})

export const markersState = atom({
  key: "markersState",
  default: null
})

export const measurementEditorLoadsState = atom({
  key: "measurementEditorLoads",
  default: null
})
