import { Trans } from "@lingui/macro"
import { Badge, Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { woodAssortmentsState } from "common/recoil/atoms"
import { useNavigate } from "react-router-dom"

export default function WoodAssortmentsTable() {
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const navigate = useNavigate()

  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <Trans>Wood assortment</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...woodAssortments]
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map(assortment => (
            <tr
              key={assortment.id}
              onClick={() => navigate(assortment.id.toString())}
            >
              <td title={assortment.name}>
                {assortment.name}{" "}
                {assortment.archived && <Badge bg="secondary">Archived</Badge>}
              </td>
            </tr>
          ))}
        {woodAssortments?.length < 1 && (
          <td colSpan={5}>
            <div className="my-3 text-center">
              <p className="h6 text-black-50">
                <Trans>No assortments found</Trans>
              </p>
            </div>
          </td>
        )}
      </tbody>
    </Table>
  )
}
