import { Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Dropdown, Form, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"
import { useRecoilState } from "recoil"
import { billOfLadingState } from "common/recoil/atoms"

export default function EditBillOfLadingModal({ id }) {
  const [show, setShow] = useState(false)
  const [bill, setBill] = useRecoilState(billOfLadingState(id))
  const [name, setName] = useState(bill?.name || "")
  const [token, setToken] = useState(bill?.token || "")
  const [contractId, setContractId] = useState(bill?.contract_id || "")
  const [woodCharacteristicId, setWoodCharacteristicId] = useState(
    bill?.wood_characteristic_id || ""
  )

  const fetch = useFetch()

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <Trans>Edit</Trans>
        <span className="float-end">
          <FontAwesomeIcon icon={faEdit} />
        </span>
      </Dropdown.Item>

      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faEdit} className="me-2" />

            <Trans>Edit Bill of Lading </Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="edit-bill-of-lading" onSubmit={submitHandler}>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Name</Trans>
              </Form.Label>
              <Form.Control
                value={name}
                onChange={event => setName(event?.target?.value)}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Token</Trans>
              </Form.Label>
              <Form.Control
                value={token}
                onChange={event => setToken(event?.target?.value)}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Contract</Trans>
              </Form.Label>
              <Form.Control
                value={contractId}
                onChange={event => setContractId(event?.target?.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <Trans>Wood characteristic</Trans>
              </Form.Label>
              <Form.Control
                value={woodCharacteristicId}
                onChange={event =>
                  setWoodCharacteristicId(event?.target?.value)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" form="edit-bill-of-lading" size="lg">
            <Trans>Save</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )

  async function submitHandler(event) {
    event.preventDefault()
    const newBill = {
      ...bill,
      name,
      token,
      contract_id: contractId,
      wood_characteristic_id: woodCharacteristicId
    }
    fetch(`/containers/bill_of_ladings/${bill.id}`, {
      method: "PATCH",
      body: newBill
    })
      .then(response => {
        if (!response) {
          return toast.error(<Trans>Failed to save changes</Trans>)
        }
        setBill(newBill)
        toast.success(<Trans>Your changes have been saved</Trans>)
      })
      .finally(() => setShow(false))
  }
}
