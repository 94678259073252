import useFetch from "common/hooks/useFetch"
import { reportsState } from "common/recoil/atoms"
import { selectorFamily, useRecoilCallback, useRecoilState } from "recoil"

export const getReportById = selectorFamily({
  key: "getReportById",
  get:
    reportId =>
    ({ get }) =>
      get(reportsState).find($ => $.id === Number(reportId)),
  set:
    reportId =>
    ({ set }, updatedReport) =>
      set(reportsState, $ =>
        $.map(report =>
          report.id === Number(reportId)
            ? { ...report, ...updatedReport }
            : report
        )
      )
})

export default function useReportsState() {
  const [reports, setReports] = useRecoilState(reportsState)
  const fetch = useFetch()

  const newReport = useRecoilCallback(
    () => async report => {
      fetch("/reports", {
        method: "POST",
        body: { report }
      }).then(response => {
        if (response) {
          setReports([...reports, response])
        }
      })
    },
    []
  )

  return {
    reports,
    newReport
  }
}
