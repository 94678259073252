export default function normalizeJson(json) {
  if (!json) return null
  let normalizedJson = {
    id: json?.id,
    type: json?.type,
    token: json?.token,
    ...json?.attributes,
    logs: json?.attributes?.logs?.map(replaceObjectKeys)
  }

  return replaceObjectKeys(normalizedJson)
}

export const replaceObjectKeys = obj =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.replaceAll("-", "_")]: value
    }),
    {}
  )
