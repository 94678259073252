import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Alert } from "react-bootstrap"

export default function NotFound() {
  return (
    <Alert variant="info" className="small my-2 fw-semibold">
      <FontAwesomeIcon
        size="lg"
        icon={faInfoCircle}
        className="me-2 opacity-50"
      />
      <Trans>Page not found</Trans>
    </Alert>
  )
}
