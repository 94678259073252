import { Trans, t } from "@lingui/macro"
import { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  ToggleButton,
  ToggleButtonGroup
} from "react-bootstrap"
import {
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import useFetch from "common/hooks/useFetch"
import {
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import formatVolumeUnit from "common/utils/formatVolumeUnit"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faEdit } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import ResourceNotFoundAlert from "common/other/ResourceNotFoundAlert"

const woodAssortmentSelector = selectorFamily({
  key: "woodAssortmentSelector",
  get:
    id =>
    ({ get }) =>
      id ? get(woodAssortmentsState).find($ => $.id === id) : null,
  set:
    id =>
    ({ set }, newWoodAssortment) =>
      id
        ? set(woodAssortmentsState, assortments =>
            assortments.map($ =>
              $.id === id ? { ...$, ...newWoodAssortment } : $
            )
          )
        : null
})

export default function EditWoodAssortmentCard() {
  const { id } = useParams()
  const [woodAssortment, setWoodAssortment] = useRecoilState(
    woodAssortmentSelector(Number(id))
  )
  const setWoodAssortments = useSetRecoilState(woodAssortmentsState)
  const [woodAssortmentName, setWoodAssortmentName] = useState("")
  const [woodAssortmentSpecies, setWoodAssortmentSpecies] = useState("")
  const [woodAssortmentQuality, setWoodAssortmentQuality] = useState("")
  const [woodAssortmentLogLength, setWoodAssortmentLogLength] = useState("")
  const [woodAssortmentWeightDensity, setWoodAssortmentWeightDensity] =
    useState("")
  const [woodAssortmentMinimumDiameter, setWoodAssortmentMinimumDiameter] =
    useState("")
  const [woodAssortmentMaximumDiameter, setWoodAssortmentMaximumDiameter] =
    useState("")
  const [woodAssortmentPricePer, setWoodAssortmentPricePer] = useState("")
  const [woodAssortmentPrice, setWoodAssortmentPrice] = useState("")
  const [woodAssortmentPriceUnit, setWoodAssortmentPriceUnit] = useState("log")
  const fetch = useFetch()
  const [volumeUnit, setVolumeUnit] = useState("m^3")
  const species = useRecoilValue(woodTypesState)
  const qualities = useRecoilValue(woodQualitiesState)
  const navigate = useNavigate()

  useEffect(() => {
    if (woodAssortment?.name) {
      setWoodAssortmentName(woodAssortment.name)
    }

    if (woodAssortment?.wood_type_id) {
      setWoodAssortmentSpecies(woodAssortment.wood_type_id)
    }

    if (woodAssortment?.wood_quality_id) {
      setWoodAssortmentQuality(woodAssortment.wood_quality_id)
    }

    if (woodAssortment?.log_length) {
      setWoodAssortmentLogLength(woodAssortment.log_length)
    }

    if (woodAssortment?.weight_density) {
      setWoodAssortmentWeightDensity(woodAssortment.weight_density)
    }

    if (woodAssortment?.min) {
      setWoodAssortmentMinimumDiameter(woodAssortment.min)
    }

    if (woodAssortment?.max) {
      setWoodAssortmentMaximumDiameter(woodAssortment.max)
    }

    if (woodAssortment?.price_per_log_cents > 0) {
      setWoodAssortmentPriceUnit("log")
      setWoodAssortmentPricePer(woodAssortment.price_per_log_cents)
    }

    if (woodAssortment?.price_cents > 0) {
      setWoodAssortmentPriceUnit("volume")
      setWoodAssortmentPrice(woodAssortment.price_cents)
    }
  }, [woodAssortment, id])

  return woodAssortment ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit wood assortment</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="edit-wood-assortment" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              value={woodAssortmentName}
              onChange={event => setWoodAssortmentName(event?.target?.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Species</Trans>
            </Form.Label>
            <Form.Select
              value={woodAssortmentSpecies}
              onChange={event => setWoodAssortmentSpecies(event?.target?.value)}
            >
              <option key="placeholder" hidden value>
                Choose species
              </option>
              {species?.map($ => (
                <option key={$.id} value={$.id}>
                  {$.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Quality</Trans>
            </Form.Label>
            <Form.Select
              value={woodAssortmentQuality}
              onChange={event => setWoodAssortmentQuality(event?.target?.value)}
            >
              <option key="placeholder" hidden value>
                Choose quality
              </option>
              {qualities?.map($ => (
                <option key={$.id} value={$.id}>
                  {$.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>
                <Trans>Log length</Trans>
              </Form.Label>
              <Form.Control
                type="number"
                value={woodAssortmentLogLength}
                onChange={event =>
                  setWoodAssortmentLogLength(event?.target?.value)
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" as={Col}>
              <Form.Label>
                <Trans>Weight density</Trans>
              </Form.Label>
              <Form.Control
                type="number"
                value={woodAssortmentWeightDensity}
                onChange={event =>
                  setWoodAssortmentWeightDensity(event?.target?.value)
                }
              />
            </Form.Group>
          </Row>

          <Row>
            {" "}
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>
                <Trans>Minimum diameter</Trans>
              </Form.Label>
              <Form.Control
                type="number"
                value={woodAssortmentMinimumDiameter}
                onChange={event =>
                  setWoodAssortmentMinimumDiameter(event?.target?.value)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>
                <Trans>Maximum diameter</Trans>
              </Form.Label>
              <Form.Control
                type="number"
                value={woodAssortmentMaximumDiameter}
                onChange={event =>
                  setWoodAssortmentMaximumDiameter(event?.target?.value)
                }
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col}>
              <Form.Label>
                <Trans>Pricing</Trans>
              </Form.Label>
              <br />
              <ToggleButtonGroup
                type="radio"
                name="priceUnit"
                className="w-100"
              >
                <ToggleButton
                  variant={
                    woodAssortmentPriceUnit === "volume"
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => setWoodAssortmentPriceUnit("volume")}
                >
                  <Trans>Volume</Trans>
                </ToggleButton>
                <ToggleButton
                  variant={
                    woodAssortmentPriceUnit === "log" ? "primary" : "secondary"
                  }
                  onClick={() => setWoodAssortmentPriceUnit("log")}
                >
                  <Trans>Single</Trans>
                </ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>
                <Trans>Price (in cents)</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className="form-control-append"
                  value={
                    woodAssortmentPriceUnit === "log"
                      ? woodAssortmentPricePer
                      : woodAssortmentPriceUnit === "volume"
                      ? woodAssortmentPrice
                      : ""
                  }
                  onChange={event => {
                    if (woodAssortmentPriceUnit === "volume")
                      setWoodAssortmentPrice(event?.target?.value)
                    else if (woodAssortmentPriceUnit === "log")
                      setWoodAssortmentPricePer(event?.target?.value)
                  }}
                />
                {woodAssortmentPriceUnit === "volume" && (
                  <DropdownButton
                    variant="secondary"
                    title={
                      <>
                        {formatVolumeUnit(volumeUnit)}{" "}
                        <FontAwesomeIcon size="xs" icon={faChevronDown} />
                      </>
                    }
                  >
                    {["m^3", "ft^3", "fbm", "pmt"].map($ => (
                      <Dropdown.Item key={$} onClick={() => setVolumeUnit($)}>
                        {formatVolumeUnit($)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                )}
              </InputGroup>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          className="float-start"
          onClick={() => navigate("/timber/assortments")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button variant="secondary" className="me-3" onClick={handleDelete}>
          <Trans>Archive</Trans>
        </Button>
        <Button
          type="submit"
          form="edit-wood-assortment"
          disabled={
            woodAssortmentName === woodAssortment?.name &&
            Number(woodAssortmentSpecies) ===
              Number(woodAssortment.wood_type_id) &&
            Number(woodAssortmentQuality) ===
              Number(woodAssortment.wood_quality_id) &&
            Number(woodAssortmentMinimumDiameter) ===
              Number(woodAssortment.min) &&
            Number(woodAssortmentMaximumDiameter) ===
              Number(woodAssortment.max) &&
            Number(woodAssortmentLogLength) ===
              Number(woodAssortment.log_length) &&
            Number(woodAssortmentPrice) ===
              Number(woodAssortment.price_cents) &&
            Number(woodAssortmentPricePer) ===
              Number(woodAssortment.price_per_log_cents) &&
            Number(woodAssortmentWeightDensity) ===
              Number(woodAssortment.weight_density)
          }
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <ResourceNotFoundAlert />
  )

  function handleSubmit(event) {
    event.preventDefault()

    const newWoodAssortment = {
      ...(woodAssortmentName && { name: woodAssortmentName }),
      ...(woodAssortmentSpecies && {
        wood_type_id: Number(woodAssortmentSpecies)
      }),
      ...(woodAssortmentQuality && {
        wood_quality_id: Number(woodAssortmentQuality)
      }),
      ...(woodAssortmentMinimumDiameter && {
        min: woodAssortmentMinimumDiameter
      }),
      ...(woodAssortmentMaximumDiameter && {
        max: woodAssortmentMaximumDiameter
      }),
      ...(woodAssortmentLogLength && {
        log_length: Number(woodAssortmentLogLength)
      }),
      ...(woodAssortmentPrice && {
        price_cents: woodAssortmentPrice
      }),
      ...(woodAssortmentPricePer && {
        price_per_log_cents: woodAssortmentPricePer
      }),
      ...(woodAssortmentWeightDensity && {
        weight_density: woodAssortmentWeightDensity
      }),
      ...(volumeUnit && {
        volume_unit: volumeUnit
      })
    }

    fetch("/assortments/" + id, {
      method: "PATCH",
      body: {
        wood_characteristic: newWoodAssortment
      }
    }).then(response => {
      if (response) {
        toast.success(<Trans>Saved</Trans>)
        setWoodAssortment({
          ...woodAssortment,
          ...newWoodAssortment
        })
      }
    })
  }

  function handleDelete() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/assortments/" + id, {
        method: "DELETE"
      }).then(response => {
        setWoodAssortments(assortments =>
          assortments.filter($ => $.id !== Number(id))
        )
        toast.success(<Trans>Archived</Trans>)
        navigate("/timber/assortments")
      })
    }
  }
}
