import { Trans } from "@lingui/macro"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

export default function DefaultTooltip({ text }) {
  return (
    <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
      <span className="fw-semibold small opacity-25 initialism">
        <Trans>Default</Trans>
      </span>
    </OverlayTrigger>
  )
}
