import { Trans, t } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilValue, useSetRecoilState } from "recoil"
import useFetch from "common/hooks/useFetch"
import { featureEnabled, woodQualitiesState } from "common/recoil/atoms"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import UpgradePlanCard from "common/other/UpgradePlanCard"

export default function NewWoodQualityCard() {
  const setWoodQualities = useSetRecoilState(woodQualitiesState)
  const [qualityName, setQualityName] = useState("")
  const fetch = useFetch()
  const enabled = useRecoilValue(featureEnabled("timber_management"))
  const navigate = useNavigate()

  function handleSubmit(event) {
    event.preventDefault()

    fetch("/wood_qualities", {
      method: "POST",
      body: {
        name: qualityName
      }
    }).then(response => {
      if (response) {
        setWoodQualities($ => [...$, response])
        navigate("/timber/qualities")
        toast.success(<Trans>Added</Trans>)
      }
    })
  }

  return enabled ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New wood quality</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="new-wood-quality" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              disabled={!enabled}
              value={qualityName}
              onChange={event => setQualityName(event?.target?.value)}
              required
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        {/* TODO: a better way to restrict features */}
        {/* {enabled ? (
          <Button type="submit" form="new-wood-quality" disabled={!qualityName}>
            <Trans>Add quality</Trans>
          </Button>
        ) : (
          <UpgradePlanCard feature={t`timber management`} />
        )} */}
        <Button
          variant="outline-secondary"
          className="float-start"
          onClick={() => navigate("/timber/qualities")}
          style={{ borderColor: "transparent" }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button type="submit" form="new-wood-quality" disabled={!qualityName}>
          <Trans>Add quality</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <UpgradePlanCard feature={t`timber management`} />
  )
}
