import { Trans } from "@lingui/macro"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { roundingPrecisionState } from "common/recoil/atoms"
import { activeStateQuery } from "common/recoil/selectors"

export default function ActiveState({ id }) {
  useRecoilValueLoadable(activeStateQuery(id))
  const activeState = useRecoilValue(activeStateQuery(id))
  const roundingPrecision = useRecoilValue(roundingPrecisionState)

  return (
    <tr style={{ borderTop: "1px solid whitesmoke" }} className="bg-white">
      <td style={{ padding: 0 }} colSpan="8">
        <table style={{ marginTop: "-8px", width: "100%" }}>
          <tbody>
            <tr className="bg-whitesmoke">
              <td style={{ paddingLeft: "68px" }}>
                <b>
                  <Trans>Timber</Trans>
                </b>
              </td>
              <td>
                <b>
                  <Trans>Previous inventory</Trans>
                </b>
              </td>
              <td style={{ display: "flex", justifyContent: "space-between" }}>
                <small className="text-black-50" style={{ float: "left" }}>
                  - {largestSubtotal(activeState).toFixed(roundingPrecision)} m³
                </small>
                <small className="text-black-50">0</small>
                <small className="text-black-50" style={{ float: "right" }}>
                  + {largestSubtotal(activeState).toFixed(roundingPrecision)} m³
                </small>
              </td>
              <td>
                <b>
                  <Trans>Volume</Trans>
                </b>
              </td>
            </tr>
            {Object.keys(activeState).reduce(
              (acc, val, i) => [
                ...acc,
                <tr key={i} className="bg-white">
                  <td style={{ paddingLeft: "68px" }}>{val}</td>
                  <td>
                    {activeState[val].last_inventory
                      ? Number(activeState[val].last_inventory).toFixed(
                          roundingPrecision
                        ) + "  m³"
                      : "–"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      {progressBar(
                        activeState[val],
                        largestSubtotal(activeState)
                      )}
                    </div>
                  </td>
                  <td>
                    {Number(activeState[val].calculated).toFixed(
                      roundingPrecision
                    ) || "–"}{" "}
                    m³
                  </td>
                </tr>
              ],
              []
            )}
            <tr>
              <td colSpan="2" />
              <td>
                <b>
                  <Trans>Previous inventory</Trans>
                </b>
              </td>
              <td>
                +{" "}
                {previousInventoryTotal(activeState).toFixed(roundingPrecision)}{" "}
                m³
              </td>
            </tr>
            <tr>
              <td colSpan="2" />
              <td>
                <b>
                  <Trans>Incoming</Trans>
                </b>
              </td>
              <td>
                + {incomingTotal(activeState).toFixed(roundingPrecision)} m³
              </td>
            </tr>
            <tr>
              <td colSpan="2" />
              <td>
                <b>
                  <Trans>Outgoing</Trans>
                </b>
              </td>
              <td>
                - {outgoingTotal(activeState).toFixed(roundingPrecision)} m³
              </td>
            </tr>
            <tr>
              <td colSpan="2" />
              <td>
                <b>
                  <Trans>Measured total</Trans>
                </b>
              </td>
              <td>
                {measuredTotal(activeState).toFixed(roundingPrecision)} m³
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  )

  function progressBar(activeStateRow, max) {
    let amount = activeStateRow.subtotal
    let isOutgoing = false
    let isIncoming = false

    if (Math.abs(activeStateRow.subtotal)) {
      if (Math.sign(activeStateRow.subtotal) === 1) {
        isIncoming = true
      } else if (Math.sign(activeStateRow.subtotal) === -1) {
        isOutgoing = true
      }
    }

    // if (activeStateRow.outgoing) {
    //   isOutgoing = true
    //   amount = activeStateRow.outgoing
    // }

    // if (activeStateRow.incoming) {
    //   isIncoming = true
    //   amount = activeStateRow.incoming
    // }

    amount = Number(amount).toFixed(3)

    const percentage =
      Math.abs(Number(Math.floor(amount) / Math.floor(max)) * 100) + "%"

    return (
      <div style={{ display: "flex" }}>
        <div style={{ background: "whitesmoke", height: "4px", width: "50%" }}>
          <div
            style={{
              float: "right",
              background: "tomato",
              borderRight: "1px solid black",
              height: "4px",
              width: isOutgoing && percentage
            }}
          >
            <small>
              {isOutgoing &&
                `- ${Math.abs(Number(amount)).toFixed(roundingPrecision)} m³`}
            </small>
          </div>
        </div>
        <div style={{ background: "whitesmoke", height: "4px", width: "50%" }}>
          <div
            style={{
              background: "lightgreen",
              height: "4px",
              width: isIncoming ? percentage : "0%"
            }}
          >
            <small>
              {isIncoming && `+ ${amount} m³`}
              {!(isIncoming || isOutgoing) && `${amount} m³`}
            </small>
          </div>
        </div>
      </div>
    )
  }
}

function previousInventoryTotal(activeState) {
  return Object.keys(activeState).reduce(
    (acc, val) => acc + Number(activeState[val].last_inventory || 0),
    0
  )
}

function incomingTotal(activeState) {
  return Object.keys(activeState).reduce(
    (acc, val) => acc + Number(activeState[val].incoming || 0),
    0
  )
}

function outgoingTotal(activeState) {
  return Object.keys(activeState).reduce(
    (acc, val) => acc + Number(activeState[val].outgoing || 0),
    0
  )
}

function measuredTotal(activeState) {
  return Object.keys(activeState).reduce(
    (acc, val) => acc + Number(activeState[val].calculated || 0),
    0
  )
}

function largestSubtotal(activeState) {
  return Object.keys(activeState).reduce(
    (acc, val) =>
      Math.abs(
        Math.abs(activeState[val].subtotal) > acc
          ? Math.abs(activeState[val].subtotal)
          : acc
      ),
    0
  )
}
