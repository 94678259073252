import { useRef, useState } from "react"
import { Circle, Group, Line } from "react-konva"
import { EDIT, LOAD_LINES } from "common/enums/constants"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { loadSelector } from "common/recoil/selectors"
import { editorSettingsState } from "."

export default function LoadLine({ load }) {
  const lineRef = useRef(null)
  const anchor1Ref = useRef(null)
  const anchor2Ref = useRef(null)
  const updateLoad = useSetRecoilState(loadSelector(load.id))
  const [x] = useState(load.x)
  const [y1, setY1] = useState(load.y1)
  const [y2, setY2] = useState(load.y2)
  const mode = useRecoilValue(editorSettingsState("mode"))
  const edit = useRecoilValue(editorSettingsState("edit"))

  function handleDragMoveY1() {
    setY1(y1 + anchor1Ref.current.y())
  }

  function handleDragMoveY2() {
    setY2(anchor2Ref.current.y() + y1)
  }

  function updateLine() {
    const newCoords = {
      x: lineRef.current.x(),
      y1: lineRef.current.y(),
      y2: lineRef.current.y() + anchor2Ref.current.y()
    }
    updateLoad(newCoords)
  }

  return (
    <Group
      ref={lineRef}
      x={x}
      y={y1}
      draggable={edit === LOAD_LINES}
      onDragEnd={updateLine}
    >
      <Line points={[0, 0, 0, y2 - y1]} stroke={"orange"} strokeWidth={8} />
      <Group
        ref={anchor1Ref}
        draggable={edit === LOAD_LINES}
        onDragMove={handleDragMoveY1}
        dragBoundFunc={function ({ y }) {
          return {
            x: this.absolutePosition().x,
            y
          }
        }}
      >
        <Line points={[-48, 0, 48, 0]} stroke={"orange"} strokeWidth={8} />
        {mode === EDIT && (
          <Circle
            radius={64}
            fill="rgba(255, 255, 255, 33%)"
            strokeWidth={2}
            visible={edit === LOAD_LINES}
          />
        )}
      </Group>
      <Group
        ref={anchor2Ref}
        draggable={edit === LOAD_LINES}
        y={y2 - y1}
        dragBoundFunc={function ({ y }) {
          return {
            x: this.absolutePosition().x,
            y
          }
        }}
        onDragMove={handleDragMoveY2}
      >
        <Line points={[-48, 0, 48, 0]} stroke={"orange"} strokeWidth={8} />
        {mode === EDIT && (
          <Circle
            radius={64}
            fill="rgba(255, 255, 255, 33%)"
            strokeWidth={2}
            visible={edit === LOAD_LINES}
          />
        )}
      </Group>
    </Group>
  )
}
