import { t, Trans } from "@lingui/macro"
// import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import { Suspense, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import { containerMeasurementState } from "./ContainerMeasurementRowList"
import { Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import ContainerMeasurementDetails from "./ContainerMeasurementDetails"
import MeasurementDevice from "common/other/MeasurementDevice"
import useFetch from "common/hooks/useFetch"
import ContainerCard from "./ContainerCard"
import MeasurementEditorCard from "../common/other/MeasurementEditorCard"

export default function ContainerMeasurementDetailView() {
  const { id: measurementId } = useParams()
  const [measurement, setMeasurement] = useRecoilState(
    containerMeasurementState(Number(measurementId))
  )
  const fetch = useFetch()
  const navigate = useNavigate()
  useEffect(() => {
    if (!measurement) {
      fetch(
        `/containers/measurements/${measurementId}?${new URLSearchParams({
          extra_fields: "logs,references"
        })}`
      ).then(response => {
        setMeasurement(response?.measurement)
      })
    }
    // eslint-disable-next-line
  }, [])

  return measurement ? (
    <Suspense fallback={<Loading text={t`Loading measurement...`} />}>
      <Row>
        <Col>
          <span
            className="fw-medium text-white-50 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
            <Trans>Back to dashboard</Trans>
          </span>
          <hr className="text-light" />
          <h5 className="text-light mb-0">
            <span className="me-3">{measurement.photo_file_name}</span>
          </h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-white-50">
          <b className="me-1">ID:</b>
          <span>{measurement.id}</span>
        </Col>
      </Row>
      <div className="d-flex flex-wrap row gy-4">
        <Col xs="12" lg="6" xl="4">
          <ContainerMeasurementDetails measurement={measurement} />
        </Col>
        <Col xs="12" lg="6" xl="4" className="d-flex flex-column gap-4">
          <MeasurementEditorCard measurement={measurement} type="container" />
          <MeasurementDevice deviceId={measurement?.device_id} />
        </Col>
        <Col xs="12" lg="6" xl="4">
          <ContainerCard container={measurement?.container} />
        </Col>
      </div>
    </Suspense>
  ) : (
    <Loading />
  )
}
