import { t, Trans } from "@lingui/macro"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import { useState } from "react"
import { Card, Form, Button } from "react-bootstrap"
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"
import { organizationsQuery, storagesListQuery } from "common/recoil/selectors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

export default function AdditionalInformationCard() {
  const organization = useRecoilValue(currentOrganizationState)
  const storages = useRecoilValue(storagesListQuery)
  const [defaultStorage, setDefaultStorage] = useState(
    organization.default_storage_id || ""
  )
  const [currency] = useState(organization.currency || "")
  const [contactPerson, setContactPerson] = useState(
    organization.contact_person || ""
  )
  const [phone, setPhone] = useState(organization.phone || "")
  const fetch = useFetch()
  const refreshOrganizations = useRecoilRefresher_UNSTABLE(organizationsQuery)
  const unchanged =
    defaultStorage === organization.default_storage_id &&
    contactPerson === organization.contact_person &&
    phone === organization.phone

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
        <Trans>Additional Information</Trans>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit} id="additional-information">
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>API token</Trans>
            </Form.Label>
            <Form.Control disabled readOnly value={organization.token} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Default storage</Trans>
            </Form.Label>
            <Form.Select
              value={defaultStorage}
              onChange={event => setDefaultStorage(event.target.value)}
            >
              <option hidden>{t`Select...`}</option>
              {storages.map($ => (
                <option key={$.id} value={$.id}>
                  {$.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Assortment currency</Trans>
            </Form.Label>
            <Form.Control disabled readOnly value={currency} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Contact person</Trans>
            </Form.Label>
            <Form.Control
              placeholder="John Smith"
              value={contactPerson}
              onChange={event => setContactPerson(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <Trans>Phone</Trans>
            </Form.Label>
            <Form.Control
              value={phone}
              onChange={event => setPhone(event?.target?.value)}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          type="submit"
          disabled={unchanged}
          form="additional-information"
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function handleSubmit(event) {
    event.preventDefault()
    fetch("/organizations/" + organization.id, {
      method: "PATCH",
      body: {
        default_storage_id: defaultStorage,
        contact_person: contactPerson,
        phone: phone
      }
    }).then(response => {
      if (response) {
        refreshOrganizations()
        toast.success(<Trans>Your changes have been saved</Trans>)
      } else {
        toast.error(<Trans>Your changes could not be saved</Trans>)
      }
    })
  }
}
