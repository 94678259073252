import { Button, Table } from "react-bootstrap"
import LumberStorageRow from "./LumberStorageRow"
import { Trans } from "@lingui/macro"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash, faPlus } from "@fortawesome/free-solid-svg-icons"

export default function LumberStoragesTable({ storages }) {
  const [hideEmptyStorages, setHideEmptyStorages] = useState(true)
  const navigate = useNavigate()

  return (
    <>
      <Button
        className="me-3 rounded-pill px-3 mb-3"
        variant="light"
        onClick={() => navigate("/storage/new")}
      >
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New storage</Trans>
      </Button>
      <Button
        variant="outline-light"
        className="mb-3 rounded-pill px-3 "
        onClick={() => setHideEmptyStorages(!hideEmptyStorages)}
      >
        {hideEmptyStorages ? (
          <>
            <FontAwesomeIcon icon={faEye} className="me-2" />
            <Trans>Show empty storages</Trans>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
            <Trans>Hide empty storages</Trans>
          </>
        )}
      </Button>
      <Table responsive>
        <thead>
          <tr>
            <th />
            <th>
              <Trans>Storage name</Trans>
            </th>
            <th>
              <Trans>Coordinates</Trans>
            </th>
            <th>
              <Trans>Plank count</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {storages?.length > 0 ? (
            storages
              .filter($ => {
                if (hideEmptyStorages) {
                  return $?.measurements?.length > 0
                }
                return true
              })
              .map($ => <LumberStorageRow key={$.id} storage={$} />)
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                <Trans>No lumber measurements</Trans>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
