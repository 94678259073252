import { faFileLines } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import Loading from "common/other/Loading"
import UpgradePlanCard from "common/other/UpgradePlanCard"
import { featureEnabled } from "common/recoil/atoms"
import { Suspense } from "react"
import { Row, Col } from "react-bootstrap"
import { NavLink, Outlet } from "react-router-dom"
import { useRecoilValue } from "recoil"

export default function ReportsIndex() {
  const enabled = useRecoilValue(featureEnabled("reports"))

  return enabled ? (
    <>
      <h4 className="text-light">
        <FontAwesomeIcon icon={faFileLines} className="me-2" />
        <Trans>Reports</Trans>
      </h4>
      <hr className="text-light" />
      <div className="d-flex flex-wrap">
        <NavLink
          to="/reports"
          end
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>All reports</Trans>
        </NavLink>
        <NavLink
          to="/reports/new"
          end
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Create new report</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <Row>
        <Col xs="12">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </Col>
      </Row>
    </>
  ) : (
    <UpgradePlanCard feature={t`reports`} />
  )
}
