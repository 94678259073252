import { useRef } from "react"
import { Line, Circle, Group } from "react-konva"
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { measurementAreaState } from "common/recoil/atoms"
import { editorSettingsState } from "."
import { EDIT, MEASUREMENT_AREA, VIEW } from "common/enums/constants"

export const lastActionAreaState = atom({
  key: "lastActionAreaState",
  default: {
    index: null,
    position: null
  }
})

export default function Polygon({ isEditMode }) {
  const setLastActionArea = useSetRecoilState(lastActionAreaState)
  const [measurementArea, setMeasurementArea] =
    useRecoilState(measurementAreaState)
  const lineRef = useRef(null)
  const edit = useRecoilValue(editorSettingsState("edit"))
  const mode = useRecoilValue(editorSettingsState("mode"))
  const showMeasurementArea = useRecoilValue(
    editorSettingsState("showMeasurementArea")
  )

  const VIEW_AREA = showMeasurementArea && mode === VIEW
  const EDIT_AREA = mode === EDIT && edit === MEASUREMENT_AREA

  const lines = []
  const points = measurementArea
  for (let i = 0; i < points.length; i++) {
    const nextIndex = (i + 1) % points.length
    const isModified =
      points[i]["modified-at"] || points[nextIndex]["modified-at"]
    lines.push(
      <Line
        key={i}
        points={[
          points[i].x,
          points[i].y,
          points[nextIndex].x,
          points[nextIndex].y
        ]}
        stroke={isModified ? "red" : "lime"}
        strokeWidth={4}
        dashEnabled={true}
        dash={[4, 2]}
        visible={VIEW_AREA || EDIT_AREA}
        closed={false}
      />
    )
  }
  // return (
  //   <Group>
  //     {lines}
  //     {/* this bottom <Line> is for coloring the fill with black tint */}
  //     <Line
  //       ref={lineRef}
  //       points={measurementAreaToPoints(measurementArea)}
  //       visible={VIEW_AREA || EDIT_AREA}
  //       fill={"rgba(0, 0, 0, 33%)"}
  //       closed={true}
  //     />
  //   </Group>
  // )

  return (
    <Group>
      {lines}
      <Line
        key={"closedLine"}
        ref={lineRef}
        points={measurementAreaToPoints(measurementArea)}
        visible={VIEW_AREA || EDIT_AREA}
        fill={"rgba(0, 255, 0, 25%)"}
        closed={true}
        onDblClick={() => {
          if (edit !== MEASUREMENT_AREA) return
          // const cursorPosition = lineRef.current.getStage().getPointerPosition()
          const cursorPosition = lineRef.current.getRelativePointerPosition()
          const x = cursorPosition.x
          const y = cursorPosition.y
          const points = lineRef.current.attrs.points
          const tolerance = 100
          for (let i = 0; i < points.length / 2; i++) {
            const s_x = points[i * 2]
            const s_y = points[i * 2 + 1]
            const e_x = points[(i * 2 + 2) % points.length]
            const e_y = points[(i * 2 + 3) % points.length]
            if (pointToLineDistance(x, y, s_x, s_y, e_x, e_y) < tolerance) {
              let newMeasurementArea = [...measurementArea]
              newMeasurementArea.splice(i + 1, 0, { x, y })
              setMeasurementArea(newMeasurementArea)
              break
            }
          }
        }}
      />
      {EDIT_AREA &&
        measurementArea.map((coords, index) => (
          <Circle
            onDblClick={() =>
              setMeasurementArea(
                measurementArea.filter(
                  $ => $.x !== coords.x && $.y !== coords.y
                )
              )
            }
            onDragEnd={() => {
              let updatedMeasurementArea = [...measurementArea]
              let modifiedAt =
                updatedMeasurementArea[index]?.["modified-at"] !== undefined
                  ? new Date().toISOString()
                  : new Date()
              updatedMeasurementArea[index] = {
                ...updatedMeasurementArea[index],
                "modified-at": modifiedAt
              }
              setMeasurementArea(updatedMeasurementArea)
            }}
            onDragMove={event => {
              const x = Math.round(event.target.x())
              const y = Math.round(event.target.y())
              let updatedMeasurementArea = [...measurementArea]
              updatedMeasurementArea[index] = {
                ...updatedMeasurementArea[index],
                x,
                y
              }
              setMeasurementArea(updatedMeasurementArea)
              lineRef.current.points(
                measurementAreaToPoints(updatedMeasurementArea)
              )
              lineRef.current.getLayer().batchDraw()
            }}
            onDragStart={() => {
              setLastActionArea({
                index: index,
                position: {
                  x: coords.x,
                  y: coords.y
                }
              })
            }}
            key={index}
            draggable
            fill="white"
            x={coords.x}
            y={coords.y}
            radius={16}
            stroke="transparent"
            strokeWidth={1}
          />
        ))}
    </Group>
  )

  // konva expects points as [x1, y1, x2, y2, ...]
  function measurementAreaToPoints(measurementArea) {
    return measurementArea.reduce(
      (points, coordinates) => [...points, coordinates.x, coordinates.y],
      []
    )
  }
}

function pointToLineDistance(x, y, x1, y1, x2, y2) {
  const A = x - x1
  const B = y - y1
  const C = x2 - x1
  const D = y2 - y1

  const dot = A * C + B * D
  const len_sq = C * C + D * D
  const param = dot / len_sq

  let xx, yy

  if (param < 0) {
    xx = x1
    yy = y1
  } else if (param > 1) {
    xx = x2
    yy = y2
  } else {
    xx = x1 + param * C
    yy = y1 + param * D
  }

  const dx = x - xx
  const dy = y - yy

  return Math.sqrt(dx * dx + dy * dy)
}
