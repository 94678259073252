import {
  faCheck,
  faCopy,
  faShareNodes
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"

export default function ShareMeasurementModal({ measurement, organization }) {
  const [show, setShow] = useState()
  const [publicUrl, setPublicUrl] = useState("")
  const [success, setSuccess] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleCopy = () => {
    navigator.clipboard.writeText(publicUrl)
    setSuccess(true)
    setTimeout(() => setSuccess(false), 2000)
  }

  useEffect(() => {
    if (organization?.vmu) {
      setPublicUrl(window.location.host + "/vmu_public/" + measurement.token)
    } else {
      setPublicUrl(
        window.location.host + "/public_measurement/" + measurement.token
      )
    }
  }, [measurement, organization])

  return (
    <>
      <Button onClick={() => setShow(true)} size="sm">
        <FontAwesomeIcon icon={faShareNodes} className="me-2" />
        <Trans>Share</Trans>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Share measurement</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className="small text-muted">
            <Trans>Public URL</Trans>
          </Form.Label>
          <Form.Control readOnly disabled defaultValue={publicUrl} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-full"
            variant={success ? "primary" : "secondary"}
            onClick={handleCopy}
            disabled={success}
          >
            <FontAwesomeIcon
              icon={success ? faCheck : faCopy}
              className="me-2"
            />
            {success ? <Trans>Copied to clipboard</Trans> : <Trans>Copy</Trans>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
