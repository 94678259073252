import { faGripLines, faLinesLeaning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Button, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { selectorFamily, useRecoilValue } from "recoil"
import { lumberMeasurementsState } from "./LumberMeasurementsTable"

const lumberMeasurementById = selectorFamily({
  key: "lumberMeasurementById",
  get:
    id =>
    ({ get }) =>
      get(lumberMeasurementsState).find($ => $.id === Number(id))
})

export default function LumberMeasurementPage() {
  const { id } = useParams()
  const measurement = useRecoilValue(lumberMeasurementById(id))

  return (
    <Container fluid="lg">
      <Row>
        <Col xs="12">
          <Button variant="light" className="mb-4 me-3 border border-2 pe-none">
            <FontAwesomeIcon
              icon={faLinesLeaning}
              size="lg"
              className={`me-2`}
            />
            <Trans>Lumber Measurement</Trans> {measurement.id}
          </Button>
          <Button className="mb-4 border border-2 pe-none fs-5">
            <FontAwesomeIcon icon={faGripLines} className={`me-2`} />
            {measurement.plank_count || 0}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
