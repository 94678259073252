import {
  faChevronDown,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Suspense, useState } from "react"
import { Button } from "react-bootstrap"
import LumberMeasurementsTable from "./LumberMeasurementsTable"
import Loading from "common/other/Loading"
import { selectorFamily } from "recoil"
import { querySelector } from "common/recoil/selectors"
import { Trans } from "@lingui/macro"
import useExportsState from "exports/useExportsState"

export const lumberMeasurementsByStorageId = selectorFamily({
  key: "lumberMeasurementsByStorageId",
  get:
    ({ storageId, page }) =>
    ({ get }) =>
      get(
        querySelector({
          url: "/lumber_measurements/search",
          options: {
            method: "POST",
            body: {
              storage_id: storageId,
              page: page
            }
          }
        })
      )
})

export default function LumberStorageRow({ storage }) {
  const [expanded, setExpanded] = useState(false)
  const { createLumberDashboardExcelReport } = useExportsState()

  return (
    <>
      <tr>
        <td style={{ width: "3rem", paddingRight: "0" }}>
          {storage?.measurements?.length > 0 ? (
            <Button
              style={{ width: "2.5rem" }}
              variant="light"
              className="border text-secondary shadow-sm"
              onClick={() => setExpanded(!expanded)}
            >
              <FontAwesomeIcon
                icon={expanded ? faChevronDown : faChevronRight}
              />
            </Button>
          ) : null}
        </td>
        <td className="text-truncate" style={{ maxWidth: "8rem" }}>
          <span>
            {storage.name}
            <br />
            <small
              className="underline text-primary"
              style={{ cursor: "pointer" }}
              onClick={() =>
                createLumberDashboardExcelReport(
                  storage?.measurements?.map($ => $.id)
                )
              }
            >
              <Trans>Export to excel</Trans>
            </small>
          </span>
        </td>
        <td>
          {storage.longitude}
          <br />
          {storage.latitude}
        </td>
        <td>{storage.planks_count}</td>
      </tr>
      {expanded && (
        <tr>
          <td style={{ padding: 0 }} colSpan={4}>
            <Suspense fallback={<Loading color="dark" />}>
              <LumberMeasurementsTable storageId={storage.id} />
            </Suspense>
          </td>
        </tr>
      )}
    </>
  )
}
