import { faUserPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Spinner from "common/other/Spinner"
import { usersState } from "common/recoil/atoms"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"

export default function NewUserCard() {
  const [email, setEmail] = useState("")
  const fetch = useFetch()
  const [sending, setSending] = useState(false)
  const users = useRecoilValue(usersState)
  const navigate = useNavigate()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faUserPlus} className="me-2" />
        <Trans>Add user</Trans>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              placeholder="Email address"
              type="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              required
            />
            <Form.Text>
              <Trans>An invitation will be sent to the email address</Trans>
            </Form.Text>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          onClick={() => navigate("/users")}
          className="float-start"
          style={{ borderColor: "transparent" }}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button disabled={!email || sending} onClick={handleSubmit}>
          {sending ? <Spinner /> : <Trans>Send</Trans>}
        </Button>
      </Card.Footer>
    </Card>
  )

  function handleSubmit(event) {
    event.preventDefault()

    if (users.find(user => user.email === email)) {
      return toast.warn(<Trans>User already exists</Trans>)
    }

    setSending(true)
    fetch("/users/invite_user", {
      method: "POST",
      body: {
        invite: {
          email
        }
      }
    })
      .then(response => {
        if (response) {
          toast.success(<Trans>Invitation sent</Trans>)
        }
      })
      .finally(() => {
        setEmail("")
        setSending(false)
      })
  }
}
