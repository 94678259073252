import { Row } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { customFieldsState, featureEnabled } from "common/recoil/atoms"
import FilterCustomField from "./FilterCustomField"

export default function FilterCustomFields() {
  const customFields = useRecoilValue(customFieldsState)
  const enabled = featureEnabled("custom_fields")

  return enabled &&
    customFields.filter($ => $.searchable && !$.archived).length ? (
    <Row className="mt-3">
      {customFields
        .filter($ => $.searchable && !$.archived)
        .map($ => (
          <FilterCustomField key={$.id} customField={$} />
        ))}
    </Row>
  ) : null
}
