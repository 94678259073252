import { Trans } from "@lingui/macro"
import formatVolumeUnit from "common/utils/formatVolumeUnit"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import useAccountState from "./useAccountState"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCog } from "@fortawesome/free-solid-svg-icons"
const timeZones = require("./timezonemapping.json")

export default function ProfileCard() {
  const [account, updateAccount] = useAccountState()
  const [name, setName] = useState(account.name)
  const [timezone, setTimezone] = useState(account.time_zone)
  const [unitSystem, setUnitSystem] = useState(account.volume_unit)
  const [roundingPrecision, setRoundingPrecision] = useState(
    account.rounding_precision
  )

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faUserCog} className="me-2" />
        <Trans>User settings</Trans>
      </Card.Header>
      <Card.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            <Trans>Email</Trans>
          </Form.Label>
          <Form.Control disabled defaultValue={account.email} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <Trans>Name</Trans>
          </Form.Label>
          <Form.Control
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <Trans>Time zone</Trans>
          </Form.Label>
          <Form.Select
            value={timezone}
            onChange={event => setTimezone(event.target.value)}
          >
            <option key="placeholder" hidden value>
              Choose time zone
            </option>
            {timeZones.map($ => {
              const [label, value] = Object.entries($)[0]

              return (
                <option key={label} value={value}>
                  {label}
                </option>
              )
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <Trans>Unit system</Trans>
          </Form.Label>
          <Form.Select
            value={unitSystem}
            onChange={event => setUnitSystem(event.target.value)}
          >
            {["m^3", "ft^3", "stere", "fbm", "pmt"].map($ => (
              <option key={$} value={$}>
                {formatVolumeUnit($)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <Trans>Rounding precision</Trans>
          </Form.Label>
          <Form.Select
            value={roundingPrecision}
            onChange={event => setRoundingPrecision(event.target.value)}
          >
            {[1, 2, 3, 4].map($ => (
              <option key={$} value={$}>
                {$}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          onClick={() =>
            updateAccount({
              name,
              time_zone: timezone,
              volume_unit: unitSystem,
              rounding_precision: roundingPrecision
            })
          }
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )
}
