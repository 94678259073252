import {
  faBoxesStacked,
  faChevronDown,
  faChevronRight,
  faFileExcel
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import Loading from "common/other/Loading"
import UpgradePlanCard from "common/other/UpgradePlanCard"
import { featureEnabled, usersState } from "common/recoil/atoms"
import { querySelector, storagesListQuery } from "common/recoil/selectors"
import useExportsState from "exports/useExportsState"
import { Suspense, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { atom, selector, useRecoilValue } from "recoil"

export const inventoriesQuery = selector({
  key: "inventoriesQuery",
  get: ({ get }) => get(querySelector("/inventories"))
})

export const inventoriesState = atom({
  key: "inventoriesState",
  default: inventoriesQuery
})

export default function Inventories() {
  const enabled = useRecoilValue(featureEnabled("inventories"))
  return (
    <>
      {enabled ? (
        <Suspense fallback={<Loading text={t`Loading inventories...`} />}>
          <h4 className="text-light">
            <FontAwesomeIcon icon={faBoxesStacked} className="me-2" />
            <Trans>Inventories</Trans>
          </h4>
          <hr className="text-light" />
          <InventoriesTable />
        </Suspense>
      ) : (
        <UpgradePlanCard />
      )}
    </>
  )
}

function InventoriesTable() {
  const inventories = useRecoilValue(inventoriesState)
  const storages = useRecoilValue(storagesListQuery)

  return (
    <Table responsive="xxl" size="sm">
      <thead>
        <tr>
          <th />
          <th className="px-3 py-2">
            <Trans>Storage / Creator</Trans>
          </th>
          <th className="px-3 py-2">
            <Trans>Inventoried on</Trans>
          </th>
          <th className="px-3 py-2">
            <Trans>Inventory total</Trans>
          </th>
          <th className="px-3 py-2">
            <Trans>Measured total</Trans>
          </th>
          <th className="px-3 py-2">
            <Trans>Incoming</Trans>
          </th>
          <th className="px-3 py-2">
            <Trans>Outgoing</Trans>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {inventories?.length > 0 ? (
          inventories?.map(($, i) => (
            <InventoryRow key={i} inventoryRow={$} storages={storages} />
          ))
        ) : (
          <tr className="bg-white">
            <Trans>Nothing to show</Trans>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

function InventoryRow({ inventoryRow, storages }) {
  const formatVolume = useVolumeFormatter()
  const users = useRecoilValue(usersState)
  const [expanded, setExpanded] = useState(false)
  const { createInventoryExcel } = useExportsState()

  return (
    <>
      <tr key={inventoryRow.id}>
        <td className="p-0" style={{ width: "minContent" }}>
          <Button
            variant=""
            className=""
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
          </Button>
        </td>
        <td>
          <span>
            {storages?.find($ => $.id === inventoryRow.storage_id)?.name}
          </span>
          <br />
          <span className="text-muted small">
            {users?.find(user => user.id === inventoryRow.created_by)?.email}
          </span>
        </td>
        <td>
          {new Intl.DateTimeFormat("en", {
            month: "long",
            year: "numeric"
          }).format(new Date(inventoryRow.created_at))}
        </td>
        <td>{formatVolume(inventoryRow.summary.inventoried_volume)}</td>
        <td>{formatVolume(inventoryRow.summary.real_total)}</td>
        <td>{formatVolume(inventoryRow.summary.incoming_volume)}</td>
        <td>{formatVolume(inventoryRow.summary.outgoing_volume)}</td>
        <td>
          <FontAwesomeIcon
            icon={faFileExcel}
            onClick={() => createInventoryExcel(inventoryRow.id)}
            className="btn p-0 border-0 text-success"
          />
        </td>
      </tr>
      {expanded && inventoryRow?.inventory?.length ? (
        <tr className="shadow" key="expand">
          <td colSpan={8} className="p-0">
            <Table style={{ borderSpacing: "0 8px" }} size="sm">
              <thead>
                <tr>
                  <th className="text-light bg-success">
                    <Trans>Timber</Trans>
                  </th>
                  <th className="text-light bg-success">
                    <Trans>Inventory total</Trans>
                  </th>
                  <th className="text-light bg-success">
                    <Trans>Measured total</Trans>
                  </th>
                  <th className="text-light bg-success">
                    <Trans>Difference</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {inventoryRow?.inventory?.length > 0
                  ? inventoryRow?.inventory?.map($ => (
                      <tr key={$.name}>
                        <td className="bg-light">{$.name}</td>
                        <td className="bg-light">
                          {formatVolume($.inventory)}
                        </td>
                        <td className="bg-light">
                          {formatVolume($.calculated)}
                        </td>
                        <td
                          className={`${
                            $.diff < 0 ? "text-danger" : "text-success"
                          } fw-medium bg-light`}
                        >
                          {$.diff < 0 ? "" : "+"}
                          {formatVolume($.diff)}
                        </td>
                      </tr>
                    ))
                  : null}
                <tr>
                  <td className="bg-light"></td>
                  <td className="bg-light"></td>
                  <td className="bg-light"></td>
                  <td className="bg-success text-light">
                    <Trans>Inventory total</Trans>
                    <b className="ms-2">
                      {formatVolume(inventoryRow.summary?.inventoried_volume)}
                    </b>
                    <br />
                    <Trans>Measured total</Trans>
                    <b className="ms-2">
                      {formatVolume(inventoryRow.summary?.measured_volume)}
                    </b>
                    <br />
                    <Trans>Incoming</Trans>
                    <b className="ms-2">
                      {formatVolume(inventoryRow.summary?.incoming_volume)}
                    </b>
                    <br />
                    <Trans>Outgoing</Trans>
                    <b className="ms-2">
                      {formatVolume(inventoryRow.summary?.outgoing_volume)}
                    </b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </td>
        </tr>
      ) : null}
      {expanded && inventoryRow.inventory.length === 0 ? (
        <tr>
          <td
            className="bg-secondary text-white fw-medium text-center"
            colSpan={8}
          >
            Empty inventory
          </td>
        </tr>
      ) : null}
    </>
  )
}
