import { Trans } from "@lingui/macro"
import { Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { woodCullsState } from "common/recoil/atoms"
import WoodCullRow from "./WoodCullRow"

export default function WoodCullsTable() {
  const woodCulls = useRecoilValue(woodCullsState)

  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <Trans>Wood cull</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...woodCulls]
          ?.sort((a, b) => a.reason.localeCompare(b.reason))
          .map($ => (
            <WoodCullRow key={$.id} woodCullId={$.id} />
          ))}
      </tbody>
    </Table>
  )
}
