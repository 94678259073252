import { Suspense, useEffect, useState } from "react"
import {
  faArrowRight,
  faCamera,
  faCheck,
  faCheckCircle,
  faCloudArrowUp,
  faMagnifyingGlass,
  faMapMarkerAlt,
  faMessage,
  faPencilRuler,
  faRefresh,
  faTimesCircle,
  faUpLong,
  faXmark
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import measurementTypes from "common/enums/measurementTypes"
import { Link, useSearchParams } from "react-router-dom"
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  measurementsStateFamily,
  selectedMeasurementsState,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import {
  isMeasurementSelectedState,
  lengthUnitSelector
} from "common/recoil/selectors"
import { measurementEditorState } from "./editor/useEditorState"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { Trans } from "@lingui/macro"
import Spinner from "common/other/Spinner"
import MeasurementPhotoSquare from "common/other/MeasurementPhotoSquare"

export default function TimberMeasurementRow(props) {
  const [searchParams] = useSearchParams()
  const assortments = useRecoilValue(woodAssortmentsState)
  const woodQualities = useRecoilValue(woodQualitiesState)
  const woodTypes = useRecoilValue(woodTypesState)
  const [measurement, setMeasurement] = useRecoilState(
    measurementsStateFamily(Number(props.measurement.id))
  )
  useEffect(() => {
    if (!measurement) {
      setMeasurement(props.measurement)
    }
    // eslint-disable-next-line
  }, [])

  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedMeasurementsState
  )
  const isSelected = useRecoilValue(isMeasurementSelectedState(measurement?.id))
  const formatVolume = useVolumeFormatter()
  const lengthUnit = useRecoilValue(lengthUnitSelector)
  const [commentVisible, setCommentVisible] = useState(false)
  const measurementEditor = useSetRecoilState(measurementEditorState)

  getMeasurementType()
  return (
    measurement && (
      <>
        <tr
          className={`${
            searchParams.get("id") === measurement.id
              ? "bg-body-secondary"
              : "bg-white"
          }`}
        >
          <td>
            <input
              disabled={measurement.inventory_id && "d-none"}
              type="checkbox"
              onChange={() => {
                if (isSelected) {
                  setSelectedMeasurements(
                    selectedMeasurements.filter($ => $.id !== measurement.id)
                  )
                } else {
                  setSelectedMeasurements([
                    ...selectedMeasurements,
                    measurement
                  ])
                }
              }}
              checked={isSelected}
            />
          </td>
          <td>
            <div className="d-flex align-items-center">
              {props.manual ? (
                <>
                  <div
                    className="me-2 border rounded d-flex align-items-center justify-content-center"
                    style={{
                      width: "62px",
                      height: "62px"
                    }}
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={faPencilRuler}
                      className="text-primary"
                    />
                  </div>
                  <Link
                    className="text-primary link"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: "/measurement/" + measurement.id,
                      state: { measurement }
                    }}
                  >
                    <span>Manual measurement</span>
                    <br />
                    <span className="text-black-50">
                      {measurement?.taken_at?.substring(0, 10)}
                    </span>
                  </Link>
                </>
              ) : (
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex">
                    <div className="me-2 flex-shrink-0">
                      <MeasurementPhotoSquare
                        measurement={measurement}
                        onClick={() => {
                          window.history.pushState("forward", null, "#editor")
                          measurementEditor({
                            id: measurement.id,
                            type: "timber",
                            storage: props?.storage
                          })
                        }}
                        thumbnail
                        style={{
                          width: 80,
                          height: 80
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column align-self-center">
                      <MeasurementLink measurement={measurement} />
                      <MeasurementId measurement={measurement} />
                      <MeasurementDate measurement={measurement} />
                      {measurement?.comment && (
                        <FontAwesomeIcon
                          icon={faMessage}
                          onClick={() => setCommentVisible(!commentVisible)}
                          className={`opacity-${
                            commentVisible ? "75" : "25"
                          } align-self-start`}
                          size="sm"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </td>
          <td className="d-none d-xxl-table-cell">
            {measurementTypes[measurement.measurement_type_id] || "ENUM_ERROR"}
          </td>
          <td className="text-center d-none d-xxl-table-cell">
            {measurement.latitude > 0 && measurement.longitude > 0 ? (
              <FontAwesomeIcon
                title={`${measurement.latitude}\n${measurement.longitude}`}
                size="xl"
                icon={faMapMarkerAlt}
                className="text-primary hover-dark cursor-pointer"
                // onClick={props.showOnMap}
              />
            ) : (
              <FontAwesomeIcon
                size="xl"
                icon={faMapMarkerAlt}
                className="text-secondary"
              ></FontAwesomeIcon>
            )}
          </td>
          <td
            className="text-truncate d-none d-xl-table-cell"
            style={{ maxWidth: "10rem" }}
          >
            {getMeasurementType()}
          </td>
          <td className="d-none d-xl-table-cell">
            {measurement.log_length} {lengthUnit}
          </td>
          <td className="d-none d-xl-table-cell">{measurement.logs.length}</td>
          <td style={{ textAlign: "center" }} className="position-relative">
            {formatVolume(measurement.volume)}
            <br />
            <small className="text-muted">{measurement.coefficient}</small>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {measurement?.metrology !== null ? (
                    <span>
                      <Suspense fallback={<Spinner />}>
                        <MetrologyInfo metrology={measurement.metrology} />
                      </Suspense>
                    </span>
                  ) : (
                    <span>
                      <Trans>Not a metrological measurement</Trans>
                    </span>
                  )}
                </Tooltip>
              }
            >
              {measurement?.metrology === null ? (
                <div></div>
              ) : measurement?.metrology?.is_valid === true ? (
                <div className="triangle-green"></div>
              ) : (
                <div className="triangle-red"></div>
              )}
            </OverlayTrigger>
          </td>
        </tr>
        {commentVisible && (
          <tr className="bg-secondary-subtle">
            <td colSpan={8}>{measurement.comment}</td>
          </tr>
        )}
      </>
    )
  )

  function getMeasurementType() {
    if (
      measurement?.assortment_id &&
      measurement?.wood_quality_id &&
      measurement?.wood_type_id
    ) {
      return (
        <>
          <span>
            {assortments?.find($ => $.id === measurement?.assortment_id)?.name}
          </span>
          <br />
          <span className="small text-muted">
            {woodTypes?.find($ => $.id === measurement?.wood_type_id)?.name}
          </span>
          <span className="small text-muted me-1">,</span>
          <span className="small text-muted">
            {
              woodQualities?.find($ => $.id === measurement?.wood_quality_id)
                ?.name
            }
          </span>
        </>
      )
    }

    if (measurement?.assortment_id) {
      return assortments?.find($ => $.id === measurement?.assortment_id)?.name
    } else if (measurement?.wood_quality_id) {
      return (
        <>
          <span>
            {woodTypes?.find($ => $.id === measurement?.wood_type_id)?.name}
          </span>
          <br />
          <span className="small text-muted">
            {
              woodQualities?.find($ => $.id === measurement?.wood_quality_id)
                ?.name
            }
          </span>
        </>
      )
      // type and quality
    }

    return woodTypes?.find($ => $.id === measurement?.wood_type_id)?.name
  }
}

export function MetrologyInfo({ metrology }) {
  const angleIsOk = metrology.angle_validation.status === "1"
  const interferenceIsOk = metrology.interference.status === "1"
  const brightnessIsOk = metrology.brightness.status === "1"

  return (
    <div className="text-start">
      <div className="text-center">
        <Trans>Metrological measurement</Trans>
      </div>
      <small className="opacity-75">Angle:</small>{" "}
      <span>
        {angleIsOk ? (
          <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
        )}
        <small className="ms-1 opacity-50">
          {metrology?.angle_validation?.angle || "-"}
        </small>
      </span>
      <br />
      <small className="opacity-75">Interference:</small>{" "}
      {interferenceIsOk ? (
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
      )}
      <small className="ms-1 opacity-50">
        {metrology?.interference?.score || "-"}
      </small>
      <br />
      <small className="opacity-75">Brightness:</small>{" "}
      {brightnessIsOk ? (
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
      )}
      <small className="ms-1 opacity-50">
        {metrology?.brightness?.score || "-"}
      </small>
      <br />
      <small className="opacity-75">Valid</small>:{" "}
      <FontAwesomeIcon
        className={metrology.is_valid ? "text-success" : "text-danger"}
        icon={metrology.is_valid ? faCheck : faXmark}
      />
    </div>
  )
}

function MeasurementLink({ measurement }) {
  return (
    <small
      title={measurement.photo_file_name}
      className="text-primary text-decoration-underline-hover"
    >
      <Link
        className={
          measurement.inventory_id
            ? "text-warning link fw-medium"
            : "text-primary link fw-medium "
        }
        style={{ textDecoration: "none" }}
        to={{
          pathname: "/measurement/" + measurement.id,
          state: { measurement }
        }}
      >
        {measurement.photo_file_name
          ? measurement.photo_file_name
          : "NO_PHOTO_ERROR"}
      </Link>
    </small>
  )
}

function MeasurementDate({ measurement }) {
  let takenAt = measurement?.taken_at
  let updatedAt = measurement?.updated_at
  let createdAt = measurement?.created_at
  let measured_at = measurement?.measured_at

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className="text-start">
            <div>
              <FontAwesomeIcon icon={faRefresh} className="me-2" />
              <span className="small">
                <Trans>Updated at</Trans> {updatedAt}
              </span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faUpLong}
                className="opacity-25"
                size="lg"
              />
            </div>
            <div>
              <FontAwesomeIcon icon={faCloudArrowUp} className="me-2" />
              <span className="small">
                <Trans>Synced at</Trans> {createdAt}
              </span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faUpLong}
                className="opacity-25"
                size="lg"
              />
            </div>
            <div>
              <FontAwesomeIcon icon={faMagnifyingGlass} className="me-2" />
              <span className="small">
                <Trans>Analyzed at</Trans> {measured_at}
              </span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faUpLong}
                className="me-2 opacity-25"
                size="lg"
              />
            </div>
            <div>
              <FontAwesomeIcon icon={faCamera} className="me-2" />
              <span className="small">
                <Trans>Photo taken at</Trans> {takenAt}
              </span>
            </div>
          </div>
        </Tooltip>
      }
    >
      <span className="text-black-50 small">
        {measurement?.taken_at?.substring(0, 10)}
      </span>
    </OverlayTrigger>
  )
}

function MeasurementId({ measurement }) {
  return <span className="fw-medium text-muted small">{measurement.id}</span>
}

export function InputGroupSeparator() {
  return (
    <InputGroup.Text>
      <FontAwesomeIcon
        icon={faArrowRight}
        size="xs"
        className="text-muted opacity-50"
      />
    </InputGroup.Text>
  )
}
