import { useState } from "react"
import { Form, Button, Card, InputGroup, Image } from "react-bootstrap"
import Select from "common/other/Select"
import useFetch from "common/hooks/useFetch"
import measurementTypes from "common/enums/measurementTypes"
import { Trans } from "@lingui/macro"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { toast } from "react-toastify"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"
import { truckDiameterMeasurement } from "cmpc-dashboard/MeasurementsList"
import {
  volumeFormulasState,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import { lengthUnitSelector } from "common/recoil/selectors"

export default function Timber({ measurement }) {
  const lengthUnit = useRecoilValue(lengthUnitSelector)
  const woodTypes = useRecoilValue(woodTypesState)
  const woodQualities = useRecoilValue(woodQualitiesState)
  const assortments = useRecoilValue(woodAssortmentsState)
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const [measurementType, setMeasurementType] = useState(
    measurement.measurement_type_id || ""
  )
  const [weight, setWeight] = useState(measurement.weight || "")
  const [volumeFormula, setVolumeFormula] = useState(
    measurement.volume_formula || ""
  )
  const [logLength, setLogLength] = useState(measurement.log_length || "")
  const [woodType, setWoodType] = useState(measurement.wood_type_id || "")
  const [woodAssortment, setWoodAssortment] = useState(
    measurement.wood_characteristic_id || ""
  )
  const [woodQuality, setWoodQuality] = useState(
    measurement.wood_quality_id || ""
  )
  const [weightDensity, setWeightDensity] = useState(
    measurement.weight_density || ""
  )
  const setMeasurement = useSetRecoilState(
    truckDiameterMeasurement(Number(measurement.id))
  )
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const formatVolume = useVolumeFormatter()
  const fetch = useFetch()

  function updateMeasurement(event) {
    event.preventDefault()

    fetch(`/truck_diameter_measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        measurement_type_id: Number(measurementType) ?? undefined,
        weight: weight || undefined,
        weight_density: Number(weightDensity) || undefined,
        volume_formula: volumeFormula || undefined,
        log_length: logLength || undefined,
        wood_type_id: woodType || undefined,
        wood_characteristic_id: woodAssortment || undefined,
        wood_quality_id: woodQuality || undefined
      }
    })
      .then(response => {
        if (response) {
          toast.success(<Trans>Your changes have been saved</Trans>)
          setMeasurement(response)
        }
      })
      .catch(() => toast.error(<Trans>Failed to save changes</Trans>))
  }

  return (
    <Card className="mb-4">
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Measurement details</Trans>
      </Card.Header>
      <Form.Group>
        <Image
          fluid
          src={measurement.image}
          onError={e => (e.target.src = "/android-chrome-192x192.png")}
          onClick={() => {
            window.history.pushState("forward", null, "#editor")
            measurementEditor({ id: measurement.id, type: "truck_diameter" })
          }}
          className="me-2 hover-dark border-bottom"
          style={{
            cursor: "pointer"
          }}
        />
        <div className="pt-3 text-center">
          <Trans>Volume</Trans>: {formatVolume(measurement.volume)}
        </div>
      </Form.Group>
      <Card.Body>
        <Form id="edit-truck-measurement" onSubmit={updateMeasurement}>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Measured at</Trans>
            </Form.Label>
            <Form.Control
              defaultValue={new Date(measurement.measured_at).toLocaleString()}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Log count</Trans>
            </Form.Label>
            <Form.Control
              readOnly
              disabled
              defaultValue={measurement?.logs?.length || 0}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Reference size</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                readOnly
                disabled
                defaultValue={
                  measurement?.references?.length > 0
                    ? measurement.references[0].size
                    : null
                }
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Measurement type</Trans>
            </Form.Label>
            <Select
              onChange={event => setMeasurementType(event.value)}
              value={{
                value: measurementType,
                label: measurementTypes[measurementType]
              }}
              options={Object.entries(measurementTypes).map(([id, type]) => ({
                value: id,
                label: type
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Log length</Trans>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                className="form-control-append"
                onChange={event => setLogLength(event.target.value)}
                value={`${logLength}`}
              />
              <InputGroup.Text>{lengthUnit}</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Wood type</Trans>
            </Form.Label>
            <Select
              onChange={event => setWoodType(event.value)}
              value={
                woodTypes && {
                  value: woodType,
                  label: woodTypes?.filter($ => $.id === woodType)[0].name
                }
              }
              options={woodTypes?.map($ => ({
                value: $.id,
                label: $.name
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Wood quality</Trans>
            </Form.Label>
            <Select
              onChange={event => setWoodQuality(event.value)}
              value={
                woodQuality && {
                  value: woodQuality,
                  label: woodQualities?.filter($ => $.id === woodQuality)[0]
                    .name
                }
              }
              options={woodQualities?.map($ => ({
                value: $.id,
                label: $.name
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Volume formula</Trans>
            </Form.Label>
            <Select
              onChange={event => setVolumeFormula(event.value)}
              value={{
                value: volumeFormula,
                label: volumeFormulas
                  ? volumeFormulas[volumeFormula]
                  : "Loading..."
              }}
              options={Object.entries(volumeFormulas || {}).map(
                ([value, label]) => ({
                  value,
                  label
                })
              )}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Wood assortment</Trans>
            </Form.Label>
            <Select
              onChange={event => setWoodAssortment(event.value)}
              value={
                woodAssortment && {
                  value: woodAssortment,
                  label: assortments?.filter($ => $.id === woodAssortment)[0]
                    ?.name
                }
              }
              options={assortments?.map($ => ({
                value: $.id,
                label: $.name
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight</Trans>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                onChange={event => setWeight(event.target.value)}
                className="form-control-append"
                value={weight}
              />
              <InputGroup.Text>kg</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight density</Trans>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                onChange={event => setWeightDensity(event.target.value)}
                className="form-control-append"
                value={weightDensity}
              />
              <InputGroup.Text>m³/t</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          {/* <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight per log</Trans>
            </Form.Label>
            <Form.Control readOnly disabled defaultValue={0} />
          </Form.Group> */}
        </Form>
        <Button
          className="w-full mt-3"
          type="submit"
          form="edit-truck-measurement"
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Body>
    </Card>
  )
}
