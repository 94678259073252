import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { Col, Form, InputGroup, Row, Collapse } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  woodAssortmentsState,
  woodTypesState,
  showAdditionalContainerFiltersState,
  containerFilterState
} from "common/recoil/atoms"
import Select from "common/other/Select"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

export default function ContainerAdditionalFilters() {
  const show = useRecoilValue(showAdditionalContainerFiltersState)
  const [woodType, setWoodType] = useRecoilState(
    containerFilterState("species")
  )
  const [minLength, setMinLength] = useRecoilState(
    containerFilterState("minLength")
  )
  const [maxLength, setMaxLength] = useRecoilState(
    containerFilterState("maxLength")
  )
  // const [minVolume, setMinVolume] = useRecoilState(
  //   containerFilterState("minVolume")
  // )
  // const [maxVolume, setMaxVolume] = useRecoilState(
  //   containerFilterState("maxVolume")
  // )
  const [assortment, setAssortment] = useRecoilState(
    containerFilterState("assortment")
  )
  const woodAssortmentList = useRecoilValue(woodAssortmentsState)
  const woodTypeList = useRecoilValue(woodTypesState)
  const [includeArchived, setIncludeArchived] = useRecoilState(
    containerFilterState("includeArchived")
  )

  return (
    <Collapse in={show}>
      <Row>
        {/* <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
          <Form.Label>
            <Trans>Volume range</Trans>
          </Form.Label>
          <InputGroup>
            <Form.Control
              className="no-focus"
              onChange={event => setMinVolume(event?.target?.value)}
              value={minVolume}
              placeholder={t`Minimum`}
            />

            <InputGroup.Text>
              <FontAwesomeIcon
                icon={faArrowRight}
                size="xs"
                className="text-muted opacity-50"
              />
            </InputGroup.Text>
            <Form.Control
              className="no-focus"
              onChange={event => setMaxVolume(event?.target?.value)}
              value={maxVolume}
              placeholder={t`Maximum`}
            />
          </InputGroup>
        </Form.Group> */}
        <Form.Group as={Col} xs="12" md="6" lg="3" className="mb-2">
          <Form.Label>
            <Trans>Length range</Trans>
          </Form.Label>
          <InputGroup>
            <Form.Control
              className="no-focus"
              onChange={event => setMinLength(event?.target?.value)}
              value={minLength}
              placeholder={t`Minimum`}
            />
            <InputGroup.Text>
              <FontAwesomeIcon
                icon={faArrowRight}
                size="xs"
                className="text-muted opacity-50"
              />
            </InputGroup.Text>
            <Form.Control
              className="no-focus"
              onChange={event => setMaxLength(event?.target?.value)}
              value={maxLength}
              placeholder={t`Maximum`}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Wood type</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            value={woodType}
            onChange={event => setWoodType(event)}
            options={woodTypeList?.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Wood assortment</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            onChange={event => setAssortment(event)}
            value={assortment}
            options={woodAssortmentList?.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Check
            checked={includeArchived}
            className="text-body"
            onChange={() => setIncludeArchived(!includeArchived)}
            label={<Trans>Include archived</Trans>}
          />
        </Form.Group>
      </Row>
    </Collapse>
  )
}
