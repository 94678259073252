import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { billOfLadingState, billsOfLadingState } from "common/recoil/atoms"
import { containersQuery } from "common/recoil/selectors"
import { useState } from "react"
import { Button, Form, Modal, Dropdown } from "react-bootstrap"
import { toast } from "react-toastify"
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useSetRecoilState
} from "recoil"

export default function AddContainerModal({ billId }) {
  const [show, setShow] = useState(false)
  const [names, setNames] = useState([])
  const [loading, setLoading] = useState(false)
  const billsOfLading = useRecoilValue(billsOfLadingState)
  const refreshContainers = useRecoilRefresher_UNSTABLE(containersQuery)
  const setBillOfLading = useSetRecoilState(billOfLadingState(billId))

  const fetch = useFetch()

  function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)
    fetch("/containers/containers", {
      method: "POST",
      body: {
        container: {
          bill_of_lading_id: billId,
          name: names.split("\n").filter($ => $)
        }
      }
    })
      .then(addedContainers => {
        setBillOfLading($ => ({
          ...$,
          containers: [...$.containers, ...addedContainers.map($ => $.id)]
        }))
        refreshContainers()
        toast.success(<Trans>Added!</Trans>)
      })
      .finally(() => {
        setLoading(false)
        setShow(false)
      })
  }

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)}>
        <Trans>Add containers</Trans>
        <span className="float-end">
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </Dropdown.Item>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>Add containers</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label>
                <Trans>Bill of lading</Trans>
              </Form.Label>
              <Form.Control
                readOnly
                disabled
                defaultValue={billsOfLading.find($ => $.id === billId).name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <Trans>Names</Trans>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="6"
                placeholder={`example_container_name_1
example_container_name_2
...`}
                value={names}
                onChange={event => setNames(event.target.value)}
              />
            </Form.Group>
            <Button
              type="submit"
              disabled={loading}
              className="rounded-pill w-full"
            >
              {loading ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
