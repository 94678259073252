import { Trans } from "@lingui/macro"
import { useState } from "react"
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  ToggleButton,
  ToggleButtonGroup
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons"
import useFetch from "common/hooks/useFetch"
import {
  featureEnabled,
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import currencyToSymbol from "common/utils/currencyToSymbol"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import formatVolumeUnit from "common/utils/formatVolumeUnit"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

export default function NewWoodAssortmentCard() {
  const setWoodAssortments = useSetRecoilState(woodAssortmentsState)
  const organization = useRecoilValue(currentOrganizationState)
  const [woodAssortmentName, setWoodAssortmentName] = useState("")
  const [woodAssortmentSpecies, setWoodAssortmentSpecies] = useState("")
  const [woodAssortmentQuality, setWoodAssortmentQuality] = useState("")
  const [woodAssortmentLogLength, setWoodAssortmentLogLength] = useState("")
  const [woodAssortmentWeightDensity, setWoodAssortmentWeightDensity] =
    useState("")
  const [woodAssortmentMinimumDiameter, setWoodAssortmentMinimumDiameter] =
    useState("")
  const [woodAssortmentMaximumDiameter, setWoodAssortmentMaximumDiameter] =
    useState("")
  const [woodAssortmentPricePer, setWoodAssortmentPricePer] = useState("")
  const [woodAssortmentPrice, setWoodAssortmentPrice] = useState("")
  const [woodAssortmentPriceUnit, setWoodAssortmentPriceUnit] = useState("log")
  const [volumeUnit, setVolumeUnit] = useState("m^3")
  const fetch = useFetch()
  const minMaxEnabled = useRecoilValue(featureEnabled("minmax_assortments"))
  const enabled = useRecoilValue(featureEnabled("timber_management"))
  const species = useRecoilValue(woodTypesState)
  const qualities = useRecoilValue(woodQualitiesState)
  const navigate = useNavigate()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New wood assortment</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="new-wood-assortment" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              disabled={!enabled}
              value={woodAssortmentName}
              onChange={event => setWoodAssortmentName(event?.target?.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Species</Trans>
            </Form.Label>
            <Form.Select
              disabled={!enabled}
              required={true}
              value={woodAssortmentSpecies}
              onChange={event => setWoodAssortmentSpecies(event?.target?.value)}
            >
              <option key="placeholder" hidden value />
              {species?.map($ => (
                <option key={$.id} value={$.id}>
                  {$.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Quality</Trans>
            </Form.Label>
            <Form.Select
              disabled={!enabled}
              value={woodAssortmentQuality}
              onChange={event => setWoodAssortmentQuality(event?.target?.value)}
            >
              <option key="placeholder" hidden value />
              {qualities?.map($ => (
                <option key={$.id} value={$.id}>
                  {$.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>
                <Trans>Log length</Trans>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={!enabled}
                  type="number"
                  value={woodAssortmentLogLength}
                  onChange={event =>
                    setWoodAssortmentLogLength(event?.target?.value)
                  }
                />
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>
                <Trans>Weight density</Trans>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  disabled={!enabled}
                  value={woodAssortmentWeightDensity}
                  onChange={event => {
                    if (
                      event?.target?.value >= 0 &&
                      event?.target?.value <= 99.999999
                    ) {
                      setWoodAssortmentWeightDensity(event?.target?.value)
                    }
                  }}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Row>

          {minMaxEnabled && (
            <Row>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>
                  <Trans>Min diameter</Trans>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    disabled={!enabled}
                    type="number"
                    value={woodAssortmentMinimumDiameter}
                    onChange={event =>
                      setWoodAssortmentMinimumDiameter(event?.target?.value)
                    }
                  />
                  <InputGroup.Text>cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" as={Col}>
                <Form.Label>
                  <Trans>Max diameter</Trans>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    disabled={!enabled}
                    type="number"
                    value={woodAssortmentMaximumDiameter}
                    onChange={event =>
                      setWoodAssortmentMaximumDiameter(event?.target?.value)
                    }
                  />
                  <InputGroup.Text>cm</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Row>
          )}

          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>
                <Trans>Pricing</Trans>
              </Form.Label>
              <br />
              <ToggleButtonGroup
                type="radio"
                name="priceUnit"
                className="w-100"
              >
                <ToggleButton
                  disabled={!enabled}
                  variant={
                    woodAssortmentPriceUnit === "volume"
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => setWoodAssortmentPriceUnit("volume")}
                >
                  <Trans>Volume</Trans>
                </ToggleButton>
                <ToggleButton
                  disabled={!enabled}
                  variant={
                    woodAssortmentPriceUnit === "log" ? "primary" : "secondary"
                  }
                  onClick={() => setWoodAssortmentPriceUnit("log")}
                >
                  <Trans>Single</Trans>
                </ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>
                <Trans>Price (in cents)</Trans>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  disabled={!enabled}
                  className="form-control-append"
                  value={
                    woodAssortmentPriceUnit === "log"
                      ? woodAssortmentPricePer
                      : woodAssortmentPriceUnit === "volume"
                      ? woodAssortmentPrice
                      : ""
                  }
                  onChange={event => {
                    if (woodAssortmentPriceUnit === "volume")
                      setWoodAssortmentPrice(event?.target?.value)
                    else if (woodAssortmentPriceUnit === "log")
                      setWoodAssortmentPricePer(event?.target?.value)
                  }}
                />
                <InputGroup.Text>
                  {currencyToSymbol(organization?.currency)}
                </InputGroup.Text>
                {woodAssortmentPriceUnit === "volume" && (
                  <DropdownButton
                    disabled={!enabled}
                    variant="secondary"
                    size="sm"
                    title={
                      <>
                        {formatVolumeUnit(volumeUnit)}{" "}
                        <FontAwesomeIcon size="xs" icon={faChevronDown} />
                      </>
                    }
                  >
                    {["m^3", "ft^3", "fbm", "pmt"].map($ => (
                      <Dropdown.Item key={$} onClick={() => setVolumeUnit($)}>
                        {formatVolumeUnit($)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                )}
              </InputGroup>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          className="float-start"
          onClick={() => navigate("/timber/assortments")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          disabled={woodAssortmentName.trim() === ""}
          type="submit"
          form="new-wood-assortment"
        >
          <Trans>Add assortment</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function handleSubmit(event) {
    event.preventDefault()

    if (!woodAssortmentSpecies) {
      return toast.warn(<Trans>Species is required</Trans>)
    }

    fetch("/assortments", {
      method: "POST",
      body: {
        wood_characteristic: {
          ...(woodAssortmentName && { name: woodAssortmentName }),
          ...(woodAssortmentSpecies && {
            wood_type_id: Number(woodAssortmentSpecies)
          }),
          ...(woodAssortmentQuality && {
            wood_quality_id: Number(woodAssortmentQuality)
          }),
          ...(woodAssortmentMinimumDiameter && {
            min: woodAssortmentMinimumDiameter
          }),
          ...(woodAssortmentMaximumDiameter && {
            max: woodAssortmentMaximumDiameter
          }),
          ...(woodAssortmentLogLength && {
            log_length: Number(woodAssortmentLogLength)
          }),
          ...(woodAssortmentPrice && {
            price_cents: woodAssortmentPrice
          }),
          ...(woodAssortmentPricePer && {
            price_per_log_cents: woodAssortmentPricePer
          }),
          ...(woodAssortmentWeightDensity && {
            weight_density: woodAssortmentWeightDensity
          }),
          ...(volumeUnit && {
            volume_unit: volumeUnit
          })
        }
      }
    }).then(response => {
      if (response) {
        setWoodAssortments(assortments => [...assortments, response])
        navigate("/timber/assortments")
        toast.success(<Trans>Added</Trans>)
      }
    })
  }
}
