import { t, Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { featureEnabled, woodTypesState } from "common/recoil/atoms"
import useFetch from "common/hooks/useFetch"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import UpgradePlanCard from "common/other/UpgradePlanCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

export default function NewWoodSpeciesCard() {
  const setWoodSpecies = useSetRecoilState(woodTypesState)
  const [speciesName, setSpeciesName] = useState("")
  const fetch = useFetch()
  const enabled = useRecoilValue(featureEnabled("timber_management"))
  const navigate = useNavigate()

  function handleSubmit(event) {
    event.preventDefault()
    fetch("/wood_types", {
      method: "POST",
      body: {
        name: speciesName
      }
    }).then(response => {
      if (response) {
        setWoodSpecies($ => [...$, response])
        navigate("/timber/species")
        toast.success(<Trans>Added</Trans>)
      }
    })
  }

  return enabled ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        <Trans>New wood species</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="new-wood-species" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              disabled={!enabled}
              value={speciesName}
              onChange={event => setSpeciesName(event?.target?.value)}
              required
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        <Button
          onClick={() => navigate("/timber/species")}
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          className="float-start"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          className="float-end"
          form="new-wood-species"
          disabled={!speciesName}
        >
          <Trans>Add species</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <UpgradePlanCard feature={t`timber management`} />
  )
}
