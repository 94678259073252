import LocationSelector from "dashboard/LocationSelector"
import useFetch from "common/hooks/useFetch"
import { useState } from "react"
import { Card, Form, Button, Row, Col } from "react-bootstrap"
import { storageStatuses } from "common/enums/storageStatuses"
import { toast } from "react-toastify"
import { storagesState } from "common/recoil/atoms"
import { useRecoilState } from "recoil"
import { Trans } from "@lingui/macro"
import Select from "common/other/Select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

export default function AddStorage() {
  const fetch = useFetch()
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [name, setName] = useState("")
  const [status, setStatus] = useState(1)
  const [storages, setStorages] = useRecoilState(storagesState)
  const [saving, setSaving] = useState(false)

  function setPosition({ lat, lng }) {
    setTimeout(() => setLatitude(lat.toFixed(6)))
    setTimeout(() => setLongitude(lng.toFixed(6)))
  }

  async function addStorage(event) {
    event.preventDefault()
    setSaving(true)

    let storage = {
      name,
      latitude: Number(latitude),
      longitude: Number(longitude),
      storage_state_id: Number(status)
    }

    const response = await fetch("/storages", {
      method: "POST",
      body: {
        storage
      }
    })
    setSaving(false)

    storage = {
      ...storage,
      ...response
    }

    setStorages([...storages, storage])

    toast.success(<Trans>Storage created</Trans>)

    window.location.replace(`/storage/${storage.id}`)
  }

  return (
    <Col xs="12" lg="8" xxl="6" xl="6">
      <Card>
        <Card.Body>
          <Card.Title className="mb-3">
            <FontAwesomeIcon icon={faPlus} className="me-2 opacity-50" />
            <Trans>New storage</Trans>
          </Card.Title>
          <LocationSelector
            position={[latitude, longitude]}
            setPosition={setPosition}
          />
          <Form onSubmit={addStorage}>
            <Row xs="1" sm="2" className="my-2">
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Trans>Storage name</Trans>
                  </Form.Label>
                  <Form.Control
                    onChange={event => setName(event.target.value)}
                    value={name}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Trans>Status</Trans>
                  </Form.Label>
                  <Select
                    onChange={event => setStatus(event.value)}
                    value={{
                      value: status,
                      label: storageStatuses
                        ? storageStatuses[status]
                        : "Loading..."
                    }}
                    options={Object.entries(storageStatuses || {}).map(
                      ([value, label]) => ({
                        value,
                        label
                      })
                    )}
                  />
                  {/* <Form.Select
                    value={status}
                    onChange={event => setStatus(event.target.value)}
                  >
                    {Object.entries(storageStatuses).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Select> */}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Trans>Latitude</Trans>
                  </Form.Label>
                  <input
                    className="form-control"
                    type="number"
                    step={0.000001}
                    max={90}
                    min={-90}
                    onChange={event => setLatitude(event.target.value)}
                    value={latitude || "00.000000"}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Trans>Longitude</Trans>
                  </Form.Label>
                  <input
                    className="form-control"
                    type="number"
                    step={0.000001}
                    max={180}
                    min={-180}
                    onChange={event => setLongitude(event.target.value)}
                    value={longitude || "00.000000"}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button className="w-full mt-3" type="submit" disabled={saving}>
              <Trans>Create</Trans>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  )
}
