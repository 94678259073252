import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"
import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState
} from "recoil"
import { diameterProfilesState } from "common/recoil/atoms"

const diameterProfileState = atomFamily({
  key: "diameterProfile",
  default: selectorFamily({
    key: "diameterProfile/default",
    get:
      id =>
      ({ get }) =>
        id ? get(diameterProfilesState).find($ => $.id === id) : null
  })
})

export default function useDiameterProfilesState(diameterProfileId) {
  const [diameterProfile, setDiameterProfile] = useRecoilState(
    diameterProfileState(diameterProfileId)
  )
  const fetch = useFetch()

  const updateDiameterProfile = useRecoilCallback(
    () => newDiameterProfile => {
      fetch(`/diameter_profiles/${diameterProfileId}`, {
        method: "PATCH",
        body: {
          diameter_profile: newDiameterProfile
        }
      }).then(response => {
        if (response) {
          setDiameterProfile({
            ...diameterProfile,
            ...newDiameterProfile
          })
          toast.success(<Trans>Saved</Trans>)
        }
      })
    },
    [diameterProfileId]
  )

  return [diameterProfile, updateDiameterProfile]
}
