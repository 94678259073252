import { Trans } from "@lingui/macro"
import ErrorBoundary from "common/other/ErrorBoundary"
import Loading from "common/other/Loading"
import { Suspense, useEffect } from "react"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"

export default function Settings() {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname.split("/").filter($ => $).length === 1) {
      navigate("account")
    }
  }, [location, navigate])
  return (
    <>
      <h4 className="text-light mb-3">
        <Trans>Settings</Trans>
      </h4>
      <hr className="text-light" />
      <div className="d-flex">
        <NavLink
          to="account"
          className={({ isActive }) =>
            `text-light me-4 text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Account</Trans>
        </NavLink>
        <NavLink
          to="organization"
          className={({ isActive }) =>
            `text-light me-4 text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>Organization</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}
