import { useEffect, useRef, useState } from "react"
import { Transformer } from "react-konva"
import { useRecoilValue } from "recoil"
import { logsInRange } from "./Logs"
import { selectedLogState } from "./useEditorState"

export default function LogTransformer() {
  const transformerRef = useRef()
  const selectedLogId = useRecoilValue(selectedLogState)
  const [nodes, setNodes] = useState([])
  const logs = useRecoilValue(logsInRange)

  useEffect(() => {
    const transformer = transformerRef.current
    if (selectedLogId !== null) {
      setNodes([transformer.getLayer().findOne("#" + selectedLogId)])
    } else {
      setNodes([])
    }

    // return () => {
    //   setNodes([])
    // }
  }, [selectedLogId, logs])

  return selectedLogId !== null ? (
    <Transformer
      ref={transformerRef}
      anchorSize={8}
      anchorCornerRadius={8}
      anchorStroke="black"
      borderStrokeWidth={1}
      borderStroke="red"
      ignoreStroke={true}
      rotateEnabled={false}
      keepRatio={true}
      centeredScaling={false}
      shouldOverdrawWholeArea={true}
      nodes={nodes}
      enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
    />
  ) : null
}
