import {
  faExclamationTriangle,
  faRotate
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { Container, Row, Col, Alert, Button } from "react-bootstrap"

export default function ErrorPage({ error, errorInfo }) {
  // async function handleRefresh() {
  //   window
  //     .fetch(`${process.env.REACT_APP_TIMBETER_API}/logins/refresh_token`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`
  //       },
  //       body: JSON.stringify({
  //         refresh_token: refreshToken
  //       })
  //     })
  //     .then(response => response.json())
  //     .then(json => {
  //       setAccessTokenExpiresAt(
  //         (Number(json.created_at) + Number(json.expires_in)) * 1000
  //       )
  //       setAccessToken(json.access_token)
  //       setRefreshToken(json.refresh_token)
  //       window.location.reload()
  //     })
  // }

  // console.log(error, errorInfo)
  return (
    <Container className="position-absolute top-50 start-50 translate-middle text-center">
      <Row className="justify-content-center">
        <Col lg={6}>
          <Alert variant="light">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="3x"
              className="opacity-25 mb-3"
            />
            <p className="h5 fw-semibold text-muted">Something went wrong</p>
            <p className="py-2 text-muted">
              Our developers have been notified and are looking into it.
            </p>
            <Button
              className="mt-3"
              size="sm"
              onClick={() => {
                localStorage.removeItem("access_token")
                localStorage.removeItem("access_token_expires_at")
                window.location.reload()
              }}
            >
              <FontAwesomeIcon icon={faRotate} className="me-2" />
              <Trans>Reload</Trans>
            </Button>
          </Alert>
        </Col>
      </Row>
    </Container>
  )
}
