import { Badge, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTree,
  faCube,
  faCalendarAlt,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons"
import {
  storageStatuses,
  storageStatusesColors
} from "common/enums/storageStatuses"
import { useEffect, useRef, useState } from "react"
import MeasurementMarker from "./MeasurementMarker"
import { toast } from "react-toastify"
import { Trans } from "@lingui/macro"
import { Popup, useMap, Marker, Tooltip } from "react-leaflet"
import L from "leaflet"
import { storageExpandedState, markersState } from "common/recoil/atoms"
import { atomFamily, useSetRecoilState } from "recoil"
import useFetch from "common/hooks/useFetch"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"

export const storageMapMeasurementsState = atomFamily({
  key: "storageMapMeasurementsState",
  default: null
})

export const markerIcon = new L.icon({
  iconUrl: "/marker-icon.png",
  shadowUrl: "/marker-shadow.png",
  iconSize: [25, 41],
  shadowSize: [30, 41],
  shadowAnchor: [8, 40],
  popupAnchor: [0, -38],
  iconAnchor: [12, 40]
})

export default function StorageMarker({ storage, shouldOpenPopup = false }) {
  const [latitude] = useState(Number(storage.latitude))
  const [longitude] = useState(Number(storage.longitude))
  const fetch = useFetch()
  const setStorageExpanded = useSetRecoilState(storageExpandedState(storage.id))
  const setMarkers = useSetRecoilState(markersState)
  const formatVolume = useVolumeFormatter()
  const map = useMap()

  useEffect(() => {
    if (shouldOpenPopup && markerRef.current) {
      if (latitude && longitude) {
        map.flyTo([latitude, longitude], 16)
      }
    }
  }, [shouldOpenPopup, latitude, longitude, map])

  const markerRef = useRef(null)

  // useEffect(() => {
  //   if (open) {
  //     markerRef.current.openPopup()
  //   }
  // }, [open, map])

  return (
    storage.latitude &&
    storage.longitude && (
      <Marker
        icon={markerIcon}
        ref={markerRef}
        position={[storage.latitude, storage.longitude]}
      >
        <Tooltip permanent direction="top" offset={[0, -40]}>
          <small>{storage.name}</small>
        </Tooltip>
        <Popup>
          <div className="marker-header">
            <span
              onClick={() => {
                const storageRow = window.document.getElementById(storage.id)
                if (!storageRow) return
                storageRow.scrollIntoView()
                setStorageExpanded(true)
              }}
              className="fw-semibold text-primary link cursor-pointer me-2 fs-6"
            >
              {storage.name}
            </span>
            <Badge
              className="mb-1"
              style={{ fontSize: "12px" }}
              bg={storageStatusesColors[storage.storage_state_id]}
              text={
                storageStatusesColors[storage.storage_state_id] === "none" &&
                "dark"
              }
            >
              {storageStatuses[storage.storage_state_id] || "ENUM_ERROR"}
            </Badge>
          </div>
          <table className="mb-2">
            <tbody>
              <tr>
                <td colSpan="2"></td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="me-2 text-primary"
                  />
                </td>
                <td>
                  <span className="small">
                    {latitude.toFixed(5)}, {longitude.toFixed(5)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="me-2 text-primary"
                  />
                </td>
                <td className="small">
                  <span>{storage.updated_at.slice(0, 10)}</span>
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faTree}
                    className="me-2 text-primary"
                  />
                </td>
                <td className="small">
                  <span>{storage.logs_count}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon
                    icon={faCube}
                    className="me-2 text-primary"
                  />
                </td>
                <td>
                  <span className="small">{formatVolume(storage.volume)}</span>
                </td>
              </tr>
            </tbody>
          </table>
          {setMarkers && (
            <>
              <div className="d-grid gap-2">
                <Button
                  size="sm"
                  onClick={() => {
                    fetch(`/measurements/markers/storage/${storage.id}`).then(
                      fetchedMeasurements => {
                        const measurementsWithCoordinates =
                          fetchedMeasurements.filter(
                            m =>
                              m.latitude &&
                              m.latitude !== "0.0" &&
                              m.longitude &&
                              m.longitude !== "0.0"
                          )

                        if (
                          !fetchedMeasurements.length ||
                          !measurementsWithCoordinates.length
                        ) {
                          return toast.info(
                            <Trans>No measurements to show</Trans>
                          )
                        }

                        map.flyToBounds(
                          fetchedMeasurements.map(m => [
                            m.latitude,
                            m.longitude
                          ])
                        )

                        setMarkers(
                          fetchedMeasurements.map((measurement, index) => (
                            <MeasurementMarker
                              storageId={storage.id}
                              key={measurement.id}
                              id={measurement.id}
                              latitude={measurement.latitude}
                              longitude={measurement.longitude}
                            />
                          ))
                        )
                      }
                    )
                  }}
                >
                  <Trans>Show measurements</Trans>
                </Button>
              </div>
            </>
          )}
        </Popup>
      </Marker>
    )
  )
}
