import { t, Trans } from "@lingui/macro"
import { Card, Col, Form } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import { truckMeasurementLoadsState } from "common/recoil/atoms"
import { useEffect, useState } from "react"
import Select from "common/other/Select"

export default function TruckLoad({ load }) {
  const [loads, setLoads] = useRecoilState(
    truckMeasurementLoadsState(load.truck_measurement_id)
  )
  const woodQualities = useRecoilValue(woodQualitiesState)
  const woodAssortments = useRecoilValue(woodAssortmentsState)
  const woodTypes = useRecoilValue(woodTypesState)
  const [coefficient, setCoefficient] = useState(load.coefficient)
  const [width, setWidth] = useState(load.load_width)
  const [logLength, setLogLength] = useState(load.log_length)
  const [woodType, setWoodType] = useState(load.wood_type_id)
  const [woodQuality, setWoodQuality] = useState(load.wood_quality_id)
  const [woodAssortment, setWoodAssortment] = useState(
    load.wood_characteristic_id
  )

  useEffect(() => {
    setLoads(
      loads?.map($ => {
        if ($.id !== load.id) return $

        return {
          ...$,
          log_length: logLength,
          coefficient: coefficient,
          load_width: width,
          wood_type_id: woodType,
          wood_quality_id: woodQuality,
          wood_characteristic_id: woodAssortment
        }
      })
    )
    // eslint-disable-next-line
  }, [coefficient, width, logLength, woodType, woodQuality, woodAssortment])

  return (
    <Col md={12} xl={6}>
      <Card className="mb-4 border">
        <Card.Header className="text-center">
          <Trans>Load</Trans> {load.index + 1}
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Height</Trans>
            </Form.Label>
            <Form.Control disabled readOnly value={load.height} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Volume</Trans>
            </Form.Label>
            <Form.Control disabled readOnly value={load.volume} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Coefficient</Trans>
            </Form.Label>
            <Form.Control
              onChange={event => setCoefficient(event.target.value)}
              value={coefficient}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Width</Trans>
            </Form.Label>
            <Form.Control
              onChange={event => setWidth(event.target.value)}
              value={width}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Log length</Trans>
            </Form.Label>
            <Form.Control
              onChange={event => setLogLength(event.target.value)}
              value={logLength}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Wood type</Trans>
            </Form.Label>
            <Select
              onChange={event => setWoodType(event.value)}
              value={{
                value: woodType,
                label: woodTypes?.find(type => type.id === woodType).name
              }}
              options={woodTypes?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Quality</Trans>
            </Form.Label>
            <Select
              onChange={event => setWoodQuality(event.value)}
              value={{
                value: woodQuality,
                label: woodQualities?.find(
                  quality => quality.id === woodQuality
                )?.name
              }}
              options={woodQualities?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
              placeholder={t`Select...`}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Trans>Assortment</Trans>
            </Form.Label>
            <Select
              onChange={event => setWoodAssortment(event.value)}
              value={{
                value: woodAssortment,
                label: woodAssortments?.find(a => a.id === woodAssortment)?.name
              }}
              options={woodAssortments?.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
              placeholder="Select"
            />
          </Form.Group>
        </Card.Body>
      </Card>
    </Col>
  )
}
