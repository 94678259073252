import { t, Trans } from "@lingui/macro"
import { capitalize } from "lodash"
import { Form } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import DuplicateCase from "./DuplicateCase"
import useAnalyzeReportState from "./useAnalyzeReportState"
import { storagesListQuery } from "common/recoil/selectors"

export default function EditAnalyzeReport() {
  const { id } = useParams()
  const [analyzeReport] = useAnalyzeReportState(id)
  const storages = useRecoilValue(storagesListQuery)

  return (
    <>
      <h5>{analyzeReport.name}</h5>
      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Type</Trans>
        </Form.Label>
        <Form.Control
          readOnly
          disabled
          value={capitalize(analyzeReport.type_key)}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Status</Trans>
        </Form.Label>
        <Form.Control
          readOnly
          disabled
          value={capitalize(analyzeReport.status)}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Analysis period</Trans>
        </Form.Label>
        <Form.Control
          readOnly
          disabled
          value={`${new Date(
            analyzeReport.period_from
          ).toLocaleDateString()} — ${new Date(
            analyzeReport.period_to
          ).toLocaleDateString()}`}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Measurement types</Trans>
        </Form.Label>
        <Form.Control
          readOnly
          disabled
          value={
            analyzeReport.measurement_types.length
              ? analyzeReport.measurement_types
              : t`All`
          }
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Storages</Trans>
        </Form.Label>
        <Form.Control
          readOnly
          disabled
          value={analyzeReport.storage_ids
            .map(id => storages.find(s => s.id === id).name)
            .join(", ")}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Elapsed time</Trans>
        </Form.Label>
        <Form.Control
          readOnly
          disabled
          value={`${Math.floor(
            (new Date(analyzeReport.completed_at).getTime() -
              new Date(analyzeReport.started_at).getTime()) /
              60000
          )} min`}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>
          <Trans>Created at</Trans>
        </Form.Label>
        <Form.Control
          readOnly
          disabled
          value={new Date(analyzeReport.created_at).toLocaleString()}
        />
      </Form.Group>

      <Form.Label>
        <Trans>Cases</Trans>
      </Form.Label>
      {analyzeReport.cases.map(duplicateCase => (
        <DuplicateCase key={duplicateCase.id} duplicateCase={duplicateCase} />
      ))}
      {!analyzeReport?.cases || analyzeReport?.cases?.length === 0 ? (
        <p className="text-muted fw-medium">
          <Trans>No duplicate measurements detected</Trans>
        </p>
      ) : null}
    </>
  )
}
