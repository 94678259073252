import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import { measurementEditorState } from "./useEditorState"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { containerMeasurementsQuery } from "container-measurement/ContainerMeasurementRowList"
import { t } from "@lingui/macro"

export default function useNavigateContainerMeasurements() {
  const [{ id: measurementId, type: measurementType }, setMeasurementEditor] =
    useRecoilState(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )

  const getNextContainerMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const measurements = snapshot.getLoadable(
          containerMeasurementsQuery(measurement.container_id)
        ).contents

        if (
          measurements.findIndex($ => $.id === measurement.id) ===
          measurements.length - 1
        ) {
          window.alert(t`There are no more measurements in this container`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) < 3) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ],
            last: true
          }
        }
      },
    [measurement]
  )

  const getPreviousContainerMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const measurements = snapshot.getLoadable(
          containerMeasurementsQuery(measurement.container_id)
        ).contents

        if (measurements.findIndex($ => $.id === measurement.id) === 0) {
          window.alert(t`This is the latest measurement in the container`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) > 1) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ],
            last: true
          }
        }
      },
    [measurement]
  )

  const nextContainerMeasurement = () => {
    const next = getNextContainerMeasurement()
    // console.log(next)
    if (next === null) {
      // console.log("nonext")
      return
    }
    // if (next.last) setStoragePage($ => $ + 1)
    setMeasurementEditor($ => ({
      id: next.measurement.id,
      type: "container"
    }))
  }

  const previousContainerMeasurement = () => {
    const previous = getPreviousContainerMeasurement()
    if (previous === null && !previous?.measurement?.id) {
      return
    }
    setMeasurementEditor($ => ({
      id: previous.measurement.id,
      type: "container"
    }))
  }

  return {
    nextContainerMeasurement,
    previousContainerMeasurement
  }
}
