import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilState
} from "recoil"
import { querySelector } from "common/recoil/selectors"
import { featureEnabled } from "common/recoil/atoms"

export const analyzeReportsState = atom({
  key: "analyzeReports",
  default: selector({
    key: "analyzeReports/default",
    get: ({ get }) => {
      if (get(featureEnabled("duplicate_storage_measurements"))) {
        return get(querySelector("/analyze_reports"))
      }

      return []
    }
  })
})

const analyzeReportState = atomFamily({
  key: "analyzeReport",
  default: selectorFamily({
    key: "analyzeReport/default",
    get:
      id =>
      ({ get }) =>
        id ? querySelector(`/analyze_reports/${id}`) : null
  })
})

export default function useAnalyzeReportState(id) {
  const [analyzeReport, setAnalyzeReport] = useRecoilState(
    analyzeReportState(id)
  )

  return [analyzeReport, setAnalyzeReport]
}
