export default function currencyToSymbol(currency) {
  return (
    {
      EUR: "€",
      USD: "$",
      BRL: "R$",
      JPY: "¥"
    }[currency] || currency
  )
}
