import { useRef } from "react"
import { Line, Circle } from "react-konva"
import { EDIT, REFERENCE } from "common/enums/constants"
import { selector, useRecoilState, useRecoilValue } from "recoil"
import { referencesState } from "common/recoil/atoms"
import { editorSettingsState } from "."

export const referenceInPxSelector = selector({
  key: "referenceInPx",
  get: ({ get }) => {
    const references = get(referencesState)
    if (!references?.length) return 0
    const reference = references[0]
    const y = Number(reference.x2) - Number(reference.x1)
    const x = Number(reference.y2) - Number(reference.y1)

    return parseFloat(Math.sqrt(x * x + y * y))
  }
})

const referenceSelector = selector({
  key: "reference",
  get: ({ get }) => {
    try {
      const references = get(referencesState)
      return references[0]
    } catch {
      return null
    }
  }
})

export const referenceInCmSelector = selector({
  key: "referenceInCm",
  get: ({ get }) => {
    const references = get(referencesState)
    if (!references?.length) return 0
    const reference = references[0]

    return reference.size * 100
  }
})

export default function Reference() {
  const [references, setReferences] = useRecoilState(referencesState)
  const reference = useRecoilValue(referenceSelector)
  const lineRef = useRef(null)
  const anchor1Ref = useRef(null)
  const anchor2Ref = useRef(null)
  const mode = useRecoilValue(editorSettingsState("mode"))
  const edit = useRecoilValue(editorSettingsState("edit"))
  const showReference = useRecoilValue(editorSettingsState("showReference"))

  function moveLine() {
    const points = [
      anchor1Ref.current.x(),
      anchor1Ref.current.y(),
      anchor2Ref.current.x(),
      anchor2Ref.current.y()
    ]

    lineRef.current.points(points)
    lineRef.current.getLayer().batchDraw()
  }

  function updateLine() {
    setReferences(
      references.map(($, i) => {
        return {
          ...$,
          x1: anchor1Ref.current.x(),
          y1: anchor1Ref.current.y(),
          x2: anchor2Ref.current.x(),
          y2: anchor2Ref.current.y()
        }
      })
    )
  }

  return reference ? (
    <>
      <Line
        visible={showReference}
        ref={lineRef}
        points={[
          Number(reference.x1),
          Number(reference.y1),
          Number(reference.x2),
          Number(reference.y2)
        ]}
        stroke={"red"}
        strokeWidth={8}
      />

      {mode === EDIT && edit === REFERENCE && (
        <>
          <Circle
            ref={anchor1Ref}
            x={Number(reference.x1)}
            y={Number(reference.y1)}
            radius={64}
            fill="rgba(255, 255, 255, 50%)"
            strokeWidth={2}
            draggable="true"
            onDragMove={moveLine}
            onDragEnd={updateLine}
          />
          <Circle
            ref={anchor2Ref}
            x={Number(reference.x2)}
            y={Number(reference.y2)}
            radius={64}
            fill="rgba(255, 255, 255, 50%)"
            strokeWidth={2}
            draggable="true"
            onDragMove={moveLine}
            onDragEnd={updateLine}
          />
        </>
      )}
    </>
  ) : null
}
