import { Trans, t } from "@lingui/macro"
import { Alert, Button, Card, Form } from "react-bootstrap"
import {
  selectorFamily,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil"
import { useEffect, useState } from "react"
import { devicesQuery, devicesState } from "common/recoil/atoms"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faLock } from "@fortawesome/free-solid-svg-icons"
import { organizationPlanDate } from "billing/AdditionalFeatures"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"
import ResourceNotFoundAlert from "common/other/ResourceNotFoundAlert"

export const deviceState = selectorFamily({
  key: "deviceState",
  get:
    id =>
    ({ get }) =>
      id ? get(devicesState).find(device => device.id === id) : null,
  set:
    id =>
    ({ set }, updatedDevice) =>
      set(devicesState, $ =>
        $.map(device =>
          device.id === id
            ? {
                ...device,
                ...updatedDevice
              }
            : device
        )
      )
})

export default function EditDeviceCard() {
  const { id } = useParams()
  const [device, setDevice] = useRecoilState(deviceState(Number(id)))
  const [name, setName] = useState("")
  const [affiliation, setAffiliation] = useState("")
  const billingDate = useRecoilValue(organizationPlanDate)
  const fetch = useFetch()
  const navigate = useNavigate()
  const refreshDevices = useRecoilRefresher_UNSTABLE(devicesQuery)

  useEffect(() => {
    if (device?.name) {
      setName(device.name)
    }

    if (device?.affiliation) {
      setAffiliation(device.affiliation)
    }
  }, [device, id])

  function update() {
    fetch(`/devices/${id}`, {
      method: "PATCH",
      body: {
        device: {
          name,
          affiliation
        }
      }
    }).then(response => {
      if (response) {
        setDevice({
          ...device,
          ...{
            name,
            affiliation
          }
        })
        toast.success(<Trans>Saved</Trans>)
      }
    })
  }

  return device ? (
    <Card className="mb-3">
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit device</Trans>
      </Card.Header>
      <Card.Body>
        {device.locked && (
          <Alert variant="warning" className="text-center">
            <FontAwesomeIcon
              size="xl"
              icon={faLock}
              className="me-2 opacity-50"
            />
            <div className="mt-3">
              <Alert.Heading className="fs-6">
                <Trans>This device is locked</Trans>
              </Alert.Heading>
              <p className="small">
                <Trans>It will be automatically unlinked at</Trans>{" "}
                {new Date(billingDate).toLocaleDateString()}
              </p>
            </div>
          </Alert>
        )}
        <Form
          id="edit-device"
          onSubmit={event => {
            event?.preventDefault()
            update()
          }}
        >
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Linked at</Trans>
            </Form.Label>
            <Form.Control
              defaultValue={new Date(device.created_at).toLocaleString()}
              // plaintext
              disabled
              readOnly
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Email</Trans>
            </Form.Label>
            <Form.Control defaultValue={device?.user_email} disabled readOnly />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              value={name}
              onChange={event => setName(event?.target?.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Trans>Affiliation</Trans>
            </Form.Label>
            <Form.Control
              value={affiliation}
              onChange={event => setAffiliation(event?.target?.value)}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          style={{ borderColor: "transparent" }}
          onClick={() => navigate("/devices")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          variant="secondary"
          className="me-3"
          onClick={unlinkDevice}
        >
          <Trans>Unlink</Trans>
        </Button>
        <Button
          type="submit"
          form="edit-device"
          disabled={
            device?.name === name && device?.affiliation === affiliation
          }
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <ResourceNotFoundAlert />
  )

  function unlinkDevice() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/devices/" + device.id, {
        method: "DELETE"
      }).then(response => {
        if (response) {
          navigate("/devices")
          refreshDevices()
          toast.success(<Trans>Unlinked</Trans>)
        }
      })
    }
  }
}
