import { Trans } from "@lingui/macro"
import { selectorFamily, useRecoilValue } from "recoil"
import ContainerRow from "./ContainerRow"
import { querySelector } from "common/recoil/selectors"

export const containersByBillOfLadingIdQuery = selectorFamily({
  key: "containersByBillOfLadingId",
  get:
    billOfLadingId =>
    ({ get }) =>
      get(querySelector("/containers/containers?bol_id=" + billOfLadingId))
})

export default function ContainerRowList({ billOfLadingId }) {
  const containers = useRecoilValue(
    containersByBillOfLadingIdQuery(billOfLadingId)
  )

  return (
    <tr>
      <td colSpan="8" className="w-full p-0">
        <table style={{ marginTop: "-8px", width: "100%" }}>
          <thead className="bg-whitesmoke border-bottom border-top text-muted">
            <tr>
              <th>
                <div className="d-flex align-items-center">
                  <div style={{ width: "2.5rem" }} />
                  <Trans>Container name</Trans>
                </div>
              </th>
              <th>
                <Trans>Created at</Trans>
              </th>
              <th>
                <Trans>Log count</Trans>
              </th>
              <th>
                <Trans>Volume</Trans>
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {containers.map(container => (
              <ContainerRow key={container.id} container={container} />
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  )
}
