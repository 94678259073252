import { Card, Button, Table } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { useRecoilValue } from "recoil"
import { volumeFormulasState } from "common/recoil/atoms"
import useExportsState from "exports/useExportsState"
import { replaceObjectKeys } from "common/utils/normalizeJson"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel, faTable } from "@fortawesome/free-solid-svg-icons"
import { enabledFeaturesQuery } from "common/recoil/selectors"

export default function MeasurementLogsTable({ measurement }) {
  const volumeFormulas = useRecoilValue(volumeFormulasState)
  const { createLogByLogReport, createPreciseLogByLogReport } =
    useExportsState()
  const enabledFeatures = useRecoilValue(enabledFeaturesQuery)
  const preciseExportEnabled = enabledFeatures.includes(
    "precise_log_by_log_excel"
  )

  measurement = {
    ...measurement,
    logs_sheet: {
      logs: measurement.logs_sheet.logs,
      summary: replaceObjectKeys(measurement.logs_sheet.summary)
    }
  }

  return (
    <Card>
      <Card.Header className="d-flex flex-column flex-wrap gap-2">
        <div className="d-flex align-items-center">
          <span className="flex-grow-1">
            <FontAwesomeIcon icon={faTable} className="me-2" />
            <Trans>Logs diameters</Trans>
          </span>
          <small className="text-black-50">
            <Trans>
              {volumeFormulas
                ? volumeFormulas[measurement.volume_formula] ||
                  measurement.volume_formula ||
                  "–"
                : "Loading..."}{" "}
              volume formula
            </Trans>
          </small>
        </div>
      </Card.Header>
      <div className="p-2 d-flex align-items-center gap-2 border-bottom">
        <Button
          size="sm"
          className="w-full"
          onClick={() => createLogByLogReport(measurement.id)}
        >
          <FontAwesomeIcon icon={faFileExcel} className="me-2" />
          <Trans>Export to Excel</Trans>
        </Button>
        {preciseExportEnabled && (
          <Button
            className="w-full text-nowrap"
            size="sm"
            variant="info"
            onClick={() => createPreciseLogByLogReport(measurement.id)}
          >
            <FontAwesomeIcon icon={faFileExcel} className="me-2" />
            <Trans>Export to Excel precise</Trans>
          </Button>
        )}
      </div>
      <Table size="sm" className="caption-top">
        <thead>
          <tr>
            <th />
            <th>
              <Trans>Diameter</Trans>
            </th>
            <th>
              <Trans>Log length</Trans>
            </th>
            <th>
              <Trans>Count</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {measurement.logs_sheet.logs.map(($, i) => (
            <tr key={i}>
              <td />
              <td>
                {$.diameter}{" "}
                <small>{measurement.logs_sheet.summary["diameter-unit"]}</small>
              </td>
              <td>
                {$["log-length"]}{" "}
                <small>{measurement.logs_sheet.summary["length-unit"]}</small>
              </td>
              <td>{$.logs}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="table-info">
            <td className="p-2">
              <small>
                <Trans>Average</Trans>
              </small>
            </td>
            <td className="py-3">
              {measurement.logs_sheet.summary.average_diameter}{" "}
              <small>{measurement.logs_sheet.summary.diameter_unit}</small>
            </td>
            <td className="py-3">
              {measurement.logs_sheet.logs[0]["log-length"]}{" "}
              <small>{measurement.logs_sheet.summary.length_unit}</small>
            </td>
            <td className="py-3">
              {measurement.logs_sheet.summary.total_logs}{" "}
              <small>
                <Trans>in total</Trans>
              </small>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Card>
  )
}
