import { Trans } from "@lingui/macro"
import { Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { diameterProfilesState } from "common/recoil/atoms"
import DiameterProfileRow from "./DiameterProfileRow"

export default function DiameterProfilesTable() {
  const diameterProfiles = useRecoilValue(diameterProfilesState)

  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <Trans>Diameter profile</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...diameterProfiles]
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map($ => (
            <DiameterProfileRow key={$.id} diameterProfileId={$.id} />
          ))}
      </tbody>
    </Table>
  )
}
