import { faNoteSticky } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import {
  Form,
  Button,
  Card,
  InputGroup,
  Spinner,
  Row,
  Col
} from "react-bootstrap"
import useFetch from "common/hooks/useFetch"
import { t, Trans } from "@lingui/macro"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { measurementsStateFamily } from "common/recoil/atoms"
import {
  densityUnitSelector,
  referenceUnitSelector,
  weightUnitSelector
} from "common/recoil/selectors"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const handleWheel = event => {
  event.preventDefault()
}

export default function MeasurementMetadata({ measurement }) {
  const setMeasurement = useSetRecoilState(
    measurementsStateFamily(Number(measurement.id))
  )
  const [coefficient, setCoefficient] = useState(0)
  const referenceUnit = useRecoilValue(referenceUnitSelector)
  const weightUnit = useRecoilValue(weightUnitSelector)
  const densityUnit = useRecoilValue(densityUnitSelector)
  const [shipmentNumber, setShipmentNumber] = useState(
    measurement?.shipment_number || ""
  )
  const [weight, setWeight] = useState(measurement?.weight || "")
  const [weightDensity, setWeightDensity] = useState(
    measurement?.weight_density || ""
  )
  const fetch = useFetch()
  const [saving, setSaving] = useState(false)
  const [comment, setComment] = useState(measurement?.comment || "")

  async function handleSubmit(event) {
    event.preventDefault()
    updateMeasurement()
  }

  const updateMeasurement = () => {
    setSaving(true)
    fetch(`/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        shipment_number: shipmentNumber || undefined,
        weight: weight || undefined,
        weight_density: Number(weightDensity) || undefined,
        coefficient: coefficient || undefined,
        comment: comment || undefined
      }
    })
      .then(updatedMeasurement => {
        // console.log(updatedMeasurement)
        if (updatedMeasurement) {
          setMeasurement({
            ...measurement,
            ...updatedMeasurement
          })
          toast.success(<Trans>Your changes have been saved</Trans>)
        } else {
          toast.error(<Trans>Your changes could not be saved</Trans>)
        }
      })
      .finally(() => {
        setSaving(false)
      })
  }

  useEffect(() => {
    setCoefficient(measurement?.coefficient)
  }, [measurement])

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faNoteSticky} className="me-2" />
        <Trans>Measurement metadata</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="update-measurement-metadata" onSubmit={handleSubmit}>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Comment</Trans>
            </Form.Label>
            <Form.Control
              as="textarea"
              value={comment}
              onChange={event => setComment(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Shipment number</Trans>
            </Form.Label>
            <Form.Control
              defaultValue={measurement.shipment_number}
              onChange={event => setShipmentNumber(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight</Trans>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                onChange={event => setWeight(event.target.value)}
                className="form-control-append"
                value={weight}
                placeholder={t`Enter number`}
              />
              <InputGroup.Text>{weightUnit}</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight density</Trans>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                onChange={event => setWeightDensity(event.target.value)}
                className="form-control-append"
                value={weightDensity}
              />
              <InputGroup.Text>{densityUnit}</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Row>
            <Form.Group as={Col} className="mb-2">
              <Form.Label>
                <Trans>Weight per log</Trans>
              </Form.Label>
              <Form.Control readOnly disabled defaultValue={"–"} />
            </Form.Group>
            {measurement?.measurement_area?.length > 0 && (
              <Form.Group as={Col} className="mb-2">
                <Form.Label>
                  <Trans>Coefficient</Trans>
                </Form.Label>
                <input
                  type="number"
                  min={0}
                  max={1}
                  onFocus={e =>
                    e.currentTarget.addEventListener("wheel", handleWheel, {
                      passive: false
                    })
                  }
                  onBlur={e =>
                    e.target.removeEventListener("wheel", handleWheel)
                  }
                  step={0.0001}
                  className="form-control"
                  disabled={measurement?.logs?.length > 0}
                  value={coefficient}
                  onChange={event => {
                    setCoefficient(event.target.value)
                  }}
                />
              </Form.Group>
            )}
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>
                <Trans>Pile heights average</Trans>
              </Form.Label>
              <Form.Control readOnly disabled defaultValue={"–"} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>
                <Trans>Reference size</Trans>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className="form-control-append"
                  readOnly
                  disabled
                  defaultValue={
                    measurement.references.length &&
                    measurement.references[0].size
                  }
                />
                <InputGroup.Text>{referenceUnit}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Row>
          <div
            className={`d-grid mt-3 ${measurement.inventory_id && "d-none"}`}
          >
            <Button
              form="update-measurement-metadata"
              type="submit"
              disabled={saving}
            >
              {saving ? (
                <Spinner
                  size="sm"
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Trans>Save</Trans>
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}
