import { t, Trans } from "@lingui/macro"
import { Card, Table, Badge, Row, Col, Button } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { analyzeReportsState } from "./useAnalyzeReportState"
import { capitalize } from "lodash"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { Suspense } from "react"
import Loading from "common/other/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { featureEnabled } from "common/recoil/atoms"
import UpgradePlanCard from "common/other/UpgradePlanCard"

export default function AnalyzeReportsCard() {
  const navigate = useNavigate()
  const enabled = useRecoilValue(
    featureEnabled("duplicate_storage_measurements")
  )

  return enabled ? (
    <div>
      <h5 className="text-light d-flex align-items-center">
        <span className="flex-grow-1">
          <Trans>Duplicates detection</Trans>
        </span>
        <Button onClick={() => navigate("new")} disabled={!enabled}>
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          <Trans>New analysis</Trans>
        </Button>
      </h5>
      <Card>
        <Row className="g-0">
          <Col className="border-end">
            <AnalyzeReportsTable />
          </Col>
          <Col>
            <Card.Body>
              <Suspense fallback={<Loading color="dark" />}>
                <Outlet />
              </Suspense>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </div>
  ) : (
    <UpgradePlanCard
      feature={t`duplicate detection`}
      url={
        "https://timbeter.com/new-timbeters-feature-makes-it-possible-to-detect-duplicate-piles/"
      }
    />
  )
}

function AnalyzeReportsTable() {
  const reports = useRecoilValue(analyzeReportsState)
  let sortedPosts = [...reports]
  sortedPosts = sortedPosts?.sort((a, b) => {
    if (new Date(a.created_at) < new Date(b.created_at)) {
      return 1
    }

    if (new Date(a.created_at) > new Date(b.created_at)) {
      return -1
    }

    return 0
  })

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <Trans>Name</Trans>
          </th>
          <th>
            <Trans>Status</Trans>
          </th>
          <th>
            <Trans>Created at</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedPosts?.map($ => (
          <AnalyzeReportRow key={$.id} report={$} />
        ))}
      </tbody>
    </Table>
  )
}

function AnalyzeReportRow({ report }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const finished = report.status === "finished"

  const statusBadgeMap = {
    finished: "primary",
    created: "secondary",
    failed: "danger"
  }

  return (
    <tr
      className={`${finished && "cursor-pointer"} ${
        id === report.id && "bg-primary text-light"
      }`}
      onClick={() => finished && navigate(report.id.toString())}
    >
      <td>{report.name}</td>
      {/* <td className="text-nowrap">
        {new Date(report.period_from).toLocaleDateString()} -{" "}
        {new Date(report.period_to).toLocaleDateString()}
      </td> */}
      <td>
        <Badge bg={statusBadgeMap[report.status]}>
          {capitalize(report.status)}
        </Badge>
      </td>
      <td>{new Date(report.created_at).toLocaleDateString()}</td>
    </tr>
  )
}
