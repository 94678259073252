import { Trans, t } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { useState } from "react"
import { Button, Card, Form, InputGroup } from "react-bootstrap"
import { useRecoilState } from "recoil"
import { lumberProductsState } from "."
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import ResourceNotFoundAlert from "common/other/ResourceNotFoundAlert"

export default function EditLumberProductCard() {
  const { id } = useParams()
  const [lumberProducts, setLumberProducts] =
    useRecoilState(lumberProductsState)
  const lumberProduct = lumberProducts?.find($ => $.id === Number(id))
  const [name, setName] = useState(lumberProduct?.name || "")
  const [length, setLength] = useState(lumberProduct?.plank_length || "")
  const [width, setWidth] = useState(lumberProduct?.plank_width || "")
  const [height, setHeight] = useState(lumberProduct?.plank_height || "")
  const [saving, setSaving] = useState(false)
  const fetch = useFetch()
  const navigate = useNavigate()

  return lumberProduct ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit lumber product</Trans>
      </Card.Header>

      <Card.Body>
        <Form id="edit-lumber-product" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              required
              value={name}
              onChange={$ => setName($.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Length</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                required
                value={length}
                onChange={$ => setLength($.target.value)}
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Width</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                required
                value={width}
                onChange={$ => setWidth($.target.value)}
              />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Trans>Height</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                required
                value={height}
                onChange={$ => setHeight($.target.value)}
              />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          className="float-start"
          style={{ borderColor: "transparent" }}
          variant="outline-secondary"
          onClick={() => navigate("/timber/lumber_products")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          className="me-3"
          variant="danger"
          onClick={handleDelete}
          disabled={saving}
        >
          <Trans>Delete</Trans>
        </Button>
        <Button
          form="edit-lumber-product"
          variant="primary"
          type="submit"
          disabled={saving}
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <ResourceNotFoundAlert />
  )

  function handleDelete() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/lumber_products/" + lumberProduct.id, {
        method: "DELETE"
      }).then(response => {
        if (response) {
          navigate("/timber/lumber_products")
          setLumberProducts($ => $.filter(lp => lp.id !== lumberProduct.id))
          toast.success(<Trans>Deleted</Trans>)
        }
      })
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    const updatedLumberProduct = {
      name,
      plank_length: length,
      plank_width: width,
      plank_height: height
    }
    setSaving(true)
    fetch("/lumber_products/" + lumberProduct.id, {
      method: "PATCH",
      body: updatedLumberProduct
    })
      .then(response => {
        if (response) {
          setLumberProducts($ =>
            $.map(lp =>
              lp.id === lumberProduct.id
                ? { ...lp, ...updatedLumberProduct }
                : lp
            )
          )
          toast.success(<Trans>Saved</Trans>)
        }
      })
      .finally(() => setSaving(false))
  }
}
