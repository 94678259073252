import { t, Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap"
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from "recoil"
import { InputGroupSeparator } from "timber-measurement/TimberMeasurementRow"
import { shipmentsState } from "./ShipmentsTable"
import useFetch from "common/hooks/useFetch"
import ReactDatePicker from "react-datepicker"
import Select from "common/other/Select"
import measurementTypesEnum from "common/enums/measurementTypes"
import {
  woodAssortmentsState,
  woodQualitiesState,
  woodTypesState
} from "common/recoil/atoms"
import { devicesListQuery, shipmentsListQuery } from "common/recoil/selectors"

export const shipmentsLoadingState = atom({
  key: "shipmentsLoadingState",
  default: false
})

export default function Filters() {
  const fetch = useFetch()
  const [filtering, setFiltering] = useRecoilState(shipmentsLoadingState)
  const [lengthStart, setLengthStart] = useState("")
  const [lengthEnd, setLengthEnd] = useState("")
  const [volumeStart, setVolumeStart] = useState("")
  const [volumeEnd, setVolumeEnd] = useState("")
  const [createdStart, setCreatedStart] = useState()
  const [createdEnd, setCreatedEnd] = useState()
  const [measuredStart, setMeasuredStart] = useState()
  const [measuredEnd, setMeasuredEnd] = useState()
  const [takenStart, setTakenStart] = useState()
  const [takenEnd, setTakenEnd] = useState()
  const [shipmentNumber, setShipmentNumber] = useState()
  const [shipmentIDs, setShipmentIDs] = useState([])
  const [measurementTypes, setMeasurementTypes] = useState([])
  const [woodTypes, setWoodTypes] = useState([])
  const [woodQualities, setWoodQualities] = useState([])
  const [woodCharacteristics, setWoodCharacteristics] = useState([])
  const [devicesIDs, setDevicesIDs] = useState([])
  const [measurementIDs, setMeasurementIDs] = useState([])
  // const [tokens, setTokens] = useState([])
  const setShipments = useSetRecoilState(shipmentsState)
  const resetShipments = useResetRecoilState(shipmentsState)
  const woodTypeList = useRecoilValue(woodTypesState)
  const woodQualitiesList = useRecoilValue(woodQualitiesState)
  const woodAssortmentList = useRecoilValue(woodAssortmentsState)
  const devicesList = useRecoilValue(devicesListQuery)
  const shipmentsList = useRecoilValue(shipmentsListQuery)

  return (
    <>
      <Card bg="light" body>
        <div className="gap-3 d-flex flex-column">
          <Row>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Length range</Trans>
              </Form.Label>
              <InputGroup style={{ flexWrap: "nowrap" }}>
                <Form.Control
                  placeholder={t`Min`}
                  value={lengthStart}
                  onChange={event => setLengthStart(event.target.value)}
                />
                <InputGroupSeparator />
                <Form.Control
                  placeholder={t`Max`}
                  value={lengthEnd}
                  onChange={event => setLengthEnd(event.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Volume range</Trans>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder={t`Min`}
                  value={volumeStart}
                  onChange={event => setVolumeStart(event.target.value)}
                />
                <InputGroupSeparator />
                <Form.Control
                  placeholder={t`Max`}
                  value={volumeEnd}
                  onChange={event => setVolumeEnd(event.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Created between</Trans>
              </Form.Label>
              <InputGroup style={{ flexWrap: "nowrap" }}>
                <ReactDatePicker
                  placeholderText="..."
                  wrapperClassName="datepicker"
                  dateFormat="dd.MM.yyyy"
                  className="form-control no-focus cursor-pointer rounded-0 rounded-start"
                  selected={createdStart}
                  onChange={date => setCreatedStart(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                  }}
                />
                <InputGroupSeparator />
                <ReactDatePicker
                  placeholderText={t`Today`}
                  wrapperClassName="datepicker"
                  dateFormat="dd.MM.yyyy"
                  className="form-control no-focus cursor-pointer rounded-0 rounded-end"
                  selected={createdEnd}
                  onChange={date => setCreatedEnd(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Measured between</Trans>
              </Form.Label>
              <InputGroup style={{ flexWrap: "nowrap" }}>
                <ReactDatePicker
                  placeholderText="..."
                  wrapperClassName="datepicker"
                  dateFormat="dd.MM.yyyy"
                  className="form-control no-focus cursor-pointer rounded-0 rounded-start"
                  selected={measuredStart}
                  onChange={date => setMeasuredStart(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                  }}
                />
                <InputGroupSeparator />
                <ReactDatePicker
                  placeholderText={t`Today`}
                  wrapperClassName="datepicker"
                  dateFormat="dd.MM.yyyy"
                  className="form-control no-focus cursor-pointer rounded-0 rounded-end"
                  selected={measuredEnd}
                  onChange={date => setMeasuredEnd(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Taken between</Trans>
              </Form.Label>
              <InputGroup style={{ flexWrap: "nowrap" }}>
                <ReactDatePicker
                  placeholderText="..."
                  wrapperClassName="datepicker"
                  dateFormat="dd.MM.yyyy"
                  className="form-control no-focus cursor-pointer rounded-0 rounded-start"
                  selected={takenStart}
                  onChange={date => setTakenStart(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                  }}
                />
                <InputGroupSeparator />
                <ReactDatePicker
                  placeholderText={t`Today`}
                  wrapperClassName="datepicker"
                  dateFormat="dd.MM.yyyy"
                  className="form-control no-focus cursor-pointer rounded-0 rounded-end"
                  selected={takenEnd}
                  onChange={date => setTakenEnd(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Shipment numbers</Trans>
              </Form.Label>
              <Select
                placeholder={<Trans>All</Trans>}
                isMulti
                value={shipmentIDs}
                onChange={event => setShipmentIDs(event)}
                options={shipmentsList?.map(({ id, number }) => ({
                  value: id,
                  label: number
                }))}
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Measurement types</Trans>
              </Form.Label>
              <Select
                placeholder={<Trans>All</Trans>}
                isMulti
                value={measurementTypes}
                onChange={event => setMeasurementTypes(event)}
                options={Object.entries(measurementTypesEnum).map(
                  ([id, type]) => ({
                    value: id,
                    label: type
                  })
                )}
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Wood types</Trans>
              </Form.Label>
              <Select
                placeholder={<Trans>All</Trans>}
                isMulti
                onChange={event => setWoodTypes(event)}
                value={woodTypes}
                options={woodTypeList?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Wood qualities</Trans>
              </Form.Label>
              <Select
                placeholder={<Trans>All</Trans>}
                isMulti
                onChange={event => setWoodQualities(event)}
                value={woodQualities}
                options={woodQualitiesList?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Wood characteristics</Trans>
              </Form.Label>
              <Select
                placeholder={<Trans>All</Trans>}
                isMulti
                value={woodCharacteristics}
                onChange={event => setWoodCharacteristics(event)}
                options={woodAssortmentList?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Devices</Trans>
              </Form.Label>
              <Select
                isMulti
                placeholder={t`Select device`}
                onChange={event => setDevicesIDs(event)}
                value={devicesIDs}
                options={devicesList?.map(
                  ({ id, hw_id, name, user_email }) => ({
                    value: id,
                    label: `${name} ${user_email ? `(${user_email})` : ``}`
                  })
                )}
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Measurement IDs</Trans>
              </Form.Label>
              <Select
                isMulti
                creatable
                placeholder={t`Enter measurement ID`}
                onChange={event => {
                  setMeasurementIDs(event)
                }}
                value={measurementIDs}
                options={[]}
              />
            </Form.Group>
            {/* <Form.Group as={Col} xs="12" md="6" lg="4" className="mb-2">
              <Form.Label>
                <Trans>Tokens</Trans>
              </Form.Label>
              <Form.Control />
            </Form.Group> */}
          </Row>
          <Col xs="12" className="gap-2 d-flex">
            <Button
              variant="dark"
              disabled={filtering}
              type="submit"
              className="text-nowrap"
              onClick={filterShipments}
            >
              {filtering ? <Trans>Loading...</Trans> : <Trans>Search</Trans>}
            </Button>
            <Button
              disabled={filtering}
              className="mx-2"
              variant="outline-secondary"
              onClick={clearFilters}
            >
              <Trans>Clear</Trans>
            </Button>
          </Col>
        </div>
      </Card>
      <hr className="text-light" />
    </>
  )

  function filterShipments(event) {
    event && event.preventDefault()
    setFiltering(true)

    fetch(
      `/dashboard_filter/truck_diameter_measurements?${new URLSearchParams({
        ...(measurementTypes?.length && {
          measurement_type_ids: measurementTypes.map($ => $.value)
        }),
        ...(woodQualities?.length && {
          wood_quality_ids: woodQualities.map($ => $.value)
        }),
        ...(woodTypes?.length && {
          wood_type_ids: woodTypes.map($ => $.value)
        }),
        ...(woodCharacteristics?.length && {
          wood_characteristic_ids: woodCharacteristics.map($ => $.value)
        }),
        ...(devicesIDs?.length && {
          device_ids: devicesIDs.map($ => $.value)
        }),
        ...(lengthStart && { length_range_start: lengthStart }),
        ...(lengthEnd && { length_range_end: lengthEnd }),
        ...(volumeStart && { volume_range_start: volumeStart }),
        ...(volumeEnd && { volume_range_end: volumeEnd }),
        ...(createdStart && {
          created_at_start: new Intl.DateTimeFormat("et-EE").format(
            createdStart
          )
        }),
        ...(createdEnd && {
          created_at_end: new Intl.DateTimeFormat("et-EE").format(createdEnd)
        }),
        ...(measuredStart && {
          measured_at_start: new Intl.DateTimeFormat("et-EE").format(
            measuredStart
          )
        }),
        ...(measuredEnd && {
          measured_at_end: new Intl.DateTimeFormat("et-EE").format(createdEnd)
        }),
        ...(takenStart && {
          taken_at_start: new Intl.DateTimeFormat("et-EE").format(takenStart)
        }),
        ...(takenEnd && {
          taken_at_end: new Intl.DateTimeFormat("et-EE").format(takenEnd)
        }),
        ...(shipmentNumber && {
          shipment_number: shipmentNumber
        }),
        ...(shipmentIDs?.length && {
          shipment_ids: shipmentIDs?.map($ => $.value)
        }),
        ...(devicesIDs?.length && {
          device_ids: devicesIDs?.map($ => $.value)
        }),
        ...(measurementIDs?.length && {
          measurement_ids: measurementIDs?.map($ => $.value)
        })
        // ...(tokens?.length && {
        //   tokens: tokens
        // })
        // ...searchableCustomFieldNames
      })}`
    )
      .then(response => {
        setShipments(response || [])
      })
      .finally(() => setFiltering(false))
  }

  function clearFilters() {
    setLengthStart("")
    setLengthEnd("")
    setVolumeStart("")
    setVolumeEnd("")
    setCreatedStart("")
    setCreatedEnd("")
    setMeasuredStart("")
    setMeasuredEnd("")
    setTakenStart("")
    setTakenEnd("")
    setShipmentNumber("")
    setShipmentIDs([])
    setMeasurementTypes([])
    setWoodTypes([])
    setWoodQualities([])
    setWoodCharacteristics([])
    setDevicesIDs([])
    setMeasurementIDs([])
    // setTokens([])
    resetShipments()
  }
}
