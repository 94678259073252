import { Badge } from "react-bootstrap"
import useWoodCullState from "./useWoodCullState"
import { useNavigate } from "react-router-dom"

export default function WoodCullRow({ woodCullId }) {
  const [woodCull] = useWoodCullState(woodCullId)
  const navigate = useNavigate()

  return (
    <tr onClick={() => navigate(woodCullId.toString())}>
      <td
        style={{
          maxWidth: "6rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
        title={woodCull.reason}
      >
        {woodCull.reason}{" "}
        {woodCull.archived && <Badge bg="secondary">Archived</Badge>}
      </td>
    </tr>
  )
}
