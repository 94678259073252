import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"

export default function useAuthActions() {
  const fetch = useFetch()
  const logOut = ({ sessionExpired = false }) => {
    fetch("/logout", {
      method: "POST"
    }).then(() => {
      if (sessionExpired) {
        toast.warn(<Trans>Your session has expired</Trans>)
      }
      localStorage.removeItem("access_token")
      localStorage.removeItem("access_token_expires_at")
      window.location.assign("/login")
    })
  }

  return {
    logOut
  }
}
