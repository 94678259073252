import Loading from "common/other/Loading"
import { memo, useCallback, useEffect, useMemo, useRef } from "react"
import { Stage, Image, Layer } from "react-konva"
// import Konva from "konva"
import useImage from "use-image"
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from "recoil"
import { measurementEditorState, selectedLogState } from "./useEditorState"
import Reference, {
  referenceInCmSelector,
  referenceInPxSelector
} from "./Reference"
import { editorSettingsState } from "."
import { EDIT, LOGS, MEASUREMENT_AREA, PLANKS } from "common/enums/constants"
import useShapesActions from "./useShapesActions"
import Logs from "./Logs"
import Planks from "./Planks"
import LoadLines from "./LoadLines"
import Polygon from "./Polygon"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import LogTransformer from "./LogTransformer"
import { debounce } from "lodash"
// Konva.pixelRatio = 1

function Canvas() {
  const { id, type: measurementType } = useRecoilValue(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({ id, type: measurementType })
  )
  const { type } = useRecoilValue(measurementEditorState)
  const canvasRef = useRef(null)
  const setSelectedLog = useSetRecoilState(selectedLogState)
  const resetSelectedLog = useResetRecoilState(selectedLogState)
  const Bridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
  const [img] = useImage(
    measurement.photo_optimized || measurement.photo || measurement.image
  )
  const referenceInPixels = useRecoilValue(referenceInPxSelector)
  const referenceInCm = useRecoilValue(referenceInCmSelector)
  const getEdit = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(editorSettingsState("edit")).contents
  )
  const getMode = useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(editorSettingsState("mode")).contents
  )
  const imageRef = useRef()
  const layerRef = useRef()

  const { addPlank, addLog, getSelectedLog, getLogs } = useShapesActions()
  useEffect(() => {
    if (img) {
      const widthScale = window.innerWidth / img.width
      const heightScale = (window.innerHeight - 148) / img.height
      const scale = widthScale < heightScale ? widthScale : heightScale
      canvasRef?.current.scale({
        x: scale,
        y: scale
      })
      imageRef.current.cache()
    }

    return imageRef?.current?.clearCache()
  }, [img])

  const onKeypress = event => {
    const stage = canvasRef?.current
    if (stage) {
      const scale = stage.scaleX()
      if (event.code === "NumpadSubtract") {
        stage.scale({ x: scale / 1.1, y: scale / 1.1 })
      } else if (event.code === "NumpadAdd") {
        stage.scale({ x: scale * 1.1, y: scale * 1.1 })
      }
    }
  }

  const handleWheel = debounce(e => {
    const stage = canvasRef.current
    const scale = stage.scaleX()
    const scaleBy = 1.25

    e.evt.preventDefault()
    let pointer = stage.getPointerPosition()

    let mousePointTo = {
      x: (pointer.x - stage.x()) / scale,
      y: (pointer.y - stage.y()) / scale
    }

    let newScale = e.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy

    if (newScale > 10 || newScale < 0.25) {
      return
    }

    // if (newScale > window.innerWidth / img.width) {
    let newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale
    }
    stage.position(newPos)
    stage.scale({ x: newScale, y: newScale })
    // }
  }, 5)

  const handleKeyPress = event => {
    if (event.keyCode === 37) {
      if (document.getElementById("previous-measurement")) {
        document.getElementById("previous-measurement").click()
      }
    } else if (event.keyCode === 39) {
      if (document.getElementById("next-measurement")) {
        document.getElementById("next-measurement").click()
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    window.document.addEventListener("keypress", onKeypress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
      window.document.removeEventListener("keydown", onKeypress)
    }
  }, [])

  const zoomIn = () => {
    const stage = canvasRef?.current
    const scale = stage?.scaleX()
    stage?.scale({ x: scale * 1.1, y: scale * 1.1 })
  }
  const zoomOut = () => {
    const stage = canvasRef?.current
    const scale = stage?.scaleX()
    stage?.scale({ x: scale * 0.9, y: scale * 0.9 })
  }
  const resetZoom = useCallback(() => {
    if (img) {
      const widthScale = window.innerWidth / img.width
      const heightScale = (window.innerHeight - 148) / img.height
      const scale = widthScale < heightScale ? widthScale : heightScale
      canvasRef?.current?.scale({
        x: scale,
        y: scale
      })
      canvasRef?.current?.position({
        x: 0,
        y: 0
      })
    }
  }, [img, canvasRef])

  useEffect(() => {
    window.addEventListener("zoom_in", zoomIn)
    window.addEventListener("zoom_out", zoomOut)
    window.addEventListener("reset_zoom", resetZoom)

    return () => {
      window.removeEventListener("zoom_in", zoomIn)
      window.removeEventListener("zoom_out", zoomOut)
      window.removeEventListener("reset_zoom", resetZoom)
    }
    // eslint-disable-next-line
  }, [resetZoom])

  const LogsComponent = useMemo(() => {
    if (
      type === "timber" ||
      type === "container" ||
      type === "truck_diameter"
    ) {
      return (
        <>
          <Logs />
          <LogTransformer />
        </>
      )
    }
    return null
  }, [type])

  return img ? (
    <Stage
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight}
      onWheel={handleWheel}
      onClick={handleClick}
      onDblClick={handleDblClick}
      draggable
    >
      {/* <StrictMode> */}
      <Bridge>
        <Layer ref={layerRef}>
          <Image ref={imageRef} image={img} />
          {measurement.references?.length !== 0 && <Reference />}
          {LogsComponent}
          {type === "lumber" && <Planks />}
          {type === "truck" && <LoadLines />}
          {type === "timber" && measurement?.measurement_area && <Polygon />}
        </Layer>
      </Bridge>
      {/* </StrictMode> */}
    </Stage>
  ) : (
    <Loading color="white" />
  )

  function handleDblClick(event) {
    if (getEdit() === MEASUREMENT_AREA) return
    if (getMode() !== EDIT) return

    const pointerPosition = canvasRef.current.getPointerPosition()
    const x =
      (pointerPosition.x - canvasRef.current.x()) / canvasRef.current.scaleX()
    const y =
      (pointerPosition.y - canvasRef.current.y()) / canvasRef.current.scaleX()
    if (getEdit() === LOGS && event.target.__proto__.className === "Image") {
      addLog({
        d: Number(
          Math.round(180 / (referenceInPixels / referenceInCm) + "e+2") + "e-2"
        ),
        "default-assortment": true,
        r: 90,
        x: Math.round(x),
        y: Math.round(y),
        ...(type === "container" && { qr_code: null })
      })
    } else if (getEdit() === PLANKS) {
      addPlank({
        width: 128,
        height: 48,
        x: x - 64,
        y: y - 24
      })
    }
  }

  function handleClick(event) {
    if (getEdit() === MEASUREMENT_AREA) return
    if (
      getMode() === EDIT &&
      (type === "timber" || type === "container" || type === "truck_diameter")
    ) {
      if (event.target.__proto__.className === "Circle") {
        if (getSelectedLog() !== null) {
          resetSelectedLog()
        }
        const logs = getLogs()
        for (let i = 0; i <= logs.length; i++) {
          if (
            logs[i].x === event.target.attrs.x &&
            logs[i].y === event.target.attrs.y
          ) {
            setSelectedLog(i)
            break
          }
        }
        return
      }
      resetSelectedLog()
    }
  }
}

export default memo(Canvas)
