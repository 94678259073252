import { t, Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Image } from "react-bootstrap"

export default function MeasurementPhotoSquare({
  measurement,
  onClick,
  thumbnail = false,
  style = {}
}) {
  const [photoMissing, setPhotoMissing] = useState(false)

  useEffect(() => {
    if (measurement?.image?.includes("missing")) {
      setPhotoMissing(true)
    }
  }, [measurement])

  return photoMissing ? (
    <div
      style={style}
      className="p-2 text-wrap bg-whitesmoke border rounded d-flex align-items-center justify-content-center user-select-none"
    >
      <span className="small opacity-50">
        {photoMissing && <Trans>No photo</Trans>}
      </span>
    </div>
  ) : (
    <Image
      alt={t`Open measurement editor`}
      title={t`Open measurement editor`}
      thumbnail={thumbnail}
      onClick={onClick}
      src={
        measurement?.photo_optimized ||
        measurement?.photo_thumb ||
        measurement?.image
      }
      onError={() => setPhotoMissing(true)}
      className="hover-dark"
      style={{
        maxWidth: 256,
        maxHeight: 256,
        cursor: "pointer",
        objectFit: "cover",
        ...style
      }}
    />
  )
}
