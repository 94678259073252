import { useRecoilState, useRecoilValue } from "recoil"
import { measurementEditorState } from "./useEditorState"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { t } from "@lingui/macro"

export default function useNavigateDuplicateMeasurements() {
  const [
    {
      id: measurementId,
      type: measurementType,
      duplicateCaseMeasurements = []
    },
    setMeasurementEditor
  ] = useRecoilState(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )
  const nextDuplicateMeasurement = () => {
    const index = duplicateCaseMeasurements.indexOf(Number(measurement.id))
    if (index === duplicateCaseMeasurements?.length - 1) {
      return window.alert(t`This is the last measurement in the case`)
    }
    if (index >= 0 && index < duplicateCaseMeasurements.length - 1) {
      setMeasurementEditor($ => ({
        id: duplicateCaseMeasurements[index + 1] + "",
        type: "timber",
        duplicateCaseMeasurements: duplicateCaseMeasurements
      }))
    }
  }

  const previousDuplicateMeasurement = () => {
    const index = duplicateCaseMeasurements.indexOf(Number(measurement.id))
    if (index === 0) {
      return window.alert(t`This is the first measurement in the case`)
    }
    if (index >= 0 && index < duplicateCaseMeasurements.length - 1) {
      setMeasurementEditor($ => ({
        id: duplicateCaseMeasurements[index - 1] + "",
        type: "timber",
        duplicateCaseMeasurements: duplicateCaseMeasurements
      }))
    }
  }

  return {
    nextDuplicateMeasurement,
    previousDuplicateMeasurement
  }
}
