import { faChevronDown, faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { useEffect, useState } from "react"
import {
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup
} from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import useCustomFieldState from "./useCustomFieldState"
import { toast } from "react-toastify"

export default function EditCustomFieldCard() {
  const { id } = useParams()
  const [customField, setCustomField] = useCustomFieldState(id)
  const [name, setName] = useState("")
  const [values, setValues] = useState([])
  const [archived, setArchived] = useState("")
  const [searchable, setSearchable] = useState(customField.searchable)
  const navigate = useNavigate()
  const fetch = useFetch()

  useEffect(() => {
    if (customField?.name) setName(customField.name)
    if (customField?.values) setValues(customField.values)
    if (customField?.archived !== undefined) setArchived(customField.archived)
  }, [customField])

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit custom field</Trans>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Name</Trans>
            </Form.Label>
            <Form.Control
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Key</Trans>
            </Form.Label>
            <Form.Control readOnly disabled value={customField.key} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Source key</Trans>
            </Form.Label>
            <Form.Control readOnly disabled value={customField.source_key} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Data type</Trans>
            </Form.Label>
            <Form.Control readOnly disabled value={customField.data_type} />
          </Form.Group>

          <Form.Group>
            <Form.Check
              onChange={() => setSearchable(!searchable)}
              checked={searchable}
              label={<Trans>Searchable</Trans>}
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              onChange={() => setArchived(!archived)}
              checked={archived}
              label={<Trans>Archived</Trans>}
            />
          </Form.Group>
          {customField.data_type === "string" ||
          customField.data_type === "integer" ||
          customField.data_type === "decimal" ? (
            <div className="mt-3">
              {values?.length !== 0 && (
                <Form.Label>
                  <Trans>Edit values</Trans>
                </Form.Label>
              )}
              {values.map(value => (
                <EditCustomFieldValueForm key={value.value} value={value} />
              ))}
              <Form.Label className="mt-3">
                <Trans>Create new value</Trans>
              </Form.Label>
              <AddCustomFieldValueForm />
            </div>
          ) : null}
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          style={{ borderColor: "transparent" }}
          className="float-start"
          onClick={() => navigate("/custom_fields")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          onClick={() =>
            setCustomField({
              name,
              archived,
              searchable
            })
          }
          disabled={
            name === customField.name &&
            archived === customField.archived &&
            searchable === customField.searchable
          }
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  )

  function EditCustomFieldValueForm({ value }) {
    const [name, setName] = useState("")
    const [rank, setRank] = useState("")
    const [archived, setArchived] = useState("")

    useEffect(() => {
      if (value?.value !== undefined) setName(value.value)
      if (value?.rank !== undefined) setRank(value.rank)
      if (value?.archived !== undefined) setArchived(value.archived)
    }, [value])

    const isDisabled = () => {
      return (
        value.value === name &&
        value.rank === rank &&
        value.archived === archived
      )
    }

    return (
      <InputGroup key={value.id} className="mb-2">
        <FormControl
          value={name}
          onChange={event => setName(event.target.value)}
        />
        <DropdownButton
          variant="secondary"
          title={
            <>
              <Trans>Rank</Trans> {rank}
              <FontAwesomeIcon icon={faChevronDown} className="ms-1" />
            </>
          }
        >
          {values.map((value, index) => (
            <Dropdown.Item onClick={() => setRank(index + 1)} key={index}>
              {index + 1}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Button
          variant="outline-secondary"
          onClick={() => setArchived(!archived)}
        >
          <Trans>{archived ? "Unarchive" : "Archive"}</Trans>
        </Button>
        <Button disabled={isDisabled()} onClick={() => saveValue()}>
          <Trans>Save</Trans>
        </Button>
      </InputGroup>
    )

    function saveValue() {
      fetch(`/custom_fields/values/${value.id}`, {
        method: "PATCH",
        body: {
          custom_field_value: {
            value: name,
            rank,
            archived
          }
        }
      }).then(response => {
        if (!response.error) {
          toast.success(<Trans>Saved</Trans>)
          setValues(
            values.map($ => {
              if ($.id !== value.id) return $

              return {
                ...value,
                value: name,
                rank,
                archived
              }
            })
          )
        }
      })
    }
  }

  function AddCustomFieldValueForm() {
    const [name, setName] = useState("")
    const isDisabled = () => name === ""

    return (
      <InputGroup>
        <FormControl
          value={name}
          onChange={event => setName(event.target.value)}
        />
        <Button disabled={isDisabled()} onClick={() => addValue()}>
          <Trans>Add value</Trans>
        </Button>
      </InputGroup>
    )

    function addValue() {
      fetch(`/custom_fields/${customField.id}/values`, {
        method: "POST",
        body: {
          custom_field_value: {
            value: name,
            rank: values.length + 1
          }
        }
      }).then(response => {
        if (!response.error) {
          setValues([...values, response])
          toast.success(<Trans>Added</Trans>)
        }
      })
    }
  }
}
