import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { useState } from "react"
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  ListGroup,
  Modal,
  Row
} from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { subscriptionExistsSelector } from "./TimbeterPro"
import Spinner from "common/other/Spinner"

export default function StartTrial() {
  const fetch = useFetch()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [timbeterAmount, setTimbeterAmount] = useState(1)
  const [containerAmount, setContainerAmount] = useState(0)
  const [lumberAmount, setLumberAmount] = useState(0)
  const [logCounterAmount, setLogCounterAmount] = useState(0)
  const total =
    timbeterAmount + containerAmount + lumberAmount + logCounterAmount
  const subscriptionExists = useRecoilValue(subscriptionExistsSelector)
  const [confirming, setConfirming] = useState(false)
  const confirmTrial = () => {
    const line_items = []
    setConfirming(true)
    if (timbeterAmount) {
      line_items.push({
        quantity: timbeterAmount,
        package_id: "pro_subscription"
      })
    }
    if (containerAmount) {
      line_items.push({
        quantity: containerAmount,
        package_id: "container_subscription"
      })
    }
    if (lumberAmount) {
      line_items.push({
        quantity: lumberAmount,
        package_id: "lumber_subscription"
      })
    }
    if (logCounterAmount) {
      line_items.push({
        quantity: logCounterAmount,
        package_id: "log_counter_subscription"
      })
    }

    fetch("/checkout/subscription", {
      method: "POST",
      body: {
        trial: true,
        period: 1,
        line_items
      }
    })
      .then(response => {
        window.location.assign(response.redirect_url)
      })
      .finally(() => setConfirming(false))
  }

  return subscriptionExists ? null : (
    <>
      <Button
        onClick={() => setShow(true)}
        variant="info"
        className="ms-3 fw-medium bg-gradient"
      >
        <Trans>Start free 7-day trial</Trans>
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>
            <Trans>Start free 7-day trial</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert className="text-muted">
            <Trans>
              You can select among 4 products and use 3 different devices.
            </Trans>
          </Alert>
          <ListGroup variant="flush">
            <ListGroup.Item className="py-4">
              <Row>
                <Col className="pe-0" xs="auto">
                  <img
                    src="/logos/timber.svg"
                    width="40"
                    className="rounded align-self-center img-fluid"
                    alt="Timbeter"
                  />
                </Col>
                <Col>
                  <b>Timbeter</b>
                  <br />
                  <small className="text-muted">
                    <Trans>
                      The easiest solution for measuring roundwood digitally
                    </Trans>
                  </small>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="light"
                    className="fw-medium bg-white border-0"
                    disabled
                  >
                    {timbeterAmount}{" "}
                    {timbeterAmount === 1 ? (
                      <Trans>device</Trans>
                    ) : (
                      <Trans>devices</Trans>
                    )}
                  </Button>
                  <ButtonGroup size="sm">
                    <Button
                      disabled={total === 3}
                      onClick={() => setTimbeterAmount($ => $ + 1)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                      disabled={timbeterAmount < 1}
                      onClick={() => setTimbeterAmount($ => $ - 1)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className="py-4">
              <Row>
                <Col className="pe-0" xs="auto">
                  <img
                    src="/logos/container.svg"
                    width="40"
                    className="rounded align-self-center img-fluid"
                    alt="Timbeter"
                  />
                </Col>
                <Col>
                  <b>Container</b>
                  <br />
                  <small className="text-muted">
                    <Trans>
                      Essential digital solution to accurately measure timber
                      logs in containers
                    </Trans>
                  </small>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="light"
                    className="fw-medium bg-white border-0"
                    disabled
                  >
                    {containerAmount}{" "}
                    {containerAmount === 1 ? (
                      <Trans>device</Trans>
                    ) : (
                      <Trans>devices</Trans>
                    )}
                  </Button>
                  <ButtonGroup size="sm">
                    <Button
                      disabled={total === 3}
                      onClick={() => setContainerAmount($ => $ + 1)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                      disabled={containerAmount < 1}
                      onClick={() => setContainerAmount($ => $ - 1)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className="py-4">
              <Row>
                <Col className="pe-0" xs="auto">
                  <img
                    src="/logos/lumber.svg"
                    width="40"
                    className="rounded align-self-center img-fluid"
                    alt="Timbeter"
                  />
                </Col>
                <Col>
                  <b>Lumber</b>
                  <br />
                  <small className="text-muted">
                    <Trans>Our detection solution for processed wood</Trans>
                  </small>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="light"
                    className="fw-medium bg-white border-0"
                    disabled
                  >
                    {lumberAmount}{" "}
                    {lumberAmount === 1 ? (
                      <Trans>device</Trans>
                    ) : (
                      <Trans>devices</Trans>
                    )}
                  </Button>
                  <ButtonGroup size="sm">
                    <Button
                      disabled={total === 3}
                      onClick={() => setLumberAmount($ => $ + 1)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                      disabled={lumberAmount < 1}
                      onClick={() => setLumberAmount($ => $ - 1)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className="py-4">
              <Row>
                <Col className="pe-0" xs="auto">
                  <img
                    src="/logos/log_counter.svg"
                    width="40"
                    className="rounded align-self-center img-fluid"
                    alt="Timbeter"
                  />
                </Col>
                <Col>
                  <b>Log Counter</b>
                  <br />
                  <small className="text-muted">
                    <Trans>
                      A simple tool for counting logs with your mobile
                    </Trans>
                  </small>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="light"
                    className="fw-medium bg-white border-0"
                    disabled
                  >
                    {logCounterAmount}{" "}
                    {logCounterAmount === 1 ? (
                      <Trans>device</Trans>
                    ) : (
                      <Trans>devices</Trans>
                    )}
                  </Button>
                  <ButtonGroup size="sm">
                    <Button
                      disabled={total === 3}
                      onClick={() => setLogCounterAmount($ => $ + 1)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                      disabled={logCounterAmount < 1}
                      onClick={() => setLogCounterAmount($ => $ - 1)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          {/* <p className="text-muted small">
            You will have to enter your credit card information to our payment
            processor Stripe. You will not be charged for the duration of the
            trial. If you don't cancel before the end of the trial you will be
            charged.
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            disabled={total === 0 || confirming}
            variant="primary"
            onClick={confirmTrial}
          >
            {confirming ? <Spinner /> : <Trans>Next</Trans>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
