import { Trans, t } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilCallback, useRecoilState } from "recoil"
import useFetch from "common/hooks/useFetch"
import useWoodCullState from "./useWoodCullState"
import { woodCullsState } from "common/recoil/atoms"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import ResourceNotFoundAlert from "common/other/ResourceNotFoundAlert"

export default function EditWoodCullCard() {
  const { id } = useParams()
  const [woodCull, setWoodCull] = useWoodCullState(Number(id))
  const [woodCullReason, setWoodCullReason] = useState("")
  const [woodCullMax, setWoodCullMax] = useState("")
  const [woodCullMin, setWoodCullMin] = useState("")
  const deleteWoodCull = useDeleteWoodCull(id)
  const fetch = useFetch()
  const navigate = useNavigate()

  useEffect(() => {
    if (woodCull?.reason) {
      setWoodCullReason(woodCull.reason)
    }

    if (woodCull?.max) {
      setWoodCullMax(woodCull.max)
    }

    if (woodCull?.min) {
      setWoodCullMin(woodCull.min)
    }
  }, [woodCull, id])

  return woodCull ? (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Edit wood cull</Trans>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Reason</Trans>
            </Form.Label>
            <Form.Control
              value={woodCullReason}
              onChange={event => setWoodCullReason(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Minimum diameter</Trans>
            </Form.Label>
            <Form.Control
              type="number"
              value={woodCullMin}
              onChange={event => setWoodCullMin(event?.target?.value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <Trans>Maximum diameter</Trans>
            </Form.Label>
            <Form.Control
              type="number"
              value={woodCullMax}
              onChange={event => setWoodCullMax(event?.target?.value)}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          variant="outline-secondary"
          className="float-start"
          style={{ borderColor: "transparent" }}
          onClick={() => navigate("/timber/culls")}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="secondary"
          className="me-3"
          onClick={() => {
            if (window.confirm(t`Are you sure?`)) {
              deleteWoodCull()
            }
          }}
        >
          <Trans>Archive</Trans>
        </Button>
        <Button
          onClick={() => {
            setWoodCull({
              ...(woodCullReason && { reason: woodCullReason }),
              ...(woodCullMax && {
                max: Number(woodCullMax)
              }),
              ...(woodCullMin && {
                min: Number(woodCullMin)
              })
            })
          }}
          disabled={
            woodCullReason === woodCull?.reason &&
            Number(woodCullMax) === Number(woodCull.max) &&
            Number(woodCullMin) === Number(woodCull.min)
          }
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Footer>
    </Card>
  ) : (
    <ResourceNotFoundAlert />
  )

  function useDeleteWoodCull(woodCullId) {
    const [woodCulls, setWoodCulls] = useRecoilState(woodCullsState)

    return useRecoilCallback(
      () => async () => {
        fetch(`/culls/${woodCullId}`, {
          method: "DELETE"
        }).then(response => {
          navigate("/timber/culls")
          setWoodCulls(woodCulls.filter($ => $.id !== Number(woodCullId)))
          toast.success(<Trans>Archived</Trans>)
        })
      },
      [woodCullId]
    )
  }
}
