import { referencesState } from "common/recoil/atoms"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { useRecoilCallback } from "recoil"
import { planksState } from "."
import { selectedPlankState } from "./Plank"
import { measurementEditorVolumeFormulaState } from "./ReferencePane"
import {
  logsState,
  measurementEditorState,
  selectedLogState
} from "./useEditorState"

export default function useShapesActions() {
  const addLog = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      log =>
        set(logsState, logs => [...logs, log]),
    []
  )

  const addPlank = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      plank =>
        set(planksState, planks => [...planks, plank]),
    []
  )

  const getPlanks = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      () =>
        snapshot.getLoadable(planksState).contents,
    []
  )

  const getLogs = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      () =>
        snapshot.getLoadable(logsState).contents,
    []
  )

  const getSelectedLog = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      () =>
        snapshot.getLoadable(selectedLogState).contents,
    []
  )

  const getSelectedPlank = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      () =>
        snapshot.getLoadable(selectedPlankState).contents,
    []
  )

  const logsChanged = useRecoilCallback(({ snapshot }) => () => {
    let changed = false
    const { id, type } = snapshot.getLoadable(measurementEditorState).contents
    const measurement = snapshot.getLoadable(
      measurementByIdAndTypeQuery({
        id,
        type
      })
    ).contents
    const logs = snapshot.getLoadable(logsState).contents
    const measurementLogs = measurement.logs || []

    if (measurementLogs.length !== logs?.filter($ => !$.isDeleted)?.length) {
      changed = true
    } else {
      for (let i = 0; i <= measurementLogs.length; i++) {
        if (!shallowEqual(measurementLogs[i], logs[i])) {
          changed = true
          break
        }
      }
    }

    return changed
  })

  const deleteLog = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      () =>
        set(logsState, logs =>
          logs.map((log, i) => {
            if (i === snapshot.getLoadable(selectedLogState).contents) {
              set(selectedLogState, null)
              // console.log("delete", log)
              return {
                ...log,
                isDeleted: true
              }
            }
            return log
          })
        ),
    []
  )

  const deletePlank = useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      () =>
        set(planksState, planks =>
          planks.map((plank, i) => {
            if (i === snapshot.getLoadable(selectedPlankState).contents) {
              set(selectedPlankState, null)
              // console.log("delete", plank)
              return {
                ...plank,
                isDeleted: true
              }
            }
            return plank
          })
        ),
    []
  )

  const referenceChanged = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        let changed = false
        const { id, type } = snapshot.getLoadable(
          measurementEditorState
        ).contents
        const measurement = snapshot.getLoadable(
          measurementByIdAndTypeQuery({
            id,
            type
          })
        ).contents
        const references = snapshot.getLoadable(referencesState).contents
        const measurementEditorVolumeFormula = snapshot.getLoadable(
          measurementEditorVolumeFormulaState
        ).contents

        // console.log(references, measurement.references)
        if (measurement.volume_formula !== measurementEditorVolumeFormula) {
          changed = true
        }

        if (measurement?.references?.length !== references?.length) {
          changed = true
        } else if (measurement?.references?.length) {
          if (!shallowEqual(measurement?.references[0], references[0])) {
            changed = true
          }
        }

        return changed
      },
    []
  )

  const planksChanged = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        let changed = false
        const { id, type } = snapshot.getLoadable(
          measurementEditorState
        ).contents
        const measurement = snapshot.getLoadable(
          measurementByIdAndTypeQuery({
            id,
            type
          })
        ).contents
        const planks = snapshot.getLoadable(planksState).contents

        if (
          measurement?.planks?.length !==
          planks?.filter($ => !$.isDeleted)?.length
        ) {
          // console.log(attributes.planks, planks)
          // console.log(
          //   measurement?.planks?.length,
          //   planks?.filter($ => !$.isDeleted)?.length
          // )
          changed = true
        } else if (measurement?.planks?.length) {
          for (let i = 0; i <= measurement.planks.length; i++) {
            if (!shallowEqual(measurement.planks[i], planks[i])) {
              changed = true
              break
            }
          }
        }

        return changed
      },
    []
  )

  return {
    addPlank,
    addLog,
    deleteLog,
    deletePlank,
    getSelectedLog,
    getSelectedPlank,
    getLogs,
    getPlanks,
    logsChanged,
    referenceChanged,
    planksChanged
  }
}

function shallowEqual(object1, object2) {
  if (!object1 && !object2) return true
  if (!object1 || !object2) return false
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}
