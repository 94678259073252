import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import { measurementEditorState } from "./useEditorState"
import { measurementByIdAndTypeQuery } from "common/recoil/selectors"
import { t } from "@lingui/macro"
import {
  lumberMeasurementsQuery,
  lumberStorageMeasurementsPageState
} from "lumber-measurement/LumberMeasurementsTable"

export default function useNavigateLumberMeasurements() {
  const [
    { id: measurementId, type: measurementType, storage: storageId },
    setMeasurementEditor
  ] = useRecoilState(measurementEditorState)
  const measurement = useRecoilValue(
    measurementByIdAndTypeQuery({
      id: measurementId,
      type: measurementType
    })
  )

  const [storagePage, setStoragePage] = useRecoilState(
    lumberStorageMeasurementsPageState(storageId)
  )

  const getNextLumberMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const { measurements, meta } = snapshot.getLoadable(
          lumberMeasurementsQuery({
            page: storagePage,
            storageId: storageId
          })
        ).contents

        // console.log(measurements, meta)

        if (
          storagePage === meta.total_pages &&
          measurements.findIndex($ => $.id === measurement.id) ===
            measurements.length - 1
        ) {
          window.alert(t`There are no more measurements`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) < 8) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) + 1
              ],
            last: true
          }
        }
      },
    [measurement, storagePage]
  )

  const getPreviousLumberMeasurement = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const { measurements } = snapshot.getLoadable(
          lumberMeasurementsQuery({
            page: storagePage,
            storageId
          })
        ).contents

        if (
          storagePage === 1 &&
          measurements.findIndex($ => $.id === measurement.id) === 0
        ) {
          window.alert(t`This is the latest measurement`)
          return null
        }

        if (measurements.findIndex($ => $.id === measurement.id) > 1) {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ]
          }
        } else {
          return {
            measurement:
              measurements[
                measurements.findIndex($ => $.id === measurement.id) - 1
              ],
            last: true
          }
        }
      },
    [measurement, storagePage]
  )

  const nextLumberMeasurement = () => {
    const next = getNextLumberMeasurement()
    if (next === null) {
      return
    }
    if (next.last) setStoragePage($ => $ + 1)
    setMeasurementEditor($ => ({
      id: next.measurement.id,
      type: "lumber",
      storage: storageId
    }))
  }

  const previousLumberMeasurement = () => {
    const previous = getPreviousLumberMeasurement()
    if (previous === null && !previous?.measurement?.id) {
      return
    }
    if (previous.last && storagePage > 1) {
      setStoragePage($ => $ - 1)
    }
    setMeasurementEditor($ => ({
      id: previous.measurement.id,
      type: "lumber",
      storage: storageId
    }))
  }

  return {
    nextLumberMeasurement,
    previousLumberMeasurement
  }
}
