import { Trans } from "@lingui/macro"
import { Col, Row } from "react-bootstrap"
import { NavLink, Outlet } from "react-router-dom"

export default function WoodCullsIndex() {
  return (
    <>
      <div className="d-flex flex-wrap">
        <NavLink
          to="/timber/culls"
          end
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>All wood culls</Trans>
        </NavLink>
        <NavLink
          to="/timber/culls/new"
          className={({ isActive }) =>
            `me-4 text-light text-decoration-none ${
              isActive ? "fw-semibold" : "fw-normal text-white-50"
            }`
          }
        >
          <Trans>New wood cull</Trans>
        </NavLink>
      </div>
      <hr className="text-light" />
      <Row>
        <Col xs="12" lg="8" xxl="6">
          <Outlet />
        </Col>
      </Row>
    </>
  )
}
