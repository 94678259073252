import { querySelector } from "common/recoil/selectors"
import { useEffect } from "react"
import { Marker, Polyline, Tooltip, useMap } from "react-leaflet"
import { selectorFamily, useRecoilValue } from "recoil"
import { start, finish } from "./markers"

const routeCheckpointsQuery = selectorFamily({
  key: "routeCheckpointsQuery",
  get:
    routeId =>
    ({ get }) =>
      get(querySelector(`/truck_routes/${routeId}/checkpoints`))
})

export const routePositions = selectorFamily({
  key: "routePositions",
  get:
    routeId =>
    ({ get }) =>
      get(routeCheckpointsQuery(routeId)).map($ => [
        Number($.latitude),
        Number($.longitude)
      ])
})

export default function RouteLine({ routeId }) {
  const positions = useRecoilValue(routePositions(routeId))
  const map = useMap()

  useEffect(() => {
    if (positions.length) {
      const somewhereInTheMiddle = positions[Math.floor(positions.length / 2)]
      map.setView(somewhereInTheMiddle, 12)
    }
  }, [positions, map])

  return (
    <>
      {positions.length && (
        <>
          <Polyline
            pathOptions={{
              color: "royalblue",
              dashArray: "1,5",
              opacity: 0.75
            }}
            positions={positions}
          />
          <Marker icon={start} position={positions[0]}>
            <Tooltip>Starting point</Tooltip>
          </Marker>
          <Marker icon={finish} position={positions[positions.length - 1]}>
            <Tooltip>Destination</Tooltip>
          </Marker>
        </>
      )}
    </>
  )
}
