import { Trans } from "@lingui/macro"
import { Table } from "react-bootstrap"
import { lumberProductsState } from "."
import { useRecoilValue } from "recoil"
import { useNavigate } from "react-router-dom"

export default function LumberProductsTable() {
  const lumberProducts = useRecoilValue(lumberProductsState)
  const navigate = useNavigate()

  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <Trans>Lumber product</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {lumberProducts?.length > 0 ? (
          lumberProducts.map($ => (
            <tr key={$.id} onClick={() => navigate($.id.toString())}>
              <td>{$.name}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={4}>
              <div className="my-3 text-center">
                <p className="text-black-50">
                  <Trans>No lumber products found</Trans>
                </p>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
