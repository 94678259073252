import { Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { toast } from "react-toastify"
import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState
} from "recoil"
import { woodCullsState } from "common/recoil/atoms"

const woodCullState = atomFamily({
  key: "woodCull",
  default: selectorFamily({
    key: "woodCull/default",
    get:
      id =>
      ({ get }) =>
        id ? get(woodCullsState).find($ => $.id === id) : null
  })
})

export default function useWoodCullState(woodCullId) {
  const [woodCull, setWoodCull] = useRecoilState(woodCullState(woodCullId))
  const fetch = useFetch()

  const updateWoodCull = useRecoilCallback(
    () => newWoodCull => {
      fetch(`/culls/${woodCullId}`, {
        method: "PATCH",
        body: {
          cull: newWoodCull
        }
      }).then(response => {
        if (!response) {
          return toast.error(<Trans>Failed to save changes</Trans>)
        }
        setWoodCull({
          ...woodCull,
          ...newWoodCull
        })
        toast.success(<Trans>Saved</Trans>)
      })
    },
    [woodCullId]
  )

  return [woodCull, updateWoodCull]
}
