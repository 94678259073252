import { Trans, t } from "@lingui/macro"
import { FormControl, InputGroup, Table } from "react-bootstrap"
import UserRow from "./UserRow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { useRecoilValue } from "recoil"
import { usersState } from "common/recoil/atoms"
import { useEffect, useState } from "react"

export default function UsersTable() {
  const users = useRecoilValue(usersState)
  const [emailFilter, setEmailFilter] = useState("")
  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    setFilteredUsers(users?.filter($ => $.email.includes(emailFilter)))
  }, [users, emailFilter])
  return (
    <>
      <InputGroup className="rounded-3 border mb-3">
        <InputGroup.Text className="bg-white border-0">
          <FontAwesomeIcon icon={faSearch} className="opacity-25" />
        </InputGroup.Text>

        <FormControl
          value={emailFilter}
          onChange={$ => setEmailFilter($?.target?.value.trim())}
          placeholder={t`Search...`}
          className="border-0 shadow-none ps-0"
        />
        {emailFilter ? (
          <InputGroup.Text
            onClick={() => setEmailFilter("")}
            className="bg-white border-0 cursor-pointer"
          >
            <FontAwesomeIcon
              size="sm"
              icon={faTimesCircle}
              className="opacity-25"
            />
          </InputGroup.Text>
        ) : null}
      </InputGroup>

      <Table hover responsive>
        <thead>
          <tr>
            <th>
              <Trans>Users</Trans>
              <small className="text-black-50 float-end">
                {filteredUsers?.length} <Trans>users found</Trans>
              </small>
            </th>
          </tr>
        </thead>
        {filteredUsers.length > 0 && (
          <tbody>
            {filteredUsers.map(user => (
              <UserRow key={user.id} user={user} />
            ))}
          </tbody>
        )}
        {filteredUsers.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={4} className="text-center">
                No users found
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  )
}
