import { Trans } from "@lingui/macro"
import { Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { billsOfLadingState } from "common/recoil/atoms"
import BillOfLadingRow from "./BillOfLadingRow"
import ContainerToolbar from "./Toolbar"

export default function BillOfLadingRowList() {
  const bills = useRecoilValue(billsOfLadingState)

  return (
    <>
      <ContainerToolbar />
      <Table
        className="storagesTable"
        borderless
        responsive="xxl"
        style={{ borderSpacing: "0 8px" }}
      >
        <thead className="text-light">
          <tr>
            <td colSpan="2" className="bg-transparent text-light">
              <div className="d-flex align-items-center">
                <div style={{ width: "2rem" }} />
                <span className="ms-2">
                  <Trans>Bill of lading</Trans>
                </span>
              </div>
            </td>
            <td className="bg-transparent text-light">
              <Trans>Contract</Trans>
            </td>
            <td className="bg-transparent text-light">
              <Trans>Log count</Trans>
            </td>
            <td className="bg-transparent text-light">
              <Trans>Volume</Trans>
            </td>
            <td className="bg-transparent text-light">
              <Trans>Container count</Trans>
            </td>
          </tr>
        </thead>
        <tbody>
          {bills.map(bill => (
            <BillOfLadingRow key={bill.id} billOfLading={bill} />
          ))}
          {(!bills || !bills?.length) && (
            <tr className="bg-whitesmoke">
              <td colSpan="7" className="text-center text-muted">
                <Trans>No bill of ladings found</Trans>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
