import { Trans } from "@lingui/macro"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { toast } from "react-toastify"

export default function Protected({ children }) {
  const location = useLocation()
  const accessToken = localStorage.getItem("access_token")
  const accessTokenExpiresAt = localStorage.getItem("access_token_expires_at")
  const accessTokenFromCookie = document.cookie
    .split(";")
    .find($ => $.includes("timbeter_dashboard_token="))
    ?.split("=")[1]
  const accessTokenExpiresAtFromCookie = document.cookie
    .split(";")
    .find($ => $.includes("timbeter_dashboard_token_expires_at="))
    ?.split("=")[1]

  if (!accessToken) {
    if (accessTokenFromCookie) {
      localStorage.setItem("access_token", accessTokenFromCookie)
      localStorage.setItem(
        "access_token_expires_at",
        accessTokenExpiresAtFromCookie * 1000
      )
      return children ?? <Outlet />
    }

    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (accessTokenExpiresAt - Date.now() < 0) {
    toast.warn(<Trans>Your session has expired</Trans>, {
      autoClose: 3000
    })
    localStorage.removeItem("access_token")
    localStorage.removeItem("access_token_expires_at")
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children ?? <Outlet />
}
