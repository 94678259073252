import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import { Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useRecoilState } from "recoil"
import { timberFilterState } from "common/recoil/atoms"

export default function FilterCustomField({ customField }) {
  const [field, setField] = useRecoilState(timberFilterState(customField.key))

  return (
    <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
      <OverlayTrigger
        overlay={
          <Tooltip>
            <Trans>This is a custom field</Trans>
          </Tooltip>
        }
      >
        <Form.Label>
          {customField.data_type !== "boolean" && (
            <>
              <Trans>{customField.name}</Trans>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ms-1 opacity-50"
              />
            </>
          )}
        </Form.Label>
      </OverlayTrigger>
      {customField.values?.length ? (
        <Form.Select onChange={event => setField(event.target.value)}>
          <option key="placeholder" hidden value>
            {t`Select`} {customField.name.toLowerCase()}...
          </option>
          {customField.values.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
      ) : customField.data_type === "boolean" ? (
        <Form.Check
          checked={field}
          onChange={() => setField(!field)}
          label={
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <Trans>This is a custom field</Trans>
                </Tooltip>
              }
            >
              <Form.Label>
                <Trans>{customField.name}</Trans>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ms-1 opacity-50"
                />
              </Form.Label>
            </OverlayTrigger>
          }
        />
      ) : (
        <Form.Control
          onChange={event => {
            if (
              (customField.data_type === "integer" ||
                customField.data_type === "decimal") &&
              isNaN(event.target.value)
            ) {
              return
            }

            setField(event.target.value)
          }}
          value={field}
          placeholder={t`Enter` + " " + customField.name.toLowerCase()}
        />
      )}
    </Form.Group>
  )
}
