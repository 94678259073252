import { t, Trans } from "@lingui/macro"
import { useEffect, useMemo, useState } from "react"
import { Badge, Button, Card, Form, InputGroup, Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { devicesState } from "common/recoil/atoms"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faSearch,
  faSort,
  faTabletAlt,
  faTimesCircle,
  faCaretDown,
  faCaretUp,
  faLock
} from "@fortawesome/free-solid-svg-icons"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"

export default function DevicesTable() {
  const [sorting, setSorting] = useState()
  const [globalFilter, setGlobalFilter] = useState("")
  const devices = useRecoilValue(devicesState)
  const columnHelper = createColumnHelper()
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: <Trans>Device name</Trans>,
        cell: info => (
          <>
            <span>{info.getValue() ? info.getValue() : "-"}</span>
            {Date.now() - new Date(info.row.original.created_at).getTime() <
              86400000 && (
              <Badge className="ms-2" bg="info">
                <Trans>New</Trans>
              </Badge>
            )}
            {info.row.original.locked && (
              <Badge className="ms-2" bg="warning">
                <FontAwesomeIcon icon={faLock} className="me-1" />
                <Trans>Locked</Trans>
              </Badge>
            )}
          </>
        ),
        footer: info => info.column.id
      }),
      columnHelper.accessor("last_used", {
        header: <Trans>Last used</Trans>,
        cell: info => {
          if (info.getValue()) {
            return new Date(info.getValue()).toLocaleString()
          }

          return "–"
        },
        footer: info => info.column.id
      }),
      columnHelper.accessor("affiliation", {
        header: <Trans>Affiliation</Trans>,
        cell: info => info?.getValue() || "",
        footer: info => info.column.id
      })
    ],
    [columnHelper]
  )
  const table = useReactTable({
    data: devices,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      global: globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: row => row?.original?.name?.includes(globalFilter)
  })
  const navigate = useNavigate()

  if (!devices?.length) {
    return (
      <Card body className="text-center">
        <FontAwesomeIcon
          icon={faTabletAlt}
          size="3x"
          className="opacity-25 my-3"
        />
        <div className="my-2">
          <p className="h5 fw-semibold text-muted">
            <Trans>No linked devices</Trans>
          </p>
          <p className="mt-2 text-muted">
            <Trans>There are no devices linked to your organization.</Trans>
          </p>
        </div>
        <Button
          onClick={() => navigate("/devices/new")}
          size="sm"
          className="my-3"
        >
          <FontAwesomeIcon icon={faPlus} className="me-2 opacity-50" />
          <Trans>Link device</Trans>
        </Button>
      </Card>
    )
  }

  return (
    <>
      <div className="d-flex mb-3">
        <InputGroup className="rounded-3 border d-inline-flex">
          <InputGroup.Text className="bg-white border-0">
            <FontAwesomeIcon icon={faSearch} className="opacity-25" />
          </InputGroup.Text>
          {/* <Form.Control
              value={globalFilter}
              onChange={$ => setGlobalFilter($?.target?.value)}
              placeholder={t`Search...`}
              className="border-0 shadow-none ps-0"
            /> */}
          <DebouncedFormControl
            value={globalFilter}
            onChange={$ => setGlobalFilter($)}
            placeholder={t`Search...`}
            className="border-0 shadow-none ps-0"
          />
          {globalFilter ? (
            <InputGroup.Text
              onClick={() => setGlobalFilter("")}
              className="bg-white border-0 cursor-pointer"
            >
              <FontAwesomeIcon
                size="sm"
                icon={faTimesCircle}
                className="opacity-25"
              />
            </InputGroup.Text>
          ) : null}
        </InputGroup>
      </div>

      <div className="text-white-50 mb-3">
        {table.getRowModel().rows.length} <Trans>devices found</Trans>
      </div>
      <Table hover responsive>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  <div
                    {...{
                      style: header.column.getCanSort()
                        ? {
                            cursor: "pointer",
                            userSelect: "none"
                          }
                        : null,
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: (
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          className="ms-2 opacity-50"
                        />
                      ),
                      desc: (
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          className="ms-2 opacity-50"
                        />
                      )
                    }[header.column.getIsSorted()] ?? (
                      <FontAwesomeIcon
                        icon={faSort}
                        className="ms-2 opacity-50"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              onClick={() => navigate("/devices/" + row.original.id)}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <tr className="text-center text-black-50">
              <td colSpan="5" className="py-3">
                <FontAwesomeIcon
                  icon={faSearch}
                  size="2x"
                  className="mb-2 opacity-50"
                />
                <p>
                  <Trans>No results found for</Trans> "{globalFilter}"
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}

function DebouncedFormControl({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [value])

  return (
    <Form.Control
      {...props}
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  )
}
