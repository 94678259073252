import { Trans } from "@lingui/macro"
import { Button, Card, Form } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import { useEffect, useState } from "react"
import Select from "common/other/Select"
import { featureEnabled } from "common/recoil/atoms"
import { useParams } from "react-router-dom"
import UpgradePlanCard from "common/other/UpgradePlanCard"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"
import { deviceState } from "./EditDeviceCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faKey } from "@fortawesome/free-solid-svg-icons"
import { storagesListQuery } from "common/recoil/selectors"

export default function EditDeviceAccessCard() {
  const { id } = useParams()
  const [device, setDevice] = useRecoilState(deviceState(Number(id)))
  const [connectedStoragesIds, setConnectedStorageIds] = useState(
    device?.storage_ids
  )
  const [connectedStorages, setConnectedStorages] = useState("")
  const [connectedStorageDefaultId, setConnectedStorageDefaultId] = useState(
    device?.default_storage_id
  )
  const storages = useRecoilValue(storagesListQuery)
  const storageAccessManagementEnabled = useRecoilValue(
    featureEnabled("storage_access_management")
  )
  const fetch = useFetch()

  useEffect(() => {
    if (device?.storage_ids?.length > 0) {
      setConnectedStorages("custom")
    } else {
      setConnectedStorages("all")
    }
  }, [device, id])

  useEffect(() => {
    if (connectedStorages === "all") {
      setConnectedStorageIds([])
    }
  }, [connectedStorages])

  function update(event) {
    event.preventDefault()
    fetch(`/devices/${id}`, {
      method: "PATCH",
      body: {
        device: {
          storage_ids: connectedStoragesIds,
          ...(connectedStoragesIds.length !== 0 &&
            connectedStorageDefaultId !== "" && {
              default_storage_id: Number(connectedStorageDefaultId)
            })
        }
      }
    }).then(response => {
      if (response) {
        setDevice({
          ...device,
          ...{
            storage_ids: connectedStoragesIds,
            ...(connectedStoragesIds.length !== 0 &&
              connectedStorageDefaultId !== "" && {
                default_storage_id: Number(connectedStorageDefaultId)
              })
          }
        })
        toast.success(<Trans>Device updated</Trans>)
      }
    })
  }

  return device ? (
    <Card className="shadow-sm">
      <Card.Header>
        <FontAwesomeIcon icon={faKey} className="me-2" />
        <Trans>Device access management</Trans>
      </Card.Header>
      {storageAccessManagementEnabled ? (
        <>
          <Card.Body>
            <Form id="edit-device-access" onSubmit={update}>
              <Form.Group className="mb-2">
                <Form.Check
                  checked={connectedStorages === "all"}
                  type={"radio"}
                  id={"radio"}
                  disabled={!storageAccessManagementEnabled}
                  label={<Trans>All storages</Trans>}
                  onChange={() => setConnectedStorages("all")}
                />
                <Form.Check
                  checked={connectedStorages === "custom"}
                  type={"radio"}
                  id={"radio"}
                  disabled={!storageAccessManagementEnabled}
                  label={
                    <>
                      <Trans>Choose custom</Trans>
                    </>
                  }
                  onChange={() => setConnectedStorages("custom")}
                />
              </Form.Group>
              {connectedStorages === "custom" && (
                <>
                  <Form.Group className="my-2">
                    <Form.Label>Connected storages</Form.Label>
                    <Select
                      isMulti
                      value={connectedStoragesIds
                        .map(id => storages.find(storage => storage.id === id))
                        .map(_ => ({ value: _.id, label: _.name }))}
                      onChange={event =>
                        setConnectedStorageIds(event.map($ => $.value))
                      }
                      options={storages?.map(storage => ({
                        value: storage.id,
                        label: storage.name
                      }))}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Default storage</Form.Label>
                    <Form.Select
                      value={connectedStorageDefaultId}
                      onChange={event =>
                        setConnectedStorageDefaultId(event?.target?.value)
                      }
                    >
                      <option key="placeholder" hidden value>
                        Choose default
                      </option>
                      {connectedStoragesIds
                        .map(id => storages.find(storage => storage.id === id))
                        .map($ => (
                          <option key={$.id} value={$.id}>
                            {$.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </>
              )}
            </Form>
          </Card.Body>
          <Card.Footer className="text-end">
            <Button
              type="submit"
              form="edit-device-access"
              disabled={
                device.storage_ids === connectedStoragesIds &&
                device.default_storage_id === connectedStorageDefaultId
              }
            >
              <Trans>Save</Trans>
            </Button>
          </Card.Footer>
        </>
      ) : (
        <UpgradePlanCard className="rounded-0 rounded-bottom" />
      )}
    </Card>
  ) : null
}
