import { Trans } from "@lingui/macro"
import { Link } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  featureEnabled,
  measurementCustomFieldsSelector,
  measurementsStateFamily
} from "common/recoil/atoms"
import MeasurementCustomField from "./MeasurementCustomField"
import { Button, Card, Form, Spinner } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRectangleList } from "@fortawesome/free-solid-svg-icons"
import { measurementCustomFields } from "./MeasurementCustomField"
import { useState } from "react"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"

export default function MeasurementCustomFieldsCard({ measurement }) {
  const setMeasurement = useSetRecoilState(
    measurementsStateFamily(Number(measurement.id))
  )
  const customFields = useRecoilValue(measurementCustomFieldsSelector)
  const enabled = useRecoilValue(featureEnabled("custom_fields"))
  const getMeasurementCustomFields = useRecoilValue(measurementCustomFields)
  const [saving, setSaving] = useState(false)
  const fetch = useFetch()

  const handleSubmit = event => {
    event.preventDefault()
    setSaving(true)
    fetch(`/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        custom_fields: getMeasurementCustomFields
      }
    })
      .then(updatedMeasurement => {
        if (updatedMeasurement) {
          setMeasurement({
            ...measurement,
            ...updatedMeasurement
          })
          toast.success(<Trans>Your changes have been saved</Trans>)
        } else {
          toast.error(<Trans>Your changes could not be saved</Trans>)
        }
      })
      .finally(() => setSaving(false))
  }

  return enabled && customFields.filter($ => !$.archived).length !== 0 ? (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <div className="flex-grow-1">
          <FontAwesomeIcon icon={faRectangleList} className="me-2" />
          <Trans>Measurement custom fields</Trans>
        </div>
        <small>
          <Link to="/custom_fields" className="text-muted">
            <Trans>Edit</Trans>
          </Link>
        </small>
      </Card.Header>
      <Card.Body>
        <Form id="update-custom-fields" onSubmit={handleSubmit}>
          {customFields
            .filter($ => !$.archived)
            .map($ => {
              const cc = measurement?.custom_fields.find(c => c.key === $.key)

              return (
                <MeasurementCustomField
                  key={$.id}
                  customField={{ ...$, value: cc?.value }}
                />
              )
            })}
          <div className="d-grid mt-4">
            <Button form="update-custom-fields" type="submit" disabled={saving}>
              {saving ? (
                <Spinner
                  size="sm"
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Trans>Save</Trans>
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  ) : null
}
