import { EDIT, LOGS, MEASUREMENT_AREA, VIEW } from "common/enums/constants"
import { logsRangeState } from "common/recoil/atoms"
import { memo, useLayoutEffect, useMemo, useRef } from "react"
import { Group } from "react-konva"
import { selector, useRecoilValue } from "recoil"
import { editorSettingsState } from "."
import Log from "./Log"
import { logsState } from "./useEditorState"

export const logsInRange = selector({
  key: "logsInRange",
  get: ({ get }) => {
    const logs = get(logsState)
    const thumbs = get(logsRangeState)
    const mode = get(editorSettingsState("mode"))
    const isEditMode = mode === "EDIT"
    if (isEditMode) return logs
    // console.log(thumbs)
    if (logs.every($ => $?.d === 0)) {
      return logs.map(log => {
        return log.r * 2 >= thumbs[0] && log.r * 2 <= thumbs[1] ? log : null
      })
    }
    return logs.map(log =>
      log.d >= thumbs[0] && log.d <= thumbs[1] ? log : null
    )
  }
})

function Logs() {
  const logs = useRecoilValue(logsInRange)
  const mode = useRecoilValue(editorSettingsState("mode"))
  const edit = useRecoilValue(editorSettingsState("edit"))
  const showDiameters = useRecoilValue(editorSettingsState("showDiameters"))
  const VIEW_LOGS =
    (showDiameters && mode === VIEW) ||
    (mode === EDIT && edit === MEASUREMENT_AREA)
  const EDIT_LOGS = mode === EDIT && edit === LOGS
  const groupRef = useRef(null)
  const memoizedLogs = useMemo(
    () =>
      logs.map((log, index) =>
        log ? (
          <Log
            index={index}
            key={`${log.x}_${log.y}_${log.d}_${log.r}_${log?.qr_code || ""}`}
            log={log}
          />
        ) : null
      ),
    [logs]
  )

  useLayoutEffect(() => {
    if (mode === "EDIT" && edit !== MEASUREMENT_AREA) {
      groupRef?.current?.clearCache()
    } else if (logs?.length > 0) {
      groupRef?.current?.cache()
    }
  }, [mode, logs, edit])

  return (
    <Group
      name="logs"
      id="logs"
      ref={groupRef}
      visible={VIEW_LOGS || EDIT_LOGS}
      listening={EDIT_LOGS}
    >
      {memoizedLogs}
    </Group>
  )
}

export default memo(Logs)
